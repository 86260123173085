import React from "react"
import { AsyncNoOpFunctionType, NoOpFunctionType } from "src/types"
import { setTimeout } from "timers"

function asyncUiDataFetch<DataType>(
  asyncFetchFn: () => Promise<DataType | undefined>,
  setStateFn?: (_: React.SetStateAction<DataType | undefined>) => void,
): Promise<DataType | undefined> {
  return asyncFetchFn().then(data => {
    if (data) {
      if (!setStateFn) {
        return data
      }
      setStateFn(data)
    }
  })
}

const noOpFn: NoOpFunctionType = () => {}
const asyncNoOpFn: AsyncNoOpFunctionType = () => {
  return Promise.resolve()
}

function delay(milliseconds = 3000): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export { asyncUiDataFetch, noOpFn, asyncNoOpFn, delay }
