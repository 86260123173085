import { Form } from "formik"
import React, { useState } from "react"
import { Helmet } from "react-helmet-async"
import * as yup from "yup"
import {
  Pages,
  Feedback,
  Grid,
  GridItem,
  Headings,
  Panels,
  StretchForm,
  Spacing,
  SubmitButton,
  TextInput,
} from "src/components"
import { useOnboardingContext } from "src/context/OnboardingContext"
import { useAppDispatch } from "src/hooks/redux"
import { thunkStartBankingOnboarding } from "src/store"
import { parseError } from "src/utilities/errorUtils"
import {
  didValuesChange,
  NAME_REGEX,
  NAME_REGEX_ERROR,
} from "src/utilities/formUtils"
import Disclaimer from "./Disclaimer"
import { useAppConfigContext } from "src/context/AppConfigContext"

const Name: React.FC = () => {
  const { appConfig } = useAppConfigContext()
  const signUpCodeRequired = appConfig?.signUpCodesEnabled !== false

  // Validations outlined in Highnote docs https://highnote.com/docs/reference/input_object/PersonAccountHolderNameInput
  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required("Please provide your first name")
      .max(255, "Your name cannot be longer than 255 characters")
      .matches(NAME_REGEX, NAME_REGEX_ERROR),
    lastName: yup
      .string()
      .required("Please provide your last name")
      .max(255, "Your name cannot be longer than 255 characters")
      .matches(NAME_REGEX, NAME_REGEX_ERROR),
    activationCode: signUpCodeRequired
      ? yup
          .string()
          .required("Please enter the activation code you were provided")
          .min(8,"The activation code must be 8 digits")
          .max(8,"The activation code must be 8 digits")
      : yup.string().optional(),
  })

  const dispatch = useAppDispatch()
  const [error, setError] = useState("")
  const [loading,setLoading] = useState(false)
  const { proceedToNextScreen, onboardingFormData } = useOnboardingContext()
  const initialValues = {
    firstName: onboardingFormData.firstName,
    lastName: onboardingFormData.lastName,
    activationCode: onboardingFormData.activationCode,
  }

  const handleSubmit = async (params: typeof initialValues) => {
    if (!didValuesChange(initialValues, params)) {
      proceedToNextScreen({})
      return
    }
    try {
      setLoading(true)
      await dispatch(thunkStartBankingOnboarding(params))
      setLoading(false)
      proceedToNextScreen(params)
    } catch (err) {
      setLoading(false)
      const { errorMessage } = parseError(err)
      setError(errorMessage)
      
    }
  }

  return (
    <Pages.FullPage>
      <Helmet>
        <title>Card Application | Stretch</title>
      </Helmet>
      <Panels.Default>
        <StretchForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          validateOnBlur
        >
          {({
            errors,
            values,
            handleChange,
            handleBlur,
            touched,
            isValid
          }) => {
            return (
              <Form>
                <div>
                  <Headings.H3>What&apos;s your name?</Headings.H3>
                  <Spacing.Horizontal />
                  <Grid>
                    <GridItem $span={6} $spanS={12}>
                      <TextInput
                        autoComplete="given-name"
                        name="firstName"
                        placeholder="First Name"
                        value={values.firstName}
                        error={touched.firstName ? errors.firstName : undefined}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        
                  
                      />
                    </GridItem>
                    <GridItem $span={6} $spanS={12}>
                      <TextInput
                        autoComplete="family-name"
                        name="lastName"
                        placeholder="Last Name"
                        value={values.lastName}
                        error={touched.lastName ? errors.lastName : undefined}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </GridItem>
                  </Grid>
                </div>
                {signUpCodeRequired ? (
                 /*Please note that the max length parameter only works when the inputType is text. 
                   Other input types do not seem to work.  This seems to mean that there is a tradeoff between
                   forcing numeric input and setting a maximum length.   Set inputMode to numeric to try
                   and force a numeric keypad.
                 */ 
                  <div>
                    <Headings.H3>What&apos;s your activation code?</Headings.H3>
                    <TextInput
                      placeholder="Enter your code"
                      name="activationCode"
                      maxLength={8}
                      value={values.activationCode}
                      error={
                        touched.activationCode
                          ? errors.activationCode
                          : undefined
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputMode="numeric"
                    />
                  </div>
                ) : null}
                <SubmitButton
                  label="Continue"
                  disabled={loading || !isValid}
                  loading={loading}
                />
                {!!error && <Feedback message={error} />}
              </Form>
            )
          }}
        </StretchForm>
      </Panels.Default>
      <Spacing.Horizontal />
      <Disclaimer />
    </Pages.FullPage>
  )
}

export default Name
