import { isDevelopment } from "src/config"
import { Address } from "src/types"

export function shouldVerifyAddress(address: Address): boolean {
  return !isDevelopment && !isSimulatedAddress(address)
}

function isSimulatedAddress(address: Address) {
  return (
    address.firstLine.toUpperCase() === "123 DECLINE ST." ||
    ["66666", "11111"].includes(address.postalCode)
  )
}
