import { Form } from "formik"
import React, { ChangeEvent, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useHistory, useParams } from "react-router-dom"
import { confirmEmail, requestEmailVerificationEmail } from "src/api"
import * as yup from "yup"
import {
  Card,
  Feedback,
  Headings,
  Message,
  StretchForm,
  SubmitButton,
  TextInput,
} from "src/components"
import { useAppDispatch } from "src/hooks/redux"
import { thunkFinishLoginUser } from "src/store"
import { VerificationCodePagePathParams } from "src/types"
import { parseError } from "src/utilities/errorUtils"
import {
  DASHBOARD_PAGE_ROUTE,
  redirectToLogin,
} from "src/utilities/routingUtils"

const validationSchema = yup.object({
  email: yup.string().email().required(""),
})

const GetVerified: React.FC = () => {
  const { code } = useParams<VerificationCodePagePathParams>()
  const [verificationError, setVerificationError] = useState("")

  const history = useHistory()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (code !== "new") {
      sendCode()
    }
  }, [])

  const sendCode = async () => {
    try {
      await confirmEmail(code)
    } catch (error) {
      const { errorField, errorMessage } = parseError(error)
      if (errorField === "email") {
        redirectToLogin(errorMessage)
      } else {
        setVerificationError(errorMessage)
      }

      return
    }
    try {
      await dispatch(thunkFinishLoginUser(code))
    } catch (err) {
      redirectToLogin(
        "Your email has been verified. Please log in to\u00A0continue.",
      )
      return
    }

    history.push(DASHBOARD_PAGE_ROUTE)
  }

  // Request Link

  const requestLink = (email: string) => {
    requestEmailVerificationEmail(email)
      .then(_ => {
        setSuccess(true)
      })
      .catch(error => {
        const { errorMessage } = parseError(error)
        if (
          errorMessage ===
          "Your account was already confirmed, please try signing in."
        ) {
          redirectToLogin(errorMessage)
        } else {
          setNewLinkError(errorMessage)
        }
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const [validationMessage, setValidationMessage] = useState("")
  const [submitting, setSubmitting] = useState(false)

  const [newLinkError, setNewLinkError] = useState("")
  const [success, setSuccess] = useState(false)

  return (
    <>
      <Helmet>
        <title>Email Verification – Stretch</title>
      </Helmet>
      <Card $page $fullPage>
        {/* eslint-disable-next-line no-extra-boolean-cast */}
        {!!verificationError || code == "new" ? (
          <>
            <Headings.H2>Verify Your Email</Headings.H2>
            <div className="mb-6"></div>
            {!!verificationError && (
              <Feedback message={verificationError} altStyle />
            )}
            <StretchForm
              initialValues={{
                email: "",
              }}
              onSubmit={values => {
                setSubmitting(true)
                requestLink(values.email)
              }}
              validationSchema={validationSchema}
              validateOnBlur
            >
              {({ values, errors, touched, handleChange, handleBlur }) => {
                const handleFieldChange = (
                  setField: (_value: string) => void,
                  value: string,
                ) => {
                  setField(value)
                  setValidationMessage("")
                }

                return (
                  <Form>
                    <TextInput
                      autoComplete="home email"
                      label="Email Address"
                      name="email"
                      value={values.email}
                      error={touched.email ? errors.email : undefined}
                      onChange={(
                        event: ChangeEvent<HTMLInputElement>,
                      ): void => {
                        handleFieldChange(
                          handleChange("email"),
                          event.target.value,
                        )
                      }}
                      onBlur={handleBlur("email")}
                      type="email"
                    />
                    {!!validationMessage && (
                      <Feedback message={validationMessage} />
                    )}
                    <SubmitButton
                      disabled={submitting || !!validationMessage}
                      label="Request Verification Link"
                    />
                    {success && (
                      <Message
                        message="Email sent. Check your inbox for the verification link."
                        onTimeout={() => setSuccess(false)}
                      />
                    )}
                    {!!newLinkError && <Feedback message={newLinkError} />}
                  </Form>
                )
              }}
            </StretchForm>
          </>
        ) : (
          <>
            <Headings.H2>Checking verification code...</Headings.H2>
            <p className="mb-0">
              You will be automatically redirected to the dashboard page.
            </p>
          </>
        )}
      </Card>
    </>
  )
}

export default GetVerified
