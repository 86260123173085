import { InputBase, MantineSize, Sx } from "@mantine/core"
import React from "react"

import ReactInputMask, { Props as ReactInputMaskProps } from "react-input-mask"

type Props = {
  error?: string
  size?: MantineSize
  centered?: boolean
  letterSpacingSize?: MantineSize
  rightSection?: React.ReactNode
}

export type MaskedInputProps = Props & Omit<ReactInputMaskProps, keyof Props>

export const MaskedInput: React.VFC<MaskedInputProps> = ({
  centered,
  letterSpacingSize = "md",
  inputMode,
  maskPlaceholder,
  ...props
}) => {
  let letterSpacing
  switch (letterSpacingSize) {
    case "xl":
      letterSpacing = "2em"
      break
    case "lg":
      letterSpacing = "1em"
      break
    case "md":
      letterSpacing = ".5em"
      break
    case "sm":
      letterSpacing = ".2em"
      break
    case "xs":
      letterSpacing = ".05em"
      break

    default: {
      letterSpacing = "unset"
    }
  }
  const sx: Sx = {
    ".mantine-Input-input": {
      textAlign: centered ? "center" : "unset",
      letterSpacing,
    },
    ".mantine-Input-input::placeholder": {
      letterSpacing: "normal", // This does not work, likely an issue with Mantine
    },
    label: {
      fontSize: "inherit",
    },
    input: {
      fontSize: "inherit",
    },
  }

  return (
    <InputBase
      sx={sx}
      component={ReactInputMask}
      inputMode={inputMode ?? "text"}
      maskPlaceholder={maskPlaceholder}
      {...props}
    />
  )
}

export const MaskedPhoneInput: React.VFC<MaskedInputProps> = props => {
  return <MaskedInput inputMode="tel" {...props} />
}

export const MaskedNumericInput: React.VFC<MaskedInputProps> = props => {
  return <MaskedInput inputMode="numeric" {...props} />
}
