import { Form } from "formik"
import React, { ChangeEvent, useState } from "react"
import { Helmet } from "react-helmet-async"
//import { Link } from "react-router-dom"
import * as yup from "yup"
import { signUp } from "src/api"
import { Bucket, Button} from "src/components"

import {
  Card,
  Feedback,
  Headings,
  InputCheckbox,
  StretchForm,
  SubmitButton,
  TextInput,
} from "src/components"
import { parseError } from "src/utilities/errorUtils"
import {
  redirectTo,
  SIGN_UP_VERIFY_PAGE_ROUTE,
} from "src/utilities/routingUtils"

const validationSchema = yup.object({
  email: yup.string().email().required("Please enter your email"),
  acceptTerms: yup.bool().oneOf([true], "You must accept terms to continue"),
  hasActivationCode: yup.bool().oneOf([true], "You must have an activation code to continue")
})

const SignUp: React.FC = () => {
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [error, setError] = useState("")

  const handleSignup = async (email: string) => {
    try {
      await signUp(email)
      redirectTo(SIGN_UP_VERIFY_PAGE_ROUTE, { email })
    } catch (error) {
      const { errorMessage } = parseError(error)
      setError(errorMessage)
    }
  }

  return (
    <>
      <Card $page $fullPage2>
        <Helmet>
          <title>Stretch - Let’s Sign You Up</title>
        </Helmet>
        <Headings.H1>Let’s Sign You Up</Headings.H1>

        <hr />

        <StretchForm
          initialValues={{
            email: "",
            acceptTerms: false,
            hasActivationCode: false,
          }}
          onSubmit={values => {
            handleSignup(values.email)
          }}
          validationSchema={validationSchema}
        >
          {({ values, errors, handleChange, touched, handleBlur }) => {
            validationSchema.isValid(values).then(isValid => {
              setSubmitDisabled(!isValid)
            })

            const handleFieldChange = (
              setField: (_value: string) => void,
              value: string,
            ) => {
              setField(value)
              setError("")
            }

            return (
              <Form>
                <div>
                  <Headings.H3>What’s your email address?</Headings.H3>

                  <div>
                    <TextInput
                      autoComplete="home email"
                      name="email"
                      placeholder="Enter your email"
                      value={values.email}
                      error={touched.email ? errors.email : undefined}
                      onChange={(
                        event: ChangeEvent<HTMLInputElement>,
                      ): void => {
                        handleFieldChange(
                          handleChange("email"),
                          event.target.value,
                        )
                      }}
                      onBlur={handleBlur("email")}
                      type="email"
                    />
                  </div>
                </div>
                <InputCheckbox
                  id="acceptTerms"
                  name="acceptTerms"
                  checked={values.acceptTerms}
                  onChange={handleChange("acceptTerms")}
                  label={
                    <span>
                      I have read and agree to the Stretch{" "}
                      <a
                        href="https://www.stretch.money/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy&nbsp;Policy
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://www.stretch.money/user-agreement"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        User&nbsp;Agreement
                      </a>
                      .
                    </span>
                  }
                />
                <InputCheckbox
                  id="hasActivationCode"
                  name="hasActivationCode"
                  checked={values.hasActivationCode}
                  onChange={handleChange("hasActivationCode")}
                  label={
                    <span>
                      I have an activation code provided by my Parole Officer or my Reentry Case Manager.
                    </span>
                  }
                />
                <SubmitButton disabled={submitDisabled} label="Sign Up" />
                {!!error && <Feedback message={error} />}
              </Form>
            )
          }}
        </StretchForm>
      </Card>
      <div>
        <Bucket $spaceM>
          <Headings.H4>Already have an account?</Headings.H4>
          <Button as="Link" to="/login" $secondary>
            Log In
          </Button>
        </Bucket>
      </div>
    </>
  )
}

export default SignUp
