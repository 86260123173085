import { Group, Space, Text } from "@mantine/core"
import { Dropzone, MIME_TYPES } from "@mantine/dropzone"
import { IconFileUpload, IconUpload, IconX } from "@tabler/icons"
import React, { useState } from "react"
import theme from "src/assets/theme"
import { Feedback } from "src/components"

type Props = {
  fileSizeLimit: number
  handleFileAttached: (_file: File) => void
}
const DocumentAttach: React.VFC<Props> = ({
  fileSizeLimit,
  handleFileAttached,
}) => {
  const [error, setError] = useState("")

  return (
    <>
      <Dropzone
        onDrop={files => handleFileAttached(files[0])}
        onReject={files => {
          const { file, errors } = files[0]
          setError(`Cannot upload file ${file.name}. ${errors[0].message}`)
        }}
        maxSize={fileSizeLimit}
        accept={[MIME_TYPES.jpeg, MIME_TYPES.png, MIME_TYPES.pdf]}
        multiple={false}
      >
        <Group
          position="center"
          spacing="xl"
          style={{ minHeight: 220, pointerEvents: "none" }}
        >
          <Dropzone.Accept>
            <IconUpload style={{ color: theme.blue }} size={80} />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX style={{ color: theme.red }} size={80} />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconFileUpload style={{ color: theme.grayT }} size={80} />
          </Dropzone.Idle>

          <div>
            <Text size="xl" inline>
              Click here
            </Text>
            <Text size="sm" color="dimmed" inline mt={7}>
              The file should not exceed 10 MB
            </Text>
          </div>
        </Group>
      </Dropzone>
      {!!error && (
        <>
          <Space h="md" />
          <Feedback message={error} />
        </>
      )}
    </>
  )
}

export default DocumentAttach
