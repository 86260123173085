import React, { useState } from "react"
import {
  MantineDefaultButton as Button,
  Card,
  Feedback,
  Headings,
} from "src/components"
import { useAppDispatch } from "src/hooks/redux"
import { thunkFreezePaymentCard, thunkUnfreezePaymentCard } from "src/store"
import { PaymentCard } from "src/types"
import { parseError } from "src/utilities/errorUtils"

type Props = {
  card: PaymentCard
}
const LockCard: React.VFC<Props> = ({ card }) => {
  const { status, lifecycle } = card
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState("")
  const dispatch = useAppDispatch()

  const handleClick = async () => {
    setIsSubmitting(true)

    const paymentCardThunk = isCardLocked
      ? thunkUnfreezePaymentCard
      : thunkFreezePaymentCard
    try {
      await dispatch(paymentCardThunk(lifecycle))

      setIsSubmitting(false)
    } catch (err) {
      const { errorMessage } = parseError(err)
      setError(errorMessage)
      setIsSubmitting(false)
    }
  }
  const isCardLocked = status === "SUSPENDED"
  const canChangeLockStatus = status === "ACTIVE" || status === "SUSPENDED"

  const buttonLabel = `${isCardLocked ? "Unlock" : "Lock"} your Card`
  const action = isCardLocked ? "Unlocking" : "Locking"
  const description =
    action +
    " " +
    (isCardLocked
      ? "will re-enable transactions."
      : "will temporarily block all card usage")
  const cardStatusTitle = () => {
    switch (status) {
      case "ACTIVATION_REQUIRED":
        return "Needs Activation"
      case "ACTIVE":
        return "Unlocked"
      case "SUSPENDED":
        return "Locked"
      case "CLOSED":
        return "Closed"
      default: {
        const never: never = status
        throw new Error(never)
      }
    }
  }

  return (
    <Card>
      <Headings.H3>Card Status: {cardStatusTitle()}</Headings.H3>
      {canChangeLockStatus && (
        <>
          <p>
            You may lock and unlock your card for any reason. {description}
          </p>
          <Button
            onClick={handleClick}
            isLoading={isSubmitting}
            label={buttonLabel}
            disabled={isSubmitting}
          />
          {!!error && <Feedback message={error} />}
        </>
      )}
    </Card>
  )
}

export default LockCard
