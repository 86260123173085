import axios from "axios"
import { AppConfig, validateAppConfigResponse } from "src/types/systemTypes"
import { generateStretchAPIURL, requestConfiguration } from "./common"

export const APP_CONFIG_URL = generateStretchAPIURL("/configs")
export function getAppConfig(): Promise<AppConfig> {
  return axios
    .get<unknown>(APP_CONFIG_URL, requestConfiguration())
    .then(validateAppConfigResponse)
}
