import { ActionIcon } from "@mantine/core"
import { IconRefresh } from "@tabler/icons"
import { Duration } from "luxon"
import React, { useEffect, useState } from "react"
import { useCountdown } from "src/hooks"
import { NoOpFunctionType } from "src/types"

type Props = {
  interval: Duration
  onClick: NoOpFunctionType
}

const TimedRefreshButton: React.VFC<Props> = ({ interval, onClick }) => {
  const { durationLeft: remainingInterval, resetCountdown } = useCountdown(
    interval,
    true,
  )
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (remainingInterval.toMillis() <= 0) {
      setShow(true)
    }
  }, [remainingInterval])

  const handleClick = () => {
    onClick()
    setShow(false)
    resetCountdown?.()
  }

  return <>{show ? <RefreshIconButton onClick={handleClick} /> : null}</>
}

type RefreshIconButtonProps = {
  onClick: NoOpFunctionType
}
const RefreshIconButton: React.VFC<RefreshIconButtonProps> = ({ onClick }) => {
  return (
    <ActionIcon onClick={onClick}>
      <IconRefresh />
    </ActionIcon>
  )
}

export default TimedRefreshButton
