import { Stack } from "@mantine/core"
import React from "react"
import { Address } from "src/types"

type Props = {
  address: Address
}

const AddressCard: React.VFC<Props> = ({ address }) => {
  const { firstLine, secondLine, locality, region, postalCode } = address
  return (
    <Stack align="center" justify="center" spacing={0}>
      <div>{firstLine},</div>
      {!!secondLine && <div>{secondLine},</div>}
      <div>
        {locality}, {region} {postalCode}
      </div>
    </Stack>
  )
}

export default AddressCard
