import { MantineProvider } from "@mantine/core"
import React from "react"
import {
  LG_BREAKPOINT_WIDTH_PX,
  MD_BREAKPOINT_WIDTH_PX,
  SM_BREAKPOINT_WIDTH_PX,
  XL_BREAKPOINT_WIDTH_PX,
  XS_BREAKPOINT_WIDTH_PX,
} from "src/uiConstants"

const Provider: React.FC = ({ children }) => (
  <MantineProvider
    withGlobalStyles
    withNormalizeCSS
    theme={{
      fontFamily: "Poppins, sans serif",
      breakpoints: {
        xs: XS_BREAKPOINT_WIDTH_PX,
        sm: SM_BREAKPOINT_WIDTH_PX,
        md: MD_BREAKPOINT_WIDTH_PX,
        lg: LG_BREAKPOINT_WIDTH_PX,
        xl: XL_BREAKPOINT_WIDTH_PX,
      },
      colors: {
        "stretch-red": [
          "#f7f3f3",
          "#FEDEDD",
          "#FDBDBB",
          "#FD9B9A",
          "#FC7A78",
          "#FB5755",
          "#E2504D",
          "#C94745",
          "#B03E3C",
          "#973534",
        ],
      },
      primaryColor: "stretch-red",
    }}
  >
    {children}
  </MantineProvider>
)

export default Provider
