import React, { FC, useEffect } from "react"
import Feedback, { FeedbackProps } from "./Feedback"

type TimedFeedbackProps = {
  timeout: number
  onTimeout?: () => void
} & FeedbackProps
const TimedFeedback: FC<TimedFeedbackProps> = ({
  timeout,
  onTimeout,
  ...props
}) => {
  useEffect(() => {
    if (onTimeout) {
      setTimeout(() => {
        onTimeout()
      }, timeout || 7000)
    }
  }, [])

  return <Feedback {...props} />
}
export default TimedFeedback
