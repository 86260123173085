import React from "react"
import { Headings, Panels, CardActivationForm } from "src/components"
import { PaymentCard } from "src/types"

type ActivateCardProps = {
  card: PaymentCard
}

const ActivateCard: React.VFC<ActivateCardProps> = ({ card }) => {
  //const { lifecycle } = card

  //const cardName: string = lifecycle === "TEMPORARY" ? "Temporary" : "Debit"
  return (
    <Panels.Default>
      <Headings.H2>Activate your Card</Headings.H2>
      <CardActivationForm card={card} />
    </Panels.Default>
  )
}

export default ActivateCard
