import styled, { css } from "styled-components"

interface GridProps {
  $spaceS?: boolean
  $widePadding?: boolean
}

const Grid = styled.div<GridProps>`
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  ${props =>
    props.$spaceS &&
    css`
      margin-left: -0.25rem;
      margin-right: -0.25rem;
    `}
  @media (min-width: 50em) {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
`

interface GridItemProps {
  $spaceS?: boolean
  $span?: number
  $spanS?: number
  $equalHeight?: boolean
  $centerContent?: boolean
  $noPaddingBottom?: boolean
}

export const GridItem = styled.div<GridItemProps>`
  box-sizing: border-box;
  flex: 0 1 100%;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 100%;
  ${props =>
    props.$centerContent &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
    `}
  ${props =>
    props.$span &&
    css`
      flex-basis: ${(props.$span / 12) * 100}%;
    `};
  ${props =>
    props.$spanS &&
    css`
      flex-basis: ${(props.$spanS / 12) * 100}%;
    `};
  ${props =>
    props.$spaceS &&
    css`
      padding-bottom: 0;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    `}
  ${props =>
    props.$noPaddingBottom &&
    css`
      padding-bottom: 0;
    `}
  ${props =>
    props.$equalHeight &&
    css`
      display: flex;
      flex-direction: column;
      > * {
        flex-grow: 1;
      }
    `}
  @media (min-width: 70em) {
    order: var(--wideOrder);
    padding-bottom: 0;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    ${props =>
      props.$span &&
      css`
        flex-basis: ${(props.$span / 12) * 100}%;
      `};
  }
`

export default Grid
