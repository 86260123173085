import React from "react"
import { Helmet } from "react-helmet-async"
import { Grid, GridItem } from "src/components"
import { useAppSelector } from "src/hooks/redux"
import {
  selectIsPermanentCardClosed,
  selectIsPermanentCardActiveOrSuspended,
  selectIsTempCardActiveOrSuspended,
  selectPermanentPaymentCard,
  selectShouldActivatePermanentCard,
  selectShouldActivateTempCard,
  selectShouldOrderPermanentCard,
  selectTemporaryPaymentCard,
  selectCanHaveTempCard,
  selectIsTempCardClosed,
} from "src/store"
import OrderPhysicalCard from "./OrderPhysicalCard"
import ActivateCard from "./ActivateCard"
import CardView from "./CardView"
import ReorderPermanentCard from "./ReorderCard/ReorderPermanentCard"
import OrderTemporaryCard from "./OrderTemporaryCard"

const ManageCards: React.VFC = () => {
  const tempCard = useAppSelector(selectTemporaryPaymentCard)
  const canHaveTempCard = useAppSelector(selectCanHaveTempCard)
  const shouldActivateTempCard = useAppSelector(selectShouldActivateTempCard)
  const isTempCardActiveOrSuspended = useAppSelector(
    selectIsTempCardActiveOrSuspended,
  )

  const permanentCard = useAppSelector(selectPermanentPaymentCard)
  const shouldActivatePermanentCard = useAppSelector(
    selectShouldActivatePermanentCard,
  )
  const isPermanentCardActiveOrSuspended = useAppSelector(
    selectIsPermanentCardActiveOrSuspended,
  )
  const isTempCardClosed = useAppSelector(selectIsTempCardClosed)
  const isPermanentCardClosed = useAppSelector(selectIsPermanentCardClosed)
  const shouldOrderPermanentCard = useAppSelector(
    selectShouldOrderPermanentCard,
  )

  return (
    <>
      <Helmet>
        <title>Manage Your Cards – Stretch</title>
      </Helmet>

      {isPermanentCardClosed && (
        <>
          <ReorderPermanentCard />
          <div className="mt-10 wideOnly"></div>
          <div className="mt-6 narrowOnly"></div>
        </>
      )}

      {shouldOrderPermanentCard && (
        <>
          <OrderPhysicalCard />
          <div className="mt-10 wideOnly"></div>
          <div className="mt-6 narrowOnly"></div>
        </>
      )}

      {!!permanentCard && shouldActivatePermanentCard && (
        <Grid>
          <GridItem>
            <ActivateCard card={permanentCard} />
          </GridItem>
        </Grid>
      )}
      {!!permanentCard && isPermanentCardActiveOrSuspended && (
        <CardView card={permanentCard} />
      )}

      <div className="mb-12" />

      {(!tempCard || isTempCardClosed) && canHaveTempCard && (
        <Grid>
          <GridItem>
            <OrderTemporaryCard />
          </GridItem>
        </Grid>
      )}

      {!!tempCard && canHaveTempCard && shouldActivateTempCard && (
        <Grid>
          <GridItem>
            <ActivateCard card={tempCard} />
          </GridItem>
        </Grid>
      )}
      {!!tempCard && canHaveTempCard && isTempCardActiveOrSuspended && (
        <CardView card={tempCard} />
      )}
    </>
  )
}

export default ManageCards
