import { createMachine } from "xstate"
import { useMachine } from "@xstate/react"

type State = string
type Transitions = {
  [event: string]: State
}
export type XStateConfig = {
  [state: string]: Transitions
}

export const useStateMachine = (
  stateId: string,
  initialState: State,
  config: XStateConfig,
): ReturnType<typeof useMachine> => {
  const machine = createMachine({
    id: stateId,
    initial: initialState,
    states: Object.entries(config).reduce((states, [state, transitions]) => {
      const events = Object.keys(transitions)
      if (events.length > 0) {
        return {
          ...states,
          [state]: {
            on: events.reduce(
              (allEvents, currentEvent) => ({
                ...allEvents,
                [currentEvent]: transitions[currentEvent],
              }),
              {},
            ),
          },
        }
      } else {
        return {
          ...states,
          [state]: {
            type: "final",
          },
        }
      }
    }, {}),
  })
  return useMachine(machine)
}
