import React from "react"
import { Formik, FormikConfig, FormikHelpers } from "formik"

type FormProps<TValues> = {
  onSubmit: (
    _values: TValues,
    _helpers: FormikHelpers<TValues>,
  ) => void | Promise<void | string> | string
} & Omit<FormikConfig<TValues>, "onSubmit">

const StretchForm = <TValues extends Record<string, unknown>>({
  onSubmit,
  ...props
}: FormProps<TValues>): React.ReactElement => {
  const trimWhitespaceOnSubmit = (
    values: TValues,
    helpers: FormikHelpers<TValues>,
  ) => {
    // trim whitespace from any fields that are of type string
    Object.keys(values).forEach(key => {
      const value = values[key]
      if (typeof value === "string") {
        values[key as keyof TValues] = value.trim() as TValues[keyof TValues]
      }
    })
    onSubmit(values, helpers)
  }
  return <Formik onSubmit={trimWhitespaceOnSubmit} {...props} />
}

export { StretchForm }
