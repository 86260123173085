export const PRISON_ID_CARD_HELPER_TEXT = `Please capture the front of your prison ID card in a single document.
   Make sure your name, ID number, and date of birth are visible (if displayed).`
export const PRISON_RELEASE_PAPERWORK_HELPER_TEXT = `Please capture the requested information (such as your address,
  social security number, name, or date of birth) in a single document.`
export const BIRTH_CERTIFICATE_HELPER_TEXT = `Please capture the front of your entire birth certificate in a single document.
   Make sure your name and date of birth are visible.`
export const DRIVERS_LICENSE_HELPER_TEXT = `Please capture the front of your driver’s license in a single document.
 Make sure your name, address, and date of birth are visible.`
export const PASSPORT_HELPER_TEXT = `Please capture the full page of your passport showing your photo, name and date of birth.`
export const UTILITY_BILL_HELPER_TEXT = `Please capture the front of your entire water, gas, or light bill in a single document.
    The bill must be from the past 60 days. Make sure your name and address are visible.`
export const PAY_STUB_HELPER_TEXT = `Please capture the front of your pay stub in a single document.
    The pay stub must be from the past 60 days. Make sure your name and address are visible.`
export const SSN_CARD_HELPER_TEXT = `Please capture the front of your social security card in a single document.
    Make sure your name and identification number are visible.`
export const TIN_CARD_HELPER_TEXT = `Please capture the front of your taxpayer identification document in a single document.`
export const PHONE_BILL_HELPER_TEXT = `Please capture the front of your phone bill in a single document.
 Make sure your name and phone number are visible.`
export const LEASE_HELPER_TEXT = `Please capture the front of your current lease agreement in a single document.
 Make sure your name and address are visible.`
export const DEED_HELPER_TEXT = `Please capture the front of your deed in a single document. 
Make sure your name and address are visible.`
