import React from "react"
import { Helmet } from "react-helmet-async"
import { Card, Headings } from "src/components"

const PasscodeEmailSent: React.VFC = () => {
  return (
    <>
      <Helmet>
        <title>Stretch - Passcode Reset</title>
      </Helmet>
      <Card $page $fullPage>
        <Headings.H2>Check Your Email</Headings.H2>
        <p className="mb-0">
          An email was sent to your account’s email address. Check your inbox
          for a message with a link to reset your passcode. If you don’t see it,
          please check your spam&nbsp;folder.
        </p>
      </Card>
    </>
  )
}

export default PasscodeEmailSent
