import { FC } from "react"

import * as Sentry from "@sentry/react"
import { HelmetProvider } from "react-helmet-async"
import { ThemeProvider } from "styled-components"
import { StretchAuthProvider } from "./context/StretchAuthContext"
import Router from "./routes/Router"
import { getGTM } from "./utilities"

import { ErrorBoundary } from "react-error-boundary"

import "./assets/css/base.css"
import "./assets/css/normalize.css"
import "./assets/css/vendor/input.css"
import theme from "./assets/theme"
import AppConfigProvider from "./context/AppConfigContext"
import { BankingProvider } from "./context/BankingContext"
import MantineProvider from "./context/MantineContext"
import { OnboardingProvider } from "./context/OnboardingContext"
import ErrorFallbackPage from "./pages/ErrorFallbackPage/ErrorFallbackPage"
import ReduxProvider from "./store/ReduxProvider"

getGTM()

const App: FC = () => {
  const logErrorToSentry = (
    error: Error,
    errorInfo: { componentStack: string },
  ) => {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      Sentry.captureException(error)
    })
  }
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallbackPage}
      onError={logErrorToSentry}
    >
      <ReduxProvider>
        <StretchAuthProvider>
          <BankingProvider>
            <ThemeProvider theme={theme}>
              <HelmetProvider>
                <MantineProvider>
                  <OnboardingProvider>
                    <AppConfigProvider>
                      <Router />
                    </AppConfigProvider>
                  </OnboardingProvider>
                </MantineProvider>
              </HelmetProvider>
            </ThemeProvider>
          </BankingProvider>
        </StretchAuthProvider>
      </ReduxProvider>
    </ErrorBoundary>
  )
}

export default App
