import React, { FC } from "react"
import styled, { css } from "styled-components"

import theme from "src/assets/theme"

import { Card, Icon, Headings } from "src/components"

type EmptyProps = {
  icon: string
  title: string
  $large?: boolean
  $noMargin?: boolean
}
const Empty: FC<EmptyProps> = ({ icon, title, $large }) => (
  <Card>
    <Content $large={$large}>
      <Icon id={icon} />
      <Headings.H4>{title}</Headings.H4>
    </Content>
  </Card>
)

type ContentProps = {
  $large?: boolean
}
const Content = styled.div<ContentProps>`
  align-items: center;
  flex-direction: column;
  color: ${theme.graySS};
  display: flex;
  justify-content: center;
  svg {
    font-size: 2rem;
    margin-bottom: 0.25rem;
  }
  ${props =>
    props.$large &&
    css`
      padding: 2rem 0;
    `}
  @media (min-width: 50em) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    svg {
      margin-bottom: 0;
      margin-right: 0.625rem;
    }
  }
`

export default Empty
