import { Form } from "formik"
import React, { ChangeEvent } from "react"
import {
  Card,
  Feedback,
  Headings,
  StretchForm,
  SubmitButton,
  TextInput,
  TimedFeedback,
} from "src/components"
import { UiFeedback } from "src/types"
import * as yup from "yup"

const validationSchema = yup.object({
  email: yup.string().email().required("Please enter your email."),
})

type EmailLoginFormProps = {
  error: string
  isSubmitting: boolean
  loginWithEmail: (_email: string) => void
  uiFeedback?: UiFeedback
  setError: (_error: string) => void
}
const EmailLoginForm: React.FC<EmailLoginFormProps> = ({
  error,
  uiFeedback,
  isSubmitting,
  loginWithEmail,
  setError,
}) => {
  const { message, messageType } = uiFeedback ?? {}
  return (
    <Card $marginAuto $page $fullPage>
      {!!message && (
        <Feedback
          message={message}
          altStyle
          $green={messageType == "positive"}
        />
      )}

      <Headings.H2>Log In</Headings.H2>

      <StretchForm
        initialValues={{
          email: "",
        }}
        onSubmit={values => {
          loginWithEmail(values.email)
        }}
        validationSchema={validationSchema}
        validateOnBlur
      >
        {({ values, errors, touched, isValid, handleChange, handleBlur }) => {
          const handleFieldChange = (
            setField: (_value: string) => void,
            value: string,
          ) => {
            setField(value)
            setError("")
          }

          return (
            <Form>
              <TextInput
                autoComplete="home email"
                name="email"
                placeholder="Enter your email"
                value={values.email}
                error={touched.email ? errors.email : undefined}
                onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                  handleFieldChange(handleChange("email"), event.target.value)
                }}
                onBlur={handleBlur("email")}
                type="email"
              />
              <SubmitButton
                disabled={isSubmitting || !isValid}
                loading={isSubmitting}
                label="Continue"
              />
              {!!error && (
                <TimedFeedback
                  message={error}
                  timeout={3000}
                  onTimeout={() => {
                    setError("")
                  }}
                />
              )}
            </Form>
          )
        }}
      </StretchForm>
    </Card>
  )
}

export default EmailLoginForm
