import { TextInput } from "@mantine/core"
import { Form, FormikHelpers } from "formik"
import React, { ReactNode, useState } from "react"
import {
  Feedback,
  Flex,
  FlexItem,
  MantineSubmitButton as Button,
  Messages,
  Spacing,
  StretchForm,
} from "src/components"
import { UiFeedback } from "src/types"
import { parseError } from "src/utilities/errorUtils"
import { didValuesChange } from "src/utilities/formUtils"
import * as yup from "yup"

const validationSchema = yup.object({
  email: yup.string().email().required("Please enter your email."),
})

type FormData = yup.InferType<typeof validationSchema>

type Props = {
  email?: string
  feedback?: UiFeedback
  submitButtonLabel?: string
  submitSuccessMessage?: string
  shouldDisableSubmit?: (_valuesChanged: boolean) => boolean
  otherButtons?: ReactNode
  handleSubmit: (_email: string) => Promise<void>
}

const EmailInputForm: React.VFC<Props> = ({
  email = "",
  feedback,
  submitButtonLabel = "Submit",
  submitSuccessMessage,
  shouldDisableSubmit,
  otherButtons,
  handleSubmit,
}) => {
  const fieldName = "email"
  const initialValues = { email }
  const [submitResult, setSubmitResult] = useState<UiFeedback>()

  const onSubmitClicked = async (
    { email }: FormData,
    { setSubmitting }: FormikHelpers<FormData>,
  ) => {
    try {
      await handleSubmit(email)
      if (submitSuccessMessage) {
        setSubmitResult({
          message: submitSuccessMessage,
          messageType: "positive",
        })
      }
      setSubmitting(false)
    } catch (err) {
      const { errorMessage } = parseError(err)
      setSubmitResult({ message: errorMessage, messageType: "negative" })
      setSubmitting(false)
    }
  }

  return (
    <Flex.Vertical hAlign="center">
      <StretchForm
        initialValues={initialValues}
        onSubmit={onSubmitClicked}
        validationSchema={validationSchema}
      >
        {({
          errors,
          values,
          handleBlur,
          setFieldValue,
          isValid,
          isSubmitting,
          touched,
        }) => (
          <Form style={{ width: "100%" }}>
            {!!submitResult && (
              <Messages.Ephemeral
                message={submitResult.message}
                messageType={submitResult.messageType}
                onTimeout={() => {
                  setSubmitResult(undefined)
                }}
              />
            )}
            {!!feedback && (
              <Feedback
                message={feedback.message}
                altStyle
                $green={feedback.messageType == "positive"}
              />
            )}
            <Spacing.Horizontal />
            <TextInput
              name={fieldName}
              type="email"
              autoComplete="home email"
              placeholder="Enter your email"
              value={values.email}
              error={touched.email === true ? errors.email : undefined}
              onChange={evt => {
                setFieldValue(fieldName, evt.target.value)
              }}
              onBlur={handleBlur}
            />
            <Spacing.Horizontal />
            <Flex.Horizontal hAlign="center" noWrap noHeight>
              {!!otherButtons && (
                <>
                  {otherButtons}
                  <Spacing.Vertical />
                </>
              )}
              <FlexItem
                grow="equally"
                component={Button}
                label={submitButtonLabel}
                disabled={
                  !isValid ||
                  isSubmitting ||
                  shouldDisableSubmit?.(didValuesChange(initialValues, values))
                }
                isLoading={isSubmitting}
              />
            </Flex.Horizontal>
          </Form>
        )}
      </StretchForm>
    </Flex.Vertical>
  )
}

export default EmailInputForm
