import { Group, Loader, Space, Stack } from "@mantine/core"
import React from "react"
import theme from "src/assets/theme"
import { Button, Card, Headings } from "src/components"
import { Address } from "src/types"

type ConfirmAddressProps = {
  address?: Address
  isSubmitting?: boolean
  onConfirmation: () => void
}
const ConfirmAddress: React.VFC<ConfirmAddressProps> = ({
  address,
  isSubmitting,
  onConfirmation,
}) => {
  const {
    firstLine: line1,
    secondLine: line2,
    locality: city,
    region,
    postalCode,
  } = address ?? {}

  return (
    <Stack>
      <Headings.H2>Confirm your Address</Headings.H2>
      <p>
        Before we can send you a new card, you need to confirm your address.
      </p>
      <Card $page $fullPage2>
        {!address && <Loader color={theme.red} />}
        {!!address && (
          <>
            <p style={{ fontWeight: "bold" }}>Is this still your Address?</p>
            <div>{line1}</div>
            <div>{line2}</div>
            <div>
              {city}, {region} {postalCode}
            </div>
            <Space h="md" />
            <Group>
              <Button $small onClick={onConfirmation} loading={isSubmitting}>
                Yes
              </Button>
            </Group>
          </>
        )}
      </Card>
    </Stack>
  )
}

export default ConfirmAddress
