import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import { Spinner } from "src/components"
import { useAppDispatch, useAppSelector } from "src/hooks/redux"
import {
  selectIsLoggedIn,
  thunkHydrateBankingState,
  thunkRecoverUserSession,
} from "src/store"
import { saveUserSession } from "src/store/savedUserSession"
import { isPublicRoute, parseError } from "src/utilities"
import RouteDirectory from "./RouteDirectory"
import * as Sentry from "@sentry/react"

const Router: React.VFC = () => {
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const [isSessionRecoveryComplete, setIsSessionRecoveryComplete] =
    useState(false)

  useEffect(() => {
    // On component mount (i.e. initial app load), if this is an auth-protected route, check if the
    // user's previous session is still active and reuse it if available
    const recoverUserSession = async () => {
      if (!isPublicRoute(pathname)) {
        await dispatch(thunkRecoverUserSession())
      }

      setIsSessionRecoveryComplete(true)
    }

    recoverUserSession()
  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(thunkHydrateBankingState()).catch((err: Error) => {
        console.error(
          `Failed to rehydrate account holder state: ${
            parseError(err).errorMessage
          }`,
        )
      })
    }
  }, [isLoggedIn, dispatch])

  // On page close/refresh, save details of the user's Stretch session
  window.onunload = () => {
    saveUserSession({
      isLoggedIn,
    })
    Sentry.flush(500)
  }

  // Since it's an SPA, we want want all route changes to scroll to the top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  if (!isSessionRecoveryComplete) {
    return <Spinner />
  }
  return <RouteDirectory />
}

export default Router
