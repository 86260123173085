import { Center } from "@mantine/core"
import React, { useEffect, useState } from "react"
import { getAccountHolderDetails as getAccountholderAddress } from "src/api"
import { useAppDispatch } from "src/hooks/redux"
import { thunkIssuePermanentCard } from "src/store"
import { Address } from "src/types"
import { asyncUiDataFetch } from "src/utilities/asyncUtils"
import { DASHBOARD_PAGE_ROUTE, redirectTo } from "src/utilities/routingUtils"
import CardsClosedNotice from "./CardsClosedNotice"
import ConfirmAddress from "./ConfirmAddress"

const ReorderPermanentCard: React.VFC = () => {
  const [startOrderCard, setStartOrderCard] = useState(false)
  const [address, setAddress] = useState<Address>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (startOrderCard) {
      asyncUiDataFetch(getAccountholderAddress, setAddress)
    }
  }, [startOrderCard])

  const onReorderClicked = () => {
    setStartOrderCard(true)
  }
  const onAddressConfirmed = async () => {
    setIsSubmitting(true)
    await dispatch(thunkIssuePermanentCard())
    setIsSubmitting(false)
    redirectTo(DASHBOARD_PAGE_ROUTE)
  }
  return (
    <Center>
      {!startOrderCard && (
        <CardsClosedNotice onConfirmation={onReorderClicked} />
      )}
      {startOrderCard && (
        <ConfirmAddress
          address={address}
          isSubmitting={isSubmitting}
          onConfirmation={onAddressConfirmed}
        />
      )}
    </Center>
  )
}

export default ReorderPermanentCard
