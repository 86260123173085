import { Accordion as MantineAccordion, useMantineTheme } from "@mantine/core"
import { IconChevronDown } from "@tabler/icons"
import React from "react"
import { useAccordionContext } from "./Accordion"

type AccordionLabelProps = {
  isActiveAccordion: boolean
}
export interface Props {
  renderLabel: (_props: AccordionLabelProps) => React.ReactNode
  value: string
}
export const AccordionItem: React.FC<Props> = ({
  children,
  renderLabel,
  value,
}) => {
  const theme = useMantineTheme()
  const { activeAccordion } = useAccordionContext()
  return (
    <MantineAccordion.Item value={value}>
      <MantineAccordion.Control
        chevron={
          <IconChevronDown
            color={
              activeAccordion === value
                ? theme.colors["stretch-red"][5]
                : "black"
            }
          />
        }
      >
        {renderLabel({ isActiveAccordion: activeAccordion === value })}
      </MantineAccordion.Control>
      <MantineAccordion.Panel>{children}</MantineAccordion.Panel>
    </MantineAccordion.Item>
  )
}
