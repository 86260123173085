import {
  AnyAction,
  createSelector,
  createSlice,
  PayloadAction,
  ThunkAction,
} from "@reduxjs/toolkit"
import { getPhysicalCardOrder } from "src/api"
import { RootState } from "src/store/store"
import { AsyncThunkLoadingStatus, PhysicalCardOrder } from "src/types"
import { isApiAuthenticationError } from "src/utilities/errorUtils"
import { redirectAfterSessionExpired } from "src/utilities/routingUtils"
import { thunkClearUserData } from "../user/userReducer"

type State = {
  physicalCardOrder?: PhysicalCardOrder
  loadingStatus: AsyncThunkLoadingStatus
}
const initialState: State = {
  loadingStatus: "idle",
}

const slice = createSlice({
  name: "physicalCardOrder",
  initialState,
  reducers: {
    setPhysicalCardOrder: (state, action: PayloadAction<PhysicalCardOrder>) => {
      state.physicalCardOrder = action.payload
    },
  },
})

export const cardOrderSlice = slice.reducer

export const { setPhysicalCardOrder } = slice.actions

export const thunkRefreshPhysicalCardOrder =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async dispatch => {
    try {
      const physicalCardOrder = await getPhysicalCardOrder()
      dispatch(setPhysicalCardOrder(physicalCardOrder))
    } catch (err) {
      if (isApiAuthenticationError(err)) {
        dispatch(thunkClearUserData())
        redirectAfterSessionExpired()
        return
      }
      throw err
    }
  }

export const selectPhysicalCardOrder = (
  rootState: RootState,
): PhysicalCardOrder | undefined => {
  return rootState.cardOrder.physicalCardOrder
}

export const selectIsPermanentCardOrderedButNotShipped: (
  _rootState: RootState,
) => boolean = createSelector(
  selectPhysicalCardOrder,
  (cardOrder: PhysicalCardOrder | undefined) => {
    return (
      cardOrder !== undefined &&
      !["SHIPPED", "SHIP_FAILED"].includes(cardOrder?.status)
    )
  },
)

export const selectIsPhysicalCardShipped: (_rootState: RootState) => boolean =
  createSelector(
    selectPhysicalCardOrder,
    (cardOrder: PhysicalCardOrder | undefined) => {
      return cardOrder !== undefined && cardOrder?.status === "SHIPPED"
    },
  )
