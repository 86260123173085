import { Text } from "@mantine/core"
import useObserver from "pojo-observer"
import React, { useState } from "react"
import {
  getCardApplication,
  getDocumentUploadToken,
  notifyDocUploadCompleted,
  userBankingDetail,
} from "src/api"
import {
  Flex,
  FormFeedback,
  MantineDefaultButton,
  Spacing,
  Spinner,
} from "src/components"
import { docUploadSession as rootDocUploadSession } from "src/models"
import { useAppDispatch } from "src/hooks"
import { thunkRefreshCardApplication } from "src/store"
import {
  AsyncNoOpFunctionType,
  ClientToken,
  DocumentToUpload,
  UiFeedback,
  UserBankingDetails,
  VerificationTag,
} from "src/types"
import { asyncUiDataFetch, delay, formatBytes, parseError } from "src/utilities"
import { DocUploadForm } from "../DocUploadForm"
import * as Sentry from "@sentry/react"

type Props = {
  documentUploadSessionId: string
  verificationTags: VerificationTag[]
}

const ManageDocUploadContainer: React.VFC<Props> = ({
  documentUploadSessionId,
  verificationTags,
}) => {
  const dispatch = useAppDispatch()
  const [docUploadToken, setDocUploadToken] = useState<ClientToken>()
  const [userBankingDetails, setUserBankingDetails] =
    useState<UserBankingDetails>()
  const [submitResult, setSubmitResult] = useState<UiFeedback>()
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const docUploadSession = rootDocUploadSession
  useObserver(docUploadSession)

  const initializeDocUpload = async () => {
    setIsPageLoading(true)
    const transaction = Sentry.startTransaction({
      name: "doc-upload",
      op: "function",
      description: "start and finish a doc upload session",
      data: {
        session_id: documentUploadSessionId,
      },
    })
    // If there's currently an unfinished transaction, it may be dropped
    Sentry.getCurrentHub().configureScope(scope => scope.setSpan(transaction))
    const span = transaction.startChild({
      op: "function",
      description: "initialize doc upload",
    })
    const token = await getDocumentUploadToken()
    setDocUploadToken(token)
    await docUploadSession.initialize(documentUploadSessionId, token)
    await asyncUiDataFetch(userBankingDetail, setUserBankingDetails)
    span.finish()
    setIsPageLoading(false)
  }

  const handleSubmit = async (documents: DocumentToUpload[]) => {
    setIsSubmitting(true)
    const transaction = Sentry.getCurrentHub().getScope().getTransaction()
    try {
      const submissionSpan = transaction?.startChild({
        op: "function",
        description: "submit doc upload",
        data: {
          file_count: documents.length,
          file_sizes: documents.map(doc => formatBytes(doc.file.size)),
          file_types: documents.map(doc => doc.file.type),
        },
      })
      await docUploadSession.complete(documents, docUploadToken)

      await notifyDocUploadCompleted(documents.map(doc => doc.type))

      submissionSpan?.finish()

      const delaySpan = transaction?.startChild({
        op: "function",
        description: "wait for highnote state change",
        data: {
          file_count: documents.length,
          file_sizes: documents.map(doc => formatBytes(doc.file.size)),
          file_types: documents.map(doc => doc.file.type),
        },
      })

      // Wait for HN to update the application status so when we refresh it, we render the correct screen in the app
      await delay(5000)
      const cardApplication = await getCardApplication()
      if (cardApplication.status === "DOCUMENT_UPLOAD_REQUIRED") {
        await delay(5000)
      }

      delaySpan?.finish()

      await dispatch(thunkRefreshCardApplication())
    } catch (err) {
      setIsSubmitting(false)
      setSubmitResult({
        message: parseError(err).errorMessage,
        messageType: "negative",
      })
    } finally {
      transaction?.finish()
    }
  }

  if (isPageLoading || isSubmitting) {
    return <Spinner />
  }

  return (
    <Flex.Vertical hAlign="center">
      <FormFeedback
        feedback={submitResult}
        onTimerExpired={() => {
          setSubmitResult(undefined)
        }}
      />
      <Spacing.Horizontal />
      {docUploadSession.getIsInitialized() &&
      userBankingDetails !== undefined ? (
        <DocUploadForm
          requiredDocumentTypes={docUploadSession.getRequiredDocTypes()}
          uploadedDocs={docUploadSession.getUploadeDocuments()}
          submitDocuments={handleSubmit}
          verificationTags={verificationTags}
          userBankingDetails={userBankingDetails}
        />
      ) : (
        <InitialNotice onAccept={initializeDocUpload} />
      )}
    </Flex.Vertical>
  )
}

type InitialNoticeProps = {
  onAccept: AsyncNoOpFunctionType
}
const InitialNotice: React.VFC<InitialNoticeProps> = ({ onAccept }) => {
  return (
    <>
      <Text>We need extra documents to help us verify your identity.</Text>
      <Spacing.Horizontal />
      <MantineDefaultButton label="Continue" onClick={onAccept} />
    </>
  )
}

export default ManageDocUploadContainer
