import { LocalAppStorage, LocalDataKey } from "src/storage/localAppStorage"

type StretchSession = {
  isLoggedIn: boolean
}

export const saveUserSession = (sessionInfo: StretchSession): void => {
  LocalAppStorage.save(LocalDataKey.USER_SESSION_INFO, sessionInfo)
}

export const wasUserPreviouslyLoggedIn = (): boolean => {
  const storedSessionData = LocalAppStorage.loadAndClear(
    LocalDataKey.USER_SESSION_INFO,
  ) as StretchSession

  return storedSessionData.isLoggedIn
}
