import React, { useEffect, useState, useRef } from "react"
import { Helmet } from "react-helmet-async"

import { Feedback, Spinner } from "src/components"
// import { Horizontal } from "src/components/shared/layouts/Flex/FlexParent"
import { useAppDispatch, useAppSelector } from "src/hooks/redux"
import {
  selectApplicationApproved,
  selectCardApplication,
  selectFinancialAccount,
  selectHasApplied,
  selectIsCardApplicationStateLoading,
  thunkIssueFinancialAccountAndOrderCard
} from "src/store"
import { parseError } from "src/utilities/errorUtils"
import ApplicationStatus from "./ApplicationStatus"
//import FullyOnboarded from "./FullyOnboarded"
import FullyOnboardedV2 from "./FullyOnboardedV2"
import NonBankingUser from "./NonBankingUser"
import ClosedFinancialAccountUser from "./ClosedFinancialAccountUser"
import { getAccountHolderStatusInfo } from "src/api"
import { Banner,Icon} from "src/components"

const DashboardV2: React.VFC = () => {
  const dispatch = useAppDispatch()
  const cardApplication = useAppSelector(selectCardApplication)
  const isCardApplicationLoading = useAppSelector(
    selectIsCardApplicationStateLoading,
  )

  const financialAccount = useAppSelector(selectFinancialAccount)
  const hasApplied = useAppSelector(selectHasApplied)
  const applicationApproved = useAppSelector(selectApplicationApproved)

  const [error, setError] = useState("")
  const [isLoading, setLoading] = useState(true);

  const [displayClosureRequestedBanner, setDisplayClosureRequestedBanner] = useState(false);

  const [isBottom,setIsBottom] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    const container = containerRef.current;

    console.log("Checking Container");
    console.log(container?.scrollHeight);
    console.log("Completed Container Check");

    if(container != null){
      if (container.scrollHeight - container.scrollTop === container.clientHeight) {
        setIsBottom(true);

        console.log("At bottom 1");

        if(isBottom){
          console.log("At bottom 2");
        }
        
      } else {
        setIsBottom(false);
      }

    }
    
  };

  // TODO: Create a separate ApplicationStatus page to handle pending application state
  // and create financial account once application is approved
  // TODO: Consider moving financial account creation to server-side and use HN events
  useEffect(() => {
    async function issueFinancialAccount() {
      try {
        await dispatch(thunkIssueFinancialAccountAndOrderCard())
      } catch (err) {
        const { errorMessage } = parseError(err)
        setError(errorMessage)
      }

      const container = containerRef.current;
      if(container != null){
        console.log("adding event listener");
        container.addEventListener('scroll', handleScroll);
      }

    }

    if (applicationApproved) {
      issueFinancialAccount()
    }

    getAccountHolderStatuses();
  }, [dispatch, applicationApproved])

  useEffect(() => {
    setLoading(isCardApplicationLoading)
  }, [isCardApplicationLoading])

 function getAccountHolderStatuses(){

  getAccountHolderStatusInfo().then(res => {

    if(res?.success == true){

      if(res?.userRequestedAccountClosure == true){

        if(!(financialAccount?.status == 'CLOSED')){

          setDisplayClosureRequestedBanner(true);

        }else{

          setDisplayClosureRequestedBanner(false);

        }
        
      }else{

        setDisplayClosureRequestedBanner(false);

      }
      
    }else {
      setDisplayClosureRequestedBanner(false);
      
    }

  })

}

  const determineUserExperience = () => {
    if (isLoading) {
      return <Spinner />
    }

    if(financialAccount?.status == 'CLOSED'){
      return <ClosedFinancialAccountUser />
    }else if(!hasApplied){
      return <NonBankingUser />
    } else {
      return applicationApproved ? (
        <FullyOnboardedV2 />
      ) : cardApplication ? (
        <ApplicationStatus cardApplication={cardApplication} />
      ) : null
    }

  }

  return (
    <div ref={containerRef}>
      <Helmet>
        <title>Dashboard – Stretch</title>
      </Helmet>

      {!!error && <Feedback message={error} />}

      {displayClosureRequestedBanner && (
        <Banner>
          <Icon id="info" />
          We have received the request to close your account
        </Banner>
      )}

      {determineUserExperience()}
    </div>
  )
}

export default DashboardV2
