import { CSSObject, Group, Stack, Sx } from "@mantine/core"
import React from "react"

export type FlexAlignment = "center" | "space-between"
type Props = {
  hAlign?: FlexAlignment
  vAlign?: FlexAlignment
  noWrap?: boolean
  noWidth?: boolean
  noHeight?: boolean
  spaceOut?: boolean
  backgroundColor?: string
  style?: Sx
}

export const Horizontal: React.FC<Props> = ({ children, ...props }) => {
  return (
    <Group spacing={0} sx={getStyle(false, props)}>
      {children}
    </Group>
  )
}

export const Vertical: React.FC<Props> = ({ children, ...props }) => {
  return (
    <Stack spacing={0} sx={getStyle(true, props)}>
      {children}
    </Stack>
  )
}

function getStyle(
  isFlexVertical: boolean,
  { backgroundColor, noWidth, noHeight, noWrap, hAlign, vAlign, style }: Props,
): CSSObject {
  const baseStyle: CSSObject = {
    flexWrap: noWrap ? "nowrap" : undefined,
    width: noWidth ? undefined : "100%",
    height: noHeight ? undefined : "100%",
    backgroundColor,
    ...style,
  }

  if (isFlexVertical) {
    return {
      ...baseStyle,
      alignItems: getAlignment(hAlign),
      justifyContent: getAlignment(vAlign),
    }
  } else {
    return {
      ...baseStyle,
      justifyContent: getAlignment(hAlign),
      alignItems: getAlignment(vAlign),
    }
  }
}

function getAlignment(prop?: FlexAlignment): string | undefined {
  return prop?.toString()
}
