import { MantineSize, PasswordInputProps } from "@mantine/core"
import { useUncontrolled } from "@mantine/hooks"
import React from "react"
import {
  MaskedInput,
  MaskedInputProps,
  VisibilityIconButton,
} from "src/components"

type Props = {
  error?: string
  centered?: boolean
  visible?: boolean
  letterSpacingSize?: MantineSize
  onVisibilityChange?: (_visible: boolean) => void
} & Omit<PasswordInputProps, "error">

export type MaskedPasswordInputProps = Props &
  Omit<MaskedInputProps, keyof Props | "type">

const MaskedPasswordInput: React.VFC<MaskedPasswordInputProps> = ({
  visible,
  disabled,
  onVisibilityChange,
  ...props
}) => {
  const [_visible, setVisibility] = useUncontrolled({
    value: visible,
    defaultValue: false,
    finalValue: false,
    onChange: onVisibilityChange,
  })

  return (
    <MaskedInput
      {...props}
      maskPlaceholder={null}
      type={visible ? "text" : "password"}
      rightSection={
        !disabled && (
          <VisibilityIconButton
            color="black"
            visible={_visible}
            toggleVisibility={() => {
              setVisibility(!_visible)
            }}
          />
        )
      }
    />
  )
}

export default MaskedPasswordInput
