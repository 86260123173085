import { Duration } from "luxon"
import React from "react"

type Props = {
  durationLeft: Duration
}
const CountdownTimer: React.FC<Props> = ({ durationLeft }) => {
  return <span>{durationLeft.toFormat("mm:ss")}</span>
}

export default CountdownTimer
