import React, { FC, ReactNode } from "react"
import styled, { css } from "styled-components"
import theme from "src/assets/theme"
import { Icon } from "src/components"

export type FeedbackProps = {
  message: string
  link?: ReactNode
  altStyle?: boolean
  $green?: boolean
  inlineStyle?: boolean
}

const Feedback: FC<FeedbackProps> = ({
  message,
  link,
  altStyle,
  $green,
  inlineStyle,
}) => (
  <Container $marginTop={altStyle || inlineStyle ? false : true}>
    <Content
      $showTooltip={altStyle || inlineStyle ? false : true}
      $largeIcon={altStyle}
      $green={$green}
      inlineStyle={inlineStyle}
    >
      <Icon id="important" />
      <span>
        {message} {link}
      </span>
    </Content>
  </Container>
)

// Styles
type ContainerProps = {
  $marginTop: boolean
}
const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  ${props =>
    props.$marginTop &&
    css`
      margin-top: 1.5rem;
    `}
`

type ContentProps = {
  $showTooltip?: boolean
  $largeIcon?: boolean
  $green?: boolean
  inlineStyle?: boolean
}
const Content = styled.div<ContentProps>`
  align-items: center;
  background: ${theme.goldTT};
  border-radius: 6px;
  display: flex;
  margin: 0 auto 1.25rem;
  padding: 0.75rem 1rem;
  position: relative;
  width: 100%;
  a {
    color: ${theme.redSS};
    text-decoration: underline;
  }
  svg {
    font-size: 1.375rem;
    margin-right: 0.75rem;
    text-align: center;
  }
  ${props =>
    props.$showTooltip &&
    css`
      justify-content: center;
      margin-bottom: 0;
      width: unset;
      &::before {
        background: ${theme.goldTT};
        border-radius: 4px;
        content: "";
        display: block;
        height: 1rem;
        left: -0.5rem;
        margin: 0 auto;
        position: absolute;
        right: 0;
        top: -0.375rem;
        transform: rotate(45deg);
        width: 1rem;
      }
    `}
  ${props =>
    props.$largeIcon &&
    css`
      svg {
        font-size: 1.5rem;
      }
    `}
    ${props =>
    props.$green &&
    css`
      background: ${theme.greenTT};
      color: ${theme.greenSSSS};
      &::before {
        background: ${theme.greenTT};
      }
    `}
    ${props =>
    props.inlineStyle &&
    css`
      margin: 0;
    `}
  @media (min-width: 50em) {
    margin-bottom: 2rem;
    ${props =>
      props.$showTooltip &&
      css`
        margin-bottom: 0;
      `}
    ${props =>
      props.inlineStyle &&
      css`
        font-size: 0.875rem;
        margin-bottom: 0;
        padding: 0.25rem 0.5rem;
        svg {
          margin-right: 0.25rem;
        }
      `}
  }
`
export default Feedback
