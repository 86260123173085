import React from "react"
import { useAppDispatch, useAppSelector } from "src/hooks"
import {
  selectIsPermanentCardOrderedButNotShipped,
  thunkRefreshPhysicalCardOrder,
} from "src/store"
import { PhysicalCardOrder } from "src/types"
import CardShipmentTracker from "./CardShipmentTracker"

type Props = {
  cardOrder: PhysicalCardOrder
}

const CardShipmentTrackerContainer: React.VFC<Props> = ({ cardOrder }) => {
  const canPollCardOrder = useAppSelector(
    selectIsPermanentCardOrderedButNotShipped,
  )
  const dispatch = useAppDispatch()
  const refreshCardOrder = () => {
    dispatch(thunkRefreshPhysicalCardOrder())
  }
  return (
    <CardShipmentTracker
      cardOrder={cardOrder}
      canPollCardOrder={canPollCardOrder}
      refreshCardOrder={refreshCardOrder}
    />
  )
}

export default CardShipmentTrackerContainer
