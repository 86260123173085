import React from "react"
import { Helmet } from "react-helmet-async"
import { Bucket, Button, Card, Headings } from "src/components"

const Home: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Stretch</title>
      </Helmet>
      <Card $page $fullPage2>
        <Headings.H1>New to Stretch?</Headings.H1>
        <p className="mb-6 mt-2">
          Joining is free and easy. Make sure you have the activation code provided by your Parole Officer or Reentry Case Manager. 
        </p>
        <Button as="Link" to="/signup" $grow>
          Sign Up
        </Button>
      </Card>

      <div>
        <Bucket $spaceM>
          <Headings.H4>Already have an account?</Headings.H4>
          <Button as="Link" to="/login" $secondary>
            Log In
          </Button>
        </Bucket>
      </div>
    </>
  )
}

export default Home
