import React from "react"
import { BankingDetails } from "./BankingDetails"
import { Flex } from "src/layouts"
import { Accordion, AccordionItem, Headings } from "src/components"
import { useMediaQuery } from "@mantine/hooks"
import { useMantineTheme } from "@mantine/core"
import CloseAccount from "./CloseAccount"
import { useState } from "react"
import { useEffect } from "react"
import { useAppSelector } from "src/hooks"
import { selectFinancialAccount } from "src/store"
import { getAccountHolderStatusInfo } from "src/api"

export const BankAccount: React.FC = () => {

  const financialAccount = useAppSelector(selectFinancialAccount)

  const isMobile = useMediaQuery("(max-width: 50rem)")
  const mantineTheme = useMantineTheme()

  const [userRequestedAccountClosureStatus, setUserRequestedAccountClosureStatus] = useState(true);

  const [displayClosureExperience, 
    setDisplayClosureExperience] 
    = useState(false);

  useEffect(() => {
    getAccountHolderStatuses();
  })

  useEffect(() => {
    if(userRequestedAccountClosureStatus 
      || financialAccount?.status == "CLOSED"){
        setDisplayClosureExperience(false);
    }else{
      setDisplayClosureExperience(true);
    }
  }, [userRequestedAccountClosureStatus])

  function getAccountHolderStatuses(){

    getAccountHolderStatusInfo().then(res => {
  
      if(res?.success == true){
  
        if(res?.userRequestedAccountClosure == true){
  
          setUserRequestedAccountClosureStatus(true);
  
        }else{
  
          setUserRequestedAccountClosureStatus(false);
  
        }
        
      }else {
        setUserRequestedAccountClosureStatus(false);
      }
  
    })
  
  }

  return (
    <Flex hAlign="center">
      <Flex column basis={isMobile ? "90%" : "50%"} flex="shrink">
        <Accordion initialOpenAccordion="banking">
          <AccordionItem
            renderLabel={({ isActiveAccordion }) => (
              <Headings.H2
                margin="none"
                style={{
                  color: isActiveAccordion
                    ? mantineTheme.colors["stretch-red"][5]
                    : "black",
                }}
              >
                Banking Details
              </Headings.H2>
            )}
            value="banking"
          >
            <BankingDetails />
          </AccordionItem>

          {displayClosureExperience && (
            <AccordionItem
              renderLabel={({ isActiveAccordion }) => (
                <Headings.H2
                  margin="none"
                  style={{
                    color: isActiveAccordion
                      ? mantineTheme.colors["stretch-red"][5]
                      : "black",
                  }}
                >
                Close Account
                </Headings.H2>
              )}
            value="closure"
          >
            <CloseAccount/>
          </AccordionItem>
          
         
          )}
           
        </Accordion>
      </Flex>
    </Flex>
  )
}
