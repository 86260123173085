import { Form, FormikHelpers } from "formik"
import React, { ReactNode, useState } from "react"
import {
  Flex,
  FlexItem,
  Headings,
  MantineSubmitButton as Button,
  Messages,
  PasscodeInput,
  Spacing,
  StretchForm,
} from "src/components"
import { UiFeedback } from "src/types"
import { isApiAuthNOrAuthZError, parseError } from "src/utilities/errorUtils"
import * as yup from "yup"

const validationSchema = yup.object({
  passcode: yup
    .string()
    .min(4, "Your passcode must be 4 digits")
    .max(4, "Your passcode must be 4 digits")
    .required("Please provide your 4-digit passcode"),
})

type FormData = yup.InferType<typeof validationSchema>

type Props = {
  submitButtonLabel?: string
  submitSuccessMessage?: string
  otherButtons?: ReactNode
  handleSubmit: (_value: FormData) => Promise<void>
}

const PasscodeFormSingleInput: React.VFC<Props> = ({
  submitButtonLabel = "Email me a link",
  submitSuccessMessage,
  otherButtons,
  handleSubmit,
}) => {
  const fieldName = "passcode"
  const initialValues = { passcode: "" }
  const [submitResult, setSubmitResult] = useState<UiFeedback>()
  const [visible, setVisible] = useState(false)

  const onSubmitClicked = async (
    data: FormData,
    helpers: FormikHelpers<FormData>,
  ) => {
    try {
      await handleSubmit(data)
      if (submitSuccessMessage) {
        setSubmitResult({
          message: submitSuccessMessage,
          messageType: "positive",
        })
      }
    } catch (err) {
      let message
      if (isApiAuthNOrAuthZError(err)) {
        message = "That passcode is invalid."
      } else {
        message = parseError(err).errorMessage
      }
      setSubmitResult({ message, messageType: "negative" })
    } finally {
      helpers.setSubmitting(false)
    }
  }

  return (
    <Flex.Vertical hAlign="center" noHeight>
      <StretchForm
        initialValues={initialValues}
        onSubmit={onSubmitClicked}
        validationSchema={validationSchema}
        validateOnBlur
      >
        {({
          errors,
          values,
          handleChange,
          handleBlur,
          touched,
          isValid,
          isSubmitting,
        }) => (
          <Form style={{ width: "100%" }}>
            <Headings.H2>Enter Your Passcode</Headings.H2>
            {!!submitResult && (
              <>
                <Messages.Ephemeral
                  message={submitResult.message}
                  messageType={submitResult.messageType}
                  onTimeout={() => {
                    setSubmitResult(undefined)
                  }}
                />
                <Spacing.Horizontal />
              </>
            )}
            <PasscodeInput
              name={fieldName}
              value={values.passcode}
              error={touched.passcode === true ? errors.passcode : undefined}
              onChange={handleChange}
              onBlur={handleBlur}
              visible={visible}
              onVisibilityChange={() => {
                setVisible(!visible)
              }}
            />
            <Spacing.Horizontal />
            <Flex.Horizontal hAlign="center" noWrap noHeight>
              {!!otherButtons && (
                <>
                  {otherButtons}
                  <Spacing.Vertical />
                </>
              )}
              <FlexItem
                grow="equally"
                component={Button}
                label={submitButtonLabel}
                disabled={!isValid || isSubmitting}
                isLoading={isSubmitting}
              />
            </Flex.Horizontal>
          </Form>
        )}
      </StretchForm>
    </Flex.Vertical>
  )
}

export default PasscodeFormSingleInput
