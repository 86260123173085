import React from "react"
import { MaskedPasswordInput, MaskedPasswordInputProps } from "src/components"

type Props = Omit<
  MaskedPasswordInputProps,
  "mask" | "autoComplete" | "inputMode" | "centered"
>

const PasscodeInput: React.VFC<Props> = props => {
  return (
    <MaskedPasswordInput
      mask="9999"
      autoComplete="off"
      inputMode="numeric"
      centered
      letterSpacingSize="xs"
      {...props}
    />
  )
}

export default PasscodeInput
