import React, { useEffect } from "react"
import { useOnboardingContext } from "src/context/OnboardingContext"
import Address from "./Address"
//import DOB from "./DOB"
import Name from "./Name"
import Passcode from "./Passcode"
//import Phone from "./Phone"
//import PhoneVerify from "./PhoneVerify"
import Review from "./Review"
//import SSN from "./SSN"

const CardApplication: React.VFC = () => {
  const { currentOnboardingScreen } = useOnboardingContext()

  // Since it's an SPA, we want want all route changes to scroll to the top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentOnboardingScreen])

  switch (currentOnboardingScreen) {
    case "Passcode":
      return <Passcode />
    case "Name":
      return <Name />
    case "Address":
      return <Address />
    case "Review":
      return <Review />
    default:
      return null
  }

  /*
  switch (currentOnboardingScreen) {
    case "Passcode":
      return <Passcode />
    case "Name":
      return <Name />
    case "Phone Enter":
      return <Phone />
    case "Phone Verify":
      return <PhoneVerify />
    case "DateOfBirth":
      return <DOB />
    case "SocialSecurityNumber":
      return <SSN />
    case "Address":
      return <Address />
    case "Review":
      return <Review />
    default:
      return null
  }
  */
}

export default CardApplication
