import { Duration } from "luxon"
import { useEffect } from "react"
import { SetIntervalType } from "src/types"
import { clearInterval, setInterval } from "timers"

/**
 * Encapsulates functionality that can be used to repeatedly execute a callback at a given interval.
 * @param shouldRun whether executions should continue or cease. Can be used by the parent to start and stop the executions
 * @param interval the interval between each execution
 * @param callback the callback to execute at the given interval
 */
export function useExecuteOnInterval(
  shouldRun: boolean,
  interval: Duration,
  callback: () => void,
): void {
  useEffect(() => {
    let intervalHandle: SetIntervalType | undefined
    if (shouldRun && intervalHandle === undefined) {
      intervalHandle = setInterval(callback, interval.toMillis())
    }

    if (!shouldRun && intervalHandle !== undefined) {
      clearInterval(intervalHandle)
    }

    return () => {
      if (intervalHandle !== undefined) {
        clearInterval(intervalHandle)
      }
    }
  }, [shouldRun, callback, interval])
}
