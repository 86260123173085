import React from "react"
import { Button, Card } from "src/components"
import { getAccountHolderStatusInfo } from "src/api"
import { useEffect,useState} from "react"
import {Spinner } from "src/components"
import {format, parseISO} from 'date-fns'
import { selectFinancialAccount } from "src/store"
import { useAppSelector } from "src/hooks"


const ClosedFinancialAccountUser: React.VFC = () => {

    const [isLoading, setLoading] = useState(true)
    const [accountClosureStatement,setAccountClosureStatement] = useState("")
    const financialAccount = useAppSelector(selectFinancialAccount)

    useEffect(() => {
        getAccountHolderStatuses();
    })

    function getAccountHolderStatuses(){

        getAccountHolderStatusInfo().then(res => {
      
          if(res?.success == true){

            if(financialAccount?.status == 'CLOSED'){

                if(res.debitAccountClosureDate != undefined){
                    setAccountClosureStatement("Your account was closed on " 
                    + format(parseISO(res.debitAccountClosureDate.toString()), 
                    'MM/dd/yyyy'))
                }else{
                    setAccountClosureStatement("Your account was closed.")
                }

            }else{
                setAccountClosureStatement("")
            }
            
            setLoading(false);

          }else {

            setLoading(false);

          }
      
        })
    }

    return (
        <>
            {isLoading && (
               <Spinner /> 
            )}

            {!isLoading && (
               <Card>
                <p className="font-italic">
                 {accountClosureStatement}
               </p>
               <div className="mb-4"></div>
                <Button disabled >
                   Re-Apply
                </Button>
                </Card> 
            )}
        </>
    )
}

export default ClosedFinancialAccountUser

