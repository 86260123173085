import React from "react"
import { FormFeedback, Spacing } from "src/components"
import { ApplicationDocumentType } from "src/types"
import {
  BIRTH_CERTIFICATE_HELPER_TEXT,
  DRIVERS_LICENSE_HELPER_TEXT,
  LEASE_HELPER_TEXT,
  PASSPORT_HELPER_TEXT,
  PAY_STUB_HELPER_TEXT,
  PHONE_BILL_HELPER_TEXT,
  PRISON_ID_CARD_HELPER_TEXT,
  PRISON_RELEASE_PAPERWORK_HELPER_TEXT,
  SSN_CARD_HELPER_TEXT,
  UTILITY_BILL_HELPER_TEXT,
} from "src/utilities/copy"

type Props = {
  docType: ApplicationDocumentType
  isFileAttached: boolean
}

const DocUploadToolTip: React.VFC<Props> = ({ docType, isFileAttached }) => {
  return (
    <>
      {docType !== "NULL_DOC_TYPE" && !isFileAttached && (
        <FormFeedback
          feedback={{
            message: helperTextMap[docType],
            messageType: "informational",
          }}
        />
      )}

      <Spacing.Horizontal />
    </>
  )
}

const helperTextMap: Record<ApplicationDocumentType, string> = {
  BIRTH_CERTIFICATE: BIRTH_CERTIFICATE_HELPER_TEXT,
  DRIVERS_LICENSE: DRIVERS_LICENSE_HELPER_TEXT,
  LEASE_AGREEMENT: LEASE_HELPER_TEXT,
  PASSPORT: PASSPORT_HELPER_TEXT,
  PAY_STUB: PAY_STUB_HELPER_TEXT,
  PHONE_BILL: PHONE_BILL_HELPER_TEXT,
  PRISON_ID: PRISON_ID_CARD_HELPER_TEXT,
  PRISON_RELEASE_PAPERWORK: PRISON_RELEASE_PAPERWORK_HELPER_TEXT,
  SOCIAL_SECURITY_CARD: SSN_CARD_HELPER_TEXT,
  UTILITY_BILL: UTILITY_BILL_HELPER_TEXT,
  NULL_DOC_TYPE: "",
}

export default DocUploadToolTip
