import React from "react"
import { CardPinInput, Headings } from "src/components"
import { AsyncNoOpFunctionType } from "src/types"

type Props = {
  clientToken: string
  paymentCardId: string
  isHighnoteProdEnv: boolean
  activateCard: AsyncNoOpFunctionType
  onCardActivated: AsyncNoOpFunctionType
}

const TemporaryCardActivation: React.VFC<Props> = ({
  paymentCardId,
  clientToken,
  isHighnoteProdEnv,
  activateCard,
  onCardActivated,
}) => {
  return (
    <>
      <Headings.H4>Set your PIN</Headings.H4>
      <CardPinInput
        submitButtonLabel="Set PIN"
        clientToken={clientToken}
        paymentCardId={paymentCardId}
        isHighnoteProdEnv={isHighnoteProdEnv}
        onSuccessfulSubmit={async () => {
          await activateCard()
          await onCardActivated()
        }}
      />
    </>
  )
}

export default TemporaryCardActivation
