import React from "react"
import { useLocation } from "react-router-dom"
import { Layout } from "src/components"
import Topbar from "src/components/shared/layouts/Topbar"
import { AppLayout } from "src/layouts"

type Props = {
  component: React.ElementType
}
const PublicView: React.VFC<Props> = ({ component: Component }) => {
  const { search } = useLocation()

  return new URLSearchParams(search).get("layout") === "v2" ? (
    <AppLayout bodyComponent={<Component />} topBarComponent={<Topbar />} />
  ) : (
    <Layout type="basic">
      <Component />
    </Layout>
  )
}

export default PublicView
