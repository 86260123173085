import { Box, Sx } from "@mantine/core"
import React from "react"
import theme from "src/assets/theme"

export const Default: React.FC = ({ children }) => {
  return (
    <Panel
      style={{
        backgroundColor: theme.redTTTTT,
        borderRadius: "12px",
        width: "100%",
        padding: "1.25rem",
      }}
    >
      {children}
    </Panel>
  )
}

type PanelProps = {
  style: Sx
}
const Panel: React.FC<PanelProps> = ({ style, children }) => {
  return <Box sx={style}>{children}</Box>
}
