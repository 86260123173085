import { Box, createPolymorphicComponent, CSSObject } from "@mantine/core"
import React, { forwardRef } from "react"

export type FlexSizing = "equally"
export type FlexAlign = "equally"
type Props = {
  grow?: FlexSizing
  shrink?: FlexSizing
  align?: "flex-start" | "flex-end"
}

const InnerFlexItem = forwardRef<HTMLDivElement, Props>(
  ({ children, ...props }, ref) => {
    return (
      <Box
        // define default component, you will be able to override it with `component` prop from ...others
        component="div"
        sx={getFlexStyle(props)}
        ref={ref}
        {...props}
      >
        {children}
      </Box>
    )
  },
)
InnerFlexItem.displayName = "InnerFlexItem"

function getFlexStyle({ grow, shrink, align }: Props): CSSObject {
  return {
    flexGrow: computeSizing(grow) ?? 0,
    flexShrink: computeSizing(shrink) ?? 1,
    flexBasis: grow === "equally" || shrink === "equally" ? "100%" : "auto",
    alignSelf: align,
  }
}

function computeSizing(option?: FlexSizing): number | undefined {
  return option === "equally" ? 1 : undefined
}

const FlexItem = createPolymorphicComponent<"div", Props>(InnerFlexItem)
export default FlexItem
