import React from "react"
import { notifyCardPinChanged } from "src/api"
import { CardPinContainer } from "src/components"
import { useAppDispatch } from "src/hooks/redux"
import {
  thunkActivatePaymentCard,
  thunkRefreshPaymentCard,
  thunkRefreshUserSession,
} from "src/store"
import { PaymentCard } from "src/types"
import PermanentCardActivation from "./PermanentCardActivation"
import TemporaryCardActivation from "./TemporaryCardActivation"

type Props = {
  card: PaymentCard
}

const CardActivationContainer: React.VFC<Props> = ({ card }) => {
  const dispatch = useAppDispatch()

  const activateCard = async (cardLastFour?: string) => {
    await dispatch(
      thunkActivatePaymentCard({
        lifecycle: card.lifecycle,
        cardLastFour,
      }),
    )
  }

  const handleCardActivated = async () => {
    await dispatch(thunkRefreshPaymentCard(card.lifecycle))
    await notifyCardPinChanged({
      firstTime: true,
      lifecycle: card.lifecycle,
    })

    // This call is specifically for Perm card activation scenario: certain business logic
    // requires that perm card is actvated before the user can perform actions (like change address)
    // This call ensures the app has the latest user state after perm card activation so the business
    // rule is evaluated correctly
    await dispatch(thunkRefreshUserSession())
  }

  const CardActivationComponent =
    card.lifecycle === "TEMPORARY"
      ? TemporaryCardActivation
      : PermanentCardActivation

  return (
    <CardPinContainer
      card={card}
      renderPinComponent={props => (
        <CardActivationComponent
          {...props}
          activateCard={activateCard}
          onCardActivated={handleCardActivated}
        />
      )}
    />
  )
}

export default CardActivationContainer
