import styled, { css } from "styled-components"

type BannerProps = {
  to?: string
}
const Banner = styled.div<BannerProps>`
  align-items: center;
  background: ${({ theme }) => theme.blueTTTT};
  border-left: 0.375rem solid ${({ theme }) => theme.blueT};
  border-radius: 6px;
  color: ${({ theme }) => theme.blueSS};
  display: flex;
  font-weight: bold;
  margin-bottom: 1.5rem;
  padding: 0.625rem 1rem;
  svg {
    font-size: 1.5rem;
    margin-right: 0.5rem;
    vertical-align: -0.375rem;
  }
  ${props =>
    props.to &&
    css`
      display: block;
      &:hover,
      &:focus {
        background: ${({ theme }) => theme.blueTTT};
        color: ${({ theme }) => theme.blueSSSS};
      }
    `}
`

export default Banner
