import { Form } from "formik"
import React, { useState } from "react"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router-dom"
import * as yup from "yup"
import { PasswordInput } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { resetPasscode } from "src/api"
import {
  Card,
  Feedback,
  Headings,
  MantineSubmitButton as Button,
  Spacing,
  StretchForm,
} from "src/components"
import { LoginPageRoutingState } from "src/types"
import { parseError } from "src/utilities/errorUtils"
import { LOGIN_PAGE_ROUTE, redirectTo } from "src/utilities/routingUtils"

const validationSchema = yup.object({
  passcode: yup
    .string()
    .min(4, "Passcode must be at least 4 numbers")
    .required("Please provide a 4-digit passcode"),
  passcodeConfirmation: yup
    .string()
    .oneOf([yup.ref("passcode")], "Passcodes must match")
    .required("Please confirm your new passcode."),
})

const ResetPasscode: React.VFC = () => {
  const { code } = useParams<{ code: string }>()
  const initialValues = {
    passcode: "",
    passcodeConfirmation: "",
  }
  const [visible, { toggle }] = useDisclosure(false)

  const [error, setError] = useState("")

  const handleSubmit = async ({
    passcode,
    passcodeConfirmation,
  }: typeof initialValues) => {
    try {
      const { email } = await resetPasscode({
        passcode,
        passcodeConfirmation,
        token: code,
      })

      redirectTo(LOGIN_PAGE_ROUTE, {
        email,
        message:
          "Your passcode has been reset. Please enter it to\u00A0continue.",
        messageType: "positive",
      } as LoginPageRoutingState)
    } catch (error) {
      const { errorMessage } = parseError(error)
      setError(errorMessage)
    }
  }

  return (
    <>
      <Helmet>
        <title>Reset Passcode – Stretch</title>
      </Helmet>
      <Card $page $fullPage>
        <Headings.H3>Let’s Reset Your Passcode</Headings.H3>
        <p>This is a secret four-digit number used to access your account.</p>

        <StretchForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            setFieldTouched,
            isSubmitting,
            isValid,
          }) => {
            return (
              <Form>
                <PasswordInput
                  name="passcode"
                  label="New Passcode"
                  value={values.passcode}
                  error={touched.passcode ? errors.passcode : undefined}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  onBlur={() => {
                    handleBlur("passcode")
                    values.passcodeConfirmation.length > 0 &&
                      setFieldTouched("passcodeConfirmation")
                  }}
                  visible={visible}
                  onVisibilityChange={toggle}
                  inputMode="numeric"
                />
                <PasswordInput
                  name="passcodeConfirmation"
                  label="Confirm New Passcode"
                  value={values.passcodeConfirmation}
                  error={
                    touched.passcodeConfirmation
                      ? errors.passcodeConfirmation
                      : undefined
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  visible={visible}
                  onVisibilityChange={toggle}
                  inputMode="numeric"
                />
                <Spacing.Horizontal />
                <Button
                  disabled={!isValid || isSubmitting}
                  isLoading={isSubmitting}
                  label="Reset Passcode"
                />
                {!!error && <Feedback message={error} />}
              </Form>
            )
          }}
        </StretchForm>
      </Card>
    </>
  )
}

export default ResetPasscode
