import React, { useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { useLocation } from "react-router-dom"

import { Card, Headings } from "src/components"
import { useAppDispatch, useAppSelector } from "src/hooks/redux"
import { selectIsLoggedIn, thunkLogoutUser } from "src/store"
import { LogoutPageLocationState } from "src/types"
import { redirectToLogin } from "../utilities/routingUtils"

const LogoutPage: React.VFC = () => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  const dispatch = useAppDispatch()
  const location = useLocation<LogoutPageLocationState>()
  const { message } = location.state || {}

  useEffect(() => {
    const logout = async () => {
      if (isLoggedIn) {
        await dispatch(thunkLogoutUser())
      }
      redirectToLogin(message)
    }

    logout()
  }, [isLoggedIn, message])

  return (
    <>
      <Helmet>
        <title>Logging Out – Stretch</title>
      </Helmet>
      <Card $page $fullPage4>
        <Headings.H1>Logging out</Headings.H1>
        <p className="mb-0">You will be automatically redirected...</p>
      </Card>
    </>
  )
}

export default LogoutPage
