/* eslint-disable no-unused-vars */
import {
  ApolloClient,
  ApolloLink,
  gql,
  HttpLink,
  InMemoryCache,
} from "@apollo/client"
import { isHighnoteProdEnv } from "src/config"
import {
  ApplicationDocumentCategory,
  ApplicationDocumentType,
  FinancialAccountType,
} from "src/types"
import { DEFAULT_ERROR_MESSAGE } from "src/utilities"

const TEST_URL = "https://api.us.test.highnoteplatform.com/graphql"
const PROD_URL = "https://api.us.highnoteplatform.com/graphql"

const highnoteServerUrl = isHighnoteProdEnv ? PROD_URL : TEST_URL

const httpLink = new HttpLink({ uri: highnoteServerUrl })

const authMiddleware = (authToken: string) =>
  new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    if (authToken) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          authorization: `Bearer ${authToken}`,
        },
      }))
    }

    return forward(operation)
  })

function getClient(authToken: string) {
  return new ApolloClient({
    link: authMiddleware(authToken).concat(httpLink),
    cache: new InMemoryCache(),
  })
}

type UserError = {
  code: string
  description: string
}

const DIRECT_DEPOSIT_DETAILS_QUERY = gql`
  query ViewDirectDepositDetails($id: ID!) {
    node(id: $id) {
      ... on DirectDepositDetail {
        id
        restrictedDetails {
          __typename
          ... on DirectDepositDetailRestrictedDetails {
            number
            routingNumber
            type
            bank {
              name
              address {
                streetAddress
                extendedAddress
                postalCode
                region
                locality
                countryCodeAlpha3
              }
            }
          }
        }
      }
    }
  }
`
export type DirectDepositDetailsData = {
  node: {
    id: string
    restrictedDetails: {
      number: string
      routingNumber: string
      type?: FinancialAccountType | null
      bank: {
        name: string
        address: {
          streetAddress: string
          extendedAddress: string
          postalCode: string
          region: string
          locality: string
          countryCodeAlpha3: string
        }
      }
    }
  }
}
type IDInput = {
  id: string
}

export function getDirectDepositDetails(
  id: string,
  authToken: string,
): Promise<DirectDepositDetailsData> {
  return getClient(authToken)
    .query<DirectDepositDetailsData, IDInput>({
      query: DIRECT_DEPOSIT_DETAILS_QUERY,
      variables: { id },
    })
    .then(result => result.data)
}

export type DocUploadSessionId = string
export type UploadedDocumentStatus =
  | "PENDING" // The upload link has been created and is waiting for a file to be uploaded
  | "IN_PROGRESS" // A file upload is currently in progress and is being processed
  | "DENIED" // A file upload has been denied due to malicious content or malware present in the file
  | "COMPLETED" // A file upload completed successfully and has been securely stored in the document store
  | "FAILED" // A file upload has failed due to server communication and needs to be retried
export type UploadedDocument = {
  id: string
  documentType: ApplicationDocumentType
  status: UploadedDocumentStatus
}
export type DocUploadSessionStatus =
  | "CREATED" // The document upload session is created, but has not been initiated
  | "INITIATED" // The document upload session has been initiated and files can now be uploaded to the session
  | "IN_PROGRESS" // A session has started and a file upload request has been initiated
  | "SUBMITTED" // A session has ended. Files can no longer be uploaded to the session
  | "EXPIRED" //A session has expired due to inactivity. A session can result in an expired state if it is never transitioned to submitted. Files can no longer be uploaded to the session

export type DocummentUploadConstraint = {
  documentType: ApplicationDocumentCategory
  numberOfDocuments: number
}
export type UploadRequirement = {
  constraints: DocummentUploadConstraint[]
}
export type DocumentUploadSessionPayload = {
  id: string
  status: DocUploadSessionStatus
  primaryDocumentTypes: string[]
  secondaryDocumentTypes?: string[]
  uploadRequirements: UploadRequirement[]
  documents?: UploadedDocument[]
  errors?: UserError[]
}
export type DocumentUploadSessionInput = {
  input: { documentUploadSessionId: string }
}

const GET_DOC_UPLOAD_SESSION_QUERY = gql`
  query findDocumentUploadSession($id: ID!) {
    node(id: $id) {
      __typename
      ... on USAccountHolderApplicationDocumentUploadSession {
        __typename
        id
        status
        primaryDocumentTypes
        secondaryDocumentTypes
        supportingDocumentTypes
        uploadRequirements {
          constraints {
            ... on DocumentUploadRequirementConstraint {
              documentType
              numberOfDocuments
            }
          }
        }
        documents {
          ... on DocumentUploadLink {
            id
            status
            documentCategoryType
            documentType
            status
            documentUploadSession {
              id
              status
            }
          }
        }
      }
    }
  }
`
export function getDocUploadSession(
  documentUploadSessionId: string,
  authToken: string,
): Promise<DocumentUploadSessionPayload> {
  return getClient(authToken)
    .query<{ node: DocumentUploadSessionPayload }, IDInput>({
      query: GET_DOC_UPLOAD_SESSION_QUERY,
      variables: { id: documentUploadSessionId },
    })
    .then(result => result.data.node)
}

const START_DOC_UPLOAD_MUTATION = gql`
  mutation startDocumentUploadSession(
    $input: StartDocumentUploadSessionInput!
  ) {
    startDocumentUploadSession(input: $input) {
      __typename
      ... on USAccountHolderApplicationDocumentUploadSession {
        id
        status
        primaryDocumentTypes
        secondaryDocumentTypes
        supportingDocumentTypes
        uploadRequirements {
          constraints {
            ... on DocumentUploadRequirementConstraint {
              documentType
              numberOfDocuments
            }
          }
        }
      }
      ... on UserError {
        errors {
          code
          description
        }
      }
    }
  }
`
export function startDocUploadSession(
  documentUploadSessionId: string,
  authToken: string,
): Promise<DocumentUploadSessionPayload | null | undefined> {
  return getClient(authToken)
    .mutate<
      { startDocumentUploadSession: DocumentUploadSessionPayload },
      DocumentUploadSessionInput
    >({
      mutation: START_DOC_UPLOAD_MUTATION,
      variables: {
        input: {
          documentUploadSessionId,
        },
      },
    })
    .then(result => result.data?.startDocumentUploadSession)
}

const END_DOC_UPLOAD_MUTATION = gql`
  mutation endDocumentUploadSession($input: EndDocumentUploadSessionInput!) {
    endDocumentUploadSession(input: $input) {
      __typename
      ... on USAccountHolderApplicationDocumentUploadSession {
        id
        status
        primaryDocumentTypes
        secondaryDocumentTypes
        supportingDocumentTypes
        uploadRequirements {
          constraints {
            ... on DocumentUploadRequirementConstraint {
              documentType
              numberOfDocuments
            }
          }
        }
      }
      ... on UserError {
        errors {
          code
          description
        }
      }
    }
  }
`
export function endDocUploadSession(
  documentUploadSessionId: string,
  authToken: string,
): Promise<DocumentUploadSessionPayload | null | undefined> {
  return getClient(authToken)
    .mutate<
      { endDocumentUploadSession: DocumentUploadSessionPayload },
      DocumentUploadSessionInput
    >({
      mutation: END_DOC_UPLOAD_MUTATION,
      variables: {
        input: {
          documentUploadSessionId,
        },
      },
    })
    .then(result => result.data?.endDocumentUploadSession)
}

const DOC_UPLOAD_LINK_MUTATION = gql`
  mutation CreateDocumentUploadLink($input: CreateDocumentUploadLinkInput!) {
    createDocumentUploadLink(input: $input) {
      __typename
      ... on DocumentUploadLink {
        id
        documentUploadSession {
          id
          status
        }
        documentType
        status
        documentCategoryType
        uploadUrl
      }
      ... on UserError {
        errors {
          code
          description
        }
      }
    }
  }
`
export type CreateDocumentUploadLinkPayload = {
  id: string
  status: "PENDING" | "IN_PROGRESS" | "COMPLETED" | "FAILED" | "DENIED"
  documentUploadSession: {
    id: string
    status: string
  }
  documentType: ApplicationDocumentType
  documentCategoryType:
    | "PRIMARY_DOCUMENT_TYPE"
    | "SECONDARY_DOCUMENT_TYPE"
    | "SUPPORTING_DOCUMENT_TYPE"
  uploadUrl: string
}
export type CreateDocumentUploadLinkParams = {
  documentUploadSessionId: string
  documentType: string
}
type CreateDocumentUploadLinkInput = {
  input: CreateDocumentUploadLinkParams
}

export function createDocUploadLink(
  params: CreateDocumentUploadLinkParams,
  authToken: string,
): Promise<CreateDocumentUploadLinkPayload | null | undefined> {
  return getClient(authToken)
    .mutate<
      { createDocumentUploadLink: CreateDocumentUploadLinkPayload },
      CreateDocumentUploadLinkInput
    >({
      mutation: DOC_UPLOAD_LINK_MUTATION,
      variables: {
        input: params,
      },
    })
    .then(result => result.data?.createDocumentUploadLink)
}

export function uploadFile(uploadUrl: string, file: File): Promise<Response> {
  return fetch(uploadUrl, {
    method: "PUT",
    body: file,
  })
}

const TOKENIZE_ACCOUNT_HOLDER_MUTATION = gql`
  mutation TokenizeUSPersonAccountHolder(
    $input: CreateUSPersonAccountHolderInput!
  ) {
    tokenizeUSPersonAccountHolder(input: $input) {
      ... on USPersonAccountHolderToken {
        token
      }
      ... on UserError {
        errors {
          path
          description
        }
      }
      ... on AccessDeniedError {
        message
      }
    }
  }
`
export type NonDescriptUserParams = {
  email:string,
  firstname:string,
  lastname:string,
  streetaddress:string,
  postalcode:string,
  locality:string,
  region:string,
}

export type USPersonAccountHolderParams = {
  personAccountHolder: {
    email: string
    name: {
      givenName: string
      familyName: string
    }
    billingAddress: {
      streetAddress: string
      extendedAddress?: string
      postalCode: string
      locality: string
      region: string
      countryCodeAlpha3: string
    }
    phoneNumber: {
      countryCode: string
      number: string
      label: "MOBILE"
    }
    identificationDocument: {
      socialSecurityNumber: {
        number: string
        countryCodeAlpha3: "USA"
      }
    }
    dateOfBirth: string
    externalId: string
  }
}

type CreateUSPersonAccountHolderInput = {
  input: USPersonAccountHolderParams
}

type TokenizeUSPersonAccountHolderPayload = {
  token: string
  errors?: UserError[]
}

export function tokenizeUSPersonAccountHolder(
  params: USPersonAccountHolderParams,
  authToken: string,
): Promise<TokenizeUSPersonAccountHolderPayload | null | undefined> {
  return getClient(authToken)
    .mutate<
      { tokenizeUSPersonAccountHolder: TokenizeUSPersonAccountHolderPayload },
      CreateUSPersonAccountHolderInput
    >({
      mutation: TOKENIZE_ACCOUNT_HOLDER_MUTATION,
      variables: {
        input: params,
      },
    })
    .then(result => result.data?.tokenizeUSPersonAccountHolder)
}

export function parseHnApiErrors(
  responseObject?: Partial<{ errors?: UserError[] } | null>,
): string {
  if (responseObject?.errors) {
    return responseObject.errors[0]?.description ?? DEFAULT_ERROR_MESSAGE
  }

  return DEFAULT_ERROR_MESSAGE
}
