import React from "react"
import { useState } from 'react';
//import { BaseSyntheticEvent } from "react"
import { Input, Container, ActionIcon} from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { FinancialAccount } from "src/types"
import { Icon } from "src/components"

type Props = {
    financialAccount?: FinancialAccount
    searchBarClicked:any
    clearSearchBarClicked:any
}

  const SearchBar: React.VFC<Props> = ({searchBarClicked, clearSearchBarClicked}) => {
  const [value, setValue] = useState('');

  const handleSearchBarTextChange = (evt:any) => {
    setValue(evt.currentTarget.value)
    searchBarClicked(evt);
  }

  const handleSearchBarClear = (evt:any) => {
    setValue('');
    clearSearchBarClicked(evt);
  }

  /*
  <ErrorIcon>
          <Icon id="important" />
        </ErrorIcon>
  */

    return (
      <>
        <Container style={{width:"100%"}}>
        
            
            <Input 
              icon={<IconSearch size={18} />}
              placeholder="Search transactions"
              radius="xl"
              value={value}
              onClick={searchBarClicked}
              onChange={handleSearchBarTextChange}
              styles={{input:{backgroundColor:"black", 
              accentColor: "white", 
              textDecorationColor: "white", 
              caretColor:"white", color:"white",
              fontSize:14, fontFamily:"Poppins", 
              fontWeight:400
              }}}
              rightSection={
                <ActionIcon aria-label="Clear input"
                onClick={handleSearchBarClear} style={{backgroundColor:"transparent", color:"lightgray",accentColor:"transparent"}}>
                  <Icon id="clearSearchBar" />
                </ActionIcon>
              }
              
            >
              
            </Input>
           
     
        </Container>
      </>
    )
  }

  export default SearchBar;


