import React from "react"
import { ActionIcon, MantineSize } from "@mantine/core"
import { IconEye, IconEyeOff } from "@tabler/icons"
import { NoOpFunctionType } from "src/types"

type Props = {
  color?: string
  size?: MantineSize
  visible: boolean
  toggleVisibility: NoOpFunctionType
}
const VisibilityIconButton: React.VFC<Props> = ({
  color,
  size = "lg",
  visible,
  toggleVisibility,
}) => {
  const iconSizes = {
    xs: ".75rem",
    sm: ".09375rem",
    md: "1.0625rem",
    lg: "1.1875rem",
    xl: "1.3125rem",
  }

  const iconSize = iconSizes[size]

  return (
    <ActionIcon<"button">
      onMouseDown={event => {
        event.preventDefault()
        toggleVisibility()
      }}
      onKeyDown={event => {
        if (event.key === " ") {
          event.preventDefault()
          toggleVisibility()
        }
      }}
    >
      {visible ? (
        <IconEye color={color} size={iconSize} />
      ) : (
        <IconEyeOff color={color} size={iconSize} />
      )}
    </ActionIcon>
  )
}

export default VisibilityIconButton
