import { Text } from "@mantine/core"
import React from "react"
import { Helmet } from "react-helmet-async"

import { Bucket, Flex, Headings, Loader } from "src/components"
import { useAppConfigContext } from "src/context/AppConfigContext"
import {
  CardApplication,
  CardApplicationStatus,
  VerificationTag,
} from "src/types"
import { ManageDocUploadV1, ManageDocUploadV2 } from "../DocUpload"

const pendingExplainer =
  "Hold tight! We’re now processing your application. This may take a few\u00A0moments."
const manualReviewExplainer =
  "Your application has been received and is under review. We will update you on next steps soon."
const failedExplainer =
  "Thank you for submitting your application for a Stretch checking account and debit card. Unfortunately, your application has not been\u00A0approved."

const mapTag = (verificationTag: VerificationTag) => {
  switch (verificationTag) {
    case "ADDRESS_MISMATCH":
      return "address"
    case "DOB_MISKEY":
    case "DOB_MISMATCH":
      return "date of birth"
    case "NAME_MISMATCH":
      return "legal name"
    case "PHONE_MISMATCH":
      return "phone number"
    case "SSN_MISKEY":
    case "SSN_MISMATCH":
      return "social security number"
    case "MEDIUM_ABUSE_SCORE":
    case "MEDIUM_THEFT_SCORE":
    case "HIGH_ABUSE_SCORE":
    case "HIGH_THEFT_SCORE":
      return "identity"

    default: {
      const value: never = verificationTag
      throw new Error(value)
    }
  }
}

const docsRequiredExplainer = (verificationTags: VerificationTag[]) => {
  let descriptionText
  if (verificationTags.length < 1) {
    descriptionText = "identity"
  } else if (verificationTags.length === 1) {
    descriptionText = mapTag(verificationTags[0])
  } else {
    const descriptors = []
    for (let index = 0; index < verificationTags.length - 1; index++) {
      const tag = verificationTags[index]
      descriptors.push(mapTag(tag))
    }
    descriptionText =
      descriptors.join(", ") +
      ` and ${mapTag(verificationTags[verificationTags.length - 1])}`
  }

  return (
    <>
      We need extra documents to help us verify your{" "}
      <Text span weight="bold" color="stretch-red">
        {descriptionText}
      </Text>
    </>
  )
}

const isProcessing = (status: CardApplicationStatus) =>
  ["PENDING"].includes(status)
const isRejected = (status: CardApplicationStatus) =>
  ["DENIED", "CLOSED"].includes(status)
const needsDocuments = (status: CardApplicationStatus) => {
  return status === "DOCUMENT_UPLOAD_REQUIRED"
}
const statusExplainer = (
  status: CardApplicationStatus,
  useDocUploadV2: boolean,
  tags?: VerificationTag[],
) => {
  switch (status) {
    case "PENDING":
      return pendingExplainer
    case "MANUAL_REVIEW":
      return manualReviewExplainer
    case "DOCUMENT_UPLOAD_REQUIRED":
      return !useDocUploadV2 ? docsRequiredExplainer(tags ?? []) : ""

    case "DENIED":
    case "CLOSED":
      return failedExplainer
    default:
      return ""
  }
}

const statusHeading = (status: CardApplicationStatus) => {
  switch (status) {
    case "PENDING":
      return "Processing"
    case "MANUAL_REVIEW":
      return "Reviewing"
    case "DENIED":
    case "CLOSED":
      return "Not Approved"
    case "APPROVED":
      return "Approved"
    default:
      return null
  }
}

const helpEmail = "mailto:help@stretch.money"
type ApplicationStatusProps = {
  cardApplication: CardApplication
}
const ApplicationStatus: React.VFC<ApplicationStatusProps> = ({
  cardApplication,
}) => {
  const { status, verificationTags } = cardApplication
  const { appConfig } = useAppConfigContext()
  const useDocUploadV2 = appConfig?.docUploadV2Enabled === true

  return (
    <Flex.Vertical hAlign="center">
      <Helmet>
        <title>Card Application Status – Stretch</title>
      </Helmet>

      <Headings.H1>Card Application Status</Headings.H1>

      <Headings.H2>{statusHeading(status)}</Headings.H2>
      <Text>{statusExplainer(status, useDocUploadV2, verificationTags)}</Text>
      {status === "PENDING" && (
        <p>
          Questions? Reach us at <a href={helpEmail}>help@stretch.money</a> for
          any immediate&nbsp;concerns.
        </p>
      )}
      {isRejected(status) && (
        <p>
          If you believe this has been done in error or would like more
          information, please send an email to{" "}
          <a href={helpEmail}>help@stretch.money</a> for more information.
        </p>
      )}
      {isProcessing(status) && (
        <>
          <div className="mt-12 mb-8">
            <Bucket $center>
              <Loader />
            </Bucket>
          </div>
        </>
      )}
      {needsDocuments(status) && !useDocUploadV2 && (
        <ManageDocUploadV1
          documentUploadSessionId={cardApplication.documentUploadSessionId!}
        />
      )}
      {needsDocuments(status) && useDocUploadV2 && (
        <ManageDocUploadV2
          documentUploadSessionId={cardApplication.documentUploadSessionId!}
          verificationTags={verificationTags!}
        />
      )}
    </Flex.Vertical>
  )
}

export default ApplicationStatus
