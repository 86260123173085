import axios, { AxiosResponse } from "axios"
import {
  AccountHolderPersonalDetails,
  Address,
  ApplicationDocumentType,
  CardApplication,
  CardApplicationStatus,
  ClientToken,
  CurrencyCode,
  FinancialAccount,
  FinancialAccountStatus,
  PaymentCard,
  PaymentCardFormFactor,
  PaymentCardLifecycle,
  PaymentCardStatus,
  PaymentCardTokenPermission,
  PhysicalCardOrder,
  PhysicalCardOrderStatus,
  Transaction,
  TransactionState,
  TransactionType,
  VerificationTag,
  AccountHolderStatusResponse,
} from "src/types"
import {
  AddressResponseSchema,
  ServerUser,
  validateUserResponse,
} from "src/types/userTypes"
import * as yup from "yup"
import { generateStretchAPIURL, requestConfiguration } from "./common"

export const START_BANKING_URL = generateStretchAPIURL(
  "/api/v1/user/banking/start",
)

export type StartBankingParams = {
  firstName: string
  lastName: string
  activationCode: string
}
export function startBankingOnboarding(
  params: StartBankingParams,
): Promise<ServerUser> {
  const { firstName, lastName, activationCode } = params
  const request = {
    user: {
      first_name: firstName,
      last_name: lastName,
      activation_code: activationCode,
    },
  }
  return axios
    .post<unknown>(START_BANKING_URL, request, requestConfiguration())
    .then(validateUserResponse)
}

// TODO: Move all response schema and validate*** methods to ***types file
const ClientTokenResponseSchema = yup
  .object({
    value: yup.string().required(),
    expiresAt: yup.string().required(),
  })
  .camelCase()
  .required()
function validateClientTokenResponse(response: AxiosResponse<unknown>) {
  return ClientTokenResponseSchema.validateSync(response.data, {
    stripUnknown: true,
  }) as ClientToken
}

export const CREATE_ACCOUNT_HOLDER_TOKEN_URL = generateStretchAPIURL(
  "/api/v1/banking/token/create-account-holder",
)
export function getCreateAccountHolderToken(): Promise<ClientToken> {
  return axios
    .post<unknown>(
      CREATE_ACCOUNT_HOLDER_TOKEN_URL,
      undefined,
      requestConfiguration(),
    )
    .then(validateClientTokenResponse)
}

export const CREATE_BANKING_USER_URL = generateStretchAPIURL(
  "/api/v1/banking/user",
)
export function createBankingUser(): Promise<void> {
  return axios
    .post<void>(CREATE_BANKING_USER_URL, undefined, requestConfiguration())
    .then(response => response.data)
}

const CREATE_BANKING_USER_FROM_TOKEN_URL = generateStretchAPIURL(
  "/api/v1/banking/user/token",
)
export function createBankingUserFromToken(token: string): Promise<void> {
  return axios
    .post<void>(
      CREATE_BANKING_USER_FROM_TOKEN_URL,
      { token },
      requestConfiguration(),
    )
    .then(response => response.data)
}

const CREATE_NONDESCRIPT_BANKING_USER_URL = generateStretchAPIURL(
  "/api/v1/banking/user/nondescript",
)

export function createNonDescriptBankingUser(email: string, firstname: string, lastname: string, 
  streetaddress: string, locality: string, postalcode: string, region: string): Promise<void> {

  console.log(firstname);
  console.log(lastname);
  console.log(streetaddress); 
  return axios
    .post<void>(
      CREATE_NONDESCRIPT_BANKING_USER_URL,
      { email, firstname, lastname, streetaddress, locality, postalcode, region},
      requestConfiguration(),
    )
    .then(response => response.data)
}


const CardApplicationResponseSchema = yup
  .object({
    id: yup.string().required(),
    status: yup.mixed<CardApplicationStatus>().required(),
    documentUploadSessionId: yup.string().optional(),
    verificationTags: yup.mixed<VerificationTag>().optional(),
  })
  .camelCase()
  .required()
function validateCardApplicationResponse(response: AxiosResponse<unknown>) {
  return CardApplicationResponseSchema.validateSync(response.data, {
    stripUnknown: true,
  }) as CardApplication
}
export const CARD_APPLICATION_URL = generateStretchAPIURL(
  "/api/v1/banking/card/apply",
)
export function applyForDebitCard(): Promise<CardApplication> {
  return axios
    .post<CardApplication>(
      CARD_APPLICATION_URL,
      undefined,
      requestConfiguration(),
    )
    .then(validateCardApplicationResponse)
}

export function getCardApplication(): Promise<CardApplication> {
  return axios
    .get<CardApplication>(CARD_APPLICATION_URL, requestConfiguration())
    .then(validateCardApplicationResponse)
}

export const DOC_UPLOAD_TOKEN_URL = generateStretchAPIURL(
  "/api/v1/banking/token/doc-upload",
)
export function getDocumentUploadToken(): Promise<ClientToken> {
  return axios
    .post<unknown>(DOC_UPLOAD_TOKEN_URL, undefined, requestConfiguration())
    .then(validateClientTokenResponse)
}

export const DOC_UPLOAD_COMPLETED_URL = generateStretchAPIURL(
  "/api/v1/banking/doc-upload/complete",
)
export function notifyDocUploadCompleted(
  submittedDocTypes: ApplicationDocumentType[],
): Promise<void> {
  return axios
    .post<unknown>(
      DOC_UPLOAD_COMPLETED_URL,
      {
        submitted_document_types: submittedDocTypes,
      },
      requestConfiguration(),
    )
    .then()
}

const TransactionResponseSchema = yup.array(
  yup
    .object({
      id: yup.string().required(),
      createdAt: yup.string().required(),
      updatedAt: yup.string().optional(),
      state: yup.mixed<TransactionState>().required(),
      amount: yup.number().required(),
      type: yup.mixed<TransactionType>().required(),
      merchantName: yup.string().optional().default(""),
      merchantCategory: yup.string().optional().default(""),
      currencyCode: yup.mixed<CurrencyCode>().required(),
      enrichedDescription: yup.string().optional(),
      personalCategoryUrl: yup.string().optional(),
      transactionLogoUrl: yup.string().optional(),
      requestedAmount: yup.number().optional(),
      approvedAmount: yup.number().optional(),
      originalAmount: yup.number().optional(),
      primaryPersonalCategory: yup.string().optional(),
      cardLastFour: yup.string().optional(),
      enrichedMerchantName: yup.string().optional(),
      derivedTransactionType: yup.string().optional()
    })
    .camelCase(),
)
export const FINANCIAL_ACCOUNT_URL = generateStretchAPIURL(
  "/api/v1/banking/fin-account",
)

export const FINANCIAL_ACCOUNT_AND_ORDER_CARD_URL = generateStretchAPIURL(
  "/api/v1/banking/fin-account/order-card",
)

const FinancialAccountResponseSchema = yup
  .object({
    status: yup.mixed<FinancialAccountStatus>().required(),
    availableCashBalance: yup.number().required(),
    currencyCode: yup.mixed<CurrencyCode>().required(),
    directDepositDetailsId: yup.string().optional().default(""),
    transactions: TransactionResponseSchema.optional().default(undefined),
    debitAccountClosureDate: yup.date().optional().default(undefined)
  })
  .camelCase()
  .required()

function validateFinancialAccountResponse(response: AxiosResponse<unknown>) {
  return FinancialAccountResponseSchema.validateSync(response.data, {
    stripUnknown: true,
  }) as FinancialAccount
}
export function issueFinancialAccount(): Promise<FinancialAccount> {
  return axios
    .post<unknown>(FINANCIAL_ACCOUNT_URL, undefined, requestConfiguration())
    .then(validateFinancialAccountResponse)
}

export function issueFinancialAccountAndOrderCard(): Promise<FinancialAccount> {
  return axios
    .post<unknown>(FINANCIAL_ACCOUNT_AND_ORDER_CARD_URL, undefined, requestConfiguration())
    .then(validateFinancialAccountResponse)
}

export function getFinancialAccount(
  withTransactions: boolean,
): Promise<FinancialAccount> {
  return axios
    .get<unknown>(
      FINANCIAL_ACCOUNT_URL,
      requestConfiguration({ params: { transactions: withTransactions }, timeout:500000 }),
    )
    .then(validateFinancialAccountResponse)
}



export function deleteFinancialAccount(): Promise<unknown> { 
  return new Promise((resolve, reject) => {
    axios.delete<unknown>(FINANCIAL_ACCOUNT_URL, requestConfiguration())
    .then(res => {
      resolve(res?.status);
    }).catch(err => {
      reject(err?.response);
    })
  }); 
}

export const DIRECT_DEPOSIT_TOKEN_URL = generateStretchAPIURL(
  "/api/v1/banking/token/direct-deposit",
)
export function getDirectDepositDetailsToken(): Promise<ClientToken> {
  return axios
    .post<unknown>(DIRECT_DEPOSIT_TOKEN_URL, undefined, requestConfiguration())
    .then(validateClientTokenResponse)
}

export type ActivatePaymentCardParams = {
  lifecycle: PaymentCardLifecycle
  cardLastFour?: string
}

const PaymentCardResponseSchema = yup
  .object({
    id: yup.string().required(),
    status: yup.mixed<PaymentCardStatus>().required(),
    formFactor: yup.mixed<PaymentCardFormFactor>().required(),
    lifecycle: yup.mixed<PaymentCardLifecycle>().required(),
    isExpiring: yup.boolean().required(),
  })
  .camelCase()
  .required()
function validatePaymentCardResponse(response: AxiosResponse<unknown>) {
  return PaymentCardResponseSchema.validateSync(response.data) as PaymentCard
}
export const BASE_PAYMENT_CARD_URL = generateStretchAPIURL(
  "/api/v1/banking/card",
)
export const TEMPORARY_PAYMENT_CARD_URL = generateStretchAPIURL(
  "/api/v1/banking/card/temp",
)
const PERMANENT_PAYMENT_CARD_URL = generateStretchAPIURL(
  "/api/v1/banking/card/permanent",
)
export function issueTemporaryCard(): Promise<PaymentCard> {
  return axios
    .post<unknown>(
      TEMPORARY_PAYMENT_CARD_URL,
      undefined,
      requestConfiguration(),
    )
    .then(validatePaymentCardResponse)
}

const PhysicalCardOrderResponseSchema = yup
  .object({
    id: yup.string().required(),
    status: yup.mixed<PhysicalCardOrderStatus>().required(),
    recipientName: yup.string().required(),
    recipientAddress: AddressResponseSchema.required(),
    shippingMethod: yup.string().required(),
    signatureRequired: yup.boolean().required(),
    trackingUrl: yup.string().optional(),
    shipDate: yup.string().optional(),
  })
  .camelCase()
  .required()

const IssuePermanentCardResponseSchema = yup
  .object({
    card: PaymentCardResponseSchema.required(),
    cardOrder: PhysicalCardOrderResponseSchema.required(),
  })
  .camelCase()
  .required()

export type IssuePermanentCardResponse = yup.InferType<
  typeof IssuePermanentCardResponseSchema
>
function validateIssuePermanentCardResponse(
  response: AxiosResponse<unknown>,
): IssuePermanentCardResponse {
  return IssuePermanentCardResponseSchema.validateSync(response.data)
}
export function issuePermanentPaymentCard(): Promise<IssuePermanentCardResponse> {
  return axios
    .post<unknown>(
      PERMANENT_PAYMENT_CARD_URL,
      undefined,
      requestConfiguration(),
    )
    .then(validateIssuePermanentCardResponse)
}

export function getPaymentCard(
  cardLifecycle: PaymentCardLifecycle,
): Promise<PaymentCard> {
  return axios
    .get<unknown>(
      BASE_PAYMENT_CARD_URL,
      requestConfiguration({
        params: { lifecycle: cardLifecycle },
      }),
    )
    .then(validatePaymentCardResponse)
}

const PAYMENT_CARD_TOKEN_URL = generateStretchAPIURL(
  "/api/v1/banking/token/card",
)
export function getPaymentCardToken(
  lifecycle: PaymentCardLifecycle,
  tokenPersmission: PaymentCardTokenPermission,
): Promise<ClientToken> {
  return axios
    .post<unknown>(
      PAYMENT_CARD_TOKEN_URL,
      {
        lifecycle,
        token_permission: tokenPersmission,
      },
      requestConfiguration(),
    )
    .then(validateClientTokenResponse)
}

function validatePhysicalCardOrderResponse(response: AxiosResponse<unknown>) {
  return PhysicalCardOrderResponseSchema.validateSync(response.data, {
    stripUnknown: true,
  }) as PhysicalCardOrder
}

export const ORDER_PHYSICAL_CARD_URL = generateStretchAPIURL(
  "/api/v1/banking/card/order",
)
export function orderPhysicalCard(): Promise<PhysicalCardOrder> {
  return axios
    .post<unknown>(ORDER_PHYSICAL_CARD_URL, undefined, requestConfiguration())
    .then(validatePhysicalCardOrderResponse)
}

export function getPhysicalCardOrder(): Promise<PhysicalCardOrder> {
  return axios
    .get<unknown>(ORDER_PHYSICAL_CARD_URL, requestConfiguration())
    .then(validatePhysicalCardOrderResponse)
}

export const CARD_ACTIVATION_URL = generateStretchAPIURL(
  "/api/v1/banking/card/activate",
)
export function activatePaymentCard(
  params: ActivatePaymentCardParams,
): Promise<PaymentCard> {
  const request = {
    lifecycle: params.lifecycle,
    card_last_four: params.cardLastFour,
  }
  return axios
    .post<unknown>(CARD_ACTIVATION_URL, request, requestConfiguration())
    .then(validatePaymentCardResponse)
}

export const CARD_FREEZE_URL = generateStretchAPIURL(
  "/api/v1/banking/card/freeze",
)
export function freezePaymentCard(
  lifecycle: PaymentCardLifecycle,
): Promise<PaymentCard> {
  const request = {
    lifecycle,
  }
  return axios
    .post<unknown>(CARD_FREEZE_URL, request, requestConfiguration())
    .then(validatePaymentCardResponse)
}
export function unfreezePaymentCard(
  lifecycle: PaymentCardLifecycle,
): Promise<PaymentCard> {
  const request = {
    lifecycle,
  }
  return axios
    .put<unknown>(CARD_FREEZE_URL, request, requestConfiguration())
    .then(validatePaymentCardResponse)
}

export function closePaymentCard(
  lifecycle: PaymentCardLifecycle,
): Promise<PaymentCard> {
  const request = {
    lifecycle,
  }
  return axios
    .delete<unknown>(
      BASE_PAYMENT_CARD_URL,
      requestConfiguration({ params: request }),
    )
    .then(validatePaymentCardResponse)
}

export type ChangePinParams = {
  firstTime: boolean
  lifecycle: PaymentCardLifecycle
}

export const CARD_PIN_URL = generateStretchAPIURL("/api/v1/banking/card/pin")
export function notifyCardPinChanged(params: ChangePinParams): Promise<void> {
  const request = {
    first_time: params.firstTime,
    lifecycle: params.lifecycle,
  }
  return axios
    .put<unknown>(CARD_PIN_URL, request, requestConfiguration())
    .then()
}

function validateTransactionResponse(
  response: AxiosResponse<unknown>,
): Transaction[] {
  return TransactionResponseSchema.validateSync(response.data, {
    stripUnknown: true,
  }) as Transaction[]
}
export const TRANSACTIONS_URL = generateStretchAPIURL(
  "/api/v1/banking/fin-account/transactions",
)
export function getTransactions(): Promise<Transaction[]> {
  return axios
    .get<unknown>(TRANSACTIONS_URL, requestConfiguration())
    .then(validateTransactionResponse)
}

const AccountHolderDetailsResponseSchema = yup
  .object({
    address: AddressResponseSchema.required(),
  })
  .camelCase()
function validateAccountHolderDetailsResponse(
  response: AxiosResponse<unknown>,
): AccountHolderPersonalDetails {
  return AccountHolderDetailsResponseSchema.validateSync(response.data, {
    stripUnknown: true,
  }) as AccountHolderPersonalDetails
}
export const ACCOUNT_HOLDER_URL = generateStretchAPIURL(
  "/api/v1/banking/user",
)
export function getAccountHolderDetails(): Promise<Address> {
  return axios
    .get<AccountHolderPersonalDetails>(
      ACCOUNT_HOLDER_URL,
      requestConfiguration(),
    )
    .then(validateAccountHolderDetailsResponse)
    .then(response => response.address)
}

export const ACCOUNT_HOLDER_STATUS_URL = generateStretchAPIURL(
  "/api/v1/banking/user/account-holder-status",
)



export function getAccountHolderStatusInfo(): Promise<AccountHolderStatusResponse> {
  return new Promise((resolve, reject) => {
    axios.get(ACCOUNT_HOLDER_STATUS_URL, requestConfiguration())
    .then(res => {

      
      const result:AccountHolderStatusResponse = {
        success:true,
        userRequestedAccountClosure:res?.data.user_requested_closure,
        permanentCardStatus: res?.data.permanent_card_status,
        temporaryCardStatus: res?.data.temporary_card_status,
        financialAccountStatus:res?.data.account_status,
        debitAccountClosureDate:res?.data.debit_account_closure_date,
        isNondescriptUser:res?.data.is_non_descript
      };
    
      resolve(result);

    }).catch(err => {

      const result:AccountHolderStatusResponse ={
        success:false,
        userRequestedAccountClosure:false,
        financialAccountStatus:"CLOSED",
        error: err
      };

      reject(result);
    })
  });
}
