import {
  renderFields,
  SecureInputsError,
} from "@highnoteplatform/secure-inputs"
import { uniqueId } from "lodash"
import React, { useCallback, useEffect, useRef, useState } from "react"
import theme from "src/assets/theme"
import {
  Loader,
  MantineDefaultButton as Button,
  Messages,
  Spacing,
  SystemError,
} from "src/components"
import { AsyncNoOpFunctionType, NoOpFunctionType, UiFeedback } from "src/types"
import { DEFAULT_ERROR_MESSAGE, parseError } from "src/utilities"
import styled from "styled-components"

export type PinComponentProps = {
  clientToken: string
  paymentCardId: string
  isHighnoteProdEnv: boolean
}

type Props = {
  submitButtonLabel: string
  onSuccessfulSubmit?: AsyncNoOpFunctionType
} & PinComponentProps

export type PinInputRefProps = {
  handleSubmit: () => void
}

const pinElementId = uniqueId("pin-")

const CardPinInput: React.VFC<Props> = ({
  clientToken,
  paymentCardId,
  isHighnoteProdEnv,
  submitButtonLabel,
  onSuccessfulSubmit,
}) => {
  const {
    models: { loadingStatus, submitResult, submitting },
    operations: { clearSubmitResult, handleSubmit, initializeSecureInputs },
  } = useCardPinInput({
    clientToken,
    isHighnoteProdEnv,
    paymentCardId,
    submitButtonLabel,
    onSuccessfulSubmit,
  })

  useEffect(() => {
    initializeSecureInputs()
  }, [initializeSecureInputs])

  return (
    <>
      {loadingStatus === "loading" ? <Loader color={theme.red} /> : null}
      {loadingStatus === "load_failed" ? <SystemError /> : null}
      <Container
        hidden={loadingStatus !== "loaded"}
        pinElementId={pinElementId}
      >
        {submitResult !== undefined ? (
          <Messages.Ephemeral
            message={submitResult.message}
            messageType={submitResult.messageType}
            onTimeout={() => {
              clearSubmitResult()
              initializeSecureInputs()
            }}
          />
        ) : null}
        <Spacing.Horizontal />
        <div id={pinElementId}></div>
        <Spacing.Horizontal />
        <Button
          label={submitButtonLabel}
          onClick={handleSubmit}
          disabled={submitting}
          isLoading={submitting}
        />
      </Container>
    </>
  )
}

type LoadingStatus = "loading" | "loaded" | "load_failed"

/**
 * This hook encapsulates the UI logic for the presentation component
 */
function useCardPinInput({
  clientToken,
  isHighnoteProdEnv,
  paymentCardId,
  onSuccessfulSubmit,
}: Props) {
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>()
  const [submitResult, setSubmitResult] = useState<UiFeedback>()
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const submitFnRef = useRef<NoOpFunctionType>()
  const unmountFnRef = useRef<AsyncNoOpFunctionType>()

  const handleError = useCallback((error: SecureInputsError) => {
    let errorMessage
    switch (error.name) {
      case "InvalidCredentialError":
        // Handle invalid/expired credential
        // Unmount fields, fetch new client token, re-initialize
        errorMessage = DEFAULT_ERROR_MESSAGE
        setLoadingStatus("load_failed")
        break

      case "SecureInputsRequestError":
        errorMessage = DEFAULT_ERROR_MESSAGE
        break

      case "SecureInputsFieldInputError":
        errorMessage = "That's an invalid PIN. Make sure to use 4 digits."
        break

      default:
        errorMessage = DEFAULT_ERROR_MESSAGE
        setLoadingStatus("load_failed")
    }
    setSubmitResult({
      message: errorMessage,
      messageType: "negative",
    })
  }, [])

  const setupPinInput = useCallback(async () => {

    const renderedFields = await renderFields({
        environment: isHighnoteProdEnv ? "live" : "test",
        // Specify the individual fields to render data into
        elements: {
          pin: {
            clientToken,
            paymentCardId,
            selector: `#${pinElementId}`,
          },
        },
        onInput: () => {},
        onSuccess: () => {
          setSubmitted(true)
        },
        onError: handleError,
    })

      

    return renderedFields

  }, [clientToken, handleError, isHighnoteProdEnv, paymentCardId])

  const initializeSecureInputs = useCallback(async () => {
    setLoadingStatus("loading")

    if (unmountFnRef.current !== undefined) {
      await unmountFnRef.current()
    }

    await setupPinInput().then(renderFields => {
      unmountFnRef.current = renderFields.unmount
      submitFnRef.current = renderFields.submit
      setLoadingStatus("loaded")
    })
  }, [setupPinInput])

  const clearSecureInputs = useCallback(async () => {
    if (unmountFnRef.current !== undefined) {
      await unmountFnRef.current()
      unmountFnRef.current = undefined
    }
  }, [])

  const handleSubmit = () => {
    setSubmitting(true)
    if (submitFnRef.current !== undefined) {
      submitFnRef.current()
    }
    setSubmitting(false)
  }

  const onSubmittedCallback = useCallback(async () => {
    try {
      await clearSecureInputs()
      await onSuccessfulSubmit?.()
      setSubmitResult({
        message: "Your PIN was changed",
        messageType: "positive",
      })
    } catch (err) {
      const { errorMessage } = parseError(err)
      setSubmitResult({
        message: errorMessage,
        messageType: "negative",
      })
    }
  }, [clearSecureInputs, onSuccessfulSubmit])

  useEffect(() => {
    if (submitted) {
      onSubmittedCallback()
      setSubmitted(false)
    }
  }, [initializeSecureInputs, onSubmittedCallback, submitted])

  const clearSubmitResult = () => {
    setSubmitResult(undefined)
  }

  return {
    models: { loadingStatus, submitResult, submitting },
    operations: {
      initializeSecureInputs,
      handleSubmit,
      clearSubmitResult,
    },
  }
}

type ContainerProps = {
  pinElementId: string
}
const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  padding: 8px 15px;
  #${props => props.pinElementId} > iframe {
    letter-spacing: 0.2rem;
    height: 20px;
  }
`

export default CardPinInput
