import React, { ChangeEvent, FC, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useHistory } from "react-router-dom"
import { parseError } from "src/utilities/errorUtils"
import { DASHBOARD_PAGE_ROUTE } from "src/utilities/routingUtils"
import { NonDescriptUserParams } from "src/api/highnote-api"
import * as yup from "yup"

//import { USPersonAccountHolderParams } from "src/api/highnote-api"
import {
  Bucket,
  Button,
  Feedback,
  Headings,
  InputCheckbox,
  Pages,
  Panels,
  SubmitButton,
} from "src/components"
import {
  OnboardingScreenName,
  useOnboardingContext,
} from "src/context/OnboardingContext"
import { useAppDispatch, useAppSelector } from "src/hooks/redux"
import { selectLoggedInUser, thunkApplyForNonDescriptBanking } from "src/store"
//import { thunkApplyForBanking } from "src/store"
//import { getFormattedDate, getFormattedUSPhoneNumber } from "src/utilities"

const validationSchema = yup
  .bool()
  .oneOf([true], "You must accept terms to continue")
const Review: React.FC = () => {
  const currentUser = useAppSelector(selectLoggedInUser)
  //const [showSsn, setShowSsn] = useState(false)
  const { onboardingFormData, setOnboardingScreen, setApplicationInReview } =
    useOnboardingContext()
  const [agreementsAccepted, setAgreementsAccepted] = useState(false)
  const [error, setError] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const history = useHistory()
  const dispatch = useAppDispatch()

  useEffect(() => {
    setApplicationInReview(true)
  }, [setApplicationInReview])

  /*
  const {
    firstName,
    lastName,
    dob,
    phoneNumber,
    firstLine,
    secondLine,
    locality,
    region,
    postalCode,
    ssn,
  } = onboardingFormData
  */

  const {
    firstName,
    lastName,
    firstLine,
    secondLine,
    locality,
    region,
    postalCode,
  } = onboardingFormData

  const handleReviewSubmission = async () => {
    if (!checkInputs()) {
      return
    }

    setError("")

    setSubmitting(true)

    /*
    const formattedPhoneNumber = phoneNumber.replace("+", "")
    */

    /*
    const personAccountHolder: USPersonAccountHolderParams = {
      personAccountHolder: {
        name: {
          givenName: firstName,
          familyName: lastName,
        },
        dateOfBirth: dob,
        phoneNumber: {
          countryCode: formattedPhoneNumber.substring(0, 1),
          number: formattedPhoneNumber.substring(1),
          label: "MOBILE",
        },
        billingAddress: {
          streetAddress: firstLine,
          extendedAddress: secondLine,
          locality: locality,
          region: region,
          postalCode: postalCode,
          countryCodeAlpha3: "USA",
        },
        identificationDocument: {
          socialSecurityNumber: {
            number: ssn,
            countryCodeAlpha3: "USA",
          },
        },
        email: currentUser.email,
        externalId: currentUser.id,
      },
    }
    */

    try {

      let streetAddress = "";
      if(firstLine == undefined && secondLine == undefined){
        streetAddress = "";
      }else if(firstLine != undefined && secondLine != undefined){
        streetAddress = firstLine + ' ' + secondLine;
      }else if(secondLine == undefined){
        streetAddress = firstLine;
      }else if(firstLine == undefined){
        streetAddress = secondLine;
      }

      //const streetAddress = firstLine.concat(' ').concat(secondLine);
      const nondescriptUserParams: NonDescriptUserParams = {
        email:currentUser.email,
        firstname:firstName,
        lastname:lastName,
        streetaddress: streetAddress,
        locality: locality,
        region: region,
        postalcode: postalCode,
      }
      console.log(nondescriptUserParams)
      await dispatch(thunkApplyForNonDescriptBanking(nondescriptUserParams))
      history.push(DASHBOARD_PAGE_ROUTE)
    } catch (err) {
      const { errorMessage } = parseError(err)
      setError(errorMessage)
      setSubmitting(false)
    }
  }

  const checkInputs = () => {
    try {
      return validationSchema.validateSync(agreementsAccepted)
    } catch (err) {
      setError((err as yup.ValidationError).errors[0])
    }
  }

  /*
  const formattedSSN =
    ssn.length == 9
      ? `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5)}`
      : ssn

  const formattedDOB = getFormattedDate(dob)

  const formattedPhone = getFormattedUSPhoneNumber(phoneNumber)
  */

  type ReviewItemProps = {
    label: string
    onboardingScreenName: OnboardingScreenName
  }
  const ReviewItem: FC<ReviewItemProps> = ({
    label,
    onboardingScreenName,
    children,
  }) => {
    return (
      <div className="mb-6">
        <Bucket $spaceS $spreadH>
          <Headings.H3 margin="none">{label}</Headings.H3>
          <Bucket $spaceM $wrapNarrow>
            <Button
              $small
              onClick={() => {
                setOnboardingScreen(onboardingScreenName)
              }}
              $secondary
            >
              Edit
            </Button>
          </Bucket>
        </Bucket>
        {children}
      </div>
    )
  }

  return (
    <Pages.FullPage2>
      <Helmet>
        <title>Card Application | Stretch</title>
      </Helmet>
      <Panels.Default>
        <Headings.H1>Review Details</Headings.H1>
        <p>
          Please ensure these details are correct before submitting the
          application.
        </p>
        <ReviewItem label="Name" onboardingScreenName="Name">
          <>
            <div>
              {firstName} {lastName}
            </div>
          </>
        </ReviewItem>
        <ReviewItem label="Address" onboardingScreenName="Address">
          <>
            <div>{firstLine}</div>
            <div>{secondLine}</div>
            <div>
              {locality}, {region} {postalCode}
            </div>
          </>
        </ReviewItem>
        <InputCheckbox
          name="accept"
          checked={agreementsAccepted}
          onChange={(event: ChangeEvent<HTMLInputElement>): void => {
            setAgreementsAccepted(event.target.checked)
            setError("")
          }}
          label={
            <span>
              By checking this box, I accept the Stretch{" "}
              <a
                href="https://www.stretch.money/cardholder-agreement"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cardholder Agreement 
              </a>{", "}
              the Stretch{" "}
              <a
                href="https://www.stretch.money/program-agreement"
                target="_blank"
                rel="noopener noreferrer"
              >
                Financial Program Agreement 
              </a>{", "}
               and the Stretch{" "}
              <a
                href="https://www.stretch.money/e-signature-agreement"
                target="_blank"
                rel="noopener noreferrer"
              >
                E-Signature&nbsp;Agreement
              </a>
              .
            </span>
          }
        />
        <SubmitButton
          label="Submit Application"
          onClick={handleReviewSubmission}
          disabled={!agreementsAccepted || submitting}
          loading={submitting}
        />
        {!!error && <Feedback message={error} />}
      </Panels.Default>
    </Pages.FullPage2>
  )

  /*
  return (
    <Pages.FullPage2>
      <Helmet>
        <title>Card Application | Stretch</title>
      </Helmet>
      <Panels.Default>
        <Headings.H1>Review Details</Headings.H1>
        <p>
          Please ensure these details are correct before submitting the
          application.
        </p>
        <ReviewItem label="Name" onboardingScreenName="Name">
          <>
            <div>
              {firstName} {lastName}
            </div>
          </>
        </ReviewItem>
        <ReviewItem label="Date of Birth" onboardingScreenName="DateOfBirth">
          <>
            <div>{formattedDOB}</div>
          </>
        </ReviewItem>
        <ReviewItem label="Phone Number" onboardingScreenName="Phone Enter">
          <>
            <div>{formattedPhone}</div>
          </>
        </ReviewItem>
        <ReviewItem label="Address" onboardingScreenName="Address">
          <>
            <div>{firstLine}</div>
            <div>{secondLine}</div>
            <div>
              {locality}, {region} {postalCode}
            </div>
          </>
        </ReviewItem>
        <ReviewItem
          label="Social Security Number"
          onboardingScreenName="SocialSecurityNumber"
        >
          <Bucket $spaceM>
            <span>
              {showSsn ? formattedSSN : `***-**-${formattedSSN.slice(7)}`}
            </span>
            <Button $small onClick={() => setShowSsn(!showSsn)} $secondary>
              {showSsn ? "Hide" : "Reveal"}
            </Button>
          </Bucket>
        </ReviewItem>
        <InputCheckbox
          name="accept"
          checked={agreementsAccepted}
          onChange={(event: ChangeEvent<HTMLInputElement>): void => {
            setAgreementsAccepted(event.target.checked)
            setError("")
          }}
          label={
            <span>
              By checking this box, I accept the Stretch{" "}
              <a
                href="https://www.stretch.money/cardholder-agreement"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cardholder Agreement
              </a>{" "}
              and the Stretch{" "}
              <a
                href="https://www.stretch.money/e-signature-agreement"
                target="_blank"
                rel="noopener noreferrer"
              >
                E-Signature&nbsp;Agreement
              </a>
              .
            </span>
          }
        />
        <SubmitButton
          label="Submit Application"
          onClick={handleReviewSubmission}
          disabled={!agreementsAccepted || submitting}
          loading={submitting}
        />
        {!!error && <Feedback message={error} />}
      </Panels.Default>
    </Pages.FullPage2>
  )
  */
}

export default Review
