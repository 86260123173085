import React from "react"
import styled from "styled-components"
import { NavigationV2 } from "../navigation"

const LayoutDashboard: React.FC = ({ children }) => (
  <Container>
    <NavigationV2 />
    <ScrollableContentContainer>
      <Content>
        {children}
        <Padding />
      </Content>
    </ScrollableContentContainer>
  </Container>
)

// Styles

const Container = styled.div`
  background: white;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  @media (max-width: 50em) {
    flex-direction: column;
  }
`

const ScrollableContentContainer = styled.div`
  overflow: scroll;
  width: 100%;
  align-items: center;
  justify-content: center;
`

const Content = styled.div`
  flex: 1;
  margin: calc(0.25rem - 1px) auto 0;
  max-width: 74vw;
  padding-top: 1rem;
  @media (min-width: 50em) {
    padding: 2rem 2rem 0;
  }
`

const Padding = styled.div`
  padding-bottom: 7rem;
`

export default LayoutDashboard
