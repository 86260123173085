import { selectIsLoggedIn } from "src/store"
import { useAppSelector } from "./redux"
import { Duration } from "luxon"
import { useExecuteOnInterval } from "./useExecuteOnInterval"

/**
 * Used to poll a remote resource at a given interval.
 * Cleansup the timing functions when user is logged out
 * @param shouldRun whether polling should continue or cease.
 * @param interval the interval between each poll
 * @param callback the polling function to execute at the given interval
 */
export function usePoller(
  shouldRun: boolean,
  interval: Duration,
  callback: () => void,
): void {
  const userIsLoggedIn = useAppSelector(selectIsLoggedIn)
  useExecuteOnInterval(shouldRun && userIsLoggedIn, interval, callback)
}
