import React from "react"
import {
  Flex,
  FlexItem,
  MantineDefaultButton as Button,
  Spacing,
} from "src/components"

type Props = {
  onGoBackward: () => void
  onGoForward: () => void
}

const ConfirmCancelCard: React.VFC<Props> = ({ onGoBackward, onGoForward }) => {
  return (
    <>
      <p>Once cancelled, your card can not be used for future transactions.</p>
      <Spacing.Horizontal />
      <Flex.Horizontal hAlign="center" noWrap>
        <FlexItem
          grow="equally"
          component={Button}
          label="Nevermind"
          variant="secondary"
          onClick={onGoBackward}
        />
        <Spacing.Vertical />

        <FlexItem
          grow="equally"
          component={Button}
          label="Cancel my Card"
          onClick={onGoForward}
        />
      </Flex.Horizontal>
    </>
  )
}

export default ConfirmCancelCard
