import styled, { css } from "styled-components"

type BucketProps = {
  $wrap?: boolean
  $wrapNarrow?: boolean
  $spreadH?: boolean
  $spreadV?: boolean
  $space?: boolean
  $spaceM?: boolean
  $spaceS?: boolean
  $center?: boolean
  $alignStart?: boolean
  $alignEnd?: boolean
  $flexEnd?: boolean
  $grow?: boolean
  $column?: boolean
  $wideBlock?: boolean
}
const Bucket = styled.div<BucketProps>`
  align-items: center;
  display: flex;
  ${props =>
    props.$wrap &&
    css`
      flex-wrap: wrap;
    `}
  ${props =>
    props.$wrapNarrow &&
    css`
      flex-wrap: wrap;
      * {
        margin-bottom: 0.375rem;
      }
    `}
  ${props =>
    props.$spreadH &&
    css`
      justify-content: space-between;
    `}
  ${props =>
    props.$spreadV &&
    css`
      justify-content: space-between;
    `}
  ${props =>
    props.$space &&
    css`
      > * + * {
        margin-left: 1.5rem;
      }
    `}
    ${props =>
    props.$spaceM &&
    css`
      > * + * {
        margin-left: 1rem;
      }
    `}
    ${props =>
    props.$spaceS &&
    css`
      > * + * {
        margin-left: 0.5rem;
      }
    `}
  ${props =>
    props.$center &&
    css`
      justify-content: center;
    `}
    ${props =>
    props.$alignEnd &&
    css`
      align-items: end;
    `}
    ${props =>
    props.$flexEnd &&
    css`
      justify-content: flex-end;
    `}
    ${props =>
    props.$grow &&
    css`
      flex-grow: 1;
    `}
  ${props =>
    props.$column &&
    css`
      flex-direction: column;
    `}
  ${props =>
    props.$alignStart &&
    css`
      align-items: flex-start;
    `}
  @media (min-width: 50em) {
    ${props =>
      props.$wideBlock &&
      css`
        display: block;
      `}
    ${props =>
      props.$wrapNarrow &&
      css`
        > * + * {
          margin-top: 0;
        }
      `}
  }
`

export default Bucket
