import axios, { AxiosError } from "axios"

export const DEFAULT_ERROR_MESSAGE =
  "Something went wrong. Please try again later or start a chat using the button in the bottom right of the\u00A0screen."

export type ParsedApiError = {
  errorField?: string
  errorMessage: string
  statusCode?: number
}

export function isApiAuthNOrAuthZError(error: unknown): boolean {
  return (
    isAxiosError(error) && [401, 403].includes(error.response?.status ?? 500)
  )
}

export function isApiAuthenticationError(error: unknown): boolean {
  return isAxiosError(error) && error.response?.status === 401
}

export function isApiAuthorizationError(error: unknown): boolean {
  return isAxiosError(error) && error.response?.status === 403
}

export function parseError(
  error: unknown,
  defaultErrMessage?: string,
): ParsedApiError {
  if (isAxiosError(error)) {
    const statusCode = error.response?.status

    const apiErrorsObject = error?.response?.data?.errors ?? {}
    const errorField = Object.entries(apiErrorsObject)?.[0]?.[0]
    const errorMessage =
      (Object.entries(apiErrorsObject)[0]?.[1] as string) ??
      defaultErrMessage ??
      DEFAULT_ERROR_MESSAGE

    return {
      errorField,
      errorMessage,
      statusCode,
    }
  }

  if (isJSError(error)) {
    return {
      errorMessage: error.message,
    }
  }

  return {
    errorMessage: DEFAULT_ERROR_MESSAGE,
  }
}

function isAxiosError(err: unknown): err is AxiosError {
  return axios.isAxiosError(err)
}

function isJSError(err: unknown): err is Error {
  return err instanceof Error
}
