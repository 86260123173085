import React from "react"
import { Flex, Headings, Spacing } from "src/components"
import { NoOpFunctionType } from "src/types"

type Props = {
  error: Error
  resetErrorBoundary: NoOpFunctionType
}

const ErrorFallbackPage: React.VFC<Props> = () => {
  return (
    <Flex.Vertical hAlign="center">
      <Headings.H2>Sorry, something went wrong</Headings.H2>
      <p>The issue has been reported.</p>
      <Spacing.Horizontal />
    </Flex.Vertical>
  )
}

export default ErrorFallbackPage
