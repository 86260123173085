import React from "react"
import styled from "styled-components"
import classnames from "classnames"
import styles from "./AppLayout.module.css"

/**
 * Needed Layouts
 *   - Public (header/body)
 *   - Authenticated (sideBar/body)
 *   - Mobile (header/body)
 */

const TopBar: React.FC = props => <div className={styles.topbar} {...props} />
const SideBar: React.FC = props => <div className={styles.sideBar} {...props} />
const Body: React.FC = ({ children }) => (
  <ScrollableContentContainer className={styles.body}>
    {children}
  </ScrollableContentContainer>
)

interface Props {
  /**
   * sideBarComponent should only be populated for Authenticated Views
   */
  sideBarComponent?: React.ReactElement
  /**
   * topBarComponent should only be populated for Public Views
   */
  topBarComponent?: React.ReactElement

  /**
   * bodyComponent must be provided for all views
   */
  bodyComponent: React.ReactElement
}

export const AppLayout: React.VFC<Props> = ({
  sideBarComponent,
  topBarComponent,
  bodyComponent,
  ...props
}) => (
  <div
    className={classnames(styles.appLayout, {
      [styles["appLayout--authenticated"]]: Boolean(sideBarComponent),
      [styles["appLayout--public"]]: Boolean(topBarComponent),
    })}
    {...props}
  >
    {topBarComponent ? <TopBar>{topBarComponent}</TopBar> : null}
    {sideBarComponent ? <SideBar>{sideBarComponent}</SideBar> : null}
    <Body>{bodyComponent}</Body>
  </div>
)

const ScrollableContentContainer = styled.div`
  overflow: scroll;
  width: 100%;
`
