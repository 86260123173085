//import { Button } from "@mantine/core"
import React from "react"
import {Empty} from "src/components"
import {TransactionListV2} from "src/components"
import { Transaction } from "src/types"
import {useState} from "react"
import {endOfDay,startOfDay,isBefore, isAfter, subDays } from "date-fns";
import {Spinner} from "src/components"

/*
import { useAppSelector } from "src/hooks"
import {selectFinancialAccountTransactionsStatus} from "src/store"
import { useEffect } from "react"
*/

type TransactionsViewProps = {
    transactions: Transaction[]
    leftFilterIsActive: boolean
    middleFilterIsActive: boolean
    rightFilterIsActive: boolean
    noFilterIsActive:boolean
    searchText:string,
    transactionsLoaded:boolean
}

const TransactionsViewV2: React.VFC<TransactionsViewProps> = ({
    transactions,leftFilterIsActive,middleFilterIsActive,rightFilterIsActive,noFilterIsActive, searchText,transactionsLoaded
}) => {

  const [numTransactionsLeftFilter, setNumTransactionsLeftFilter] = useState(0); //default number of transactions to display for left filter
  const [numTransactionsMiddleFilter, setNumTransactionsMiddleFilter] = useState(0); //default number of transactions to display for middle filter
  const [numTransactionsRightFilter, setNumTransactionsRightFilter] = useState(0); //default number of transactions to display for right filter
  const [numTransactionsAll, setNumTransactionsAll] = useState(0); //default number of transactions to display for no filter

  const [isInitializationCompleteForToday, setInitializationCompleteForToday] = useState(false); //to determine if the initialization is completed
  const [isInitializationCompleteForOneWeek, setInitializationCompleteForOneWeek] = useState(false); //to determine if the initialization is completed
  const [isInitializationCompleteForTwoWeeks, setInitializationCompleteForTwoWeeks] = useState(false); //to determine if the initialization is completed
  const [isInitializationCompleteForAll, setInitializationCompleteForAll] = useState(false); //to determine if the initialization is completed

  const pendingTransactions = transactions.filter(t => t.state === "PENDING")
  const completeTransactions = transactions.filter(t => t.state !== "PENDING")


  const [isSearchFilterActive, setSearchFilterActive] = useState(false);

  //const transactionsLoadingStatus = useAppSelector(selectFinancialAccountTransactionsStatus)

  //const [transactionsLoaded, setTransactionsLoaded] = useState(false); //transactions loaded

  /*
  useEffect(() => {

    
    if(transactionsLoadingStatus === "complete" || transactionsLoadingStatus === "idle"){
      setTransactionsLoaded(true);
    }
    
  
  },[transactionsLoadingStatus])
  */


  let filteredCompleteTransactions: Transaction[];
  filteredCompleteTransactions = [];

  let filteredPendingTransactions: Transaction[];
  filteredPendingTransactions = [];

  const defaultNumberOfTransactionsToDisplay = 5;

  //const defaultNumberOfTransactionsToDisplay = transactions.length;

  const searchFilter = (transactions:Transaction[], searchText:string) => {

    const filteredTransactions = [];

    const transactionSearchData = buildSearchDataMap(transactions);

    const filteredItems = transactionSearchData.filter((item) => item.
    transactionMetaData.toLowerCase().includes(searchText.toLowerCase()));

    for(let i=0; i<filteredItems.length; i++){
      const item = filteredItems[i];

      const transaction = item.transaction;

      filteredTransactions.push(transaction);
    }

    return filteredTransactions;

  }

  const buildSearchDataMap = (transactions:Transaction[]) => {

    const transactionSearchData = [];

    for(let i=0; i<transactions.length; i++){

      const transaction = transactions[i];

      let merchantName = '';
      let merchantCategory = '';
      let primaryPersonalCategory = '';
      let enrichedDescription = '';

      if(transaction.merchantName != undefined){
        merchantName = transaction.merchantName;
      }

      if(transaction.merchantCategory != undefined){
        merchantCategory = transaction.merchantCategory;
      }

      if(transaction.primaryPersonalCategory != undefined){
        primaryPersonalCategory = transaction.primaryPersonalCategory;
      }

      if(transaction.enrichedDescription != undefined){
        enrichedDescription = transaction.enrichedDescription;
      }
      
      const transactionData = merchantName 
      + " " + merchantCategory 
      + " " + primaryPersonalCategory 
      + " " + enrichedDescription;

      transactionSearchData.push({transactionMetaData:transactionData, transaction:transaction});

    }

    return transactionSearchData;
    

  }


  /*
    Determines the number of transactions to display. Any postive number determines the 
    number of transactions to display.  Any negative number indicates that all transactions 
    will be displayed.
  */
  const numberFilter = (count:number, transactions:Transaction[]) => {

    const filteredTransactions = [];

    //Sort entire transaction list by date
    transactions.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1)

    if(count >= 0 && isSearchFilterActive == false){

        //check to see if count is greater than length
        const length = transactions.length;

        if(count > length){
            count = length;
        }

        for(let i=0; i<count; i++){

            const transaction = transactions[i];

            filteredTransactions.push(transaction);

        }

    }else if(isSearchFilterActive == true){


        for(let i=0; i<transactions.length; i++){

            const transaction = transactions[i];

            filteredTransactions.push(transaction);

        }

    }else {

      for(let i=0; i<transactions.length; i++){

        const transaction = transactions[i];

        filteredTransactions.push(transaction);

      }

    }

    return filteredTransactions;
    
  }

  const transactionsNumberForAllFilter = (transactions:Transaction[]) => {

    let filteredTransactions = [];

    //apply number limit
    //filteredTransactions = numberFilter(numTransactionsAll,transactions);
    filteredTransactions = numberFilter(-1,transactions);

    return filteredTransactions;

  }

  //Today Filter
  const transactionsNumberAndLeftFilter = (transactions:Transaction[]) => {

    let filteredTransactions = [];

    //apply two weeks filter
    filteredTransactions = transactionsLeftFilter(transactions);

    //apply number limit
    //filteredTransactions = numberFilter(numTransactionsLeftFilter,filteredTransactions);
    filteredTransactions = numberFilter(-1,filteredTransactions);

    return filteredTransactions;

  }

  //Today Filter
  const transactionsLeftFilter = (transactions:Transaction[]) => {

    //get start of current day
    const beginOfCurrentDay = startOfDay(new Date());

    //get end of current day
    const endOfCurrentDay = endOfDay(new Date());

    const filteredTransactions = [];

    //loop through transactions and see if the date is after begin of day and before end of day

    //if the date meets criteria add it to the returned transaction array
    for(let i=0; i<transactions.length; i++){

      const transaction = transactions[i];
      const transactionDate = new Date(transaction.createdAt);

      if(isBefore(transactionDate,endOfCurrentDay) && isAfter(transactionDate, beginOfCurrentDay)){
        filteredTransactions.push(transaction);
      }

    }

    return filteredTransactions;

  }

  const transactionsNumberAndMiddleFilter = (transactions:Transaction[]) => {

    let filteredTransactions: Transaction[] = [];

    //apply two weeks filter
    filteredTransactions = transactionsMiddleFilter(transactions);

    //apply number limit
    //filteredTransactions = numberFilter(numTransactionsMiddleFilter,filteredTransactions);
    filteredTransactions = numberFilter(-1,filteredTransactions);

    return filteredTransactions;

  }

  const transactionsMiddleFilter = (transactions:Transaction[]) => {

    const currentDate = new Date();

    //Yesterday Calculation
    const yesterday = subDays(currentDate,1);
    const yesterdayBegin = startOfDay(yesterday);
    const yesterdayEnd = endOfDay(yesterday);


    /*
    //get one week in the past from current day
    const weekAgo = subDays(currentDate, 7);

    //get end of current day
    const endOfCurrentDay = endOfDay(currentDate);
    */

    const filteredTransactions = [];

    for(let i=0; i<transactions.length; i++){

      const transaction = transactions[i];
      const transactionDate = new Date(transaction.createdAt);

      /*
      if(isBefore(transactionDate,endOfCurrentDay) && isAfter(transactionDate, weekAgo)){
        filteredTransactions.push(transaction);
      }
      */

      if(isBefore(transactionDate,yesterdayEnd) && isAfter(transactionDate, yesterdayBegin)){
        filteredTransactions.push(transaction);
      }

    }

    return filteredTransactions;

  }

  const transactionsNumberAndRightFilter = (transactions:Transaction[]) => {

    let filteredTransactions: Transaction[] = [];

    //apply two weeks filter
    filteredTransactions = transactionsRightFilter(transactions);

    //apply number limit
    //filteredTransactions = numberFilter(numTransactionsRightFilter,filteredTransactions);
    filteredTransactions = numberFilter(-1,filteredTransactions);

    return filteredTransactions;

  }

  const transactionsRightFilter = (transactions:Transaction[]) => {

    const currentDate = new Date();

    //get one week in the past from current day
    const weekAgo = subDays(currentDate, 7);

    //get end of current day
    const endOfCurrentDay = endOfDay(currentDate);

    /*
    //get one week in the past from current day
    const twoWeeksAgo = subDays(currentDate, 14);

    //get end of current day
    const endOfCurrentDay = endOfDay(currentDate);
    */

    const filteredTransactions = [];

    for(let i=0; i<transactions.length; i++){

      const transaction = transactions[i];
      const transactionDate = new Date(transaction.createdAt);

      if(isBefore(transactionDate,endOfCurrentDay) && isAfter(transactionDate, weekAgo)){
        filteredTransactions.push(transaction);
      }

    }

    return filteredTransactions;

  }

  //Search filter is first before other filters in the hierarchy
  if(searchText != undefined && searchText != ''){

    if(isSearchFilterActive == false){
      setSearchFilterActive(true)
    }
  
  }else{

    if(isSearchFilterActive == true){
      setSearchFilterActive(false);
    }
   
  }

  if(leftFilterIsActive){
    //console.log("--- Today Filter Is Active ---")
    filteredPendingTransactions = transactionsNumberAndLeftFilter(pendingTransactions);
    filteredCompleteTransactions = transactionsNumberAndLeftFilter(completeTransactions);
  }

  if(middleFilterIsActive){
    //console.log("--- Week Filter Is Active ---")
    filteredPendingTransactions = transactionsNumberAndMiddleFilter(pendingTransactions);
    filteredCompleteTransactions = transactionsNumberAndMiddleFilter(completeTransactions);
  }

  if(rightFilterIsActive){
    //console.log("--- Two Week Filter Is Active ---")
    filteredPendingTransactions = transactionsNumberAndRightFilter(pendingTransactions);
    filteredCompleteTransactions = transactionsNumberAndRightFilter(completeTransactions);
  }

  if(noFilterIsActive){
    //console.log("--- No Filter Is Active ---")
    filteredPendingTransactions = transactionsNumberForAllFilter(pendingTransactions);
    filteredCompleteTransactions = transactionsNumberForAllFilter(completeTransactions);
  }

  if(searchText != undefined && searchText != ''){

    //setSearchFilterActive(true)
    console.log('search bar clicked in transactions view')

    filteredPendingTransactions = searchFilter(filteredPendingTransactions,searchText);
    filteredCompleteTransactions = searchFilter(filteredCompleteTransactions,searchText);

  }

  /*
  const increaseNumTransactionsBy10 = () => {
    increaseNumTransactions(10);
  }
  */

  const increaseNumTransactions = (increaseAmount:number) => {

    let newNumberOfTransactions = 0;

    if(leftFilterIsActive){

        const currentNum = numTransactionsLeftFilter;

        newNumberOfTransactions = currentNum + increaseAmount;

        setNumTransactionsLeftFilter(newNumberOfTransactions);

    }else if(middleFilterIsActive){

        const currentNum = numTransactionsMiddleFilter;

        newNumberOfTransactions = currentNum + increaseAmount;

        setNumTransactionsMiddleFilter(newNumberOfTransactions);


    }else if(rightFilterIsActive){

        const currentNum = numTransactionsRightFilter;

        newNumberOfTransactions = currentNum + increaseAmount;

        setNumTransactionsRightFilter(newNumberOfTransactions);

    }else if(noFilterIsActive){

        const currentNum = numTransactionsAll;

        newNumberOfTransactions = currentNum + increaseAmount;

        setNumTransactionsAll(newNumberOfTransactions);
    }

  }
  
    /*
        This section of code computes the appropriate values that are used to determine when to display the show more transactions widget
    */

    let largestLength = 0;

    //compute current number of transactions to support display of show more button

    let currentNumberOfTransactions = 0; //represents the current num of transactions that are allowed to be shown

    if(leftFilterIsActive){

        currentNumberOfTransactions = numTransactionsLeftFilter;

        const filteredCompleteTransactions = transactionsLeftFilter(completeTransactions);
        const filteredPendingTransactions = transactionsLeftFilter(pendingTransactions);

        if(filteredCompleteTransactions.length >= filteredPendingTransactions.length){
            largestLength = filteredCompleteTransactions.length;
        }else{
            largestLength = filteredPendingTransactions.length;
        }
    }
    
    if(middleFilterIsActive){

        currentNumberOfTransactions = numTransactionsMiddleFilter;

        const filteredCompleteTransactions = transactionsMiddleFilter(completeTransactions);
        const filteredPendingTransactions = transactionsMiddleFilter(pendingTransactions);

        if(filteredCompleteTransactions.length >= filteredPendingTransactions.length){
            largestLength = filteredCompleteTransactions.length;
        }else{
            largestLength = filteredPendingTransactions.length;
        }
    }
    
    if(rightFilterIsActive){

        currentNumberOfTransactions = numTransactionsRightFilter;

        const filteredCompleteTransactions = transactionsRightFilter(completeTransactions);
        const filteredPendingTransactions = transactionsRightFilter(pendingTransactions);

        if(filteredCompleteTransactions.length >= filteredPendingTransactions.length){
            largestLength = filteredCompleteTransactions.length;
        }else{
            largestLength = filteredPendingTransactions.length;
        }
    }

    
    if(noFilterIsActive){

        currentNumberOfTransactions = numTransactionsAll;

        const filteredCompleteTransactions = numberFilter(-1,completeTransactions);
        const filteredPendingTransactions = numberFilter(-1,pendingTransactions);

        if(filteredCompleteTransactions.length >= filteredPendingTransactions.length){
            largestLength = filteredCompleteTransactions.length;
        }else{
            largestLength = filteredPendingTransactions.length;
        }
    }
    
    //Initialization of structures
   
    if(leftFilterIsActive){
        if(!isInitializationCompleteForToday){
            increaseNumTransactions(defaultNumberOfTransactionsToDisplay);
            setInitializationCompleteForToday(true);
        }
    }
    
    if(middleFilterIsActive){
        if(!isInitializationCompleteForOneWeek){
            increaseNumTransactions(defaultNumberOfTransactionsToDisplay);
            setInitializationCompleteForOneWeek(true);
        }
    }

    if(rightFilterIsActive){
        if(!isInitializationCompleteForTwoWeeks){
            increaseNumTransactions(defaultNumberOfTransactionsToDisplay);
            setInitializationCompleteForTwoWeeks(true);
        }
    }

    if(noFilterIsActive){
        if(!isInitializationCompleteForAll){
            increaseNumTransactions(defaultNumberOfTransactionsToDisplay);
            setInitializationCompleteForAll(true);
        }
    }


  /*
  return isLoading || !directDepositDetails ? (
    <Spinner />
  )

  {isTransactionsLoading && (
            <Spinner />
    )}
  */
  return !transactionsLoaded ? (<Spinner />) : (
    <>
    {
        filteredPendingTransactions.length >= 1 && (
            <>
                <div style={{fontSize:18,color:"black",fontFamily:"Poppins", fontWeight:700, textAlign:'left'}} >Processing</div>
                <TransactionListV2 transactions = {filteredPendingTransactions}/>
            </>
            
        )
    }

    {
        filteredCompleteTransactions.length >= 1 && (
            <>
                <div style={{fontSize:18,color:"black",fontFamily:"Poppins", fontWeight:700, textAlign:'left'}}>Activity</div>
                <TransactionListV2 transactions={filteredCompleteTransactions}/>
            </>
            
        )
    }

    {
        filteredCompleteTransactions.length == 0 && filteredPendingTransactions.length == 0 && (
            <>
                <div className="mt-4"></div>
                <Empty
                    icon="bill"
                    title="There are no transactions to display"
                />
            </>
            
        )
    }

    {

        currentNumberOfTransactions < largestLength && (isSearchFilterActive == false) && (

            <>
                <div style = {{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    
                </div>
            </>
        )
        
    }
    </>
  )
}

/*
{

        currentNumberOfTransactions < largestLength && (isSearchFilterActive == false) && (

            <>
                <div style = {{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Button style={{backgroundColor:"transparent", color:"#FB5755", fontSize:14, fontFamily:"Poppins", fontWeight:700 }} onClick={increaseNumTransactionsBy10}>Show More Transactions</Button>
                </div>
            </>
        )
        
    }
*/

export default TransactionsViewV2

