import React, { ComponentPropsWithoutRef } from "react"
import { Link } from "react-router-dom"
import styled, { css } from "styled-components"

import theme from "src/assets/theme"
import { hexToRgb } from "src/utilities"

import { Bucket, Loader } from "src/components"

export type ButtonProps = {
  loading?: boolean
} & PolymorphicContainerProps

const Button: React.FC<ButtonProps> = ({ children, loading, ...props }) => (
  <PolymorphicContainer $iconWithText={loading} {...props}>
    {loading ? (
      <Bucket>
        <Loader color="white" width={24} />
        <span className="ml-3">Updating</span>
      </Bucket>
    ) : (
      children
    )}
  </PolymorphicContainer>
)

type ContainerProps = {
  as?: string
  $hasIcon?: boolean
  $large?: boolean
  $medium?: boolean
  $noWrap?: boolean
  $small?: boolean
  $grow?: boolean
  $growAlt?: boolean
  $secondary?: boolean
  $iconAlt?: boolean
  $iconWithText?: boolean
}

type HTMLAttributedContainerProps<T extends React.ElementType> =
  ContainerProps & ComponentPropsWithoutRef<T>

type ButtonContainerProps = HTMLAttributedContainerProps<"button"> & {
  as?: "button"
}
type AnchorContainerProps = HTMLAttributedContainerProps<"a"> & {
  as?: "a"
}
type LinkContainerProps = HTMLAttributedContainerProps<Link> & {
  as?: "Link"
}

type PolymorphicContainerProps =
  | ButtonContainerProps
  | AnchorContainerProps
  | LinkContainerProps

const PolymorphicContainer: React.FC<PolymorphicContainerProps> = ({
  children,
  ...props
}) => {
  if (props.as && props.as === "Link") {
    const { as: _discarded, ...narrowedProps } = props as LinkContainerProps
    return (
      <StyledLinkContainer {...narrowedProps}>{children}</StyledLinkContainer>
    )
  }
  if (props.as && props.as === "a") {
    const { as: _discarded, ...narrowedProps } = props as AnchorContainerProps
    return (
      <StyledBaseContainer as="a" {...narrowedProps}>
        {children}
      </StyledBaseContainer>
    )
  } else {
    const { as: _discarded, ...narrowedProps } = props as ButtonContainerProps
    return (
      <StyledBaseContainer as="button" {...narrowedProps}>
        {children}
      </StyledBaseContainer>
    )
  }
}

const boxShadow = `0 1px 2px 0 ${hexToRgb(theme.red, 0.3)}`
const boxShadowDark = `0 1px 2px 0 ${hexToRgb(theme.redS, 0.3)}`
const boxShadowBlue = `0 1px 2px 0 ${hexToRgb(theme.blue, 0.3)}`

const commonStyles = css<PolymorphicContainerProps>`
  background: ${theme.red};
  border-radius: 6px;
  box-sizing: border-box;
  /* box-shadow: ${boxShadow}; */
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  flex-shrink: 0;
  padding: 0.5rem 1rem;
  text-align: center;
  transition: 250ms background ${theme.easeOutCubic};
  &:hover,
  &:focus {
    background: ${theme.redS};
    /* box-shadow: ${boxShadowDark}; */
    color: white;
  }
  &:disabled {
    background: ${theme.redTTT};
    /* box-shadow: 0 0 0 #000; */
    cursor: not-allowed;
    &:hover,
    &:focus {
      background: ${theme.redTTT};
    }
  }

  /* Size */
  ${props =>
    props.$grow &&
    css`
      padding: 0.75rem;
      width: 100%;
    `}

  ${props =>
    props.$growAlt &&
    css`
      padding: 0.75rem;
      flex: 1 1 100%;
    `}

  ${props =>
    props.$large &&
    css`
      padding: 1rem 3.5rem;
    `}
  
  ${props =>
    props.$medium &&
    css`
      padding: 0.75rem 1.25rem;
    `}

  ${props =>
    props.$small &&
    css`
      font-size: 0.875rem;
      padding: 0.25rem 0.875rem;
    `}

  ${props =>
    props.$hasIcon &&
    css`
      font-size: 1.5rem;
      line-height: 1em;
      padding: 0.5rem;
    `}

    ${props =>
    props.$iconAlt &&
    css`
      background: none;
      color: #444444;
      font-size: 2rem;
      line-height: 1em;
      padding: 0.75rem;
      &:hover,
      &:focus {
        background: none;
        color: ${theme.graySS};
      }
    `}

    ${props =>
    props.$iconWithText &&
    css`
      align-items: center;
      display: inline-flex;
      justify-content: center;
      svg {
        font-size: 1.5rem;
        margin-left: 0.5rem;
      }
    `}

    ${props =>
    props.$noWrap &&
    css`
      white-space: nowrap;
    `}

  /* Secondary */
  ${props =>
    props.$secondary &&
    css`
      background: transparent;
      /* box-shadow: inset 0 0 0 1.5px ${theme.red}, ${boxShadow}; */
      box-shadow: inset 0 0 0 1.5px ${theme.red};
      color: ${theme.red};
      transition: 250ms background ${theme.easeOutCubic};
      &:hover,
      &:focus {
        background: ${theme.redTTTT};
        /* box-shadow: inset 0 0 0 1.5px ${theme.redS}, ${boxShadowBlue}; */
        box-shadow: inset 0 0 0 1.5px ${theme.redS};
        color: ${theme.redS};
      }
    `}
`

const StyledBaseContainer = styled.div<PolymorphicContainerProps>`
  ${commonStyles}
`

const StyledLinkContainer = styled(Link)<LinkContainerProps>`
  ${commonStyles}
  ${props =>
    props.to &&
    css`
      display: inline-block;
      text-align: center;
      text-decoration: none;
    `}
`

export default Button
