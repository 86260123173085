import { AxiosResponse } from "axios"
import * as yup from "yup"

export type AppConfig = {
  signUpCodesEnabled: boolean
  docUploadV2Enabled: boolean
}

export const AppConfigResponseSchema = yup
  .object({
    signUpCodesEnabled: yup.boolean().optional(),
    docUploadV2Enabled: yup.boolean().optional(),
  })
  .camelCase()

export function validateAppConfigResponse(
  response: AxiosResponse<unknown>,
): AppConfig {
  return AppConfigResponseSchema.validateSync(response.data, {
    stripUnknown: true,
  }) as AppConfig
}
