import axios, { AxiosResponse } from "axios"
import {
  ServerUser,
  UserBankingDetails,
  UnauthenticatedUser,
  validateUserBankingDetailsResponse,
  validateUnauthenticatedUserResponse,
  validateUserResponse,
} from "src/types/userTypes"
import { generateStretchAPIURL, requestConfiguration } from "./common"

export const REQUEST_EMAIL_VERIFICATION_LINK_URL = generateStretchAPIURL(
  "/users/confirmation",
)
export function requestEmailVerificationEmail(
  email: string,
): Promise<AxiosResponse> {
  return axios
    .post<AxiosResponse>(
      REQUEST_EMAIL_VERIFICATION_LINK_URL,
      {
        user: {
          email,
        },
      },
      requestConfiguration(),
    )
    .then(response => response)
}

export const SIGN_UP_URL = generateStretchAPIURL("/users")
export function signUp(email: string): Promise<void> {
  const request = {
    user: {
      email,
    },
  }
  return axios
    .post<unknown>(SIGN_UP_URL, request, requestConfiguration())
    .then(_ => {})
}

export const SEND_EMAIL_CONFIRMATION_URL = generateStretchAPIURL(
  "/users/confirmation",
)
export function confirmEmail(token: string): Promise<void> {
  return axios
    .get<unknown>(
      SEND_EMAIL_CONFIRMATION_URL,
      requestConfiguration({
        params: { confirmation_token: token },
      }),
    )
    .then(_ => {})
}

export const SIGN_IN_URL = generateStretchAPIURL("/users/sign_in")
export function signIn(options: {
  email: string
  timezone: string // Must be IANA format
}): Promise<UnauthenticatedUser> {
  return axios
    .post<unknown>(SIGN_IN_URL, { user: options }, requestConfiguration())
    .then(validateUnauthenticatedUserResponse)
}

export function finishSignIn(token: string): Promise<ServerUser> {
  return axios
    .get<unknown>(
      SIGN_IN_URL,
      requestConfiguration({
        params: { sign_in_token: token },
      }),
    )
    .then(validateUserResponse)
}

export const BANKING_SIGN_IN_URL = generateStretchAPIURL(
  "/users/sign_in_banking",
)
export function signInToBanking(
  email: string,
  passcode: string,
  timezone: string, // Must be IANA format
): Promise<void> {
  return axios
    .post<unknown>(
      BANKING_SIGN_IN_URL,
      {
        user: { email, passcode, timezone },
      },
      requestConfiguration(),
    )
    .then(_ => {})
}

export const SIGN_OUT_URL = generateStretchAPIURL("/users/sign_out")
export function signOut(): Promise<void> {
  return axios.delete(SIGN_OUT_URL, requestConfiguration())
}

export const USER_DETAILS_URL = generateStretchAPIURL("/api/v1/user")
export function userDetail(): Promise<ServerUser> {
  return axios
    .get<unknown>(USER_DETAILS_URL, requestConfiguration())
    .then(validateUserResponse)
}

export const USER_BANKING_DETAILS_URL = generateStretchAPIURL(
  "/api/v1/user/banking",
)
export function userBankingDetail(): Promise<UserBankingDetails> {
  return axios
    .get<unknown>(USER_BANKING_DETAILS_URL, requestConfiguration())
    .then(validateUserBankingDetailsResponse)
}

export const PASSCODE_URL = generateStretchAPIURL("/users/passcode")

export function requestPasscodeResetEmail(email: string): Promise<void> {
  return axios
    .post<void>(PASSCODE_URL, { user: { email } }, requestConfiguration())
    .then(() => {})
}

type UserResetPasscodeRequest = {
  passcode: string
  passcodeConfirmation: string
  token: string
}
export function resetPasscode({
  passcode,
  passcodeConfirmation,
  token,
}: UserResetPasscodeRequest): Promise<UnauthenticatedUser> {
  return axios
    .put<unknown>(
      PASSCODE_URL,
      {
        user: {
          passcode: passcode,
          passcode_confirmation: passcodeConfirmation,
          reset_token: token,
        },
      },
      requestConfiguration(),
    )
    .then(validateUnauthenticatedUserResponse)
}

export const ACCOUNT_UNLOCK_URL = generateStretchAPIURL("/users/unlock")

export function requestAccountUnlockEmail(email: string): Promise<void> {
  return axios
    .post<void>(ACCOUNT_UNLOCK_URL, { user: { email } }, requestConfiguration())
    .then(() => {})
}

export function unlockAccount(token: string): Promise<void> {
  return axios
    .put<void>(ACCOUNT_UNLOCK_URL, { user: { token } }, requestConfiguration())
    .then(() => {})
}

export type UpdateEmailParams = {
  email: string
}

export type UpdatePhoneParams = {
  phoneNumber: string
}

export type PhoneVerificationParams = {
  phoneNumber: string
  verificationCode: string
}

export type UpdatePasscodeParams = {
  passcode: string
  passcodeConfirmation: string
}

export type UpdateDobParams = {
  dob: string
}

export type UpdateSsnParams = {
  ssn: string
}

export type UpdateAddressParams = {
  firstLine: string
  secondLine?: string
  locality: string
  region: string
  postalCode: string
  country: string
}

export type UpdateUserAccountRequest = {
  user: {
    email?: string
    first_name?: string
    last_name?: string
    phone?: string
    passcode?: string
    passcode_confirmation?: string
    born_on?: string
    ssn?: string
    physical_address?: {
      first_line: string
      second_line?: string
      locality: string
      region: string
      postal_code: string
      country: string
    }
  }
}

export const UPDATE_ACCOUNT_URL = generateStretchAPIURL("/api/v1/user")
export type UpdateUserAccountParams =
  | UpdateEmailParams
  | UpdatePhoneParams
  | UpdatePasscodeParams
  | UpdateDobParams
  | UpdateSsnParams
  | UpdateAddressParams
export function updateUserAccount(
  params: UpdateUserAccountParams,
): Promise<ServerUser> {
  const request: UpdateUserAccountRequest = {
    user: {},
  }

  if ("email" in params) {
    request.user.email = params.email
  } else if ("phoneNumber" in params) {
    request.user.phone = params.phoneNumber
  } else if ("dob" in params) {
    request.user.born_on = params.dob
  } else if ("passcode" in params) {
    request.user.passcode = params.passcode
    request.user.passcode_confirmation = params.passcodeConfirmation
  } else if ("ssn" in params) {
    request.user.ssn = params.ssn
  } else if ("firstLine" in params) {
    request.user.physical_address = {
      first_line: params.firstLine,
      second_line: params.secondLine,
      locality: params.locality,
      region: params.region,
      postal_code: params.postalCode,
      country: params.country,
    }
  }

  return axios
    .patch<unknown>(UPDATE_ACCOUNT_URL, request, requestConfiguration())
    .then(validateUserResponse)
}

export const VERIFY_PHONE_URL = generateStretchAPIURL(
  "/api/v1/user/phone_verify",
)
export function startVerifyPhone(
  params: UpdatePhoneParams,
): Promise<ServerUser> {
  return axios
    .post<unknown>(
      VERIFY_PHONE_URL,
      { phone_number: params.phoneNumber },
      requestConfiguration(),
    )
    .then(validateUserResponse)
}
export function finishVerifyPhone(
  params: PhoneVerificationParams,
): Promise<ServerUser> {
  return axios
    .put<unknown>(
      VERIFY_PHONE_URL,
      {
        phone_number: params.phoneNumber,
        verification_code: params.verificationCode,
      },
      requestConfiguration(),
    )
    .then(validateUserResponse)
}

export type UserChangePasscodeRequest = {
  currentPasscode: string
  passcode: string
  passcodeConfirmation: string
}
export function changePasscode({
  currentPasscode,
  passcode,
  passcodeConfirmation,
}: UserChangePasscodeRequest): Promise<void> {
  return axios
    .patch<unknown>(
      PASSCODE_URL,
      {
        user: {
          current_passcode: currentPasscode,
          passcode: passcode,
          passcode_confirmation: passcodeConfirmation,
        },
      },
      requestConfiguration(),
    )
    .then()
}
