import { InputBase, MantineSize } from "@mantine/core"
import React from "react"
import PhoneInput from "react-phone-number-input/input"

type PhoneNumberInputProps = {
  value: string
  onChange: (_value: string) => void
  error?: string
  size?: MantineSize
}

type Props = PhoneNumberInputProps &
  Omit<React.InputHTMLAttributes<string>, keyof PhoneNumberInputProps | "type">

const PhoneNumberInput: React.VFC<Props> = props => {
  return (
    <InputBase
      type="tel"
      component={PhoneInput}
      {...props}
      country="US"
      withCountryCallingCode={false}
    />
  )
}

export default PhoneNumberInput
