import { Checkbox } from "@mantine/core"
import React, { useEffect, useState } from "react"
import {
  Flex,
  FlexItem,
  FormFeedback,
  MantineDefaultButton,
  Spacing,
} from "src/components"
import {
  ApplicationDocumentCategory,
  ApplicationDocumentType,
  DocumentToUpload,
  UiFeedback,
  UploadRequirements,
} from "src/types"
import { parseError } from "src/utilities"
import { default as DocUploadInput } from "../DocUploadInputV1/DocUploadInput"

type Props = {
  primaryDocumentsSelection: ApplicationDocumentType[]
  secondaryDocumentsSelection: ApplicationDocumentType[]
  submitDocuments: (_reqs: UploadRequirements) => Promise<void>
}

const DocUploadForm: React.VFC<Props> = ({
  primaryDocumentsSelection,
  secondaryDocumentsSelection,
  submitDocuments,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitResult, setSubmitResult] = useState<UiFeedback>()
  const [filesToUpload, setFilesToUpload] = useState<{
    primaryDocument?: DocumentToUpload
    secondaryDocument?: DocumentToUpload
  }>()
  const [declarations, setDeclarations] = useState<string[]>([])
  const [formIsValid, setFormIsValid] = useState(false)
  useEffect(() => {
    setFormIsValid(
      !!filesToUpload?.primaryDocument &&
        !!filesToUpload?.secondaryDocument &&
        declarations.length === 2,
    )
  }, [filesToUpload, declarations])

  const handleSubmit = async () => {
    setIsSubmitting(true)
    const { primaryDocument, secondaryDocument } = filesToUpload ?? {}
    if (!primaryDocument || !secondaryDocument) {
      throw new Error("A document is not attached")
    }
    try {
      await submitDocuments({ primaryDocument, secondaryDocument })
    } catch (error) {
      setSubmitResult({
        message: parseError(error).errorMessage,
        messageType: "negative",
      })
      setIsSubmitting(false)
    }
  }

  const handleDocSelected = (
    category: ApplicationDocumentCategory,
    file: File,
    type: ApplicationDocumentType,
  ) => {
    const changedDoc: DocumentToUpload = {
      file,
      type,
    }
    switch (category) {
      case "PRIMARY":
        setFilesToUpload({ ...filesToUpload, primaryDocument: changedDoc })
        break
      case "SECONDARY":
        setFilesToUpload({ ...filesToUpload, secondaryDocument: changedDoc })
        break
    }
  }

  const handleDocRemoved = (category: ApplicationDocumentCategory) => {
    switch (category) {
      case "PRIMARY":
        setFilesToUpload({ ...filesToUpload, primaryDocument: undefined })
        break
      case "SECONDARY":
        setFilesToUpload({ ...filesToUpload, secondaryDocument: undefined })
        break
    }
  }

  return (
    <Flex.Vertical hAlign="center">
      <FormFeedback
        feedback={submitResult}
        onTimerExpired={() => {
          setSubmitResult(undefined)
        }}
      />

      <DocUploadInput
        docCategory="PRIMARY"
        docTypes={primaryDocumentsSelection}
        onDocumentSelected={handleDocSelected}
        onDocumentRemoved={handleDocRemoved}
      />
      <Spacing.Horizontal />
      <DocUploadInput
        docCategory="SECONDARY"
        docTypes={secondaryDocumentsSelection}
        onDocumentSelected={handleDocSelected}
        onDocumentRemoved={handleDocRemoved}
      />
      <Spacing.Horizontal />
      <Checkbox.Group
        orientation="vertical"
        value={declarations}
        onChange={setDeclarations}
      >
        <Checkbox
          value="docs-readable"
          label="My attached documents are not blurry and can be easily read"
        />
        <Spacing.Horizontal />
        <Checkbox
          value="docs-match"
          label="My attached documents match the document types I selected"
        />
      </Checkbox.Group>
      <Spacing.Horizontal />
      <Flex.Horizontal hAlign="center" noWrap>
        <FlexItem
          grow="equally"
          component={MantineDefaultButton}
          label="Upload"
          onClick={handleSubmit}
          disabled={!formIsValid}
          isLoading={isSubmitting}
          loaderPosition={"right"}
          loaderProps={{ color: "white" }}
        />
      </Flex.Horizontal>
    </Flex.Vertical>
  )
}

export default DocUploadForm
