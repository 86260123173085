import { Duration } from "luxon"
import { useEffect, useState } from "react"
import { NoOpFunctionType } from "src/types"
import { setTimeout } from "timers"

/**
 * Takes an initial positive duration and counts down to zero in one second decrements.
 * At any poinnt, the parent can reset the countdown back to the initial duration.
 *
 * Returns the current duration left and a function to reset the countdown
 * @param countdownDuration the duration of the countdown
 * @param resettable whether the countdown can be reset
 */
export function useCountdown(
  countdownDuration: Duration,
  resettable = false,
): {
  durationLeft: Duration
  resetCountdown?: NoOpFunctionType
} {
  const [currentDurationMillis, setCurrentDurationMillis] = useState(
    countdownDuration.toMillis(),
  )
  const tickSizeMillis = 1000

  useEffect(() => {
    const timerHandle = setTimeout(() => {
      if (currentDurationMillis > 0) {
        setCurrentDurationMillis(
          currentDuration => currentDuration - tickSizeMillis,
        )
      }
    }, tickSizeMillis)

    return () => {
      if (timerHandle) {
        clearTimeout(timerHandle)
      }
    }
  })

  const resetCountdown = () => {
    setCurrentDurationMillis(countdownDuration.toMillis())
  }

  return {
    durationLeft: Duration.fromMillis(currentDurationMillis),
    resetCountdown: resettable ? resetCountdown : undefined,
  }
}
