import React, { InputHTMLAttributes } from "react"
import styled from "styled-components"

import { Icon } from "src/components"

type InputCheckboxProps = {
  label?: React.ReactNode
  name?: string
} & InputHTMLAttributes<HTMLInputElement>

const InputCheckbox: React.FC<InputCheckboxProps> = ({
  label,
  name,
  ...props
}) => (
  <Field>
    <label className="srt" htmlFor={name}>
      {label}
    </label>
    <Input type="checkbox" name={name} {...props} />
    <Icon id="checkmark" />
    <span aria-hidden="true">{label}</span>
  </Field>
)

// Styles

const Field = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.graySSSSS};
  display: flex;
  line-height: 1.25rem;
  margin-left: -0.25rem;
  margin-bottom: 1.5rem;
  padding: 0.25rem;
  span {
    padding-left: 1rem;
    > span {
      padding-left: 0;
    }
  }
  svg {
    color: white;
    font-size: 1.25rem;
    opacity: 0;
    margin-left: 0.125rem;
    position: absolute;
  }
  @media (min-width: 50em) {
    span {
      padding-left: 0.75rem;
    }
    svg {
      font-size: 1rem;
    }
  }
`

const Input = styled.input`
  --borderColor: ${({ theme }) => theme.grayTT};

  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
  background: white;
  box-shadow: inset 0 0 0 1px var(--borderColor);
  cursor: pointer;
  flex-shrink: 0;
  height: 1.5rem;
  width: 1.5rem;
  &:hover {
    --borderColor: ${({ theme }) => theme.grayS};
  }
  &:checked {
    background: ${({ theme }) => theme.red};
    --borderColor: ${({ theme }) => theme.red};
    &:hover:not(:disabled) {
      --borderColor: ${({ theme }) => theme.redSS};
    }
    & + svg {
      opacity: 1;
    }
  }
  &:disabled {
    --borderColor: ${({ theme }) => theme.grayTT};
    cursor: not-allowed;
    & ~ span {
      cursor: not-allowed;
    }
  }
  &:checked:disabled {
    background: ${({ theme }) => theme.grayTT};
    & + svg {
      color: ${({ theme }) => theme.gray};
    }
  }
  /* &:focus {
    --borderColor: ${({ theme }) => theme.red};
    outline: none;
    &:checked {
      --borderColor: ${({ theme }) => theme.greenS};
    }
  } */
  @media (min-width: 50em) {
    height: 1.25rem;
    width: 1.25rem;
  }
`

export default InputCheckbox
