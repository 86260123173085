import React from "react"
import { CardPinInput, Flex, Panels } from "src/components"
import { AsyncNoOpFunctionType } from "src/types"

type Props = {
  clientToken: string
  paymentCardId: string
  isHighnoteProdEnv: boolean
  onSuccessfulSubmit: AsyncNoOpFunctionType
}
const ChangePin: React.VFC<Props> = props => {
  return (
    <Panels.Default>
      <Flex.Vertical hAlign="center" vAlign="center">
        <CardPinInput submitButtonLabel="Change PIN" {...props} />
      </Flex.Vertical>
    </Panels.Default>
  )
}

export default ChangePin
