import { UploadedDocument } from "src/api"
import {
  ApplicationDocumentType,
  KycMismatch,
  VerificationTag,
} from "src/types"

export type DocumentPlaceholder = {
  id: string
  type: ApplicationDocumentType
}

/**
 * This class encapsulates the business logic needed to validate user input for the document upload
 * flow within the Stretch App.
 */
export default class DocUploadChecklist {
  private kycMismatches: KycMismatch[] = []
  private mismatchTracking: Record<
    KycMismatch,
    DocumentPlaceholder | undefined
  > = {
    ADDRESS: undefined,
    DOB: undefined,
    IDENTITY_THEFT: undefined,
    NAME: undefined,
    PHONE: undefined,
    SSN: undefined,
    SYNTHETIC_FRAUD: undefined,
  }
  private isInitialized = false
  private areRequirementsSatisfied = false
  // Assigned in initialize method
  private requiredDocumentTypes!: ApplicationDocumentType[]

  public getAreNoMismatchesFound(): boolean {
    return this.kycMismatches.length <= 0
  }
  public getKycMismatches(): KycMismatch[] {
    return this.kycMismatches
  }
  public getIsInitialized(): boolean {
    return this.isInitialized
  }
  public getAreRequirementsSatisfied(): boolean {
    return this.areRequirementsSatisfied
  }

  // Compute this filtered map once so that the allowed doc types arrays remain stable and don't cause components
  // to re-render
  private filteredMismatchToDocType: Record<
    KycMismatch,
    ApplicationDocumentType[]
  > = {
    ADDRESS: [],
    DOB: [],
    IDENTITY_THEFT: [],
    NAME: [],
    PHONE: [],
    SSN: [],
    SYNTHETIC_FRAUD: [],
  }

  public initialize(
    requiredDocumentTypes: ApplicationDocumentType[],
    uploadedDocs: UploadedDocument[],
    verificationTags: VerificationTag[],
  ): void {
    this.isInitialized = false
    this.kycMismatches = []

    // Computing KYC Mismatches

    // STEP 1: Start off by computing the mismatches based on the verification tags for the applicant.
    // These tags will always remain the same for the same combination of applicant and document upload session
    verificationTags.forEach(tag => {
      switch (tag) {
        case "ADDRESS_MISMATCH":
          this.kycMismatches.push("ADDRESS")
          break
        case "DOB_MISKEY":
        case "DOB_MISMATCH":
          this.kycMismatches.push("DOB")
          break
        case "MEDIUM_ABUSE_SCORE":
        case "HIGH_ABUSE_SCORE":
          this.kycMismatches.push("SYNTHETIC_FRAUD")
          break
        case "MEDIUM_THEFT_SCORE":
        case "HIGH_THEFT_SCORE":
          this.kycMismatches.push("IDENTITY_THEFT")
          break
        case "NAME_MISMATCH":
          this.kycMismatches.push("NAME")
          break
        case "PHONE_MISMATCH":
          this.kycMismatches.push("PHONE")
          break
        case "SSN_MISKEY":
        case "SSN_MISMATCH":
          this.kycMismatches.push("SSN")
          break

        default: {
          const never: never = tag
          throw new Error(never)
        }
      }
    })

    if (requiredDocumentTypes.includes("PHONE_BILL")) {
      // We have chosen to implement our own doc type detection logic based on the mismatches by following HN documentation
      // of which documents reconcile which mismatches: See https://highnote.com/docs/guides/onboard/application-review#manual-review
      // However, sometimes HN will (incorrectly) not return "PHONE_MISMATCH" tag, but still require that a PHONE_BILL be uploaded.
      // This is temporary workaround adds PHONE mismatch so we can ask the user for their phone bill document
      this.kycMismatches.push("PHONE")
    }

    // STEP 2: Filter out mismatches based on documents that have already been submitted
    const completelyUploadedDocTypes = uploadedDocs
      .filter(doc => doc.status === "COMPLETED")
      .map(doc => doc.documentType)

    completelyUploadedDocTypes.forEach(completedDocType => {
      documentTypeToMismatchesMap[completedDocType].forEach(
        resolvedMismatch =>
          (this.kycMismatches = this.kycMismatches.filter(
            mismmatch => mismmatch !== resolvedMismatch,
          )),
      )
    })

    // For initiated but incomplete document uploads, the required document types from HN response will be limited to
    // uninitiated uploads. We need to re-compute the required document list based on what was submitted.

    // TODO: Consider tracking an 'incompleteUpload' list and making the user finish the initiated upload(s) (or cancel, if HN provides the API)
    const newRequiredDocTypes = new Set<ApplicationDocumentType>(
      requiredDocumentTypes,
    )
    const incompletelyUploadedDocTypes = uploadedDocs
      .filter(doc => doc.status !== "COMPLETED")
      .map(doc => doc.documentType)

    incompletelyUploadedDocTypes.forEach(incompleteDocType => {
      newRequiredDocTypes.add(incompleteDocType)
    })
    this.requiredDocumentTypes = Array.from(newRequiredDocTypes)

    this.computeMismatchToDocTypeMapping()

    this.checkRequirements()

    this.isInitialized = true
  }

  computeMismatchToDocTypeMapping: () => void = () => {
    // NOTE: This method NEEDS to be an arrow function
    /**
     * Sometimes, HN API will (erroneously) return a subset of the document types that can resolve a mismatch. Thus,
     * our own logic of which doc types are needed will not be (completely) correct. We need to limit the doc types
     * to just those returned by HN API otherwise we won't be able to close the session.
     *
     * SIDE NOTE: This bug from HN will negatively affect the Applicant experience: Applicant may believe they do not
     * have sufficient documentation (due to the missing doc types). The workaround is for the applicant to select whatever
     * doc type is shown but upload the document(s) they currently possess.
     */

    // 1. Filter our logic of mismatch-to-doc-types down to what HN is asking for
    // 2. If the result is empty (no intersection b/w ours and HN's logic), then fallback to HN's logic

    Object.keys(mismatchToDocumentTypesMap)
      .map(key => key as KycMismatch)
      .forEach(mismatch => {
        const allowedDocs = mismatchToDocumentTypesMap[mismatch].filter(
          docForMismatch => this.requiredDocumentTypes.includes(docForMismatch),
        )
        this.filteredMismatchToDocType[mismatch] =
          allowedDocs.length >= 1 ? allowedDocs : this.requiredDocumentTypes
      })
  }

  public resolveMismatch(
    mismatch: KycMismatch,
    document: DocumentPlaceholder,
  ): void {
    this.mismatchTracking[mismatch] = document
    this.checkRequirements()
  }

  public unresolveMismatch(
    mismatch: KycMismatch,
  ): DocumentPlaceholder | undefined {
    const result = this.mismatchTracking[mismatch]
    this.mismatchTracking[mismatch] = undefined
    this.checkRequirements()
    return result
  }

  private checkRequirements(): void {
    const areAllMismatchesResolved =
      Object.entries(this.mismatchTracking).filter(
        entry => entry[1] !== undefined,
      ).length === this.kycMismatches.length

    const areAttachedDocsAllowed = this.areAttachedDocsAllowed()

    this.areRequirementsSatisfied =
      areAllMismatchesResolved && areAttachedDocsAllowed
  }

  public getDocTypeSelectionList(
    mismatch: KycMismatch,
  ): ApplicationDocumentType[] {
    return this.filteredMismatchToDocType[mismatch]
  }

  private areAttachedDocsAllowed(): boolean {
    const attachedDocTypes = this.getAttachedDocuments().map(doc => doc.type)
    return attachedDocTypes.every(
      // In staging, HN does not return justice documents as primary document types in the Doc Upload API V2 response
      // so we had to hardcode here. The Prod API does include justice documents now, albeit inconsistently so we'll leave
      // these hardcoded here for now.
      type =>
        this.requiredDocumentTypes.includes(type) ||
        type === "PRISON_ID" ||
        type === "PRISON_RELEASE_PAPERWORK",
    )
  }

  public getAttachedDocuments(): DocumentPlaceholder[] {
    return Object.values(this.mismatchTracking).filter(
      val => val !== undefined,
    ) as DocumentPlaceholder[]
  }
}

const mismatchToDocumentTypesMap: Record<
  KycMismatch,
  ApplicationDocumentType[]
> = {
  ADDRESS: [
    "DRIVERS_LICENSE",
    "LEASE_AGREEMENT",
    "PRISON_RELEASE_PAPERWORK",
    "UTILITY_BILL",
  ],
  DOB: [
    "BIRTH_CERTIFICATE",
    "DRIVERS_LICENSE",
    "PASSPORT",
    "PRISON_ID",
    "PRISON_RELEASE_PAPERWORK",
  ],
  IDENTITY_THEFT: [
    "DRIVERS_LICENSE",
    "PASSPORT",
    "PRISON_ID",
    "PRISON_RELEASE_PAPERWORK",
  ],
  NAME: [
    "DRIVERS_LICENSE",
    "PASSPORT",
    "PAY_STUB",
    "PRISON_ID",
    "UTILITY_BILL",
  ],
  PHONE: ["PHONE_BILL"],
  SSN: ["SOCIAL_SECURITY_CARD", "PRISON_RELEASE_PAPERWORK"],
  SYNTHETIC_FRAUD: [
    "DRIVERS_LICENSE",
    "PASSPORT",
    "PRISON_ID",
    "PRISON_RELEASE_PAPERWORK",
  ],
}

const documentTypeToMismatchesMap: Record<
  ApplicationDocumentType,
  KycMismatch[]
> = {
  BIRTH_CERTIFICATE: [],
  DRIVERS_LICENSE: [],
  LEASE_AGREEMENT: [],
  NULL_DOC_TYPE: [],
  PASSPORT: [],
  PAY_STUB: [],
  PHONE_BILL: [],
  PRISON_ID: [],
  PRISON_RELEASE_PAPERWORK: [],
  SOCIAL_SECURITY_CARD: [],
  UTILITY_BILL: [],
}

Object.entries(mismatchToDocumentTypesMap).forEach(entry => {
  const mismatch = entry[0]
  const docTypes = entry[1]
  docTypes.forEach(docType => {
    documentTypeToMismatchesMap[docType].push(mismatch as KycMismatch)
  })
})
