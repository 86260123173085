import { DateTime } from "luxon"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Link, useLocation, useParams } from "react-router-dom"
import { signIn, signInToBanking } from "src/api"
import {
  Card,
  EnterPasscodeForm,
  Feedback,
  Headings,
  Spacing,
} from "src/components"
import EmailLoginForm from "src/components/form/EmailForm/EmailLoginForm"
import { StretchLogo } from "src/components/shared/animations/StretchLogo"
import { useAppDispatch, useStateMachine, XStateConfig } from "src/hooks"
//import { Flex } from "src/layouts"
import { thunkFinishLoginUser } from "src/store"
import { LoginPageRoutingState, UiFeedback } from "src/types"
import { delay } from "src/utilities"
import { parseError } from "src/utilities/errorUtils"
import {
  DASHBOARD_PAGE_ROUTE,
  FORGOT_PASSCODE_PAGE_ROUTE,
  redirectTo,
  UNLOCK_ACCOUNT_PAGE_ROUTE,
} from "src/utilities/routingUtils"
import { Bucket, Button} from "src/components"

const LOGIN_VIEWS = {
  EMAIL_LOGIN: "email_login",
  PASSCODE_LOGIN: "passcode_login",
  EMAIL_VERIFICATION: "email_verification",
  LOGIN_SUCCESS: "login_success",
} as const

const LOGIN_TRANSITIONS = {
  USE_PASSCODE: "USE_PASSCODE",
  VERIFY_EMAIL: "VERIFY_EMAIL",
  VERIFY_PASSCODE: "VERIFY_PASSCODE",
  VERIFY_TOKEN: "VERIFY_TOKEN",
} as const

const LOGIN_MACHINE: XStateConfig = {
  [LOGIN_VIEWS.EMAIL_LOGIN]: {
    [LOGIN_TRANSITIONS.USE_PASSCODE]: LOGIN_VIEWS.PASSCODE_LOGIN,
    [LOGIN_TRANSITIONS.VERIFY_TOKEN]: LOGIN_VIEWS.LOGIN_SUCCESS,
  },
  [LOGIN_VIEWS.PASSCODE_LOGIN]: {
    [LOGIN_TRANSITIONS.VERIFY_PASSCODE]: LOGIN_VIEWS.EMAIL_VERIFICATION,
  },
  [LOGIN_VIEWS.EMAIL_VERIFICATION]: {},
  [LOGIN_VIEWS.LOGIN_SUCCESS]: {},
}

const Login: React.VFC = () => {
  const [current, send] = useStateMachine(
    "UserLogin",
    LOGIN_VIEWS.EMAIL_LOGIN,
    LOGIN_MACHINE,
  )

  const dispatch = useAppDispatch()
  const { code } = useParams<{ code?: string }>()
  const location = useLocation<LoginPageRoutingState>()
  const { routingMessage, routingMessageType } = location.state || {}

  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [uiFeedback, setUiFeedback] = useState<UiFeedback | undefined>(
    !!routingMessage && !!routingMessageType
      ? {
          message: routingMessage,
          messageType: routingMessageType,
        }
      : undefined,
  )

  useEffect(() => {
    if (code) {
      send(LOGIN_TRANSITIONS.VERIFY_TOKEN)
      validateEmailWithToken(code)
    }
  }, [code])

  const loginWithEmail = async (userEmail: string) => {
    setIsSubmitting(true)
    const timezone = DateTime.now().zoneName
    try {
      const { needsPasscodeAuth } = await signIn({
        email: userEmail,
        timezone,
      })

      if (needsPasscodeAuth) {
        setEmail(userEmail)
        send(LOGIN_TRANSITIONS.USE_PASSCODE)
      } else {
        setUiFeedback({
          message: `If a Stretch account exists for ${userEmail}, you will receive an email with a sign in link (it may be in your “Spam” or “Promotions” folder)`,
          messageType: "positive",
        })
      }
    } catch (error) {
      const { errorMessage } = parseError(error)
      console.log(`My error: ${errorMessage}`)

      if (errorMessage.includes("locked")) {
        navigateToAccoutUnlockPage(userEmail, errorMessage)
        return
      }
      setError(errorMessage)
    } finally {
      setIsSubmitting(false)
    }
  }

  const initialValues = {
    passcode: "",
  }
  const loginWithPasscode = async ({ passcode }: typeof initialValues) => {
    const timezone = DateTime.now().zoneName
    try {
      await signInToBanking(email, passcode, timezone)
    } catch (err) {
      const { errorMessage } = parseError(err)
      if (errorMessage.includes("locked")) {
        navigateToAccoutUnlockPage(email, errorMessage)
        return
      }

      throw err
    }
    send(LOGIN_TRANSITIONS.VERIFY_PASSCODE)
  }

  const navigateToAccoutUnlockPage = (email: string, errorMessage: string) => {
    redirectTo(UNLOCK_ACCOUNT_PAGE_ROUTE, {
      routingMessage: errorMessage,
      routingMessageType: "negative",
      email: email,
    })
  }

  const validateEmailWithToken = async (passCode: string) => {
    try {
      await dispatch(thunkFinishLoginUser(passCode))
      /**
       * let animated logo show for a moment before being redirected
       * to smooth out the UX if they are logged in super quickly
       */
      await delay(3000)
      redirectTo(DASHBOARD_PAGE_ROUTE)
    } catch (error) {
      const { errorMessage } = parseError(error)
      setError(errorMessage)
    }
  }

  if (current.matches(LOGIN_VIEWS.LOGIN_SUCCESS)) {
    return (
      <>
        <Helmet>
          <title>Login – Stretch</title>
        </Helmet>
        <Card $marginAuto $page $fullPage>
          {!error ? (
            <>
              <StretchLogo />
              <Headings.H2>Checking verification code...</Headings.H2>
              <p className="mb-0">
                You will be automatically redirected to the dashboard page.
              </p>
            </>
          ) : (
            <>
              <Headings.H2>Oops!</Headings.H2>
              <p className="mb-0">{error}</p>
            </>
          )}
        </Card>
      </>
    )
  } else if (current.matches(LOGIN_VIEWS.PASSCODE_LOGIN)) {
    const { message, messageType } = uiFeedback ?? {}
    return (
      <>
        <Helmet>
          <title>Stretch - Enter Passcode</title>
        </Helmet>
        <Card $marginAuto $page $fullPage>
          {message && (
            <Feedback
              message={message}
              altStyle
              $green={messageType == "positive"}
            />
          )}
          <EnterPasscodeForm handleSubmit={loginWithPasscode} />
          <Spacing.Horizontal size="xl" />
          <Link to={{ pathname: FORGOT_PASSCODE_PAGE_ROUTE, state: { email } }}>
            Forgot passcode?
          </Link>
        </Card>
      </>
    )
  } else if (current.matches(LOGIN_VIEWS.EMAIL_VERIFICATION)) {
    return (
      <>
        <Helmet>
          <title>Stretch - Check Your Email</title>
        </Helmet>
        <Card $marginAuto $page $fullPage>
          <Headings.H2>Check Your Email</Headings.H2>
          <p>
            To log in, please click on the link in the email we just sent. If
            you don’t see it, please check your “Spam” or “Promotions” folder.
          </p>
        </Card>
      </>
    )
  }

  // Default return initial login page
  return (
    <>
      <Helmet>
        <title>Stretch - Log In</title>
      </Helmet>
      <EmailLoginForm
        error={error}
        isSubmitting={isSubmitting}
        uiFeedback={uiFeedback}
        loginWithEmail={loginWithEmail}
        setError={setError}
      />
      <div>
        <Bucket $spaceM>
          <Headings.H4>Need an account?</Headings.H4>
          <Button as="Link" to="/signup" $secondary>
            Sign Up
          </Button>
        </Bucket>
      </div>
    </>
  )
}

export default Login
