import React, { useState } from "react"
import { startVerifyPhone } from "src/api"
import {
  FlexItem,
  MantineDefaultButton as Button,
  MantineModal,
  Messages,
  PhoneForm,
  PhoneVerifyForm,
  Spacing,
} from "src/components"
import { useAppDispatch } from "src/hooks/redux"
import { thunkFinishVerifyPhone } from "src/store"
import { UiFeedback } from "src/types"

type Props = {
  isOpen: boolean
  closeModal: () => void
}
const UpdatePhone: React.VFC<Props> = ({ isOpen, closeModal }) => {
  const dispatch = useAppDispatch()
  const [enteredNumber, setEnteredNumber] = useState("")
  const [numberSubmitted, setNumberSubmitted] = useState(false)
  const [result, setResult] = useState<UiFeedback>()

  return (
    <MantineModal
      opened={isOpen}
      onClose={closeModal}
      title="Change your Phone Number"
    >
      {result ? (
        <Messages.Ephemeral
          message={result.message}
          messageType={result.messageType}
          onTimeout={() => {
            setResult(undefined)
          }}
        />
      ) : null}
      <Spacing.Horizontal />
      {!numberSubmitted ? (
        <PhoneForm
          shouldDisableSubmit={valuesChanged => {
            return !valuesChanged
          }}
          handleSubmit={async values => {
            setEnteredNumber(values.phoneNumber)
            await startVerifyPhone(values)
            setNumberSubmitted(true)
          }}
        />
      ) : null}
      {numberSubmitted ? (
        <PhoneVerifyForm
          handleSubmit={async ({ verificationCode }) => {
            await dispatch(
              thunkFinishVerifyPhone({
                phoneNumber: enteredNumber,
                verificationCode,
              }),
            )
            setResult({
              message: "Your phone number has been updated",
              messageType: "positive",
            })
            setNumberSubmitted(false)
          }}
          otherButtons={
            <FlexItem
              grow="equally"
              component={Button}
              variant="secondary"
              label="Enter new number"
              onClick={() => {
                setEnteredNumber("")
                setNumberSubmitted(false)
              }}
            />
          }
        />
      ) : null}
    </MantineModal>
  )
}

export default UpdatePhone
