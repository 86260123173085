import { MantineNumberSize, Space } from "@mantine/core"
import React from "react"

type Props = {
  size?: MantineNumberSize
}

export const Horizontal: React.VFC<Props> = ({ size }) => {
  return <Space h={size ?? "md"} />
}

export const Vertical: React.VFC<Props> = ({ size }) => {
  return <Space w={size ?? "md"} />
}
