import { Form } from "formik"
import { debounce } from "lodash"
import React, { ChangeEvent, useMemo, useState } from "react"
import Autocomplete from "react-autocomplete"
import {
  AddressSuggestion,
  autoCompleteAddress,
  VerifiedAddress,
  verifyAddress,
} from "src/api/lob-api"
import theme from "src/assets/theme"
import {
  Bucket,
  Button,
  Feedback,
  Grid,
  GridItem,
  StateSelect,
  StretchForm,
  TextInput,
} from "src/components"
import { useAppDispatch } from "src/hooks/redux"
import { thunkUpdateUserAccount } from "src/store"
import { Address } from "src/types"
import { parseError } from "src/utilities/errorUtils"
import { shouldVerifyAddress } from "src/utilities/addressUtils"
import {
  ADDRESS_1_REGEX,
  ADDRESS_1_REGEX_ERROR,
  ADDRESS_2_REGEX,
  ADDRESS_2_REGEX_ERROR,
  didValuesChange,
  IS_NOT_PO_BOX_REGEX,
  IS_NOT_PO_BOX_REGEX_ERROR,
  LOCALITY_REGEX,
  LOCALITY_REGEX_ERROR,
  STATE_REGEX,
  STATE_REGEX_ERROR,
  ZIP_CODE_REGEX,
  ZIP_CODE_REGEX_ERROR,
} from "src/utilities/formUtils"
import styled, { css } from "styled-components"
import * as yup from "yup"

const isPoBox = (address: VerifiedAddress) =>
  address.components.record_type === "po_box"

const isDeliverable = (address: VerifiedAddress) =>
  address.deliverability === "deliverable"

const passesLobScoreIfIncluded = (address: VerifiedAddress) => {
  if (address.lob_confidence_score && address.lob_confidence_score.score) {
    return address.lob_confidence_score.score >= 20
  }
  return true
}

const userPrompt = (address: VerifiedAddress) => {
  switch (address.deliverability) {
    case "deliverable_incorrect_unit":
      return "Please ensure that your unit is\u00A0correct."
    case "deliverable_missing_unit":
      return "Please include your unit\u00A0number."
    case "deliverable_unnecessary_unit":
      return "Our records indicate that the secondary unit information is unnecessary. Please remove what is entered in that\u00A0field."
    case "undeliverable":
      return "This address is not deliverable according to the USPS. Please double check each field and try\u00A0again."
    default:
      return ""
  }
}

// Validations outlined in Highnote docs https://highnote.com/docs/reference/input_object/AddressInput
const validationSchema = yup.object({
  firstLine: yup
    .string()
    .trim()
    .required("Please provide the street address")
    .matches(ADDRESS_1_REGEX, ADDRESS_1_REGEX_ERROR)
    .matches(IS_NOT_PO_BOX_REGEX, IS_NOT_PO_BOX_REGEX_ERROR),
  secondLine: yup.string().trim().matches(ADDRESS_2_REGEX, {
    message: ADDRESS_2_REGEX_ERROR,
    excludeEmptyString: true,
  }),
  locality: yup
    .string()
    .trim()
    .required("Please provide the city")
    .matches(LOCALITY_REGEX, LOCALITY_REGEX_ERROR),
  region: yup
    .string()
    .required("Please provide the state")
    .matches(STATE_REGEX, STATE_REGEX_ERROR),
  postalCode: yup
    .string()
    .required("Please provide the zip code")
    .matches(ZIP_CODE_REGEX, ZIP_CODE_REGEX_ERROR),
})

type Props = {
  /**
   * Sometimes we want to prevent the user from submitting the Address Form
   * until after they've made a change, e.g. when editing their address, there's
   * no need to send already-saved data to the backend.
   *
   * @default true
   */
  disableSubmitUntilEditIsMade?: boolean
  /**
   * A value should always be provided
   */
  initialAddress: Address
  onSuccess: (_address: Address) => void
  otherButtons?: React.ReactNode
  submitButtonLabel?: string
}
const AddressForm: React.VFC<Props> = ({
  disableSubmitUntilEditIsMade = true,
  initialAddress,
  onSuccess,
  otherButtons,
  submitButtonLabel = "Submit",
}) => {
  const { firstLine, secondLine, locality, region, postalCode, country } =
    initialAddress
  const initialValues = {
    firstLine,
    secondLine,
    locality,
    region,
    postalCode,
    country,
  }
  const dispatch = useAppDispatch()
  const [error, setError] = useState("")
  const [valueChanged, setValueChanged] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const [suggestedAddresses, setSuggestedAddresses] = useState<
    AddressSuggestion[]
  >([])
  const addressSuggestionOptions = suggestedAddresses.map((a, index) => ({
    label: `${a.primary_line}, ${a.city}, ${a.state} ${a.zip_code}`,
    value: index,
  }))
  function getAddressSuggestion(values: Address, prefix: string) {
    const address = values
    address.firstLine = prefix
    autoCompleteAddress(address).then(data => {
      setSuggestedAddresses(data.suggestions)
    })
  }
  const debouncedGetAddressSuggestion = useMemo(
    () => debounce(getAddressSuggestion, 200),
    [],
  )

  const handleSubmit = async (values: typeof initialValues) => {
    if (!didValuesChange(initialValues, values)) {
      onSuccess({} as Address)
      return
    }
    let verificationResponse: string | undefined = undefined

    if (shouldVerifyAddress(values)) {
      try {
        verificationResponse = await verifyAddress(values).then(data => {
          if (isPoBox(data)) {
            return "Our records indicate this is a PO Box. Please enter your residential address."
          }

          if (!isDeliverable(data)) {
            return userPrompt(data)
          }
          if (!passesLobScoreIfIncluded(data)) {
            return "Our records indicate that this address is not a valid, mail-receiving\u00A0address."
          }
        })
      } catch (err) {
        // TODO: Log error to Sentry
        console.log(err)

        const { errorMessage } = parseError(err)
        verificationResponse = errorMessage
      }
    }

    if (!verificationResponse) {
      const { firstLine, secondLine, locality, region, postalCode, country } =
        values
      try {
        const user = await dispatch(
          thunkUpdateUserAccount({
            firstLine,
            secondLine,
            locality,
            region,
            postalCode,
            country: country || "USA",
          }),
        )
        onSuccess({
          firstLine,
          secondLine,
          locality,
          region,
          postalCode,
          country: user!.physicalAddress?.country || country,
        })
      } catch (err) {
        const { errorMessage } = parseError(err)
        setError(errorMessage)
      }
    } else {
      setError(verificationResponse)
    }
  }

  return (
    <StretchForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnBlur
    >
      {({
        values,
        errors,
        handleBlur,
        setFieldValue,
        setFieldTouched,
        touched,
        isSubmitting,
      }) => {
        // Be sure to re-validate inputs in the event the user clicks on
        // the autocomplete dropdown
        validationSchema.isValid(values).then(setIsValid)

        // Address line 1 - input text change
        const handleAddressFieldChange = (
          event: ChangeEvent<HTMLInputElement>,
        ) => {
          const newValue = event.target.value
          if (newValue !== values.firstLine) {
            setFieldValue("firstLine", newValue, true)
            setFieldTouched("firstLine", true, true)
            if (newValue.length > 3) {
              debouncedGetAddressSuggestion(values, newValue)
            }
          }
          setValueChanged(
            didValuesChange(initialValues, {
              ...values,
              firstLine: newValue,
            }),
          )
        }

        // Address line 1 - input dropdown selection
        const handleAddressFieldSelection = (_value: string, item: any) => {
          const index = item.value
          const address = suggestedAddresses[index]
          const [firstLine, secondLine, locality, region, postalCode, country] =
            [
              address.primary_line,
              undefined,
              address.city,
              address.state,
              address.zip_code,
              "USA",
            ]
          setFieldValue("firstLine", firstLine, true)
          setFieldValue("secondLine", secondLine, true)
          setFieldValue("locality", locality, true)
          setFieldValue("region", region, true)
          setFieldValue("postalCode", postalCode, true)

          setValueChanged(
            didValuesChange(initialValues, {
              firstLine: firstLine,
              secondLine: secondLine,
              locality: locality,
              region,
              postalCode,
              country,
            }),
          )
        }

        return (
          <Form>
            <div style={{ position: "relative", zIndex: 1 }}>
              <Autocomplete
                items={addressSuggestionOptions}
                getItemValue={(item: any) => item.label}
                renderItem={(item: any, isHighlighted: boolean) => (
                  <AutocompleteMenuItem
                    isHighlighted={isHighlighted}
                    key={item.label}
                  >
                    <span>{item.label}</span>
                  </AutocompleteMenuItem>
                )}
                value={values.firstLine}
                onChange={handleAddressFieldChange}
                onSelect={handleAddressFieldSelection}
                renderInput={(props: any) => (
                  <TextInput
                    {...props}
                    id="firstLine"
                    label="Street Address"
                    name="firstLine"
                    error={
                      !isValid && touched.firstLine
                        ? errors.firstLine
                        : undefined
                    }
                    onBlur={handleBlur}
                    autoComplete="new-address-firstLine"
                    value={values.firstLine}
                  />
                )}
                wrapperStyle={{
                  display: "block",
                  position: "relative",
                }}
                renderMenu={(items: any[]) => (
                  <AutocompleteMenu>{items}</AutocompleteMenu>
                )}
              />
            </div>
            <TextInput
              label="Apt, Suite, or Unit (optional)"
              name="secondLine"
              value={values.secondLine ?? ""}
              error={
                !isValid && touched.secondLine ? errors.secondLine : undefined
              }
              onChange={event => {
                setFieldValue("secondLine", event.target.value, true)
                setValueChanged(
                  didValuesChange(initialValues, {
                    ...values,
                    secondLine: event.target.value,
                  }),
                )
              }}
              onBlur={handleBlur}
            />
            <TextInput
              label="City"
              name="locality"
              value={values.locality}
              error={!isValid && touched.locality ? errors.locality : undefined}
              onChange={event => {
                setFieldValue("locality", event.target.value, true)
                setValueChanged(
                  didValuesChange(initialValues, {
                    ...values,
                    locality: event.target.value,
                  }),
                )
              }}
              onBlur={handleBlur}
            />
            <Grid>
              <GridItem $span={6} $spanS={12} $noPaddingBottom>
                <StateSelect
                  label="State"
                  name="region"
                  errors={
                    !isValid && touched.region ? errors.region : undefined
                  }
                  value={values.region}
                  onChange={event => {
                    setFieldValue("region", event.target.value, true)
                    setValueChanged(
                      didValuesChange(initialValues, {
                        ...values,
                        region: event.target.value,
                      }),
                    )
                  }}
                  onBlur={handleBlur}
                />
              </GridItem>
              <GridItem $span={6} $spanS={12} $noPaddingBottom>
                <TextInput
                  label="Zip Code"
                  name="postalCode"
                  value={values.postalCode}
                  error={
                    !isValid && touched.postalCode
                      ? errors.postalCode
                      : undefined
                  }
                  onChange={event => {
                    setFieldValue("postalCode", event.target.value, true)
                    setValueChanged(
                      didValuesChange(initialValues, {
                        ...values,
                        postalCode: event.target.value,
                      }),
                    )
                  }}
                  onBlur={handleBlur}
                />
              </GridItem>
            </Grid>
            <Bucket $spaceM>
              {otherButtons}
              <Button
                type="submit"
                disabled={
                  !isValid ||
                  isSubmitting ||
                  (disableSubmitUntilEditIsMade && !valueChanged)
                }
                loading={isSubmitting}
                $growAlt
              >
                {submitButtonLabel}
              </Button>
            </Bucket>
            {error ? <Feedback message={error} /> : null}
          </Form>
        )
      }}
    </StretchForm>
  )
}

const AutocompleteMenu = styled.div`
  background: rgba(255, 255, 255, 0.9);
  border-radius: 6px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  font-style: 16px;
  max-height: 22rem;
  max-height: max(22rem, 75vh);
  overflow: auto;
  padding: 0;
  position: absolute;
  top: 4rem;
  width: 100%;
  @media (min-width: 50em) {
    max-height: 22rem;
  }
`

const AutocompleteMenuItem = styled.div<{ isHighlighted: boolean }>`
  background: white;
  color: #444444;
  cursor: pointer;
  max-width: 100%;
  padding: 0.625rem 0.75rem;
  &:not(:last-child) {
    border-bottom: 1px solid ${theme.grayTTT};
  }
  span {
    display: block;
    padding-left: 3rem;
    text-indent: -2.5rem;
  }
  ${props =>
    props.isHighlighted &&
    css`
      background: #feeceb;
      color: ${theme.redSS};
      border-top: 1px solid ${theme.redTTT};
      margin-top: -1px;
      &:not(:last-child) {
        border-bottom: 1px solid ${theme.redTTT};
      }
    `}
`

export default AddressForm
