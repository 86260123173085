import React, { CSSProperties, useEffect } from "react"
import styled from "styled-components"
import theme from "src/assets/theme"
import { Icon } from "src/components"

type MessageProps = {
  message: string
  onTimeout?: () => void
  timeout?: number
  styleOverrides?: CSSProperties
}
const Message: React.FC<MessageProps> = ({
  message,
  onTimeout,
  timeout,
  styleOverrides,
}) => {
  useEffect(() => {
    if (onTimeout) {
      setTimeout(() => {
        onTimeout()
      }, timeout || 3500)
    }
  }, [])

  return (
    <Container>
      <Content style={styleOverrides}>
        <Icon id="checkmark" />
        <span>{message}</span>
      </Content>
    </Container>
  )
}

// Styles

const Container = styled.div`
  display: flex;
  justify-content: center;
`

const Content = styled.div`
  align-items: center;
  background: ${theme.greenTT};
  color: ${theme.greenSSS};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 0.75rem 1rem;
  position: absolute;
  margin-top: -7rem;
  &::before {
    background: inherit;
    border-radius: 4px;
    bottom: -0.375rem;
    content: "";
    display: block;
    height: 1rem;
    left: -0.5rem;
    margin: 0 auto;
    position: absolute;
    right: 0;
    transform: rotate(45deg);
    width: 1rem;
  }
  svg {
    background: ${theme.greenS};
    color: ${theme.greenTTT};
    padding: 0.25rem;
    border-radius: 100%;
    font-size: 1.5rem;
    margin-right: 0.625rem;
    text-align: center;
  }
`
export default Message
