import React from "react"
//import { Card, Headings, MantineDefaultButton as Button } from "src/components"
import { MantineDefaultButton as Button } from "src/components"
import { Modal,Dialog,Text,Group} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { deleteFinancialAccount } from "src/api";
import { redirectTo, DASHBOARD_PAGE_ROUTE } from "src/utilities";

type CloseAccountProps = {

}

const CloseAccount: React.VFC<CloseAccountProps> = () => {

    const [closeAccountConfirmationOpened, { open:openCloseAccountConfirmation, 
        close:closeCloseAccountConfirmation}] = useDisclosure(false);

    const [errorDialogOpened, {toggle:toggleErrorDialog, 
                close:closeErrorDialog}] = useDisclosure(false);

    function redirectToDashboard(){
        redirectTo(DASHBOARD_PAGE_ROUTE);
    }

    function closeAccountSequence(){
       
        deleteFinancialAccount().then(res =>{
            console.log(res);
            if(res == 200){
                closeCloseAccountConfirmation();
                redirectToDashboard();
            }else{
                //some error
                toggleErrorDialog();
            }
        })
 
    }
    

return (
    <>
    <Dialog position={{ top: 20, right: 20 }} opened={errorDialogOpened} withCloseButton onClose={closeErrorDialog} size="lg" radius="md">
        <Text size="sm" mb="xs" weight={500}>
          There was an error with your request.  Please try again later.
        </Text>
        <Group align="flex-end">
          <Button label="Ok" onClick={closeErrorDialog}></Button>
        </Group>
        
    </Dialog>
    <Modal opened={closeAccountConfirmationOpened} onClose={closeCloseAccountConfirmation} title="Are you sure?">
        <Button
            label="Yes, Please close my account"
            onClick={closeAccountSequence}
        />
    </Modal>
    <div>
      <p>
        Please click here to begin the account closure process.
      </p>
      <Button
        label="Close Account"
        onClick={openCloseAccountConfirmation}
      />
    </div>
    </>
    
  )
}

export default CloseAccount
