import React, { SelectHTMLAttributes } from "react"
import styled, { css } from "styled-components"
import theme from "src/assets/theme"
import { Icon } from "src/components"

export type Option = {
  name: string
  value: string | number
}

type SelectProps = {
  name: string
  label?: string
  errors?: string
  link?: string
  $noMargin?: boolean
  options?: Option[]
} & SelectHTMLAttributes<HTMLSelectElement>

const Select: React.FC<SelectProps> = ({
  name,
  label,
  disabled,
  errors,
  link,
  $noMargin,
  options,
  ...props
}) => {
  return (
    <Field $noMargin={$noMargin}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Container id={name} disabled={disabled} $hasError={!!errors} {...props}>
        {options?.map(o => (
          <option key={o.value} value={o.value}>
            {o.name}
          </option>
        ))}
      </Container>
      <FieldIcon>
        <Icon id="chevronDown" />
      </FieldIcon>
      {link && <FieldLink>{link}</FieldLink>}
      <End>
        {!!errors && (
          <Error>{errors.slice(0, 1).toUpperCase() + errors.slice(1)}</Error>
        )}
      </End>
    </Field>
  )
}

// Styles

// const boxShadow = `0 1px 2px 0 rgba(211, 211, 211, 0.3)`
const boxShadow = `0 0 0 black`
// const boxShadowFocus = `0 1px 2px 0 rgba(102, 86, 251, 0.3)`
const boxShadowFocus = `0 0 0 black`

const Error = styled.div`
  align-items: center;
  background: ${theme.goldTT};
  border-radius: 4px;
  display: inline-flex;
  font-size: 0.875rem;
  line-height: 1rem;
  margin-top: 0.25rem;
  padding: 0.5rem;
`

const End = styled.div`
  display: flex;
`

type FieldProps = {
  $noMargin?: boolean
}
const Field = styled.div<FieldProps>`
  margin-bottom: 1.5rem;
  position: relative;
  width: 100%;
  ${props =>
    props.$noMargin &&
    css`
      margin-bottom: 0;
    `}
`

const FieldIcon = styled.div`
  background: linear-gradient(to right, rgba(255, 255, 255, 0.5), white 50%);
  border-radius: 6px;
  margin-top: calc(1.75rem + 1px);
  padding: 0.75rem 0.625rem calc(0.5rem - 2px) 1.5rem;
  pointer-events: none;
  position: absolute;
  right: 1px;
  top: 0;
  svg {
    color: ${theme.graySSSS};
    font-size: 1.5rem;
  }
`

const FieldLink = styled.div`
  a {
    position: absolute;
    top: 0;
    right: 0;
  }
`

const Label = styled.label`
  display: block;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin-bottom: 0.25rem;
  white-space: nowrap;
`

type ContainerProps = {
  $hasError?: boolean
}
const Container = styled.select<ContainerProps>`
  appearance: none;
  border: 0;
  border-radius: 6px;
  box-shadow: inset 0 0 0 1px lightgray, ${boxShadow};
  box-sizing: border-box;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.75rem 1px 0.75rem 1.25rem;
  transition: 250ms box-shadow ${({ theme }) => theme.cubicEaseOut};
  width: 100%;
  &:focus {
    box-shadow: inset 0 0 0 1.5px ${({ theme }) => theme.redT},
      ${boxShadowFocus};
    outline: none;
    & + svg {
      color: ${theme.red};
    }
  }
  &::placeholder {
    color: #c9c9c9;
  }
  &:disabled {
    background: #eee;
    color: #999;
  }
  ${props =>
    props.$hasError &&
    css`
      box-shadow: inset 0 0 0 1.5px ${({ theme }) => theme.gold};
    `}
`

export default Select
