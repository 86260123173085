import { MediaQuery, Stepper } from "@mantine/core"
import { IconX } from "@tabler/icons"
import React from "react"
import { PhysicalCardOrderStatus } from "src/types"
import { XS_BREAKPOINT_WIDTH_PX } from "src/uiConstants"
import { minWidthMediaQuery } from "src/utilities/mantineUtils"

type TrackableOrderStatus = Exclude<PhysicalCardOrderStatus, "CANCELED">

const ORDER_STATE_CONFIG: Record<TrackableOrderStatus, number> = {
  NEW: 1,
  SENT_TO_PRINTER: 1,
  APPROVED: 2,
  SHIPPED: 3,
  SHIP_FAILED: 3,
}

type Props = {
  status: TrackableOrderStatus
}
const VisualTimeline: React.VFC<Props> = ({ status }) => {
  const breakpoint = XS_BREAKPOINT_WIDTH_PX
  const stretchRedShade = "#FB5755"
  return (
    <MediaQuery
      query={minWidthMediaQuery(breakpoint)}
      styles={{
        ".mantine-Stepper-steps": {
          width: "100%",
        },
        ".mantine-Stepper-separator": {
          borderTop: `2px dashed ${stretchRedShade}`,
        },
      }}
    >
      <Stepper
        active={ORDER_STATE_CONFIG[status]}
        breakpoint={breakpoint}
        color="stretch-red"
        styles={theme => ({
          root: {
            width: "100%",
            paddingLeft: theme.spacing.md,
            paddingRight: theme.spacing.md,
            display: "flex",
            justifyContent: "center",
          },
          separator: {
            height: 2,
            borderRight: `2px dashed ${stretchRedShade}`,
            backgroundColor: "transparent",
          },

          separatorActive: {
            borderWidth: 0,
            backgroundColor: `${stretchRedShade}`,
          },
        })}
      >
        <Stepper.Step
          label="Requested"
          allowStepClick={false}
          allowStepSelect={false}
          icon={null}
        />
        <Stepper.Step
          label="Printing"
          allowStepClick={false}
          allowStepSelect={false}
          icon={null}
        />
        {status !== "SHIP_FAILED" && (
          <Stepper.Step
            label="Shipped"
            allowStepClick={false}
            allowStepSelect={false}
            icon={null}
          />
        )}
        {status === "SHIP_FAILED" && (
          <Stepper.Step
            label="Ship Failed"
            allowStepClick={false}
            allowStepSelect={false}
            completedIcon={<IconX />}
            color="gray"
          />
        )}
      </Stepper>
    </MediaQuery>
  )
}

export default VisualTimeline
