import { AnyAction, ThunkAction } from "@reduxjs/toolkit"
import { RootState } from "src/store/store"
import { BankingOnboardingStatus, ServerUser } from "src/types"
import { parseError } from "src/utilities/errorUtils"
import { thunkRefreshCardApplication } from "./cardApplication"
import { thunkRefreshFinancialAccount } from "./financialAccount"
import { thunkRefreshPaymentCard } from "./paymentCards"

export const thunkHydrateBankingState =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (dispatch, getRootState) => {
    const {
      user: { loggedInUser },
    } = getRootState()

    const onboardingStatus = determineOnboardingStatus(loggedInUser)

    if (onboardingStatus === "NONE") {
      return
    }

    const fetchCalls: Promise<void>[] = []
    switch (onboardingStatus) {
      case "PERM_CARD_ISSUED":
        fetchCalls.push(
          dispatch(thunkRefreshPaymentCard("PERMANENT")),
          dispatch(thunkRefreshFinancialAccount(true)),
          dispatch(thunkRefreshCardApplication()),
        )
        break
      case "APP_APPROVED":
      case "ACCOUNT_CREATED":
        fetchCalls.push(
          dispatch(thunkRefreshFinancialAccount()),
          dispatch(thunkRefreshCardApplication()),
        )
        break
      case "APP_SUBMITTED":
        fetchCalls.push(dispatch(thunkRefreshCardApplication()))
        break

      default:
        break
    }

    try {
      if (fetchCalls.length > 0) {
        await Promise.all(fetchCalls)
      }
    } catch (err) {
      const { errorMessage } = parseError(err)
      // Rethrowing so that caller may catch.
      // TODO: Move caller logic here?
      throw new Error(errorMessage)
    }
  }

const determineOnboardingStatus = (
  user?: ServerUser,
): BankingOnboardingStatus => {
  const accountHolder = user?.accountHolder

  if (!accountHolder) {
    return "NONE"
  }

  const { cardApplicationStatus, debitAccountStatus, permanentCardStatus } =
    accountHolder

  if (permanentCardStatus) {
    return "PERM_CARD_ISSUED"
  }

  if (debitAccountStatus) {
    return "ACCOUNT_CREATED"
  }

  if (cardApplicationStatus) {
    switch (cardApplicationStatus) {
      case "APPROVED":
        return "APP_APPROVED"
      default:
        return "APP_SUBMITTED"
    }
  }

  return "NONE"
}
