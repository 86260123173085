import React from "react"
import styled, { css, keyframes } from "styled-components"

type ModalProps = {
  $center?: boolean
  close?: () => void
  show: boolean
}
const Modal: React.FC<ModalProps> = ({ $center, children, close, show }) => {
  if (show) {
    return (
      <Container $center={$center}>
        <Background onClick={close} />
        <Content>{children}</Content>
      </Container>
    )
  } else {
    return <></>
  }
}

const enterContainer = keyframes`
  from {
    opacity: 0;
  }
`

type ContainerProps = {
  $center?: boolean
}
const Container = styled.div<ContainerProps>`
  animation: 500ms ${enterContainer} ${props => props.theme.easeOutCubic};
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  padding-top: 4rem;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  ${props =>
    props.$center &&
    css`
      align-items: center;
      padding: 1rem;
    `}
  @media (min-width: 50em) {
    padding-top: 1.75rem;
    ${props =>
      props.$center &&
      css`
        padding: 2rem;
      `}
  }
`

const Background = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
`

const Content = styled.div`
  position: relative;
  z-index: 1;
`

export default Modal
