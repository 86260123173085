import React from "react"
import styled from "styled-components"
import stretchGif from "src/assets/images/stretch_v3_transparent.gif"

const StretchGif = styled.div`
  background-image: url(${stretchGif});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 70px;
`
export const StretchLogo = (): React.ReactElement => {
  return (
    <div className="LogoWrapper pb-16 pt-8">
      <StretchGif />
    </div>
  )
}
