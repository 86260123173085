import {
  AnyAction,
  createSlice,
  PayloadAction,
  ThunkAction,
} from "@reduxjs/toolkit"
import { getPaymentCardToken } from "src/api"
import { RootState } from "src/store/store"
import {
  ClientToken,
  PaymentCardLifecycle,
  PaymentCardTokenPermission,
} from "src/types"
import { isApiAuthenticationError } from "src/utilities/errorUtils"
import { redirectAfterSessionExpired } from "src/utilities/routingUtils"
import { thunkClearUserData } from "../user/userReducer"

type State = {
  temporaryCardViewDetailsToken?: ClientToken
  temporaryCardChangePinToken?: ClientToken
  permanentCardViewDetailsToken?: ClientToken
  permanentCardChangePinToken?: ClientToken
}
const initialState: State = {}

const slice = createSlice({
  name: "paymentCardTokens",
  initialState,
  reducers: {
    setCardToken: (
      state,
      action: PayloadAction<{
        token: ClientToken
        cardLifeCycle: PaymentCardLifecycle
        permission: PaymentCardTokenPermission
      }>,
    ) => {
      const { cardLifeCycle, permission, token } = action.payload
      if (cardLifeCycle === "TEMPORARY") {
        if (permission === "READ_RESTRICTED_DETAILS") {
          state.temporaryCardViewDetailsToken = token
        } else if (permission === "SET_PAYMENT_CARD_PIN") {
          state.temporaryCardChangePinToken = token
        }
      } else if (cardLifeCycle === "PERMANENT") {
        if (permission === "READ_RESTRICTED_DETAILS") {
          state.permanentCardViewDetailsToken = token
        } else if (permission === "SET_PAYMENT_CARD_PIN") {
          state.permanentCardChangePinToken = token
        }
      }
    },
  },
})

export const cardTokensSlice = slice.reducer

export const { setCardToken } = slice.actions

export const thunkGetPaymentCardToken =
  (
    cardLifeCycle: PaymentCardLifecycle,
    permission: PaymentCardTokenPermission,
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async dispatch => {
    try {
      const token = await getPaymentCardToken(cardLifeCycle, permission)
      dispatch(setCardToken({ token, cardLifeCycle, permission }))
    } catch (err) {
      if (isApiAuthenticationError(err)) {
        dispatch(thunkClearUserData())
        redirectAfterSessionExpired()
        return
      }
      throw err
    }
  }

export const selectTemporaryPaymentCardViewDetailsToken = (
  rootState: RootState,
): ClientToken | undefined => {
  return rootState.cardTokens.temporaryCardViewDetailsToken
}

export const selectTemporaryPaymentCardChangePinToken = (
  rootState: RootState,
): ClientToken | undefined => {
  return rootState.cardTokens.temporaryCardChangePinToken
}

export const selectPermanentPaymentCardViewDetailsToken = (
  rootState: RootState,
): ClientToken | undefined => {
  return rootState.cardTokens.permanentCardViewDetailsToken
}

export const selectPermanentPaymentCardChangePinToken = (
  rootState: RootState,
): ClientToken | undefined => {
  return rootState.cardTokens.permanentCardChangePinToken
}
