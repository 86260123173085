import { DateTime } from "luxon"
import React, { useState } from "react"
import {
  createDocUploadLink,
  endDocUploadSession,
  getDocumentUploadToken,
  getDocUploadSession,
  notifyDocUploadCompleted,
  parseHnApiErrors,
  startDocUploadSession,
  uploadFile,
} from "src/api"
import { Spinner } from "src/components"
import { useAppDispatch } from "src/hooks"
import { thunkRefreshCardApplication } from "src/store"
import { ClientToken, DocumentToUpload, UploadRequirements } from "src/types"
import { DEFAULT_ERROR_MESSAGE, delay, parseError } from "src/utilities"
import ManageDocUpload from "./ManageDocUpload"

type Props = {
  documentUploadSessionId: string
}

const ManageDocUploadContainer: React.VFC<Props> = ({
  documentUploadSessionId,
}) => {
  const dispatch = useAppDispatch()
  const [docUploadToken, setDocUploadToken] = useState<ClientToken>()
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false)

  const initializeDocUpload = async () => {
    const token = await getDocumentUploadToken()
    setDocUploadToken(token)

    const getResult = await getDocUploadSession(
      documentUploadSessionId,
      token.value,
    )

    if (
      getResult.errors === undefined &&
      ["INITIATED", "IN_PROGRESS"].includes(getResult.status)
    ) {
      return getResult
    }

    const startResult = await startDocUploadSession(
      documentUploadSessionId,
      token.value,
    )
    if (!startResult || startResult?.errors) {
      const errMessage = parseHnApiErrors(startResult)
      throw new Error(errMessage)
    }

    return startResult
  }

  const handleSubmit = async ({
    primaryDocument,
    secondaryDocument,
  }: UploadRequirements) => {
    if (
      !docUploadToken ||
      DateTime.fromISO(docUploadToken.expiresAt).diffNow().toMillis() <= 0
    ) {
      throw new Error(DEFAULT_ERROR_MESSAGE)
    }

    setShowLoadingSpinner(true)
    const { value: token } = docUploadToken
    await uploadDocument(primaryDocument, token)
    await uploadDocument(secondaryDocument, token)
    const endResult = await endDocUploadSession(documentUploadSessionId, token)
    if (endResult?.errors) {
      const errMessage = parseHnApiErrors(endResult)
      setShowLoadingSpinner(false)
      throw new Error(errMessage)
    }

    await notifyDocUploadCompleted([
      primaryDocument.type,
      secondaryDocument.type,
    ])

    // Wait for HN to update the application status so when we refresh it, we render the correct screen in the app
    await delay(5000)

    await dispatch(thunkRefreshCardApplication())
    setShowLoadingSpinner(false)
  }

  const uploadDocument = async (doc: DocumentToUpload, token: string) => {
    const result = await createDocUploadLink(
      {
        documentUploadSessionId,
        documentType: doc.type,
      },
      token,
    )

    if (!result) {
      throw new Error(DEFAULT_ERROR_MESSAGE)
    }

    try {
      await uploadFile(result.uploadUrl, doc.file)
    } catch (err) {
      const { errorMessage } = parseError(err)
      throw new Error(errorMessage)
    }

    // TODO: Verify successful upload by checking document status in HN
  }

  return showLoadingSpinner ? (
    <Spinner />
  ) : (
    <ManageDocUpload
      initializeDocUpload={initializeDocUpload}
      submitDocuments={handleSubmit}
    />
  )
}

export default ManageDocUploadContainer
