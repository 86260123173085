import { Center } from "@mantine/core"
import { DateTime } from "luxon"
import React, { ReactNode, useEffect, useState } from "react"
import { Loader } from "src/components"
import { isHighnoteProdEnv } from "src/config"
import { useAppDispatch, useAppSelector } from "src/hooks/redux"
import {
  selectPermanentPaymentCardChangePinToken,
  selectTemporaryPaymentCardChangePinToken,
  thunkGetPaymentCardToken,
} from "src/store"
import { PaymentCard } from "src/types"
import { PinComponentProps } from "./CardPinInput"

type Props = {
  card: PaymentCard
  renderPinComponent: (_props: PinComponentProps) => ReactNode
}

const DEFAULT_CARD_TOKEN_LIFESPAN_MINUTES = 15
const CardPinContainer: React.VFC<Props> = ({
  card: { id: cardId, lifecycle },
  renderPinComponent,
}) => {
  const dispatch = useAppDispatch()
  const selectorFn =
    lifecycle === "TEMPORARY"
      ? selectTemporaryPaymentCardChangePinToken
      : selectPermanentPaymentCardChangePinToken
  const changePinToken = useAppSelector(selectorFn)
  const [changePinTokenValid, setChangePinTokenValid] = useState(false)

  useEffect(() => {
    if (!changePinTokenValid) {
      dispatch(thunkGetPaymentCardToken(lifecycle, "SET_PAYMENT_CARD_PIN"))
      setChangePinTokenValid(true)
    }

    const tokenExpirationIntervalMinutes = changePinToken
      ? DateTime.fromISO(changePinToken.expiresAt).diffNow().as("minutes")
      : DEFAULT_CARD_TOKEN_LIFESPAN_MINUTES

    const timer = setTimeout(() => {
      setChangePinTokenValid(false)
    }, (tokenExpirationIntervalMinutes - 1) * 60_000)

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [changePinTokenValid])

  return (
    <>
      {!changePinToken && (
        <Center>
          <Loader />
        </Center>
      )}
      {!!changePinToken &&
        renderPinComponent({
          paymentCardId: cardId,
          clientToken: changePinToken.value,
          isHighnoteProdEnv,
        })}
    </>
  )
}

export default CardPinContainer
