import { Checkbox, Text } from "@mantine/core"
import { uniqueId } from "lodash"
import useObserver from "pojo-observer"
import React, { useEffect, useMemo, useState } from "react"
import { UploadedDocument } from "src/api"
import {
  Flex,
  FlexItem,
  FormFeedback,
  MantineDefaultButton,
  Spacing,
  Spinner,
} from "src/components"
import {
  docUploadChecklist as rootDocUploadChecklist,
  DocumentPlaceholder,
} from "src/models"
import {
  ApplicationDocumentType,
  DocumentToUpload,
  KycMismatch,
  UiFeedback,
  UserBankingDetails,
  VerificationTag,
} from "src/types"
import {
  getFormattedAddress,
  getFormattedDate,
  getFormattedUSPhoneNumber,
  getUserFullName,
  parseError,
} from "src/utilities"
import { DocUploadInput, DocUploadInputProps } from "../DocUploadInput"

type Props = {
  requiredDocumentTypes: ApplicationDocumentType[]
  uploadedDocs: UploadedDocument[]
  verificationTags: VerificationTag[]
  userBankingDetails: UserBankingDetails
  submitDocuments: (_docs: DocumentToUpload[]) => Promise<void>
}

const DocUploadForm: React.VFC<Props> = ({
  requiredDocumentTypes,
  uploadedDocs,
  verificationTags,
  userBankingDetails,
  submitDocuments,
}) => {
  const docUploadChecklist = rootDocUploadChecklist
  useObserver(docUploadChecklist)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitResult, setSubmitResult] = useState<UiFeedback>()

  const [declarations, setDeclarations] = useState<string[]>([])

  // Ideally, we would store the "file handles" in docUploadChecklist object,
  // but the handles get mangled within the object (perhaps due to the observer library)
  // So we will keep the handles here in a react component and use placeholders in the object
  const [fileMap, setFileMap] = useState<Record<string, File | undefined>>({})

  const formIsValid = useMemo(
    () =>
      docUploadChecklist.getAreRequirementsSatisfied() &&
      declarations.length === 2,
    [docUploadChecklist, declarations],
  )
  const mismatchedPersonalInfoMapping =
    useComputeMismatchedPersonalInfoText(userBankingDetails)

  useEffect(() => {
    if (
      declarations.length > 0 &&
      !docUploadChecklist.getAreRequirementsSatisfied()
    ) {
      setDeclarations([])
    }
  }, [docUploadChecklist])

  useEffect(() => {
    docUploadChecklist.initialize(
      requiredDocumentTypes,
      uploadedDocs,
      verificationTags,
    )
  }, [requiredDocumentTypes, uploadedDocs, verificationTags])

  const handleSubmit = async () => {
    setIsSubmitting(true)

    try {
      const attachedDocuments: DocumentToUpload[] = docUploadChecklist
        .getAttachedDocuments()
        .filter(doc => fileMap[doc.id] !== undefined)
        .map(doc => {
          return { type: doc.type, file: fileMap[doc.id] }
        }) as DocumentToUpload[]
      await submitDocuments(attachedDocuments)
    } catch (error) {
      setSubmitResult({
        message: parseError(error).errorMessage,
        messageType: "negative",
      })
      setIsSubmitting(false)
    }
  }

  const handleDocSelected = (
    file: File,
    docType: ApplicationDocumentType,
    mismatchType: KycMismatch,
  ) => {
    const id = uniqueId()
    const changedDoc: DocumentPlaceholder = {
      id,
      type: docType,
    }
    setFileMap({
      ...fileMap,
      [id]: file,
    })

    docUploadChecklist.resolveMismatch(mismatchType, changedDoc)
  }

  const handleDocRemoved = (mismatchType: KycMismatch) => {
    const docPlaceholder = docUploadChecklist.unresolveMismatch(mismatchType)
    const id = docPlaceholder?.id

    if (id !== undefined) {
      setFileMap({
        ...fileMap,
        [id]: undefined,
      })
    }
  }

  if (!docUploadChecklist.getIsInitialized()) {
    return <Spinner />
  }

  return (
    <Flex.Vertical hAlign="center">
      <FormFeedback
        feedback={submitResult}
        onTimerExpired={() => {
          setSubmitResult(undefined)
        }}
      />

      {docUploadChecklist.getAreNoMismatchesFound() ? (
        <>
          <Text>Almost done! Please submit your documents.</Text>
          <Spacing.Horizontal size="lg" />
        </>
      ) : null}
      {docUploadChecklist.getKycMismatches().map(mismatch => (
        <WrappedDocUploadInput
          key={mismatch}
          mismatchType={mismatch}
          mismatchedPersonalInfo={mismatchedPersonalInfoMapping[mismatch]}
          docTypes={docUploadChecklist.getDocTypeSelectionList(mismatch)}
          onDocumentSelected={handleDocSelected}
          onDocumentRemoved={handleDocRemoved}
        />
      ))}
      {docUploadChecklist.getAreRequirementsSatisfied() ? (
        <>
          <Checkbox.Group
            orientation="vertical"
            value={declarations}
            onChange={setDeclarations}
          >
            <Checkbox
              value="docs-readable"
              label="My attached documents are not blurry and can be easily read"
            />
            <Spacing.Horizontal />
            <Checkbox
              value="docs-match"
              label="My attached documents match the document types I selected"
            />
          </Checkbox.Group>
          <Spacing.Horizontal />
        </>
      ) : null}
      <Flex.Horizontal hAlign="center" noWrap>
        <FlexItem
          grow="equally"
          component={MantineDefaultButton}
          label="Upload"
          onClick={handleSubmit}
          disabled={!formIsValid}
          isLoading={isSubmitting}
          loaderPosition={"right"}
          loaderProps={{ color: "white" }}
        />
      </Flex.Horizontal>
    </Flex.Vertical>
  )
}

// We need this to properly apply a key when this gets rendered from a list of mismatches
const WrappedDocUploadInput: React.VFC<DocUploadInputProps> = props => {
  return (
    <>
      <DocUploadInput {...props} />
      <Spacing.Horizontal size="lg" />
    </>
  )
}

function useComputeMismatchedPersonalInfoText(
  userDetails: UserBankingDetails,
): Record<KycMismatch, string> {
  return {
    ADDRESS: getFormattedAddress(userDetails.physicalAddress),
    DOB: getFormattedDate(userDetails.bornOn),
    IDENTITY_THEFT: getUserFullName(
      userDetails.firstName,
      userDetails.lastName,
    ),
    NAME: getUserFullName(userDetails.firstName, userDetails.lastName),
    PHONE: getFormattedUSPhoneNumber(userDetails.phone),
    SSN: userDetails.ssnLast4,
    SYNTHETIC_FRAUD: getUserFullName(
      userDetails.firstName,
      userDetails.lastName,
    ),
  }
}

export default DocUploadForm
