import styled, { css } from "styled-components"
import theme from "src/assets/theme"

type CardProps = {
  $marginAuto?: boolean
  $noPadding?: boolean
  $slimPadding?: boolean
  $page?: boolean
  $fullPage?: boolean
  $fullPage2?: boolean
  $fullPage3?: boolean
  $fullPage4?: boolean
  $dashboardModal?: boolean
  $white?: boolean
  $widePadding?: boolean
  $widerPadding?: boolean
}
const Card = styled.div<CardProps>`
  background: ${theme.redTTTTT};
  border-radius: 6px;
  padding: 1.25rem;
  ${props =>
    props.$marginAuto &&
    css`
      margin: 1rem auto;
    `}
  ${props =>
    props.$noPadding &&
    css`
      padding: 0;
    `}
  /* ${props =>
    props.$slimPadding &&
    css`
      padding: 1.25rem;
    `} */
  ${props =>
    props.$page &&
    css`
      border-radius: 12px;
      margin-bottom: 1rem;
      padding: 1.25rem;
      width: 100%;
    `}
  ${props =>
    props.$fullPage &&
    css`
      max-width: 32rem;
    `}
  ${props =>
    props.$fullPage2 &&
    css`
      max-width: 38rem;
    `}
    ${props =>
    props.$fullPage3 &&
    css`
      max-width: 50rem;
    `}
  ${props =>
    props.$fullPage4 &&
    css`
      max-width: 24rem;
    `}
    ${props =>
    props.$dashboardModal &&
    css`
      max-width: 40rem;
      padding: 1.5rem;
      width: 100%;
    `}
    ${props =>
    props.$white &&
    css`
      background: white;
    `}
  @media (min-width: 50em) {
    /* padding: 1.5rem; */
    /* ${props =>
      props.$slimPadding &&
      css`
        padding: 1.25rem;
      `} */
    ${props =>
      props.$noPadding &&
      css`
        padding: 0;
      `}
    ${props =>
      props.$page &&
      css`
        padding: 2.5rem;
      `}
      ${props =>
      props.$widePadding &&
      css`
        padding: 1.5rem;
      `}
      ${props =>
      props.$widerPadding &&
      css`
        padding: 2rem;
      `}
      ${props =>
      props.$dashboardModal &&
      css`
        padding: 2.5rem;
      `}
  }
`
export default Card
