import React from "react"
import { BaseSyntheticEvent } from "react"
import { Link } from "react-router-dom"
//import { Banner, Grid, GridItem, Icon } from "src/components"
import { Banner, Icon } from "src/components"
import { useAppSelector } from "src/hooks/redux"
import { Button } from "@mantine/core";
import { getFinancialAccountWithTransactions } from "src/api/stretch/integration/api/invocations"
import { Transaction, TransactionState, TransactionType } from "src/types"
//import { selectTransactions } from "src/store"
//import { mapFinancialAccountTransactions } from "src/api/stretch/integration/api/mapper"

import {
  selectIsPermanentCardClosed,
  selectFinancialAccount,
  selectPhysicalCardOrder,
  selectShouldActivatePermanentCard,
  selectShouldTrackPhysicalCardOrder,
  selectCanHaveTempCard,
  selectTemporaryPaymentCard,
  selectIsTempCardClosed,
} from "src/store"
import { CARD_VIEW_PAGE_ROUTE } from "src/utilities/routingUtils"
//import AvailableBalance from "./AvailableBalance"
import TransactionsViewHeader from "./TransactionsViewHeader"
//import BankingDetails from "./BankingDetails"
//import OrderPhysicalCard from "../PaymentCard/OrderPhysicalCard"
import { CardShipmentTracker } from "./PhysicalCardOrder/CardShipmentTracker"
import Transactions from "./Transactions"
import OrderTemporaryCard from "../PaymentCard/OrderTemporaryCard"
import {useEffect,useState} from "react"
import { getAccountHolderStatusInfo } from "src/api"
import { Center } from "@mantine/core"
import { FinancialAccountTransaction } from "src/api/stretch/integration/model/financial-account"
//import { Center } from "@mantine/core"

const FullyOnboardedV2: React.VFC = () => {
  const financialAccount = useAppSelector(selectFinancialAccount)
  //const financialAccountLoadingStatus = useAppSelector(selectFinancialAccountDetailsLoading)
  //const transactions = useAppSelector(selectTransactions)
  const shouldActivatePermanentCard = useAppSelector(
    selectShouldActivatePermanentCard,
  )

  const shouldTrackPermanentCard = useAppSelector(
    selectShouldTrackPhysicalCardOrder,
  )

  const cardOrder = useAppSelector(selectPhysicalCardOrder)
  const arePaymentCardsClosed = useAppSelector(selectIsPermanentCardClosed)

  const tempCard = useAppSelector(selectTemporaryPaymentCard)
  const canHaveTempCard = useAppSelector(selectCanHaveTempCard)
  const isTempCardClosed = useAppSelector(selectIsTempCardClosed)

  /*
    These state variables are simply for filtering content and are not the SOR for the state variable changes
    The SOR for filter updates exist in TrasactionsFilterView
  */
  const [isTodayFilterActive, setTodayFilterActive] = useState(true);
  const [isOneWeekFilterActive, setOneWeekFilterActive] = useState(false);
  const [isTwoWeeksFilterActive, setTwoWeeksFilterActive] = useState(false);
  const [isNoFilterActive, setNoFilterActive] = useState(false);

  const [isInitialized, setInitialized] = useState(false);

  const [searchBarText, setSearchBarText] = useState('');
  const [isTransactionsLoaded,setIsTransactionsLoaded] = useState(false);

  //Transactions variables
  const [transactionFromCursor,setTransactionFromCursor] = useState("");
  const [transactionsLeft, setTransactionsLeft] = useState(false);

  const [currentTransactions,setCurrentTransactions] = useState<Transaction[]>([]);

  //initialization to determine when to display the transaction experience
  useEffect(() => {

    getAccountHolderStatuses();

    getFinancialTransactions();

    console.log("Begin --- Order Statuses")
    console.log(shouldTrackPermanentCard);
    console.log(cardOrder);
    console.log("End --- Order Statuses")
    
  },[])

  useEffect(() => {
    
  },[transactionsLeft])

  function loadMoreTransactionsButtonClick(){
    if(transactionsLeft){
      getFinancialTransactions();
    }
  }

  function getFinancialTransactions(){

    getFinancialAccountWithTransactions(2,transactionFromCursor).then(res => {

      setIsTransactionsLoaded(false);

      if(res?.success == true){

        setIsTransactionsLoaded(true);

        const hasTransactionsLeft = res.data.pageInfo.hasNextPage;

        setTransactionsLeft(hasTransactionsLeft);

        const recentTransactions = convertNewTransactionsToLegacyTransactions(res.data.financialAccountTransactionsList);

        const updatedTransactions = currentTransactions.concat(recentTransactions);

        setCurrentTransactions(updatedTransactions);
       
        console.log("Current Transactions is");
        console.log(res.data.financialAccountTransactionsList);
        console.log(updatedTransactions);
        console.log("End Current Transactions");

        if(hasTransactionsLeft){
          const nextCursor = res.data.pageInfo.endCursor;
          setTransactionFromCursor(nextCursor);
        }

      }else{
        setIsTransactionsLoaded(true);
      }

    })
  }

  function getAccountHolderStatuses(){

    if(!isInitialized){

      getAccountHolderStatusInfo().then(res => {
  
        if(res?.success == true){
  
          setInitialized(true);
   
        }else{
          console.log("failure response")
          console.log(res);
        }
    
      })

    }

  }
  
  /*
    Callback functions to faciliate the transaction experience
  */

  //transaction filter callbacks
  const todayFilterClickedCallback = () => {
    setTodayFilterActive(true);
    setOneWeekFilterActive(false);
    setTwoWeeksFilterActive(false);
    setNoFilterActive(false);

  }

  const oneWeekFilterClickedCallback = () => {
    setOneWeekFilterActive(true);
    setTodayFilterActive(false);
    setTwoWeeksFilterActive(false);
    setNoFilterActive(false);
  }

  const twoWeekFilterClickedCallback = () => {
    setTwoWeeksFilterActive(true);
    setOneWeekFilterActive(false);
    setTodayFilterActive(false);
    setNoFilterActive(false);
  }

  const noFilterClickedCallback = () => {
    setTwoWeeksFilterActive(false);
    setOneWeekFilterActive(false);
    setTodayFilterActive(false);
    setNoFilterActive(true);
  }

  const searchBarClickedCallback = (evt:BaseSyntheticEvent) => {

    let searchText = '';

    if(evt.target.value != undefined){
      searchText = evt.target.value;
    }
    
    setSearchBarText(searchText)
  }

  const clearSearchBarClickedCallback = () =>{
    setSearchBarText('');
  }

  /*
  export type TransactionState =
  | "PENDING"
  | "DECLINED"
  | "COMPLETE"
  | "REVERSED"
  | "FAILED"
  */

  const determineHighOrderState = (newTransaction:FinancialAccountTransaction): TransactionState => {

    let state:TransactionState = "PENDING";

    if(newTransaction.transactionResponseCode == "APPROVED"){
      state = "COMPLETE";
    }else if(newTransaction.transactionResponseCode == "APPROVED_FOR_PARTIAL_AMOUNT"){
      state = "COMPLETE";
    }else if(newTransaction.transactionResponseCode == "DO_NOT_HONOR"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "INVALID_CARD_NUMBER"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "INSUFFICIENT_FUNDS"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "EXPIRED_CARD"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "NO_CARD_RECORD"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "INCORRECT_PIN"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "BAD_CVV"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "BAD_CVV2"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "BAD_CVV3"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "UNACTIVATED_CARD"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "SUSPENDED_CARD"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "TERMINATED_CARD"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "INVALID_AUTHORIZATION_EXPIRATION"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "BLOCKED_CARD"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "RE_ENTER_TRANSACTION"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "TRANSACTION_NOT_PERMITTED"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "CASH_BACK_LIMIT_EXCEEDED"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "CLOSED_ACCOUNT"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "SPECIAL_CONDITION_NO_PICK_UP"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "EXCEEDS_APPROVAL_AMOUNT_LIMIT"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "EXCEEDS_WITHDRAWAL_FREQUENCY_LIMIT"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "INVALID_MERCHANT"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "INVALID_TRANSACTION"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "PIN_DECRYPTION_ERROR"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "RESTRICTED_LOCATION"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "VALID_ACCOUNT_AMOUNT_NOT_SUPPORTED"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "CHIP_CARD_ARQC_VALIDATION_FAILURE"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "PRE_AUTHORIZATION_EXPIRED"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "INVALID_TRACK_DATA"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "INVALID_3DS_VERIFICATION"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "CA_DECLINED"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "ALL_APPROVED_CODES"){
      state = "COMPLETE";
    }else if(newTransaction.transactionResponseCode == "ALL_ISSUER_DECLINED_CODES"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "DECLINED"){
      state = "DECLINED";
    }else if(newTransaction.transactionResponseCode == "NOT_APPLICABLE"){
      if(newTransaction.postedAmount >= 0.0){
        state = "COMPLETE"
      }
    }

    return state;

  }
  const determineTransactionState = (newTransaction:FinancialAccountTransaction): TransactionState => {

    let state:TransactionState = "PENDING";

    const mostRecentEventType = newTransaction.mostRecentEventType;

    const transactionType = newTransaction.transactionType;

    const isComplete = newTransaction.isComplete;

    //if transaction is not complete mark it as pending
    if(!isComplete){
      state = "PENDING";
    }else{
      //only debit and credit transactions have a valid most recent event type
      if(transactionType == "DebitTransaction" 
      || transactionType == "CreditTransaction"){
        if(mostRecentEventType == "ReversalEvent"){
          state = determineHighOrderState(newTransaction);
          if(state == "COMPLETE"){
            state = "REVERSED"
          }
        }else{
          state = determineHighOrderState(newTransaction);
        }
      }else{
        //default to complete for non credit & debit transactions
        state = "COMPLETE"
      }
    }

    return state;

  }

  const determineTransactionType = (newTransaction:FinancialAccountTransaction): TransactionType => {

    let direction:TransactionType = "NEUTRAL";

    const transactionType = newTransaction.transactionType;

    if(transactionType == "DebitTransaction"){
      direction = "DEBIT";
    }else if(transactionType == "CreditTransaction"){
      direction = "CREDIT";
    }else if(transactionType == "PayrollTransfer"){
      direction = "CREDIT";
    }else if(transactionType == "IntegratorInitiatedFundsWithdrawalACHTransfer"){
      direction = "DEBIT";
    }else if(transactionType == "SecureCardBalanceRepaymentACHTransfer"){
      direction = "DEBIT";
    }else if(transactionType == "IntegratorInitiatedFundsDepositACHTransfer"){
      direction = "CREDIT";
    }else if(transactionType == "InterFinancialAccountTransfer"){
      direction = "CREDIT";
    }else if(transactionType == "SecureDepositACHTransfer"){
      direction = "CREDIT";
    }else if(transactionType == "SecureDeposit"){
      direction = "CREDIT";
    }else if(transactionType == "CreditFunds"){
      direction = "CREDIT";
    }else if(transactionType == "DebitFunds"){
      direction = "DEBIT";
    }else if(transactionType == "FeeTransferEvent"){
      direction = "CREDIT";
    }else{
      direction = "CREDIT";
    }

    return direction;

  }
  
  const convertNewTransactionModelToLegacyTransactionType = (newTransaction:FinancialAccountTransaction): Transaction => {

    const transactionType = newTransaction.transactionType;
    
    let state:TransactionState = "PENDING";

    state = determineTransactionState(newTransaction);

    let type:TransactionType = "NEUTRAL"

    type = determineTransactionType(newTransaction);

    let transactionLogoUrlValue = undefined;

    if(newTransaction.transactionLogoUrl.length != 0){
      transactionLogoUrlValue = newTransaction.transactionLogoUrl;
    }

    let transactionDisplayDescription = "";

    if(newTransaction.enrichedMerchantName.length != 0){
      transactionDisplayDescription = newTransaction.enrichedMerchantName;
    }else if(newTransaction.enrichedDescription.length != 0){
      transactionDisplayDescription = newTransaction.enrichedDescription;
    }

    //Determine amount to display based on transaction state
    let displayAmount = 0.0;

    if(transactionType == "DebitTransaction"){
      if(state == "DECLINED"){
        displayAmount = newTransaction.mreRequestedAmount*100.0;
      }else if(state == "PENDING"){
        displayAmount = newTransaction.mreRequestedAmount*100.0;
      }else {
        displayAmount = newTransaction.mrePostedAmount*100.0;
      }
    }else if(transactionType == "CreditTransaction"){
      if(state == "DECLINED"){
        displayAmount = newTransaction.mreRequestedAmount*100.0;
      }else if(state == "PENDING"){
        displayAmount = newTransaction.mreRequestedAmount*100.0;
      }else {
        displayAmount = newTransaction.mrePostedAmount*100.0;
      }
    }else{
      displayAmount = newTransaction.postedAmount*100.0;
    }

    const transaction:Transaction = {
      id:newTransaction.transactionId,
      createdAt:newTransaction.createdAt,
      updatedAt:newTransaction.createdAt,
      state:state,
      amount:displayAmount,
      type:type,
      merchantName:newTransaction.mreMerchantName,
      merchantCategory:newTransaction.mreMerchantCategoryCode,
      currencyCode:"USD",
      enrichedDescription:newTransaction.enrichedDescription,
      personalCategoryUrl:newTransaction.personalCategoryUrl,
      transactionLogoUrl:transactionLogoUrlValue,
      requestedAmount:newTransaction.mreRequestedAmount*100.0,
      approvedAmount:newTransaction.postedAmount*100.0,
      originalAmount:newTransaction.pendingAmount*100.0,
      primaryPersonalCategory:newTransaction.primaryPersonalCategory,
      cardLastFour:newTransaction.mreCardLastFour,
      enrichedMerchantName:transactionDisplayDescription,
      derivedTransactionType:newTransaction.mostRecentEventType
    }

    return transaction;
    
  }

  const convertNewTransactionsToLegacyTransactions = (newTransactions:FinancialAccountTransaction[]): Transaction[] => {

    const legacyTransactions: Array<Transaction> = [];

    for(let i=0; i<newTransactions.length; i++){
      const newTransaction = newTransactions[i];
      const legacyTransaction = convertNewTransactionModelToLegacyTransactionType(newTransaction);
      legacyTransactions.push(legacyTransaction);
    }

    return legacyTransactions;

  }



  return (
    <div>
      {shouldActivatePermanentCard && (
        <Banner as={Link} to={CARD_VIEW_PAGE_ROUTE}>
          <Icon id="info" />
          Click here to activate your card.
        </Banner>
      )}
      {arePaymentCardsClosed && (
        <Banner as={Link} to={CARD_VIEW_PAGE_ROUTE}>
          <Icon id="info" />
          Your card is closed. Click here to re-order a new card.
        </Banner>
      )}
      
      {(!tempCard || isTempCardClosed) && canHaveTempCard && (
        <>
          <OrderTemporaryCard />
          <div className="mt-10 wideOnly"></div>
          <div className="mt-6 narrowOnly"></div>
        </>
      )}
      {shouldTrackPermanentCard && cardOrder && (
        <>
          <CardShipmentTracker cardOrder={cardOrder} />
          <div className="mt-10 wideOnly"></div>
          <div className="mt-6 narrowOnly"></div>
        </>
      )}

        <>
           <Center>
            
            <TransactionsViewHeader financialAccount={financialAccount} 
            todayFilterClickedCallback={todayFilterClickedCallback} 
            oneWeekFilterClickedCallback={oneWeekFilterClickedCallback} 
            twoWeekFilterClickedCallback={twoWeekFilterClickedCallback}
            noFilterClickedCallback={noFilterClickedCallback}
            searchBarClickedCallback={searchBarClickedCallback}
            clearSearchBarClickedCallback={clearSearchBarClickedCallback}
            /> 

            </Center>
          
        </>

        
        
      
      
      <div className="mt-10 wideOnly"></div>
      <div className="mt-6 narrowOnly"></div>

      
        <>
        <div style={{margin: 'auto',
        width:'100%',
        padding: '0px',
        minWidth: '200px',
        maxWidth: '675px'}}>

        

        <Transactions 
          transactions = {currentTransactions} 
          leftFilterIsActive = {isTodayFilterActive} 
          middleFilterIsActive = {isOneWeekFilterActive}
          rightFilterIsActive = {isTwoWeeksFilterActive}
          noFilterIsActive = {isNoFilterActive}
          searchText={searchBarText}
          transactionsLoaded={isTransactionsLoaded}
        />
        </div>
        </>

        {transactionsLeft && (

          <div style={{margin: 'auto',
            width:'100%',
            padding: '30px',
            minWidth: '200px',
            maxWidth: '675px'}}>

            <Center>
              <Button style = {{backgroundColor:'black', fontFamily:"Poppins",fontSize:14, fontWeight:700, color:'white', accentColor:'white', borderRadius:'12px', width:'250px'}} onClick={loadMoreTransactionsButtonClick}>See More Transactions</Button>
            </Center>

        </div>

        )}
        
        
      
    </div>
  )
}

export default FullyOnboardedV2
