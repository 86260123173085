import React from "react"
import { Helmet } from "react-helmet-async"

import { UpdatePasscodeParams } from "src/api"
import { Pages, Panels, SetPasscodeForm } from "src/components"
import { useOnboardingContext } from "src/context/OnboardingContext"
import { useAppDispatch } from "src/hooks/redux"
import { thunkUpdateUserAccount } from "src/store"

const Passcode: React.FC = () => {
  const dispatch = useAppDispatch()
  const { proceedToNextScreen } = useOnboardingContext()

  const handleSubmit = async (params: UpdatePasscodeParams) => {
    await dispatch(thunkUpdateUserAccount(params))
    proceedToNextScreen({ isPasscodeSet: true })
  }

  return (
    <Pages.FullPage>
      <Helmet>
        <title>Set Passcode | Stretch</title>
      </Helmet>
      <Panels.Default>
        <SetPasscodeForm handleSubmit={handleSubmit} />
      </Panels.Default>
    </Pages.FullPage>
  )
}

export default Passcode
