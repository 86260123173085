import React, { useState } from "react"
import { Helmet } from "react-helmet-async"
import { useLocation } from "react-router-dom"
import { Card, Headings, Message } from "src/components"

const VerifyEmail: React.FC = () => {
  const location = useLocation<{ email: string }>()
  const { email } = location.state || ""

  const [success, setSuccess] = useState(false)

  return (
    <>
      <Helmet>
        <title>Stretch - Check Your Email</title>
      </Helmet>
      <Card $page $fullPage>
        <Headings.H2>Check Your Email</Headings.H2>
        <p>Please click on the link we emailed to {email}.</p>
        <p className="m-0">
          If you don&apos;t see it, please check your &quot;Spam&quot; or
          &quot;Promotions&quot; folder
        </p>
        {success && (
          <Message
            message="Email sent. Check your inbox for the verification link."
            onTimeout={() => setSuccess(false)}
          />
        )}
      </Card>
    </>
  )
}

export default VerifyEmail
