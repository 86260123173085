import React, { FC, SelectHTMLAttributes } from "react"
import styled, { css } from "styled-components"
import theme from "src/assets/theme"
import { Icon } from "src/components"

interface StateSelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string
  value: string
  label?: string
  errors?: string
  link?: string
}

interface InputParams {
  $hasError?: boolean
}

const StateSelect: FC<StateSelectProps> = ({
  name,
  value,
  label,
  disabled,
  errors,
  link,
  ...props
}) => {
  return (
    <Field>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Select
        id={name}
        disabled={disabled}
        $hasError={!!errors}
        value={value}
        {...props}
      >
        <option value="">Select a state</option>
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="DC">District of Columbia</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="RI">Rhode Island</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="VT">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>
      </Select>
      <Icon id="chevronDown" />
      {link && <FieldLink>{link}</FieldLink>}
      <End>
        {!!errors && (
          <Error>{errors.slice(0, 1).toUpperCase() + errors.slice(1)}</Error>
        )}
      </End>
    </Field>
  )
}

// Styles

// const boxShadow = `0 1px 2px 0 rgba(211, 211, 211, 0.3)`
const boxShadow = `0 0 0 black`
// const boxShadowFocus = `0 1px 2px 0 rgba(102, 86, 251, 0.3)`
const boxShadowFocus = `0 0 0 black`

const Error = styled.div`
  align-items: center;
  background: ${theme.goldTT};
  border-radius: 4px;
  display: inline-flex;
  font-size: 0.875rem;
  line-height: 1rem;
  margin-top: 0.25rem;
  padding: 0.5rem;
`

const End = styled.div`
  display: flex;
`

const Field = styled.div`
  margin-bottom: 1.5rem;
  position: relative;
  width: 100%;
  svg {
    color: ${theme.graySSSS};
    font-size: 1.5rem;
    margin-top: 2.5rem;
    position: absolute;
    right: 0.625rem;
    top: 0;
  }
`

const FieldLink = styled.div`
  a {
    position: absolute;
    top: 0;
    right: 0;
  }
`

const Label = styled.label`
  display: block;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin-bottom: 0.25rem;
`

const Select = styled.select<InputParams>`
  appearance: none;
  border: 0;
  border-radius: 6px;
  box-shadow: inset 0 0 0 1px lightgray, ${boxShadow};
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.75rem 1.25rem;
  transition: 250ms box-shadow ${({ theme }) => theme.cubicEaseOut};
  width: 100%;
  &:focus {
    box-shadow: inset 0 0 0 1.5px ${({ theme }) => theme.redT},
      ${boxShadowFocus};
    outline: none;
    & + svg {
      color: ${theme.red};
    }
  }
  &::placeholder {
    color: #c9c9c9;
  }
  &:disabled {
    background: #eee;
    color: #999;
  }
  ${props =>
    props.$hasError &&
    css`
      box-shadow: inset 0 0 0 1.5px ${({ theme }) => theme.gold};
    `}
`

export default StateSelect
