import { configureStore } from "@reduxjs/toolkit"
import userReducer from "./user/userReducer"
import {
  cardApplicationSlice,
  cardTokensSlice,
  cardOrderSlice,
  financialAccountSlice,
  paymentCardsSlice,
  transactionsSlice,
} from "./banking"

export const store = configureStore({
  reducer: {
    user: userReducer,
    cardApplication: cardApplicationSlice,
    cardTokens: cardTokensSlice,
    cardOrder: cardOrderSlice,
    financialAccount: financialAccountSlice,
    paymentCards: paymentCardsSlice,
    transactions: transactionsSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
