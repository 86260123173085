import { AxiosRequestConfig } from "axios"
import { STRETCH_API_URL } from "src/config"
import { generateURL } from "src/utilities"

export function generateStretchAPIURL(path: string): string {
  return generateURL(path, STRETCH_API_URL)
}

export function requestConfiguration({
  headers,
  params,
  timeout,
  withCredentials,
}: Partial<AxiosRequestConfig> = {}): AxiosRequestConfig {
  return {
    headers: {
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
      ...headers,
    },
    params,
    timeout: timeout || 10000,
    withCredentials: withCredentials || true,
  }
}
