import { Modal } from "@mantine/core"
import React from "react"

type Props = {
  title: React.ReactNode
  opened: boolean
  onClose: () => void
}

const MantineModal: React.FC<Props> = ({
  title,
  children,
  opened,
  onClose,
}) => {
  return (
    <Modal centered title={title} opened={opened} onClose={onClose}>
      {children}
    </Modal>
  )
}

export default MantineModal
