import axios from "axios"
import { generateStretchAPIURL, requestConfiguration } from "../../common";
import { FinancialAccountTransactions } from "../model/financial-account";
import { mapFinancialAccountTransactions } from "./mapper";

export const FINANCIAL_ACCOUNT_URL = generateStretchAPIURL(
    "/api/v1/banking/fin-account-v2",
  )

export const getFinancialAccountWithTransactions = (numTransactions:number,fromCursor:string):Promise<ResponseContainer> => {
    return new Promise<ResponseContainer>((resolve) => {

    axios.get<unknown>(FINANCIAL_ACCOUNT_URL, 
    requestConfiguration({ params: { numTransactions: numTransactions, fromCursor: fromCursor }, timeout:500000 }))
      .then(res => {

        const financialAccountTransactions:FinancialAccountTransactions 
        = mapFinancialAccountTransactions(res.data);

        const responseContainer:ResponseContainer  = {
            data:financialAccountTransactions,
            success:true
        }

        resolve(responseContainer);

      }).catch(err => {

        const responseContainer:ResponseContainer  = {
            data:null,
            success:false,
            err:err
        }
        
        resolve(responseContainer);

      })

    });
};

export type ResponseContainer = {
    data:any,
    success:boolean,
    err?:any
}

export type IntegrationResponse = {
    data: any;
    success: boolean;
    errorMessageForUser?: string;
    err?: any;
};
