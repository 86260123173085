import { Duration } from "luxon"
import { useEffect, useState } from "react"
import { NoOpFunctionType } from "src/types"
import { delay } from "src/utilities"

/**
 * Encapsulates functionality that can be used to execute an error-prone callback. In case of failure,
 * the callback will be executed repeatedly (at a given interval) until it succeeds.
 *
 * @param interval the interval between each execution (as long as the callback hasn't succeeded yet)
 * @param callback the callback to execute
 */
export function useExecuteTillSuccessAsync(
  interval: Duration,
  callback: () => Promise<void>,
): { succeeded: boolean; iteration: number; cancel: NoOpFunctionType } {
  const [succeeded, setSucceeded] = useState(false)
  const [cancelled, setCancelled] = useState(false)
  const [iteration, setIteration] = useState(0)

  useEffect(() => {
    async function executeCallback(): Promise<void> {
      while (!succeeded && !cancelled) {
        try {
          setIteration(iteration => iteration + 1)
          await callback()
          setSucceeded(true)
          return
        } catch (err) {
          // No-op
        }
        await delay(interval.toMillis())
      }
    }

    executeCallback()
  }, [])

  function cancel(): void {
    setCancelled(true)
  }

  return { succeeded, iteration, cancel }
}
