/* eslint-disable */


import { FinancialTransactionEvent, 
    FinancialAccountTransaction, 
    FinancialAccountTransactions, 
    PageInfo } from "../model/financial-account";

export const mapFinancialAccountTransactions = (data?:any):FinancialAccountTransactions  => {

    var financialAccountTransactions:FinancialAccountTransactions = {
        totalAccountBalance:0.00,
        availableCashBalance:0.00,
        accountId:"",
        financialAccountTransactionsList:mapFinancialAccountTransactionsList(null),
        pageInfo:mapPageInfo(null),
        hasError:false,
        systemicErrorMessage:"",
        userErrorMessage:""
    }

    

    if(data != null){

        console.log("New transactions data");
        console.log(data);

        financialAccountTransactions = {
            totalAccountBalance:safeNumber(data.totalAccountBalance,0.00),
            availableCashBalance:safeNumber(data.availableCashBalance,0.00),
            accountId:safeString(data.accountId),
            financialAccountTransactionsList:mapFinancialAccountTransactionsList(data
                .financialAccountTransactionList),
            pageInfo:mapPageInfo(data.pageInfo),
            hasError:data.hasError,
            systemicErrorMessage:data.systemicErrorMessage,
            userErrorMessage:data.userErrorMessage
        }

        console.log("Parsed Transaction Object");
        console.log(financialAccountTransactions);
    }

    return financialAccountTransactions;

}

export const mapPageInfo = (data?:any) => {

    var pageInfo:PageInfo = {
        hasNextPage:false,
        hasPreviousPage:false,
        startCursor:"",
        endCursor:""
    }

    if(data != null){
        pageInfo = {
            hasNextPage:safeBoolean(data.hasNextPage),
            hasPreviousPage:safeBoolean(data.hasPreviousPage),
            startCursor:safeString(data.startCursor),
            endCursor:safeString(data.endCursor)
        }
    }

    return pageInfo;
}



export const mapFinancialAccountTransactionsList = (data?:any) => {

    var financialAccountTransactionsList = new Array<FinancialAccountTransaction>();

    if(data != null && Array.isArray(data)){

        let i = 0;

        while (i < data.length) {
            const financialAccountTransaction = mapFinancialAccountTransaction(data[i]);
            financialAccountTransactionsList.push(financialAccountTransaction);
            i++;
        }

    }

    return financialAccountTransactionsList;

}


export const mapFinancialAccountTransaction = (data?:any) => {

    console.log("Begin --- Mapping financial account tx")
    console.log(data);
    console.log("End --- Mapping financial account tx")
    var financialAccountTransaction:FinancialAccountTransaction = {
        sign:"",
        isComplete:false,
        requestedAmount:0.0,
        pendingAmount:0.0,
        postedAmount:0.0,
        createdAt:"",
        transactionType:"",
        transactionId:"",
        transactionEventList: new Array<FinancialTransactionEvent>(),
        category:"",
        description:"",
        transactionResponseCode:"",
        mostRecentEventType:"",
        mreMerchantCategory:"",
        mreMerchantCategoryCode:"",
        mreMerchantName:"",
        mreCardLastFour:"",
        mreRequestedAmount:0.0,
        mrePendingAmount:0.0,
        mrePostedAmount:0.0,
        enrichedMerchantName:"",
        enrichedDescription:"",
        transactionLogoUrl:"",
        personalCategoryUrl:"",
        primaryPersonalCategory:""
    }

    if(data != null){
        financialAccountTransaction = {
            sign:safeString(data.sign),
            isComplete:safeBoolean(data.isComplete),
            requestedAmount:safeNumber(data.requestedAmountValue,0.00),
            pendingAmount:safeNumber(data.pendingAmountValue,0.00),
            postedAmount:safeNumber(data.postedAmountValue,0.00),
            createdAt:safeString(data.createdAt),
            transactionType:safeString(data.transactionType),
            transactionId:safeString(data.transactionId),
            transactionEventList: mapFinancialTransactionEventList(data.transactionEventList),
            category:safeString(data.category),
            description:safeString(data.description),
            transactionResponseCode:safeString(data.transactionResponseCode),
            mostRecentEventType:safeString(data.mostRecentEventType),
            mreMerchantCategory:safeString(data.mreMerchantCategory),
            mreMerchantCategoryCode:safeString(data.mreMerchantCategoryCode),
            mreMerchantName:safeString(data.mreMerchantName),
            mreCardLastFour:safeString(data.mreCardLastFour),
            mreRequestedAmount:safeNumber(data.mreRequestedAmount,0.0),
            mrePendingAmount:safeNumber(data.mrePendingAmount,0.00),
            mrePostedAmount:safeNumber(data.mrePostedAmount,0.00),
            enrichedMerchantName:safeString(data.enrichedMerchantName),
            enrichedDescription:safeString(data.enrichedDescription),
            transactionLogoUrl:safeString(data.transactionLogoUrl),
            personalCategoryUrl:safeString(data.personalCategoryUrl),
            primaryPersonalCategory:safeString(data.primaryPersonalCategory)
        }
    }

    return financialAccountTransaction;
}

export const mapFinancialTransactionEventList = (data?:any) => {

    var financialTransactionEventList = new Array<FinancialTransactionEvent>();

    if(data != null && Array.isArray(data)){

        let i = 0;

        while (i < data.length) {
            const financialTransactionEvent = mapFinancialTransactionEvent(data[i]);
            financialTransactionEventList.push(financialTransactionEvent);
            i++;
        }

    }

    return financialTransactionEventList;

}



export const mapFinancialTransactionEvent = (data?:any) => {

    var financialTransactionEvent:FinancialTransactionEvent = {
        eventType:"",
        eventId:"",
        transactionId:"",
        merchantCategory:"",
        merchantName:"",
        createdAt:"",
        approvedAmount:0,
        originalAmount:0,
        requestedAmount:0,
        responseCode:""
    }

    if(data != null){

        financialTransactionEvent = {
            eventType:safeString(data.eventType),
            eventId:safeString(data.eventId),
            transactionId:safeString(data.transactionId),
            merchantCategory:safeString(data.merchantCategory),
            merchantName:safeString(data.merchantName),
            createdAt:safeString(data.createdAt),
            approvedAmount:safeNumber(data.approvedAmount,0),
            originalAmount:safeNumber(data.originalAmount,0),
            requestedAmount:safeNumber(data.requestedAmount,0),
            responseCode:safeString(data.responseCode)
        }

    }

    return financialTransactionEvent;

}


const safeString = (data:string) => {
    if(data != null){
        return data;
    }else{
        return "";
    }
}

const safeNumber = (data:number, def:number) => {
    if(data != null){
        return data;
    }else{
        return def;
    }
}

const safeBoolean = (data:boolean) => {
    if(data != null){
        return data;
    }else{
        return false;
    }
}