/* eslint-disable */

import React, { useState } from "react"
import { Helmet } from "react-helmet-async"
import { Button, useMantineTheme, Space, Center} from "@mantine/core"

import { MantineDefaultButton as MantineButton } from "src/components"

//import { Accordion, Group, MediaQuery, Text } from "@mantine/core"

import { Container } from "@mantine/core"
import { AccordionControl } from "@mantine/core/lib/Accordion/AccordionControl/AccordionControl"
import { AccordionItem as MantineAccordionItem } from "@mantine/core/lib/Accordion/AccordionItem/AccordionItem"
import {Group, MediaQuery, Text} from "@mantine/core"
import {Accordion as MantineAccordion} from "@mantine/core"
import { Accordion, AccordionItem} from "src/components"
import { IconCircleCheck } from "@tabler/icons"
import { ChangePasscodeForm, Headings, MantineModal } from "src/components"
import { ServerUser } from "src/types"
import AddressForm from "src/components/form/AddressForm"
import UpdateEmail from "./UpdateEmail"
import UpdatePhone from "./UpdatePhone"
import { UserChangePasscodeRequest } from "src/api"
import { thunkChangePasscode } from "src/store"
import { useAppDispatch } from "src/hooks/redux"
import NumberFormat from "react-number-format"

const MODALS = {
  EMAIL: "email",
  PHONE: "phone",
  ADDRESS: "address",
  PASSCODE: "passcode",
}

type Props = {
  user: ServerUser
}

const UserProfile: React.VFC<Props> = ({
  user: {
    physicalAddress,
    phone,
    phoneVerified,
    email,
    emailVerified,
    needsPasscodeAuth,
    accountHolder,
  },
}) => {
  const { firstLine, secondLine, locality, region, postalCode } =
    physicalAddress || {
      firstLine: "",
      locality: "",
      region: "",
      postalCode: "",
      country: "",
    }
  const secondLineStr = secondLine ? `, ${secondLine}` : ""
  const addressString = `${firstLine}${secondLineStr}, ${locality}, ${region} ${postalCode}`

  const dispatch = useAppDispatch()
  const [openModalName, setOpenModalName] = useState("")

  /*
  const handleOpenModal = (modalName: string) => {
    setOpenModalName(modalName)
  }
  */


  const closeModal = () => setOpenModalName("")

  const mantineTheme = useMantineTheme()

  const openPopUp = (modalName:string) => () => {
    setOpenModalName(modalName);
    console.log("open email");
  };


  const formatPhoneNumber = function (phoneNumber:any) {

    if(phoneNumber != null){
      return reformatPhoneNumber(phoneNumber);
    }else{
      return "Not Set";
    }

  }

  const reformatPhoneNumber = function (phoneNumber:string) {

    var cleaned = ('' + phoneNumber).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return "Not Set";

  }


  return (
    <>
      <Accordion initialOpenAccordion="nothing">
        <AccordionItem
              renderLabel={({ isActiveAccordion }) => (
                <Headings.H2
                  margin="none"
                  style={{
                    color: isActiveAccordion
                      ? mantineTheme.colors["stretch-red"][5]
                      : "black",
                  }}
                >
                  Update Email
                </Headings.H2>
              )}
              value="update_email" 
            >

            <div>
              <p>
                Your email on record is <br/><strong>{email}</strong>
              </p>
              <MantineButton
                  label="Update Email"
                  onClick={openPopUp(MODALS.EMAIL)}
              />
              <UpdateEmail
                isOpen={openModalName === MODALS.EMAIL}
                closeModal={closeModal}
              />
            </div>

        </AccordionItem>
        <AccordionItem
              renderLabel={({ isActiveAccordion }) => (
                <Headings.H2
                  margin="none"
                  style={{
                    color: isActiveAccordion
                      ? mantineTheme.colors["stretch-red"][5]
                      : "black",
                  }}
                >
                  Update Passcode
                </Headings.H2>
              )}
              value="update_passcode" 
            >

            <div>
              <p>
                Your passcode is the <strong>four-digit number</strong> you use to login.
              </p>
              <MantineButton
                  label="Update Passcode"
                  onClick={openPopUp(MODALS.PASSCODE)}
              />
              <MantineModal
                    onClose={closeModal}
                    opened={openModalName === MODALS.PASSCODE}
                    title="Change your Passcode"
                  >
                  <ChangePasscodeForm
                    submitButtonLabel="Update"
                    submitSuccessMessage="Your passcode has been updated"
                    handleSubmit={async (params: UserChangePasscodeRequest) => {
                      await dispatch(thunkChangePasscode(params))
                    }}
                    onSuccess={closeModal}
                  />
              </MantineModal>
            </div>

        </AccordionItem>
        <AccordionItem
              renderLabel={({ isActiveAccordion }) => (
                <Headings.H2
                  margin="none"
                  style={{
                    color: isActiveAccordion
                      ? mantineTheme.colors["stretch-red"][5]
                      : "black",
                  }}
                >
                  Update Phone
                </Headings.H2>
              )}
              value="update_phone" 
            >

            <div>
              <p>
                Your phone on record is <strong>{formatPhoneNumber(phone)}</strong>
              </p>
              <MantineButton
                  label="Update Phone"
                  onClick={openPopUp(MODALS.PHONE)}
              />
              <UpdatePhone
                isOpen={openModalName === MODALS.PHONE}
                closeModal={closeModal}
              />
            </div>

        </AccordionItem>

        {physicalAddress ? (
          <AccordionItem
          renderLabel={({ isActiveAccordion }) => (
            <Headings.H2
              margin="none"
              style={{
                color: isActiveAccordion
                  ? mantineTheme.colors["stretch-red"][5]
                  : "black",
              }}
            >
              Update Address
            </Headings.H2>
          )}
          value="update_address" 
        >

        <div>
          <p>
            Your address on record is: 
            <br/>
            <br/>
            <strong>{physicalAddress.firstLine}</strong><br/>
            {physicalAddress.secondLine && (
              <>
                <strong>{physicalAddress.secondLine}</strong><br/>
              </>
            )}
            <strong>{physicalAddress.locality}, {physicalAddress.region}</strong><br/>
            <strong>{physicalAddress.postalCode}</strong><br/>
          </p>
          {!accountHolder?.hasCardRestrictions && (
            <MantineButton
              label="Update Address"
              onClick={openPopUp(MODALS.ADDRESS)}
            />
          )}
          {accountHolder?.hasCardRestrictions && (
            <MantineButton
              label="Update Address"
              onClick={openPopUp(MODALS.ADDRESS)}
              disabled
            />
          )}
          <MantineModal
              onClose={closeModal}
              opened={openModalName === MODALS.ADDRESS}
              title="Change your Address"
            >
              <AddressForm
                initialAddress={physicalAddress}
                onSuccess={closeModal}
                submitButtonLabel="Update"
              />

            </MantineModal>
          </div>
          </AccordionItem>
        ): null}
        
      </Accordion>
    </>
  )
  
  {/*
              For address label styling this is a bit of a hack. To center value the extra label must be included
              set extra label icon and text to "verified" to ensure centering of address value.  Then colored them 
              white so it doesnt appear on the screen.
  */}
  /*

  return (
    <>
    <Accordion initialOpenAccordion="profile">
      <AccordionItem
            renderLabel={({ isActiveAccordion }) => (
              <Headings.H2
                margin="none"
                style={{
                  color: isActiveAccordion
                    ? mantineTheme.colors["stretch-red"][5]
                    : "black",
                }}
              >
                Update Your Information
              </Headings.H2>
            )}
            value="profile" 
          >

            <div onClick={openPopUp(MODALS.EMAIL)}>

              <AccordionLabel
                icon={
                  emailVerified ? (
                   <IconCircleCheck color="green" size="1rem" />
                  ) : null
                }
                label="Email"
                value={email}
                extraLabel={emailVerified ? "Verified" : undefined}
                extraLabelTextColor="green"
              />

              <UpdateEmail
                isOpen={openModalName === MODALS.EMAIL}
                closeModal={closeModal}
              />

            </div>

            <Space h="md" />

            {needsPasscodeAuth ? (
              <div onClick={openPopUp(MODALS.PASSCODE)}>
                <AccordionLabel label="Passcode" />
                  <MantineModal
                    onClose={closeModal}
                    opened={openModalName === MODALS.PASSCODE}
                    title="Change your Passcode"
                  >
                  <ChangePasscodeForm
                    submitButtonLabel="Update"
                    submitSuccessMessage="Your passcode has been updated"
                    handleSubmit={async (params: UserChangePasscodeRequest) => {
                      await dispatch(thunkChangePasscode(params))
                    }}
                    onSuccess={closeModal}
                  />
                </MantineModal>

                <Space h="md" />
              </div>
            ) : null}


            {phone ? (
              <div onClick={openPopUp(MODALS.PHONE)}> 

              <AccordionLabel
                  icon={phoneVerified ? <IconCircleCheck color="green" size="1rem" /> : null}
                  label="Phone"
                  value={phone}
                  extraLabel={phoneVerified ? "Verified" : undefined}
                  extraLabelTextColor="green"
                />

              <UpdatePhone
                isOpen={openModalName === MODALS.PHONE}
                closeModal={closeModal}
              />  

              <Space h="md" />
              </div>
            ) : null}

            {!accountHolder?.hasCardRestrictions && physicalAddress ? (
              <div onClick={openPopUp(MODALS.ADDRESS)}>
                <AccordionLabel 
                icon={phoneVerified ? <IconCircleCheck color="white" size="1rem" /> : null}
                label="Address" 
                value={addressString} 
                extraLabel="Verified"
                extraLabelTextColor="white"/>
                
                <MantineModal
                  onClose={closeModal}
                  opened={openModalName === MODALS.ADDRESS}
                  title="Change your Address"
                >
                  <AddressForm
                    initialAddress={physicalAddress}
                    onSuccess={closeModal}
                    submitButtonLabel="Update"
                  />

                </MantineModal>
              </div>
            ) : null}
           
      </AccordionItem>
      
    </Accordion>
    </>
  )
  */

  

  /*
  return (
    <>
      <Helmet>
        <title>Manage Your Account – Stretch</title>
      </Helmet>
      <Headings.H3>Update Your Information</Headings.H3>
      <MantineAccordion
        onChange={handleOpenModal}
        chevron={""}
        value={openModalName}
        styles={() => ({
          label: {
            fontSize: "initial",
          },
        })}
      >
        <MantineAccordion.Item value={MODALS.EMAIL}>
          <MantineAccordion.Control>
            <AccordionLabel
              icon={
                emailVerified ? (
                  <IconCircleCheck color="green" size="1rem" />
                ) : null
              }
              label="Email"
              value={email}
              extraLabel={emailVerified ? "Verified" : undefined}
            />
          </MantineAccordion.Control>
          <UpdateEmail
            isOpen={openModalName === MODALS.EMAIL}
            closeModal={closeModal}
          />
        </MantineAccordion.Item>
        {needsPasscodeAuth ? (
          <MantineAccordion.Item value={MODALS.PASSCODE}>
            <MantineAccordion.Control>
              <AccordionLabel label="Passcode" />
            </MantineAccordion.Control>
            <MantineModal
              onClose={closeModal}
              opened={openModalName === MODALS.PASSCODE}
              title="Change your Passcode"
            >
              <ChangePasscodeForm
                submitButtonLabel="Update"
                submitSuccessMessage="Your passcode has been updated"
                handleSubmit={async (params: UserChangePasscodeRequest) => {
                  await dispatch(thunkChangePasscode(params))
                }}
                onSuccess={closeModal}
              />
            </MantineModal>
          </MantineAccordion.Item>
        ) : null}
        {phone ? (
          <MantineAccordion.Item value={MODALS.PHONE}>
            <MantineAccordion.Control>
              <AccordionLabel
                icon={phoneVerified ? <IconCircleCheck color="green" /> : null}
                label="Phone"
                value={phone}
                extraLabel={phoneVerified ? "Verified" : undefined}
              />
            </MantineAccordion.Control>
            <UpdatePhone
              isOpen={openModalName === MODALS.PHONE}
              closeModal={closeModal}
            />
          </MantineAccordion.Item>
        ) : null}
        {!accountHolder?.hasCardRestrictions && physicalAddress ? (
          <MantineAccordion.Item value={MODALS.ADDRESS}>
            <MantineAccordion.Control>
              <AccordionLabel label="Address" value={addressString} />
            </MantineAccordion.Control>
            <MantineModal
              onClose={closeModal}
              opened={openModalName === MODALS.ADDRESS}
              title="Change your Address"
            >
              <AddressForm
                initialAddress={physicalAddress}
                onSuccess={closeModal}
                submitButtonLabel="Update"
              />
            </MantineModal>
          </MantineAccordion.Item>
        ) : null}
      </MantineAccordion>
    </>
  )
 */ 
}

type AccordionLabelProps = {
  icon?: React.ReactNode
  label: string
  value?: string
  extraLabel?: string
  extraLabelTextColor?: string
}
const AccordionLabel: React.VFC<AccordionLabelProps> = ({
  icon,
  label,
  value,
  extraLabel,
  extraLabelTextColor,
}) => {
  return (
    <Group noWrap position="apart">
      <Text>{label}</Text>

      {value ? (
        <Text color="dimmed" truncate ta="center" >
          {value}
        </Text>
      ) : null}
      
      <Group noWrap spacing="xs">
        {icon}
        <MediaQuery query="(max-width: 800px)" styles={{ display: "none" }}>
          <Text color={extraLabelTextColor} weight={400}>
            {extraLabel}
          </Text>
        </MediaQuery>
      </Group>
    </Group>
  )
}

export default UserProfile
