import {
  CardApplicationStatus,
  FinancialAccountStatus,
  PaymentCardStatus,
} from "./bankingTypes"
import { Address } from "./commonTypes"
import * as yup from "yup"
import { AxiosResponse } from "axios"

export const UnauthenticatedUserResponseSchema = yup
  .object({
    user: yup
      .object({
        email: yup.string().email().required(),
        needsPasscodeAuth: yup.boolean().required(),
      })
      .camelCase(),
  })
  .camelCase()
export type UnauthenticatedUser = {
  email: string
  needsPasscodeAuth: boolean
}
export function validateUnauthenticatedUserResponse(
  response: AxiosResponse<unknown>,
): UnauthenticatedUser {
  return UnauthenticatedUserResponseSchema.validateSync(response.data, {
    stripUnknown: true,
  }).user as UnauthenticatedUser
}

export const AddressResponseSchema = yup
  .object({
    firstLine: yup.string().required(),
    secondLine: yup.string().optional(),
    locality: yup.string().required(),
    region: yup.string().required(),
    postalCode: yup.string().required().min(5),
    country: yup.string().required(),
  })
  .camelCase()

export const UserResponseSchema = yup
  .object({
    user: yup
      .object({
        id: yup.string().required(),
        email: yup.string().email().required(),
        emailVerified: yup.boolean().required(),
        firstName: yup.string(),
        lastName: yup.string(),
        bornOn: yup.string(),
        phone: yup.string(),
        aptoStatus: yup.string().optional(),
        cards: yup.array().optional(),
        honestJobsJobseekerId: yup.string().optional(),
        intercomHash: yup.string().optional(),
        phoneVerified: yup.boolean().optional(),
        passcode: yup.string().optional(),
        ssn: yup.string().optional(),
        physicalAddress: AddressResponseSchema.optional().default(undefined),
        needsPasscodeAuth: yup.boolean().optional().default(false),
        accountHolder: yup
          .object({
            cardApplicationStatus: yup
              .mixed<CardApplicationStatus>()
              .optional(),
            debitAccountStatus: yup.mixed<FinancialAccountStatus>().optional(),
            temporaryCardStatus: yup.mixed<PaymentCardStatus>().optional(),
            permanentCardStatus: yup.mixed<PaymentCardStatus>().optional(),
            physicalCardOrderId: yup.string().optional(),
            hasCardRestrictions: yup.boolean().optional().default(undefined),
          })
          .camelCase()
          .optional(),
      })
      .camelCase()
      .required(),
  })
  .required()

export function validateUserResponse(
  response: AxiosResponse<unknown>,
): ServerUser {
  return UserResponseSchema.validateSync(response.data, {
    stripUnknown: true,
  }).user as ServerUser
}
export type ServerUser = {
  id: string
  email: string
  emailVerified: boolean
  firstName?: string
  lastName?: string
  bornOn?: string
  phone?: string
  aptoStatus?: string
  cards?: any[]
  honestJobsJobseekerId?: string
  intercomHash?: string
  phoneVerified?: boolean
  physicalAddress?: Address
  needsPasscodeAuth?: boolean
  accountHolder?: {
    cardApplicationStatus?: CardApplicationStatus
    debitAccountStatus?: FinancialAccountStatus
    temporaryCardStatus?: PaymentCardStatus
    permanentCardStatus?: PaymentCardStatus
    physicalCardOrderId?: string
    hasCardRestrictions?: boolean
  }
}

export type BankingOnboardingUser = {
  id: string
  email: string
  firstName?: string
  lastName?: string
  bornOn?: string
  phone?: string
  phoneVerified?: boolean
  physicalAddress: Address
}

export type UserBankingDetails = {
  id: string
  email: string
  firstName: string
  lastName: string
  bornOn: string
  phone: string
  ssnLast4: string
  physicalAddress: Address
}

export const UserBankingDetailsResponseSchema = yup
  .object({
    user: yup
      .object({
        id: yup.string().required(),
        email: yup.string().email().required(),
        firstName: yup.string(),
        lastName: yup.string(),
        bornOn: yup.string(),
        phone: yup.string(),
        ssnLast4: yup.string().optional(),
        physicalAddress: AddressResponseSchema.optional().default(undefined),
      })
      .camelCase()
      .required(),
  })
  .required()
export function validateUserBankingDetailsResponse(
  response: AxiosResponse<unknown>,
): UserBankingDetails {
  return UserBankingDetailsResponseSchema.validateSync(response.data, {
    stripUnknown: true,
  }).user as UserBankingDetails
}
