import { Duration } from "luxon"
import React, { createContext, useContext, useState } from "react"
import { getAppConfig } from "src/api/stretch/system-api"
import { useExecuteTillSuccessAsync } from "src/hooks/useExecuteTillSuccess"
import { AppConfig } from "src/types/systemTypes"

type ContextState = {
  appConfig?: AppConfig
}
const AppConfigContext = createContext<ContextState>({})

const ContextProvider: React.FC = ({ children }) => {
  const [appConfig, setAppConfig] = useState<AppConfig>()

  useExecuteTillSuccessAsync(Duration.fromObject({ seconds: 5 }), async () => {
    const appConfig = await getAppConfig()
    setAppConfig(appConfig)
  })

  return (
    <AppConfigContext.Provider value={{ appConfig }}>
      {children}
    </AppConfigContext.Provider>
  )
}

export function useAppConfigContext(): ContextState {
  const context = useContext(AppConfigContext)

  if (context === undefined) {
    throw new Error("AppConfigContext must be used with a Provider")
  }

  return context
}

export default ContextProvider
