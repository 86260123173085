import React, { useState } from "react"
import { ActionIcon, MantineSize } from "@mantine/core"
import { IconCopy } from "@tabler/icons"
import { Message } from "src/components/notification"
import { Flex } from "src/layouts"

type Props = {
  size?: MantineSize
  className?: string
  /**
   * value shown in tooltip when text is copied to the clipboard, e.g.
   * Copied ${value} -> Copied Account Number
   *
   * When not provided, message will read: "Copied text"
   */
  dataName?: string
  copyText?: string
}
const CopyTextButton: React.VFC<Props> = ({
  size = "xl",
  className,
  copyText,
  dataName,
}) => {
  const iconSizes = {
    xs: ".75rem",
    sm: ".09375rem",
    md: "1.0625rem",
    lg: "1.1875rem",
    xl: "1.3125rem",
  }

  const iconSize = iconSizes[size]
  const [copySuccessMessage, setCopySuccessMessage] = useState("")

  const copyTextToClipboard = () => {
    navigator.clipboard.writeText(copyText ?? "")
    setCopySuccessMessage(`Copied ${dataName ?? "text"}`)
  }

  return (
    <Flex flex="shrink">
      {copySuccessMessage ? (
        <Message
          message={copySuccessMessage}
          onTimeout={() => setCopySuccessMessage("")}
          styleOverrides={{ marginTop: "-3.5rem", marginLeft: "6rem" }}
        />
      ) : null}
      <ActionIcon<"button">
        className={className}
        onKeyDown={event => {
          if (event.key === " " || event.key === "Enter") {
            event.preventDefault()
            copyTextToClipboard()
          }
        }}
        onClick={copyTextToClipboard}
      >
        <IconCopy color="black" size={iconSize} />
      </ActionIcon>
    </Flex>
  )
}

export default CopyTextButton
