import React from "react"
import {Container} from "@mantine/core";
import { Button } from "@mantine/core";
import { useEffect,useState} from "react"



type Props = {
  todayFilterClickedCallback:any
  oneWeekFilterClickedCallback:any
  twoWeekFilterClickedCallback:any
  noFilterClickedCallback:any
}

const demoProps = {
    bg: 'var(--mantine-color-blue-light)',
    h: 50,
    mt: 'md',
  };


const TransactionsFilterView: React.VFC<Props> = ({todayFilterClickedCallback, oneWeekFilterClickedCallback, twoWeekFilterClickedCallback, noFilterClickedCallback}) => {

  useEffect(() => {

    if(!isInitialized){
      processSelection(-1);
      setInitialized(true);
    }
  
  })

  /*
    To set the default filter change the current button index and change the filter you want to true.  All other filters must be false
  */
  const [isTodayFilterActive, setTodayFilterActive] = useState(false);
  const [isTwoWeeksFilterActive, setTwoWeeksFilterActive] = useState(false);
  const [isOneWeekFilterActive, setOneWeekFilterActive] = useState(false);
  const [isNoFilterActive,setNoFilterActive] = useState(true);
  const [currentButtonIndex, setButtonIndex] = useState(-1);
  const [isInitialized,setInitialized] = useState(false);

  /*
    0: Today
    1: Week
    2: Two Weeks
  */
const processSelection = (buttonIndex: number) => {

  if(buttonIndex == 0){
    //check current index if 0, do nothing
    if(currentButtonIndex != 0){
      setButtonIndex(0);
      setTodayFilterActive(true)
      setOneWeekFilterActive(false);
      setTwoWeeksFilterActive(false);
      setNoFilterActive(false);
      todayFilterClickedCallback();
    }

    //remove filter as button with filter was clicked
    if(currentButtonIndex == 0){
      setButtonIndex(-1);
      setTodayFilterActive(false)
      setOneWeekFilterActive(false);
      setTwoWeeksFilterActive(false);
      setNoFilterActive(true);
      noFilterClickedCallback();
    }
    

  }else if(buttonIndex == 1){
    //check current index if 1, do nothing
    if(currentButtonIndex != 1){
      setButtonIndex(1);
      setTodayFilterActive(false)
      setOneWeekFilterActive(true);
      setTwoWeeksFilterActive(false);
      setNoFilterActive(false);
      oneWeekFilterClickedCallback();
    }

    
    //remove filter as button with filter was clicked
    if(currentButtonIndex == 1){
      setButtonIndex(-1);
      setTodayFilterActive(false)
      setOneWeekFilterActive(false);
      setTwoWeeksFilterActive(false);
      setNoFilterActive(true);
      noFilterClickedCallback();
    }
    

  }else if(buttonIndex == 2){
    //check current index if 2, do nothing
    if(currentButtonIndex != 2){
      setButtonIndex(2);
      setTodayFilterActive(false)
      setOneWeekFilterActive(false);
      setTwoWeeksFilterActive(true);
      setNoFilterActive(false);
      twoWeekFilterClickedCallback();
    }

    
    //remove filter as button with filter was clicked
    if(currentButtonIndex == 2){
      setButtonIndex(-1);
      setTodayFilterActive(false)
      setOneWeekFilterActive(false);
      setTwoWeeksFilterActive(false);
      setNoFilterActive(true);
      noFilterClickedCallback();
    }
    

  }else if(buttonIndex == -1){

    setButtonIndex(-1);
    setTodayFilterActive(false)
    setOneWeekFilterActive(false);
    setTwoWeeksFilterActive(false);
    setNoFilterActive(true);
    noFilterClickedCallback();

  }

}

const twoWeeksTransactionFilterClicked = () => {

  processSelection(2);

  if(isNoFilterActive){
    console.log("no filter is active")
  }
  
}

const oneWeekTransactionFilterClicked = () => {
  
  processSelection(1);

  if(isNoFilterActive){
    console.log("no filter is active")
  }
  
}

const todayTransactionFilterClicked = () => {

  processSelection(0);

  if(isNoFilterActive){
    console.log("no filter is active")
  }
  
}




    return (
      <>
        <Container fluid {...demoProps}>
            <Button.Group>
            <Button style={isTodayFilterActive ? {flex:1, color:'white', fontFamily:"Poppins",fontSize:14, fontWeight:700, accentColor:'white', borderRadius:'12px'} : {flex:1, backgroundColor:'black', fontFamily:"Poppins",fontSize:14, fontWeight:700, color:'white', accentColor:'white', borderRadius:'12px'}} onClick={todayTransactionFilterClicked}>Today</Button>
            &nbsp;
            &nbsp;
            &nbsp;
            <Button style={isOneWeekFilterActive ? {flex:1, color:'white', fontFamily:"Poppins",fontSize:14, fontWeight:700, accentColor:'white',borderRadius:'12px'} : {flex:1, backgroundColor:'black', fontFamily:"Poppins",fontSize:14, fontWeight:700, color:'white', accentColor:'white',borderRadius:'12px'}} onClick={oneWeekTransactionFilterClicked}>Yesterday</Button>
            &nbsp;
            &nbsp;
            &nbsp;
            <Button styles={{label:{focus:{color:'white'}, hover:{color:'white'}}}} style={isTwoWeeksFilterActive ? {flex:1, color:'white', fontFamily:"Poppins",fontSize:14, fontWeight:700, accentColor:'white',borderRadius:'12px'} : {flex:1, backgroundColor:'black', fontFamily:"Poppins",fontSize:14, fontWeight:700, color:'white', accentColor:'white',borderRadius:'12px'}} onClick={twoWeeksTransactionFilterClicked}>Week</Button> 
            </Button.Group>
        </Container>
      </>
    )
}

export default TransactionsFilterView;
