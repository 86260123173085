import {
  AnyAction,
  createSelector,
  createSlice,
  PayloadAction,
  ThunkAction,
} from "@reduxjs/toolkit"
import * as Sentry from "@sentry/browser"
import {
  changePasscode,
  finishSignIn,
  finishVerifyPhone,
  PhoneVerificationParams,
  signOut,
  startBankingOnboarding,
  StartBankingParams,
  startVerifyPhone,
  UpdatePhoneParams,
  updateUserAccount,
  UpdateUserAccountParams,
  UserChangePasscodeRequest,
  userDetail,
} from "src/api"
import { AsyncThunkLoadingStatus, ServerUser } from "src/types"
import {
  getGTM,
  getUserFullName,
  isApiAuthenticationError,
  parseError,
} from "src/utilities"
import { redirectAfterSessionExpired } from "src/utilities/routingUtils"
import { wasUserPreviouslyLoggedIn } from "../savedUserSession"
import { RootState } from "../store"


const gtm = getGTM()

// Using a wrapper around serverUser since user may not be logged in but root
// reducer state cannot be null/undefined
interface UserState {
  loggedInUser?: ServerUser
  loadingStatus: AsyncThunkLoadingStatus
}
const initialState: UserState = {
  loadingStatus: "idle",
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoadingStatus: (
      state,
      action: PayloadAction<AsyncThunkLoadingStatus>,
    ) => {
      state.loadingStatus = action.payload
    },
    setLoggedInUser: (state, action: PayloadAction<ServerUser | undefined>) => {
      const user = action.payload
      state.loggedInUser = user
      onAuthenticatedUserChange(user)
    },
  },
})

// Action creators are generated for each case reducer function
export const { setLoggedInUser, setLoadingStatus: setUserLoading } =
  userSlice.actions

export const thunkFinishLoginUser =
  (
    signInToken: string,
  ): ThunkAction<
    Promise<ServerUser | undefined>,
    RootState,
    unknown,
    AnyAction
  > =>
  async dispatch => {
    try {
      dispatch(setUserLoading("pending"))
      const loggedInUser = await finishSignIn(signInToken)
      onLogin(loggedInUser)
      dispatch(setLoggedInUser(loggedInUser))
      return loggedInUser
    } catch (err) {
      const { errorMessage } = parseError(err)
      console.error(`Failed to login: ${errorMessage}`)
      throw err
    } finally {
      dispatch(setUserLoading("idle"))
    }
  }

const onLogin = (loggedInUser: ServerUser) => {
  const { id, intercomHash } = loggedInUser

  gtm.setData({
    intercomHash,
    userId: id,
  })
  gtm.trackEvent("login")
}

const onAuthenticatedUserChange = (newUser: ServerUser | undefined) => {
  if (newUser) {
    Sentry.setUser({ id: newUser.id })
  } else {
    Sentry.configureScope(scope => scope.setUser(null))
  }
}

export const thunkLogoutUser =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async dispatch => {
    try {
      await signOut()
      dispatch(thunkClearUserData())
    } catch (err) {
      const { errorMessage } = parseError(err)
      console.error(`Failed to logout: ${errorMessage}`)
      throw err
    }
  }

export const thunkClearUserData =
  (): ThunkAction<void, RootState, unknown, AnyAction> => dispatch => {
    onLogout()
    dispatch(setLoggedInUser(undefined))
  }

const onLogout = () => {
  gtm.trackEvent("logout")
  gtm.setData({
    intercomHash: undefined,
    intercomId: undefined,
    userId: undefined,
  })
}

export const thunkRecoverUserSession =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async dispatch => {
    if (wasUserPreviouslyLoggedIn()) {
      await dispatch(thunkRefreshUserSession())
    }
  }

export const thunkRefreshUserSession =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async dispatch => {
    try {
      dispatch(setUserLoading("pending"))
      const user = await userDetail()
      dispatch(setLoggedInUser(user))
    } catch (err) {
      if (isApiAuthenticationError(err)) {
        dispatch(thunkClearUserData())
        redirectAfterSessionExpired()
        return
      }
      throw err
    } finally {
      dispatch(setUserLoading("idle"))
    }
  }

export const thunkUpdateUserAccount =
  (
    params: UpdateUserAccountParams,
  ): ThunkAction<
    Promise<ServerUser | undefined>,
    RootState,
    unknown,
    AnyAction
  > =>
  async dispatch => {
    try {
      dispatch(setUserLoading("pending"))
      const user = await updateUserAccount(params)
      dispatch(setLoggedInUser(user))
      return user
    } catch (err) {
      if (isApiAuthenticationError(err)) {
        dispatch(thunkClearUserData())
        redirectAfterSessionExpired()
        return
      }

      throw err
    } finally {
      dispatch(setUserLoading("idle"))
    }
  }

export const thunkStartBankingOnboarding =
  (
    params: StartBankingParams,
  ): ThunkAction<
    Promise<ServerUser | undefined>,
    RootState,
    unknown,
    AnyAction
  > =>
  async dispatch => {
    try {
      dispatch(setUserLoading("pending"))
      const user = await startBankingOnboarding(params)
      dispatch(setLoggedInUser(user))
      return user
    } catch (err) {
      if (isApiAuthenticationError(err)) {
        dispatch(thunkClearUserData())
        redirectAfterSessionExpired()
        return
      }

      throw err
    } finally {
      dispatch(setUserLoading("idle"))
    }
  }

export const thunkStartVerifyPhone =
  (
    params: UpdatePhoneParams,
  ): ThunkAction<
    Promise<ServerUser | undefined>,
    RootState,
    unknown,
    AnyAction
  > =>
  async dispatch => {
    try {
      dispatch(setUserLoading("pending"))
      const user = await startVerifyPhone(params)
      dispatch(setLoggedInUser(user))
      return user
    } catch (err) {
      if (isApiAuthenticationError(err)) {
        dispatch(thunkClearUserData())
        redirectAfterSessionExpired()
        return
      }
      throw err
    } finally {
      dispatch(setUserLoading("idle"))
    }
  }

export const thunkFinishVerifyPhone =
  (
    params: PhoneVerificationParams,
  ): ThunkAction<
    Promise<ServerUser | undefined>,
    RootState,
    unknown,
    AnyAction
  > =>
  async dispatch => {
    try {
      dispatch(setUserLoading("pending"))
      const user = await finishVerifyPhone(params)
      dispatch(setLoggedInUser(user))
      return user
    } catch (err) {
      if (isApiAuthenticationError(err)) {
        dispatch(thunkClearUserData())
        redirectAfterSessionExpired()
        return
      }
      throw err
    } finally {
      dispatch(setUserLoading("idle"))
    }
  }

export const thunkChangePasscode =
  (
    params: UserChangePasscodeRequest,
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async dispatch => {
    try {
      await changePasscode(params)
    } catch (err) {
      if (isApiAuthenticationError(err)) {
        dispatch(thunkClearUserData())
        redirectAfterSessionExpired()
        return
      }
      throw err
    }
  }

export const selectUserState = (rootState: RootState): UserState => {
  return rootState.user
}

export const selectIsLoggedIn: (_: RootState) => boolean = createSelector(
  selectUserState,
  (user: UserState) => {
    return user.loggedInUser !== undefined
  },
)

export const selectMaybeLoggedInUser: (_: RootState) => ServerUser | undefined =
  createSelector(selectUserState, user => {
    return user.loggedInUser
  })

export const selectLoggedInUser: (_: RootState) => ServerUser = createSelector(
  selectUserState,
  user => {
    if (user.loggedInUser) return user.loggedInUser

    throw new Error("No logged in user")
  },
)

export const selectLoggedInUserName: (_: RootState) => string = createSelector(
  selectLoggedInUser,
  ({ firstName, lastName }) => {
    return firstName !== undefined && lastName !== undefined
      ? getUserFullName(firstName, lastName)
      : ""
  },
)

export const selectHasBankingRestrictions: (
  _: RootState,
) => boolean | undefined = createSelector(
  selectUserState,
  (user: UserState) => {
    return user?.loggedInUser?.accountHolder?.hasCardRestrictions
  },
)

export default userSlice.reducer
