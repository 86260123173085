import { Text } from "@mantine/core"
import { useTimeout } from "@mantine/hooks"
import React, { useEffect, useState } from "react"
import { Feedback, Flex, Loader, Spacing } from "src/components"
import { useAppDispatch } from "src/hooks"
import { thunkClosePaymentCard } from "src/store"
import { NoOpFunctionType, PaymentCard } from "src/types"
import { parseError } from "src/utilities"

type Props = {
  card: PaymentCard
  onCompleted: NoOpFunctionType
  completionPauseSeconds: number
}

const ViewCancelCardResult: React.VFC<Props> = ({
  card,
  onCompleted,
  completionPauseSeconds,
}) => {
  const [cardCancelled, setCardCancelled] = useState(false)
  const [error, setError] = useState("")
  const dispatch = useAppDispatch()
  const { start: pauseAfterComplete } = useTimeout(
    onCompleted,
    completionPauseSeconds * 1000,
  )

  useEffect(() => {
    // Execute just once, when the component mounts
    async function cancelCard() {
      try {
        await dispatch(thunkClosePaymentCard(card.lifecycle))
        setCardCancelled(true)
      } catch (err) {
        const { errorMessage } = parseError(err)
        setError(errorMessage)
      }
    }

    cancelCard()
  }, [])

  useEffect(() => {
    if (cardCancelled) {
      pauseAfterComplete()
    }
  }, [cardCancelled, pauseAfterComplete])

  return (
    <Flex.Vertical hAlign="center" vAlign="center">
      {!cardCancelled && (
        <>
          <Loader />
          <Spacing.Horizontal />
          <Text>Cancelling your card</Text>
        </>
      )}
      {cardCancelled && (
        <>
          <Text>Your card is cancelled.</Text>
        </>
      )}
      {!!error && <Feedback message={error} />}
    </Flex.Vertical>
  )
}

export default ViewCancelCardResult
