import React, { useState } from "react"
import { EmailForm, Headings, MantineModal } from "src/components"
import { useAppDispatch } from "src/hooks/redux"
import { thunkUpdateUserAccount } from "src/store"

type Props = {
  isOpen: boolean
  closeModal: () => void
}

const UpdateEmail: React.VFC<Props> = ({ isOpen, closeModal }) => {
  const [submitted, setSubmitted] = useState(false)
  const dispatch = useAppDispatch()

  return (
    <MantineModal
      opened={isOpen}
      onClose={closeModal}
      title="Change your Email"
    >
      {submitted ? (
        <>
          <Headings.H2>Check Your Email</Headings.H2>
          <p>
            To confirm your email address, please click on the link in the email
            we just sent you. If you don&apos;t see it, please check your “Spam”
            or “Promotions” folder.
          </p>
        </>
      ) : (
        <>
          <EmailForm
            shouldDisableSubmit={valuesChanged => {
              return !valuesChanged
            }}
            handleSubmit={async ({ email }) => {
              await dispatch(
                thunkUpdateUserAccount({
                  email,
                }),
              )
              setSubmitted(true)
            }}
          />
        </>
      )}
    </MantineModal>
  )
}

export default UpdateEmail
