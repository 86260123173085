import { Group, Select, Space } from "@mantine/core"
import React, { useMemo, useState } from "react"

import { IconFileX as FileIcon } from "@tabler/icons"
import { DocumentAttach, Flex } from "src/components"
import { ApplicationDocumentCategory, ApplicationDocumentType } from "src/types"
import { toProperCaseWithSkipList } from "src/utilities/stringUtils"
import DocUploadToolTip from "./DocUploadToolTip"

const FILE_SIZE_LIMIT = 10 * 1024 ** 2 // 10MB
type Props = {
  docCategory: ApplicationDocumentCategory
  docTypes: ApplicationDocumentType[]
  onDocumentSelected: (
    _category: ApplicationDocumentCategory,
    _file: File,
    _type: ApplicationDocumentType,
  ) => void
  onDocumentRemoved: (_category: ApplicationDocumentCategory) => void
}
const DocUploadInput: React.VFC<Props> = ({
  docCategory,
  docTypes,
  onDocumentSelected,
  onDocumentRemoved,
}) => {
  const [fileToUpload, setFileToUpload] = useState<File>()
  const docTypeOptions = useMemo(() => {
    const options = docTypes.map(type => {
      return {
        value: type,
        label: toProperCaseWithSkipList(type.replace(/_/g, " "), [
          "us",
          "id",
          "irs",
          "cp",
        ]),
      }
    })
    options.splice(0, 0, {
      label: "Pick a document",
      value: "NULL_DOC_TYPE",
    })
    return options
  }, [docTypes])
  const [selectedDocumentType, setSelectedDocumentType] =
    useState<ApplicationDocumentType>("NULL_DOC_TYPE")

  const handleFileAttached = (file: File) => {
    setFileToUpload(file)
    onDocumentSelected(docCategory, file, selectedDocumentType)
  }

  const removeFile = () => {
    if (fileToUpload) {
      setFileToUpload(undefined)
      onDocumentRemoved(docCategory)
    }
  }

  return (
    <Flex.Vertical>
      <DocUploadToolTip
        docType={selectedDocumentType}
        isFileAttached={!!fileToUpload}
      />
      <Select
        data={docTypeOptions}
        defaultValue={undefined}
        value={selectedDocumentType}
        onChange={value => {
          setSelectedDocumentType(value as ApplicationDocumentType)
        }}
        styles={theme => ({
          input: {
            background: theme.colors.gray[1],
            fontWeight: "bold",
            textAlign: "center",
          },
        })}
      />
      {selectedDocumentType !== "NULL_DOC_TYPE" && !fileToUpload && (
        <DocumentAttach
          fileSizeLimit={FILE_SIZE_LIMIT}
          handleFileAttached={handleFileAttached}
        />
      )}
      <Space h="md" />
      {!!fileToUpload && (
        <AttachedFile fileName={fileToUpload.name} onClick={removeFile} />
      )}
    </Flex.Vertical>
  )
}

type AttachedFileProps = {
  fileName: string
  onClick: () => void
}
const AttachedFile: React.VFC<AttachedFileProps> = ({ fileName, onClick }) => {
  return (
    <Group onClick={onClick} style={{ cursor: "pointer" }}>
      <FileIcon />
      {fileName}
    </Group>
  )
}

export default DocUploadInput
