import { Container as MantineContainer, Sx } from "@mantine/core"
import React from "react"

export const FullPage: React.FC = ({ children }) => {
  return (
    <Container
      style={{
        maxWidth: "32rem",
      }}
    >
      {children}
    </Container>
  )
}

export const FullPage2: React.FC = ({ children }) => {
  return (
    <Container
      style={{
        maxWidth: "38rem",
      }}
    >
      {children}
    </Container>
  )
}

export const FullPage3: React.FC = ({ children }) => {
  return (
    <Container
      style={{
        maxWidth: "50rem",
      }}
    >
      {children}
    </Container>
  )
}

export const FullPage4: React.FC = ({ children }) => {
  return (
    <Container
      style={{
        maxWidth: "24rem",
      }}
    >
      {children}
    </Container>
  )
}

type ContainerProps = {
  style: Sx
}
const defaultStyle: Sx = {
  width: "100%",
  margin: "auto auto",
}
const Container: React.FC<ContainerProps> = ({ style, children }) => {
  return (
    <MantineContainer sx={{ ...defaultStyle, ...style }}>
      {children}
    </MantineContainer>
  )
}
