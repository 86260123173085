import { Group } from "@mantine/core"
import React from "react"
import { Card, Headings, MantineDefaultButton as Button } from "src/components"

type CardsClosedNoticeprops = {
  onConfirmation: () => void
}
const CardsClosedNotice: React.VFC<CardsClosedNoticeprops> = ({
  onConfirmation,
}) => {
  return (
    <Card $page $fullPage2>
      <Headings.H2>Card Closed</Headings.H2>
      <p>You closed your Stretch Mastercard® debit card.</p>
      <Group>
        <Button label="Order a new card" onClick={onConfirmation} />
      </Group>
    </Card>
  )
}

export default CardsClosedNotice
