export function minWidthMediaQuery(widthInPx: number): string {
  return `(min-width: ${widthInPx}px)`
}

export function maxWidthMediaQuery(widthInPx: number): string {
  return `(max-width: ${widthInPx}px)`
}

export function minAndMaxWidthMediaQuery(
  minWidthInPx: number,
  maxWidthInPx: number,
): string {
  return `${minWidthMediaQuery(minWidthInPx)} and ${maxWidthMediaQuery(
    maxWidthInPx,
  )}`
}
