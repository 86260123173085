import React from "react"
import { notifyCardPinChanged } from "src/api"
import { CardPinContainer } from "src/components"
import { PaymentCard } from "src/types"
import ChangePin from "./ChangePin"

type Props = {
  card: PaymentCard
}

const ChangePinContainer: React.VFC<Props> = ({ card }) => {
  const onPinSubmitted = async () => {
    await notifyCardPinChanged({
      firstTime: false,
      lifecycle: card.lifecycle,
    })
  }

  return (
    <CardPinContainer
      card={card}
      renderPinComponent={props => (
        <ChangePin {...props} onSuccessfulSubmit={onPinSubmitted} />
      )}
    />
  )
}

export default ChangePinContainer
