import { isEqual } from "lodash"

export function didValuesChange<Values>(
  initialValues: Values,
  finalValues: Values,
): boolean {
  return !isEqual(initialValues, finalValues)
}

/**
 * Regexs outlined in Highnote docs
 * https://highnote.com/docs/reference/input_object/AddressInput
 */

/**
 * Matches the following:
 *   - All upper & lowercase letters from A-Z
 *   - comma (,)
 *   - apostrophe / single quote (')
 *   - period (.)
 *   - space ( )
 *   - or hyphen (-)
 */
export const NAME_REGEX = /^[a-zA-Z',. -]*$/
export const NAME_REGEX_ERROR =
  "Only upper and lowercase letters from a through z are allowed"

/**
 * Matches the following:
 *   - 2-4 groups of non-whitespace characters
 */
export const ADDRESS_1_REGEX = /^\s*\S+(?:\s+\S+){1,3}$/
export const ADDRESS_1_REGEX_ERROR = "Please enter a valid street address"

/**
 * Matches any combination of PO Box, e.g. (PO Box 1, P.O. Box , po box) when
 * the text comes after a valid Address 1.  This Regex must be used AFTER
 * ADDRESS_1_REGEX above, e.g.
 *
 * yup
 * .matches(ADDRESS_1_REGEX, ADDRESS_1_REGEX_ERROR)
 * .matches(IS_NOT_PO_BOX_REGEX, IS_NOT_PO_BOX_REGEX_ERROR)
 */
export const IS_NOT_PO_BOX_REGEX =
  /^(?:((\S([^pPOo])+)|(?:[0-9]+)))\s(?:[0-9A-Za-z.]|[^\S\r\n])+$/
export const IS_NOT_PO_BOX_REGEX_ERROR =
  "Please list additional address information on the next line"

/**
 * This is a looser address validation than ADDRESS_1_REGEX that allows for
 * additional special characters that are commonly associated with the second
 * line of an address, e.g. when a Suite or Unit # is provided
 */
export const ADDRESS_2_REGEX =
  /^[a-zA-Z\d',. \-#]+(([',. \-#][a-zA-Z ])?[a-zA-Z.]*)*$/
export const ADDRESS_2_REGEX_ERROR =
  "Please enter a valid secondary street address"

/**
 * Matches the following:
 *   - Must begin with a single quote or any letter
 *   - Subsequent characters may included:
 *      - comma (,)
 *      - apostrophe / single quote (')
 *      - any letter (a-zA-Z)
 *      - or character in this set: ()*+-/0123456789: .
 */
export const LOCALITY_REGEX = /^['a-zA-Z]+(?:[ a-zA-Z,'-:])*$/
export const LOCALITY_REGEX_ERROR = "Please enter a valid city"

/**
 * Matches the following:
 *   - Any 2-character combination of upper or lowercase letters
 */
export const STATE_REGEX = /[a-zA-Z]{2}/
export const STATE_REGEX_ERROR = "Please enter a valid state"

/**
 * Matches the following:
 *   - 5 Digit Zip Codes (12345)
 *   - 9 Digit Zip Codes (12345-6789)
 */
export const ZIP_CODE_REGEX = /^[0-9]{5}(-[0-9]{4})?$/
export const ZIP_CODE_REGEX_ERROR = "Please enter a valid zip code"
