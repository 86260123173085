import { Center } from "@mantine/core"
import { DateTime } from "luxon"
import React, { useEffect, useState } from "react"
import { Loader } from "src/components"
import { isHighnoteProdEnv } from "src/config"
import { useAppDispatch, useAppSelector } from "src/hooks/redux"
import {
  selectLoggedInUserName,
  selectPermanentPaymentCardViewDetailsToken,
  selectTemporaryPaymentCardViewDetailsToken,
  thunkGetPaymentCardToken,
} from "src/store"
import { PaymentCard } from "src/types"
import CardImage from "./CardImage"

type Props = {
  card: PaymentCard
}

const DEFAULT_CARD_TOKEN_LIFESPAN_MINUTES = 15
const CardImageContainer: React.VFC<Props> = ({
  card: { id: cardId, lifecycle },
}) => {
  const dispatch = useAppDispatch()
  const selectorFn =
    lifecycle === "TEMPORARY"
      ? selectTemporaryPaymentCardViewDetailsToken
      : selectPermanentPaymentCardViewDetailsToken
  const viewCardDetailsToken = useAppSelector(selectorFn)
  const loggedInUserName = useAppSelector(selectLoggedInUserName)
  const [cardDetailsTokenValid, setCardDetailsTokenValid] = useState(false)

  useEffect(() => {
    if (!cardDetailsTokenValid) {
      dispatch(thunkGetPaymentCardToken(lifecycle, "READ_RESTRICTED_DETAILS"))
      setCardDetailsTokenValid(true)
    }

    const tokenExpirationIntervalMinutes = viewCardDetailsToken
      ? DateTime.fromISO(viewCardDetailsToken.expiresAt).diffNow().as("minutes")
      : DEFAULT_CARD_TOKEN_LIFESPAN_MINUTES

    const timer = setTimeout(() => {
      setCardDetailsTokenValid(false)
    }, (tokenExpirationIntervalMinutes - 1) * 60_000)

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [cardDetailsTokenValid])

  return (
    <>
      {!viewCardDetailsToken && (
        <Center>
          <Loader />
        </Center>
      )}
      {!!viewCardDetailsToken && loggedInUserName && (
        <CardImage
          cardHolderName={loggedInUserName}
          clientToken={viewCardDetailsToken.value}
          paymentCardId={cardId}
          isHighnoteProdEnv={isHighnoteProdEnv}
        />
      )}
    </>
  )
}

export default CardImageContainer
