import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router-dom"
import { confirmEmail } from "src/api"

import { Pages, Feedback, Headings, Panels } from "src/components"
import { useAppDispatch } from "src/hooks"
import { thunkRefreshUserSession } from "src/store"
import { VerificationCodePagePathParams } from "src/types"
import { DASHBOARD_PAGE_ROUTE, redirectTo, delay } from "src/utilities"
import { parseError } from "src/utilities/errorUtils"

const EmailChangeVerification: React.VFC = () => {
  const { code } = useParams<VerificationCodePagePathParams>()
  const [verificationError, setVerificationError] = useState("")
  const [success, setSuccess] = useState(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (code !== "new") {
      sendCode()
    }
  }, [])

  const sendCode = async () => {
    try {
      await confirmEmail(code)
      setSuccess(true)
      // We want a delay between the email confirmation view and the redirect so
      // the user knows their email has been successfully confirmed
      await delay()
      await dispatch(thunkRefreshUserSession())
      redirectTo(DASHBOARD_PAGE_ROUTE)
    } catch (error) {
      const { errorMessage } = parseError(error)

      setVerificationError(errorMessage)
    }
  }

  return (
    <>
      <Helmet>
        <title>Email Verification – Stretch</title>
      </Helmet>
      <Pages.FullPage>
        <Panels.Default>
          {!success ? (
            <Headings.H2>Checking verification code...</Headings.H2>
          ) : (
            <>
              <Headings.H2>Thanks!</Headings.H2>
              <p>
                Your email has been verified. You will be re-directed in a
                second.
              </p>
            </>
          )}
          {verificationError ? <Feedback message={verificationError} /> : null}
        </Panels.Default>
      </Pages.FullPage>
    </>
  )
}

export default EmailChangeVerification
