import { Form, FormikHelpers } from "formik"
import React, { useState } from "react"
import {
  Flex,
  FlexItem,
  MantineSubmitButton as Button,
  MaskedNumericInput,
  Messages,
  Spacing,
  StretchForm,
} from "src/components"
import { UiFeedback } from "src/types"
import { stripNonAlphaNumericCharacters } from "src/utilities"
import { parseError } from "src/utilities/errorUtils"
import * as yup from "yup"

const validationSchema = yup.object({
  cardLast4: yup
    .string()
    .transform(value => stripNonAlphaNumericCharacters(value))
    .min(4, "Input must be four digits long.")
    .max(4, "Input must be four digits long.")
    .required("Please enter the last four digits of the card number."),
})
type FormData = yup.InferType<typeof validationSchema>

type Props = {
  submitButtonLabel?: string
  submitSuccessMessage?: string
  handleSubmit: (_value: string) => Promise<void>
  onSuccessfulSubmit?: () => void
}

const ExpirationDateForm: React.VFC<Props> = ({
  submitButtonLabel = "Submit",
  submitSuccessMessage,
  handleSubmit,
  onSuccessfulSubmit,
}) => {
  const fieldName = "cardLast4"
  const initialValues = { cardLast4: "" }
  const [submitResult, setSubmitResult] = useState<UiFeedback>()

  const onSubmitClicked = async (
    { cardLast4 }: FormData,
    formikHelpers: FormikHelpers<FormData>,
  ) => {
    try {
      await handleSubmit(cardLast4)
      if (submitSuccessMessage) {
        setSubmitResult({
          message: submitSuccessMessage,
          messageType: "positive",
        })
      }
      onSuccessfulSubmit?.()
    } catch (err) {
      const { errorMessage } = parseError(err)
      setSubmitResult({ message: errorMessage, messageType: "negative" })
      formikHelpers.resetForm()
    }
  }

  return (
    <Flex.Vertical hAlign="center">
      <StretchForm
        initialValues={initialValues}
        onSubmit={onSubmitClicked}
        validationSchema={validationSchema}
      >
        {({
          errors,
          values,
          handleChange,
          handleBlur,
          isValid,
          isSubmitting,
          touched,
        }) => (
          <Form style={{ width: "100%" }}>
            {!!submitResult && (
              <Messages.Ephemeral
                message={submitResult.message}
                messageType={submitResult.messageType}
                onTimeout={() => {
                  setSubmitResult(undefined)
                }}
              />
            )}
            <Spacing.Horizontal />
            <MaskedNumericInput
              name={fieldName}
              mask="9999"
              value={values.cardLast4}
              error={touched.cardLast4 === true ? errors.cardLast4 : undefined}
              onChange={handleChange}
              onBlur={handleBlur}
              autoFocus
              autoComplete="off"
              centered
            />
            <Spacing.Horizontal />
            <Flex.Horizontal hAlign="center" noWrap>
              <FlexItem
                grow="equally"
                component={Button}
                label={submitButtonLabel}
                disabled={!isValid || isSubmitting}
                isLoading={isSubmitting}
              />
            </Flex.Horizontal>
          </Form>
        )}
      </StretchForm>
    </Flex.Vertical>
  )
}

export default ExpirationDateForm
