import React, { useState } from "react"
import {
  Feedback,
  Flex,
  Headings,
  MantineDefaultButton as Button,
  Panels,
} from "src/components"
import { useAppDispatch } from "src/hooks/redux"
import { thunkIssuePermanentCard, thunkRefreshUserSession } from "src/store"
import { parseError } from "src/utilities/errorUtils"
import { DASHBOARD_PAGE_ROUTE, redirectTo } from "src/utilities/routingUtils"

const OrderPhysicalCard: React.VFC = () => {
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState("")
  const dispatch = useAppDispatch()

  const handleSubmit = async () => {
    setSubmitting(true)
    try {
      await dispatch(thunkIssuePermanentCard())
      await dispatch(thunkRefreshUserSession())
      redirectTo(DASHBOARD_PAGE_ROUTE)
    } catch (err) {
      const { errorMessage } = parseError(err)
      setError(errorMessage)
    } finally {
      setSubmitting(false)
    }
  }
  return (
    <Panels.Default>
      <Flex.Vertical hAlign="center">
        <Headings.H3>You&apos;ve been approved!</Headings.H3>
        <Button
          label="Order your physical card"
          disabled={submitting}
          isLoading={submitting}
          onClick={handleSubmit}
        />
        {!!error && <Feedback message={error} />}
      </Flex.Vertical>
    </Panels.Default>
  )
}

export default OrderPhysicalCard
