import axios, { AxiosRequestConfig } from "axios"
import { isDevelopment, LOB_API_KEY } from "src/config"
import { Address } from "src/types"

export const VERIFY_ADDRESS = "https://api.lob.com/v1/us_verifications"
export const COMPLETE_ADDRESS = "https://api.lob.com/v1/us_autocompletions"

// Request Configuration

export function requestConfiguration({
  auth,
  headers,
  params,
  timeout,
}: Partial<AxiosRequestConfig> = {}): AxiosRequestConfig {
  return {
    headers: {
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
      ...headers,
    },
    auth,
    params,
    timeout: timeout || 10000,
    withCredentials: true,
  }
}

export interface AddressRequest {
  addressLine1: string
  addressLine2?: string
  city: string
  state: string
  zip: string
}

export interface VerifiedAddress {
  id: string
  recipient: string | null
  primary_line: string
  secondary_line: string
  urbanization: string
  last_line: string
  deliverability:
    | "deliverable"
    | "deliverable_unnecessary_unit"
    | "deliverable_incorrect_unit"
    | "deliverable_missing_unit"
    | "undeliverable"
  components: {
    primary_number: string
    street_predirection: "N" | "S" | "E" | "W" | "NE" | "SW" | "SE" | "NW" | ""
    street_name: string
    street_suffix: string
    street_postdirection: "N" | "S" | "E" | "W" | "NE" | "SW" | "SE" | "NW" | ""
    secondary_designator: string
    secondary_number: string
    pmb_designator: string
    pmb_number: string
    extra_secondary_designator: string
    extra_secondary_number: string
    city: string
    state: string
    zip_code: string
    zip_code_plus_4: string
    zip_code_type: "standard" | "po_box" | "unique" | "military" | ""
    delivery_point_barcode: string
    address_type: "residential" | "commercial" | ""
    record_type:
      | "street"
      | "highrise"
      | "firm"
      | "po_box"
      | "rural_route"
      | "general_delivery"
      | ""
    default_building_address: boolean
    county: string
    county_fips: string
    carrier_route: string
    carrier_route_type:
      | "city_delivery"
      | "rural_route"
      | "highway_contract"
      | "po_box"
      | "general_delivery"
    latitude: number | null
    longitude: number | null
  }
  deliverability_analysis: {
    dpv_confirmation: "Y" | "S" | "D" | "N" | ""
    dpv_cmra: "Y" | "N" | ""
    dpv_vacant: "Y" | "N" | ""
    dpv_active: "Y" | "N" | ""
    dpv_footnotes: [
      | "AA"
      | "A1"
      | "BB"
      | "CC"
      | "N1"
      | "F1"
      | "G1"
      | "U1"
      | "M1"
      | "M3"
      | "P1"
      | "P3"
      | "R1"
      | "R7"
      | "RR",
    ]
    ews_match: boolean
    lacs_indicator: "Y" | "N" | ""
    lacs_return_code: string
    suite_return_code: "A" | "00" | ""
  }
  lob_confidence_score: {
    score: number | null
    level: "high" | "medium" | "low" | ""
  }
  object: "us_verification"
}

// Verify Address

export function verifyAddress(
  address: Omit<Address, "country">,
): Promise<VerifiedAddress> {
  return axios
    .post(
      VERIFY_ADDRESS,
      {
        primary_line: address.firstLine,
        secondary_line: address.secondLine,
        city: address.locality,
        state: address.region,
        zip_code: address.postalCode,
      },
      requestConfiguration({
        auth: {
          username: LOB_API_KEY,
          password: "",
        },
      }),
    )
    .then(response => response.data)
}

export interface AddressSuggestion {
  city: string
  primary_line: string
  state: string
  zip_code: string
}

interface AddressSuggestionResponse {
  id: string
  suggestions: AddressSuggestion[]
  object: "us_autocompletion"
}

// Auto Complete Address

export function autoCompleteAddress(
  query: Address,
): Promise<AddressSuggestionResponse> {
  if (isDevelopment) {
    return Promise.resolve<AddressSuggestionResponse>({
      id: "id",
      suggestions: [
        {
          primary_line: "123 Main St",
          city: "San Jose",
          state: "CA",
          zip_code: "99021",
        },
      ],
      object: "us_autocompletion",
    })
  }
  return axios
    .post(
      COMPLETE_ADDRESS,
      {
        // Only submit the first line so we get results across all cities/regions
        address_prefix: query.firstLine,
      },
      requestConfiguration({
        auth: {
          username: LOB_API_KEY,
          password: "",
        },
      }),
    )
    .then(response => response.data)
}
