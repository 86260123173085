import { Group, Select, Space, Text } from "@mantine/core"
import React, { useEffect, useMemo, useState } from "react"

import { IconFileX as FileIcon } from "@tabler/icons"
import { DocumentAttach, Flex, Spacing } from "src/components"
import { ApplicationDocumentType, KycMismatch } from "src/types"
import { toProperCaseWithSkipList } from "src/utilities/stringUtils"
import DocUploadToolTip from "./DocUploadToolTip"

const FILE_SIZE_LIMIT = 10 * 1024 ** 2 // 10MB
export type Props = {
  mismatchType: KycMismatch
  mismatchedPersonalInfo: string
  docTypes: ApplicationDocumentType[]
  onDocumentSelected: (
    _file: File,
    _doctype: ApplicationDocumentType,
    _mismatchType: KycMismatch,
  ) => void
  onDocumentRemoved: (_mismatchType: KycMismatch) => void
}
const DocUploadInput: React.VFC<Props> = ({
  mismatchType,
  mismatchedPersonalInfo,
  docTypes,
  onDocumentSelected,
  onDocumentRemoved,
}) => {
  const [fileToUpload, setFileToUpload] = useState<File>()
  const docTypeOptions = useMemo(() => {
    const options = docTypes.map(type => {
      // Turn UPPER_CASED enums into spaced out words that are properly capitalized
      // Skip re-capitalization of acronyms
      return {
        value: type,
        label: toProperCaseWithSkipList(type.replace(/_/g, " "), [
          "us",
          "id",
          "irs",
          "cp",
        ]),
      }
    })

    // Insert a default value, null into the options list.
    options.splice(0, 0, {
      label: "Pick a document",
      value: "NULL_DOC_TYPE",
    })
    return options
  }, [docTypes])
  const [selectedDocumentType, setSelectedDocumentType] =
    useState<ApplicationDocumentType>("NULL_DOC_TYPE")

  useEffect(() => {
    setSelectedDocumentType("NULL_DOC_TYPE")
  }, [docTypeOptions])

  const handleFileAttached = (file: File) => {
    setFileToUpload(file)
    onDocumentSelected(file, selectedDocumentType, mismatchType)
  }

  const removeFile = () => {
    if (fileToUpload) {
      setFileToUpload(undefined)
      onDocumentRemoved(mismatchType)
    }
  }

  return (
    <Flex.Vertical>
      <Text>
        We need an extra document to help us verify your{" "}
        <Text span weight="bold" color="stretch-red">
          {mismatchTypeToTextMap[mismatchType]}
        </Text>{" "}
        as {mismatchType === "SSN" ? "ending in " : null}
        <Text span weight="bold" color="stretch-red">
          {mismatchedPersonalInfo}
        </Text>
      </Text>
      <Spacing.Horizontal />
      <DocUploadToolTip
        docType={selectedDocumentType}
        isFileAttached={!!fileToUpload}
      />
      <Select
        data={docTypeOptions}
        defaultValue={undefined}
        value={selectedDocumentType}
        onChange={value => {
          if (selectedDocumentType !== value) {
            removeFile()
            setSelectedDocumentType(value as ApplicationDocumentType)
          }
        }}
        styles={theme => ({
          input: {
            background: theme.colors.gray[1],
            fontWeight: "bold",
            textAlign: "center",
          },
        })}
      />
      {selectedDocumentType !== "NULL_DOC_TYPE" && !fileToUpload ? (
        <DocumentAttach
          fileSizeLimit={FILE_SIZE_LIMIT}
          handleFileAttached={handleFileAttached}
        />
      ) : null}
      <Space h="md" />
      {fileToUpload !== undefined ? (
        <AttachedFile fileName={fileToUpload.name} onClick={removeFile} />
      ) : null}
    </Flex.Vertical>
  )
}

type AttachedFileProps = {
  fileName: string
  onClick: () => void
}
const AttachedFile: React.VFC<AttachedFileProps> = ({ fileName, onClick }) => {
  return (
    <Group onClick={onClick} style={{ cursor: "pointer" }}>
      <FileIcon />
      {fileName}
    </Group>
  )
}

const mismatchTypeToTextMap: Record<KycMismatch, string> = {
  ADDRESS: "physical address",
  DOB: "date of birth",
  IDENTITY_THEFT: "identity",
  NAME: "legal name",
  PHONE: "phone number",
  SSN: "social security number",
  SYNTHETIC_FRAUD: "identity",
}

export default DocUploadInput
