import { Duration } from "luxon"
import React, { createContext, useContext, useEffect, useState } from "react"

import { userDetail } from "src/api"
import { useAppSelector } from "src/hooks"
import { selectIsLoggedIn } from "src/store"
import { SetTimeoutType } from "src/types"
import { autoLogout } from "src/utilities/routingUtils"
import {
  STRETCH_SESSION_DURATION_MINUTES,
  STRETCH_SESSION_WARNING_DURATION_MINUTES,
} from "src/config"

type StretchAuthContextState = {
  restartSessionTimer: () => void
  cancelSessionTimer: () => void
  showSessionMessage: boolean
}

const StretchAuthContext = createContext<StretchAuthContextState | undefined>(
  undefined,
)

let sessionLogoutTimer: SetTimeoutType | undefined
let sessionWarningMessageTimer: SetTimeoutType | undefined

function getSessionDurationTillWarning(): Duration {
  return Duration.fromObject({ minutes: STRETCH_SESSION_DURATION_MINUTES })
}

function getSessionDurationTillLogout(): Duration {
  return Duration.fromObject({
    seconds:
      STRETCH_SESSION_DURATION_MINUTES * 60 +
      STRETCH_SESSION_WARNING_DURATION_MINUTES * 60 +
      5, // Add a little buffer to let the user know they will be logged out
  })
}

export const StretchAuthProvider: React.FC = ({ children }) => {
  const [showSessionMessage, setShowSessionMessage] = useState(false)

  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  useEffect(() => {
    if (isLoggedIn) {
      cancelSessionTimer()
      startSessionTimer()
    }
  }, [isLoggedIn])

  const restartSessionTimer = () => {
    cancelSessionTimer()
    // make a Stretch API call to stay logged in
    userDetail()
      .then(() => {
        startSessionTimer()
      })
      .catch(() => {
        autoLogout()
      })
  }

  const startSessionTimer = () => {
    if (sessionWarningMessageTimer === undefined) {
      sessionWarningMessageTimer = setTimeout(() => {
        setShowSessionMessage(true)
      }, getSessionDurationTillWarning().toMillis())
    }

    if (sessionLogoutTimer === undefined) {
      sessionLogoutTimer = setTimeout(() => {
        cancelSessionTimer()
        autoLogout()
      }, getSessionDurationTillLogout().toMillis())
    }
  }

  const cancelSessionTimer = () => {
    // hide modal
    setShowSessionMessage(false)
    // clear timers
    if (sessionWarningMessageTimer !== undefined) {
      clearTimeout(sessionWarningMessageTimer)
      sessionWarningMessageTimer = undefined
    }
    if (sessionLogoutTimer !== undefined) {
      clearTimeout(sessionLogoutTimer)
      sessionLogoutTimer = undefined
    }
  }

  return (
    <StretchAuthContext.Provider
      value={{
        showSessionMessage,
        restartSessionTimer,
        cancelSessionTimer,
      }}
    >
      {children}
    </StretchAuthContext.Provider>
  )
}

export const useStretchAuthContext = (): StretchAuthContextState => {
  const context = useContext(StretchAuthContext)

  if (context === undefined) {
    throw new Error("StretchAuthContext must be used with a Provider")
  }

  return context
}
