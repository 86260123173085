import React, { useState } from "react"
import { Card, Headings, MantineDefaultButton as Button } from "src/components"
import { PaymentCard } from "src/types"
import { DASHBOARD_PAGE_ROUTE, redirectTo } from "src/utilities"
import { CancelCardSequence } from "./sequences"

type CancelCardProps = {
  card: PaymentCard
}
const CancelCard: React.VFC<CancelCardProps> = ({ card }) => {
  const [startCancelCard, setStartCancelCard] = useState(false)
  const cardCancelCompleted = () => {
    redirectTo(DASHBOARD_PAGE_ROUTE)
  }

  return (
    <Card>
      <Headings.H3>Cancel Card</Headings.H3>
      <p>
        If you suspect that your card is permanently lost or that your card
        number may be compromised, you may cancel your card.
      </p>
      <Button
        label="Cancel your Card"
        onClick={() => {
          setStartCancelCard(true)
        }}
      />
      <CancelCardSequence
        card={card}
        showSequence={startCancelCard}
        cancelSequence={() => {
          setStartCancelCard(false)
        }}
        completeSequence={cardCancelCompleted}
      />
    </Card>
  )
}

export default CancelCard
