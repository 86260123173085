import TagManager from "react-gtm-module"
import { GTM_AUTH, GTM_CONTAINER_ID, GTM_PREVIEW } from "src/config"

let instance: GoogleTagManager

interface Args {
  id?: string
  auth?: string
  preview?: string
}

type DataLayerObject = Record<string, unknown>

declare global {
  interface Window {
    dataLayer: DataLayerObject[]
  }
}

class GoogleTagManager {
  private auth?: string
  private id?: string
  private initialized = false
  private preview?: string

  constructor(options: Args) {
    this.auth = options.auth
    this.id = options.id
    this.preview = options.preview
  }

  initialize(): void {
    if (this.initialized || !this.id) {
      return
    }

    TagManager.initialize({
      auth: this.auth,
      gtmId: this.id,
      preview: this.preview,
    })

    this.initialized = true
  }

  setData(data: DataLayerObject): void {
    if (!this.initialized) {
      return
    }

    this.window.dataLayer.push(data)
  }

  trackEvent(event: string, data?: DataLayerObject): void {
    if (!this.initialized) {
      return
    }

    this.window.dataLayer.push({ event: event, ...data })
  }

  trackPageview(): void {
    if (!this.initialized) {
      return
    }

    this.window.dataLayer.push({
      event: "pageview",
    })
  }

  private get window(): Window {
    return window
  }
}

export function getGTM(): GoogleTagManager {
  if (!instance) {
    instance = new GoogleTagManager({
      auth: GTM_AUTH,
      id: GTM_CONTAINER_ID,
      preview: GTM_PREVIEW,
    })

    instance.initialize()
  }

  return instance
}
