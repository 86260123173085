export type RedirectionMessageType = "positive" | "negative"
export type UiFeedbackType = "positive" | "negative" | "informational"
export type UiFeedback = {
  message: string
  messageType: UiFeedbackType
}
export type LogoutPageLocationState = {
  message?: string
}
export type LoginVerifyPageLocationState = {
  dateOfBirth: string
  phoneNumber: string
  verificationId1: string
}
export type LoginPageRoutingState = {
  routingMessage?: string
  routingMessageType?: RedirectionMessageType
}
export type UnlockPageRoutingState = {
  routingMessage: string
  routingMessageType: RedirectionMessageType
  email: string
}
export type VerificationCodePagePathParams = {
  code: string
}
export type SetIntervalType = ReturnType<typeof setInterval>
export type SetTimeoutType = ReturnType<typeof setTimeout>

export type Address = {
  firstLine: string
  secondLine?: string
  locality: string
  region: string
  postalCode: string
  country: string
}

export type AsyncThunkLoadingStatus = "idle" | "pending" | "started" | "complete"
export function isStateLoading(
  loadingStatus: AsyncThunkLoadingStatus,
): boolean {
  return loadingStatus === "pending"
}

export function stateStatus(
  loadingStatus: AsyncThunkLoadingStatus,
): string {
  return loadingStatus;
}

export type NoOpFunctionType = () => unknown
export type AsyncNoOpFunctionType = () => Promise<unknown>
