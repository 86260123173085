import {
  AnyAction,
  createSelector,
  createSlice,
  PayloadAction,
  ThunkAction,
} from "@reduxjs/toolkit"
import {
  activatePaymentCard,
  ActivatePaymentCardParams,
  closePaymentCard,
  freezePaymentCard,
  getPaymentCard,
  issuePermanentPaymentCard,
  issueTemporaryCard,
  unfreezePaymentCard,
} from "src/api"
import { RootState } from "src/store/store"
import {
  AsyncThunkLoadingStatus,
  PaymentCard,
  PaymentCardLifecycle,
  PaymentCardStatus,
} from "src/types"
import { isApiAuthenticationError } from "src/utilities/errorUtils"
import { redirectAfterSessionExpired } from "src/utilities/routingUtils"
import { thunkClearUserData } from "../user/userReducer"
import {
  setPhysicalCardOrder,
  selectIsPhysicalCardShipped,
  selectPhysicalCardOrder,
} from "./physicalCardOrder"

type State = {
  temporaryPaymentCard?: PaymentCard
  temporaryCardLoadingStatus: AsyncThunkLoadingStatus
  permanentPaymentCard?: PaymentCard
  permanentCardLoadingStatus: AsyncThunkLoadingStatus
}
const initialState: State = {
  permanentCardLoadingStatus: "idle",
  temporaryCardLoadingStatus: "idle",
}

const slice = createSlice({
  name: "paymentCards",
  initialState,
  reducers: {
    setPaymentCard: (state, action: PayloadAction<PaymentCard>) => {
      if (action.payload.lifecycle === "TEMPORARY") {
        state.temporaryPaymentCard = action.payload
      } else if (action.payload.lifecycle === "PERMANENT") {
        state.permanentPaymentCard = action.payload
      }
    },
  },
})

export const paymentCardsSlice = slice.reducer

export const { setPaymentCard } = slice.actions

const ACTIVE_OR_SUSPENDED_STATES: PaymentCardStatus[] = ["ACTIVE", "SUSPENDED"]

export const thunkIssueTemporaryCard =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async dispatch => {
    try {
      const paymentCard = await issueTemporaryCard()
      dispatch(setPaymentCard(paymentCard))
    } catch (err) {
      if (isApiAuthenticationError(err)) {
        dispatch(thunkClearUserData())
        redirectAfterSessionExpired()
        return
      }
      throw err
    }
  }

export const thunkIssuePermanentCard =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async dispatch => {
    try {
      const { card, cardOrder } = await issuePermanentPaymentCard()
      dispatch(setPaymentCard(card))
      dispatch(setPhysicalCardOrder(cardOrder))
    } catch (err) {
      if (isApiAuthenticationError(err)) {
        dispatch(thunkClearUserData())
        redirectAfterSessionExpired()
        return
      }
      throw err
    }
  }

export const thunkRefreshPaymentCard =
  (
    lifecycle: PaymentCardLifecycle,
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async dispatch => {
    try {
      const paymentCard = await getPaymentCard(lifecycle)
      dispatch(setPaymentCard(paymentCard))
    } catch (err) {
      if (isApiAuthenticationError(err)) {
        dispatch(thunkClearUserData())
        redirectAfterSessionExpired()
        return
      }
      throw err
    }
  }

export const thunkActivatePaymentCard =
  (
    params: ActivatePaymentCardParams,
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async dispatch => {
    try {
      const paymentCard = await activatePaymentCard(params)
      if (paymentCard.lifecycle !== "PERMANENT") {
        // HACK: We don't want to upadte the permanent card state yet because
        // we still need to set the card PIN. If we update the state, the UI
        // would render away the CardActivation component
        // A cleaner way to do this would be to track PinSet state on the card
        // in the backend app. We would have the following steps:
        // 1. UI sets PIN directly with HN via SecureInputs SDK
        // 2. UI tells backend PIN is set to that the PinSet state can be updated
        dispatch(setPaymentCard(paymentCard))
      }
    } catch (err) {
      if (isApiAuthenticationError(err)) {
        dispatch(thunkClearUserData())
        redirectAfterSessionExpired()
        return
      }
      throw err
    }
  }

export const thunkFreezePaymentCard =
  (
    lifecycle: PaymentCardLifecycle,
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async dispatch => {
    try {
      const paymentCard = await freezePaymentCard(lifecycle)
      dispatch(setPaymentCard(paymentCard))
    } catch (err) {
      if (isApiAuthenticationError(err)) {
        dispatch(thunkClearUserData())
        redirectAfterSessionExpired()
        return
      }
      throw err
    }
  }

export const thunkUnfreezePaymentCard =
  (
    lifecycle: PaymentCardLifecycle,
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async dispatch => {
    try {
      const paymentCard = await unfreezePaymentCard(lifecycle)
      dispatch(setPaymentCard(paymentCard))
    } catch (err) {
      if (isApiAuthenticationError(err)) {
        dispatch(thunkClearUserData())
        redirectAfterSessionExpired()
        return
      }
      throw err
    }
  }

export const thunkClosePaymentCard =
  (
    lifecycle: PaymentCardLifecycle,
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async dispatch => {
    try {
      const paymentCard = await closePaymentCard(lifecycle)
      dispatch(setPaymentCard(paymentCard))
    } catch (err) {
      if (isApiAuthenticationError(err)) {
        dispatch(thunkClearUserData())
        redirectAfterSessionExpired()
        return
      }
      throw err
    }
  }

export const selectTemporaryPaymentCard = (
  rootState: RootState,
): PaymentCard | undefined => {
  return rootState.paymentCards.temporaryPaymentCard
}

export const selectShouldActivateTempCard: (_rootState: RootState) => boolean =
  createSelector(
    selectTemporaryPaymentCard,
    (card: PaymentCard | undefined) => {
      return card !== undefined && card.status === "ACTIVATION_REQUIRED"
    },
  )

export const selectIsTempCardActiveOrSuspended: (
  _rootState: RootState,
) => boolean = createSelector(
  selectTemporaryPaymentCard,
  (card: PaymentCard | undefined) => {
    return (
      card !== undefined && ACTIVE_OR_SUSPENDED_STATES.includes(card.status)
    )
  },
)

export const selectIsTempCardClosed: (_rootState: RootState) => boolean =
  createSelector(
    selectTemporaryPaymentCard,
    (card: PaymentCard | undefined) => {
      return card !== undefined && card.status === "CLOSED"
    },
  )

export const selectPermanentPaymentCard = (
  rootState: RootState,
): PaymentCard | undefined => {
  return rootState.paymentCards.permanentPaymentCard
}

export const selectIsPermanentCardActiveOrSuspended: (
  _rootState: RootState,
) => boolean = createSelector(
  selectPermanentPaymentCard,
  (card: PaymentCard | undefined) => {
    return (
      card !== undefined && ACTIVE_OR_SUSPENDED_STATES.includes(card.status)
    )
  },
)

export const selectIsPermanentCardClosed: (_rootState: RootState) => boolean =
  createSelector(
    selectPermanentPaymentCard,
    (card: PaymentCard | undefined) => {
      return card !== undefined && card.status === "CLOSED"
    },
  )

export const selectShouldOrderPermanentCard: (_: RootState) => boolean =
  // issuing a permanent card (physical form factor) requires 2 steps in the HN API
  // 1. Issuing a virtual card
  // 2. Ordering a card shipment against said virtual card
  // To tolerate failure in either step, ensure both the permCard exists AND has the right formFactor
  // HIGHNOTE ISSUE: The orderPhysical card mutation is eventually consistent and the card formfactor
  // does not immediately change to PHYSICAL. We may get a VIRTUAL form factor so we need to also check
  // the cardOrder since in this scenario that is definitive proof that a physical card is issued.
  createSelector(
    selectPermanentPaymentCard,
    selectPhysicalCardOrder,
    (permCard, cardOrder) => {
      return !permCard || (permCard.formFactor === "VIRTUAL" && !cardOrder)
    },
  )

export const selectShouldActivatePermanentCard: (
  _rootState: RootState,
) => boolean = createSelector(
  selectPermanentPaymentCard,
  selectIsPhysicalCardShipped,
  (card, isCardShipped) => {
    return (
      card !== undefined &&
      card.status === "ACTIVATION_REQUIRED" &&
      isCardShipped
    )
  },
)

export const selectHasCardsToManage: (_rootState: RootState) => boolean =
  createSelector(
    selectTemporaryPaymentCard,
    selectShouldActivatePermanentCard,
    selectIsPermanentCardActiveOrSuspended,
    (tempCard, pendingPermCard, activePermCard) => {
      return tempCard !== undefined || pendingPermCard || activePermCard
    },
  )
