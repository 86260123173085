const GRAYS = {
  grayTTTTT: "#F6F6F6",
  grayTTTT: "#EEEEEE",
  grayTTT: "#DCDCDC",
  grayTT: "#CBCBCB",
  grayT: "#B9B9B9",
  gray: "#A8A8A8",
  grayS: "#979797",
  graySS: "#868686",
  graySSS: "#767676",
  graySSSS: "#656565",
} as const

const BLUES = {
  blueTTTTT: "#EEEFF8",
  blueTTTT: "#D5D8EF",
  blueTTT: "#BBC0E5",
  blueTT: "#9AA0D7",
  blueT: "#7881CA",
  blue: "#5661BD",
  blueS: "#4D57AA",
  blueSS: "#454E97",
  blueSSS: "#3C4484",
  blueSSSS: "#343A71",
} as const

const REDS = {
  redTTTTT: "#f7f3f3",
  redTTTT: "#FEDEDD",
  redTTT: "#FDBDBB",
  redTT: "#FD9B9A",
  redT: "#FC7A78",
  red: "#FB5755",
  redS: "#E2504D",
  redSS: "#C94745",
  redSSS: "#B03E3C",
  redSSSS: "#973534",
} as const

const GOLDS = {
  goldTTTT: "#FFF9EE",
  goldTTT: "#FEF1D5",
  goldTT: "#FDE3AA",
  goldT: "#FCD580",
  gold: "#FBC756",
  goldS: "#EFBA4E",
  goldSS: "#E4AD45",
} as const

const GREENS = {
  greenTTTT: "#F3FAF3",
  greenTTT: "#E2F4E1",
  greenTT: "#C5E8C4",
  greenT: "#A8DDA6",
  green: "#8BD189",
  greenS: "#7EBF7D",
  greenSS: "#72AE70",
  greenSSS: "#659C64",
  greenSSSS: "#598B57",
} as const

const BLACK_AND_WHITE = { black: "#000000", white: "#fbfbfb" } as const

const COLORS = {
  ...GRAYS,
  ...BLUES,
  ...REDS,
  ...GOLDS,
  ...GREENS,
  ...BLACK_AND_WHITE,
  transparent: "transparent",
} as const
export type ThemeColor = keyof typeof COLORS

const FONTS = {
  heading: "Poppins, sans-serif",
  mono: "Roboto Mono, mono",
}

const ANIMATIONS = {
  ease: "cubic-bezier(0.23, 1, 0.32, 1)",
  easeOutCubic: "cubic-bezier(0.33, 1, 0.68, 1)",
}
const theme = {
  ...COLORS,
  ...FONTS,
  ...ANIMATIONS,
} as const

export { COLORS, GRAYS, BLUES, REDS, GOLDS, GREENS, BLACK_AND_WHITE }
export default theme
