import { Text } from "@mantine/core"
import React, { useState } from "react"
import {
  Feedback,
  Flex,
  Headings,
  MantineDefaultButton as Button,
  Panels,
  Spacing,
} from "src/components"
import { useAppDispatch } from "src/hooks/redux"
import { thunkIssueTemporaryCard } from "src/store"
import { parseError } from "src/utilities/errorUtils"

const OrderTemporaryCard: React.VFC = () => {
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState("")
  const dispatch = useAppDispatch()

  const handleSubmit = async () => {
    setSubmitting(true)
    try {
      await dispatch(thunkIssueTemporaryCard())
    } catch (err) {
      const { errorMessage } = parseError(err)
      setError(errorMessage)
    } finally {
      setSubmitting(false)
    }
  }
  return (
    <Panels.Default>
      <Flex.Vertical hAlign="center">
        <Headings.H3>Your Stretch Card is on it&apos;s way!</Headings.H3>
        <Text span>
          You can order a temporary card while you wait. A temporary card is
          valid for 30 days.
        </Text>
        <Spacing.Horizontal />
        <Button
          label="Order temporary card"
          disabled={submitting}
          isLoading={submitting}
          onClick={handleSubmit}
        />
        {!!error && <Feedback message={error} />}
      </Flex.Vertical>
    </Panels.Default>
  )
}

export default OrderTemporaryCard
