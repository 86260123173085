import React, { useEffect, useState } from "react"
import { useStretchAuthContext } from "src/context/StretchAuthContext"

import { Duration } from "luxon"
import {
  Bucket,
  Button,
  Card,
  CountdownTimer,
  Headings,
  Modal,
} from "src/components"
import { STRETCH_SESSION_WARNING_DURATION_MINUTES } from "src/config"
import { useCountdown } from "src/hooks"
import { LOGOUT_PAGE_ROUTE } from "src/utilities/routingUtils"

const sessionWarningDuration = Duration.fromDurationLike({
  minutes: STRETCH_SESSION_WARNING_DURATION_MINUTES,
})

const SessionModal: React.FC = () => {
  const { restartSessionTimer } = useStretchAuthContext()
  const { durationLeft: remainingSessionDuration } = useCountdown(
    sessionWarningDuration,
  )
  const [sessionExpired, setSessionExpired] = useState(false)
  useEffect(() => {
    setSessionExpired(remainingSessionDuration.toMillis() <= 0)
  }, [remainingSessionDuration])

  return (
    <Modal show>
      <Card $dashboardModal>
        {sessionExpired ? (
          <>
            <Headings.H3>Your session has expired.</Headings.H3>
            <p>You will be logged out.</p>
          </>
        ) : (
          <Headings.H3>
            Your session will expire in&nbsp;
            <span
              style={{
                display: "inline-block",
                minWidth: "2.5rem",
                maxWidth: "2.5rem",
                whiteSpace: "nowrap",
              }}
            >
              <CountdownTimer durationLeft={remainingSessionDuration} />
            </span>
          </Headings.H3>
        )}
        <div className="mb-4"></div>
        <Bucket $spaceM $center>
          <Button $medium $secondary as="a" href={LOGOUT_PAGE_ROUTE}>
            Log Out
          </Button>
          {!sessionExpired && (
            <Button $growAlt onClick={() => restartSessionTimer()}>
              Stay Logged In
            </Button>
          )}
        </Bucket>
      </Card>
    </Modal>
  )
}

export default SessionModal
