import React from "react"
import {
  Text as MantineText,
  TextProps,
  Anchor as MantineAnchor,
  AnchorProps,
} from "@mantine/core"

export const Body: React.FC<TextProps> = props => (
  <MantineText {...props} style={{ fontSize: "initial" }} />
)
export const Link: React.FC<AnchorProps & { href: string }> = props => (
  <MantineAnchor {...props} component="a" style={{ fontSize: "initial" }} />
)
