import React, { CSSProperties } from "react"
import classnames from "classnames"
import { useMantineTheme } from "@mantine/core"

type TMargin = "reduced" | "none"

interface SharedHeadingProps {
  align?: "left" | "center" | "right"
  children: React.ReactNode
  className?: string
  element: "h1" | "h2" | "h3" | "h4" | "h5"
  margin?: TMargin
  style?: CSSProperties
}

const getMargin = (margin?: TMargin): { margin?: string } => {
  if (margin) {
    return margin === "reduced" ? { margin: ".5rem 0" } : { margin: "0" }
  }
  return {}
}

const Heading: React.FC<SharedHeadingProps> = ({
  align = "left",
  children,
  className,
  element,
  margin,
  style,
  ...props
}) => {
  const theme = useMantineTheme()
  return React.createElement(
    element,
    {
      ...props,
      className: classnames(className, `text-${align}`),
      style: {
        ...style,
        ...getMargin(margin),
        fontFamily: theme.headings.fontFamily,
      },
    },
    children,
  )
}

type HeadingProps = Omit<SharedHeadingProps, "element">

export const H1: React.FC<HeadingProps> = props => (
  <Heading element="h1" {...props} />
)
export const H2: React.FC<HeadingProps> = props => (
  <Heading element="h2" {...props} />
)
export const H3: React.FC<HeadingProps> = props => (
  <Heading element="h3" {...props} />
)
export const H4: React.FC<HeadingProps> = props => (
  <Heading element="h4" {...props} />
)
export const H5: React.FC<HeadingProps> = props => (
  <Heading element="h5" {...props} />
)
