import { Anchor, Badge, Group, Stack } from "@mantine/core"
import { IconExternalLink } from "@tabler/icons"
import { Duration } from "luxon"
import React from "react"
import {
  AddressCard,
  Flex,
  Panels,
  Spacing,
  TimedRefreshButton,
} from "src/components"
import {
  NoOpFunctionType,
  PhysicalCardOrder,
  PhysicalCardOrderStatus,
} from "src/types"
import { VisualTimeline } from "../VisualTimeline"

type Props = {
  cardOrder: PhysicalCardOrder
  canPollCardOrder: boolean
  refreshCardOrder: NoOpFunctionType
}

const CardShipmentTracker: React.VFC<Props> = ({
  cardOrder: {
    status,
    shippingMethod,
    signatureRequired,
    recipientAddress,
    trackingUrl,
  },
  canPollCardOrder,
  refreshCardOrder,
}) => {
  const statusString = (status: PhysicalCardOrderStatus) => {
    switch (status) {
      case "NEW":
      case "SENT_TO_PRINTER":
        return "You are approved and we are preparing your card."

      case "APPROVED":
        return "We're printing your card. It should ship soon."

      case "SHIPPED":
        return "We've shipped your card!"

      case "CANCELED":
        return "You canceled your card shipment."

      case "SHIP_FAILED":
        return "Sorry, we couldn't ship your card"
    }
  }
  const addressString = (status: PhysicalCardOrderStatus) => {
    switch (status) {
      case "SHIP_FAILED":
        return "Please verify the address below and contact us at help@stretch.money:"

      default:
        return "We'll send it to:"
    }
  }

  return (
    <Panels.Default>
      <Stack spacing={0} align="center" sx={{ width: "100%" }}>
        <Stack spacing="xs" align="center" mb="sm">
          <Flex.Horizontal hAlign="center">
            {statusString(status)}
            <Spacing.Vertical size="sm" />
            {canPollCardOrder ? (
              <TimedRefreshButton
                interval={Duration.fromObject({ seconds: 10 })}
                onClick={refreshCardOrder}
              />
            ) : null}
          </Flex.Horizontal>
          {!!trackingUrl && (
            <Anchor target="_blank" href={trackingUrl} color="blue">
              Track it <IconExternalLink />
            </Anchor>
          )}
        </Stack>
        {status !== "CANCELED" && (
          <>
            <VisualTimeline status={status} />
            <div className="mb-1"></div>
            <p>{addressString(status)}</p>
            <AddressCard address={recipientAddress} />

            <Spacing.Horizontal />

            <Group>
              <Badge variant="outline">{shippingMethod}</Badge>
              {signatureRequired && (
                <Badge variant="outline">Signature Required</Badge>
              )}
            </Group>
          </>
        )}
      </Stack>
    </Panels.Default>
  )
}

export default CardShipmentTracker
