import React, { useState } from "react"
import { CardLast4Form, CardPinInput, Headings } from "src/components"
import { AsyncNoOpFunctionType } from "src/types"

type Props = {
  clientToken: string
  paymentCardId: string
  isHighnoteProdEnv: boolean
  activateCard: (_cardDetail: string) => Promise<void>
  onCardActivated: AsyncNoOpFunctionType
}

const PermanentCardActivation: React.VFC<Props> = ({
  paymentCardId,
  clientToken,
  isHighnoteProdEnv,
  activateCard,
  onCardActivated,
}) => {
  const [lastFourSubmitted, setLastFourSubmitted] = useState(false)

  return (
    <>
      {!lastFourSubmitted && (
        <>
          <Headings.H4>What are the last four digits?</Headings.H4>
          <CardLast4Form
            handleSubmit={activateCard}
            onSuccessfulSubmit={() => {
              setLastFourSubmitted(true)
            }}
          />
        </>
      )}
      {lastFourSubmitted && (
        <>
          <Headings.H4>Set your PIN</Headings.H4>
          <CardPinInput
            submitButtonLabel="Set PIN"
            clientToken={clientToken}
            paymentCardId={paymentCardId}
            isHighnoteProdEnv={isHighnoteProdEnv}
            onSuccessfulSubmit={onCardActivated}
          />
        </>
      )}
    </>
  )
}

export default PermanentCardActivation
