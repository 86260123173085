import React, { CSSProperties } from "react"
import theme, { ThemeColor } from "src/assets/theme"

export const SPACING = {
  sm: ".5rem",
  md: "1rem",
  lg: "2rem",
  none: undefined,
}
export type Spacing = keyof typeof SPACING

export const FLEX_OPTIONS = {
  grow: "1 1 auto",
  shrink: "0 1 auto",
  equal: "1 0 0",
  fixed: "0 0 auto",
} as const
export type FlexSizing = keyof typeof FLEX_OPTIONS

export type FlexWrap = "wrap" | "nowrap"

type JustifyContent =
  | "start"
  | "end"
  | "space-between"
  | "space-around"
  | "center"
type AlignItems = "start" | "end" | "baseline" | "stretch" | "center"

type Props = {
  basis?: string
  className?: string
  column?: boolean
  bgColor?: ThemeColor
  height?: "inherit" | string
  overflow?: "scroll" | "hidden"
  padding?: Spacing
  margin?: Spacing
  flex?: FlexSizing
  flexWrap?: FlexWrap
  style?: Omit<CSSProperties, (typeof STYLES_TO_IGNORE)[number]>
}

type RowProps = Props & {
  vAlign?: AlignItems
  hAlign?: JustifyContent
}

type ColumnProps = Props & {
  vAlign?: JustifyContent
  hAlign?: AlignItems
}

const STYLES_TO_IGNORE = [
  "flex",
  "flexDirection",
  "flexBasis",
  "flexFlow",
  "flexGrow",
  "flexShrink",
  "flexWrap",
  "alignItems",
  "justifyContent",
  "backgroundColor",
  "display",
  "margin",
  "padding",
  "height",
  "overflow",
]

export const Flex: React.FC<RowProps | ColumnProps> = ({
  basis,
  bgColor = "transparent",
  column,
  children,
  height,
  overflow,
  padding,
  margin,
  flex = "grow",
  flexWrap = "nowrap",
  vAlign,
  hAlign,
  style,
  ...props
}) => {
  const flexBasis = basis ? { flexBasis: basis } : {}
  const mainAxisAlignment = column
    ? { justifyContent: vAlign }
    : { justifyContent: hAlign }
  const crossAxisAlignment = column
    ? { alignItems: hAlign }
    : { alignItems: vAlign }

  const flexStyles: CSSProperties = {
    backgroundColor: theme[bgColor],
    display: "flex",
    flexDirection: column ? "column" : "row",
    flex: flex ? FLEX_OPTIONS[flex] : undefined,
    margin: margin ? SPACING[margin] : undefined,
    padding: padding ? SPACING[padding] : undefined,
    height,
    overflow,
    flexWrap,
    ...flexBasis,
    ...mainAxisAlignment,
    ...crossAxisAlignment,
    ...style,
  }
  return (
    <div {...props} style={flexStyles}>
      {children}
    </div>
  )
}
Flex.displayName = "Flex"
