import { Button, ButtonProps, CSSObject } from "@mantine/core"
import { PolymorphicComponentProps } from "@mantine/utils"
import React from "react"
import theme from "src/assets/theme"

// TODO: Use native Mantine support for polymorphism
// https://mantine.dev/guides/polymorphic/#create-your-own-polymorphic-components
export type ButtonVariant = "primary" | "secondary"
export type MantineButtonProps = {
  label: string
  isLoading?: boolean
  variant?: ButtonVariant
} & PolymorphicComponentProps<
  "button",
  Omit<ButtonProps, "variant" | "loading">
>
const MantineButton: React.VFC<MantineButtonProps> = ({
  label,
  isLoading,
  variant,
  styles,
  ...props
}) => {
  return (
    <Button
      {...props}
      loading={isLoading}
      styles={{
        root: {
          ...getStyles(variant || "primary"),
          fontSize: "initial",
          height: "auto",
          padding: ".5rem 1.125rem",
        },
        ...styles,
      }}
    >
      {label}
    </Button>
  )
}

function getStyles(variant: ButtonVariant): CSSObject {
  switch (variant) {
    case "secondary":
      return {
        background: "transparent",
        boxShadow: `inset 0 0 0 1.5px ${theme.red}`,
        color: theme.red,
        borderRadius: "6px",
        "&:hover,&:focus": {
          background: theme.redTTTT,
          color: theme.redS,
          boxShadow: `inset 0 0 0 1.5px ${theme.redS}`,
        },
        "&:disabled": {
          color: theme.redTTT,
          cursor: "not-allowed",
          boxShadow: `inset 0 0 0 1.5px ${theme.redTTT}`,
          "&:hover,&:focus": {
            color: theme.redTTT,
          },
        },
      }

    default:
      return {
        background: theme.red,
        color: "white",
        borderRadius: "6px",
        "&:hover,&:focus": {
          background: theme.redS,
          color: "white",
        },
        "&:disabled": {
          background: theme.redTTT,
          cursor: "not-allowed",
          "&:hover,&:focus": {
            background: theme.redTTT,
            color: "white",
          },
        },
      }
  }
}

export const MantineDefaultButton: React.VFC<Omit<MantineButtonProps, "type">> =
  props => <MantineButton type="button" {...props} />
export const MantineSubmitButton: React.VFC<Omit<MantineButtonProps, "type">> =
  props => <MantineButton type="submit" {...props} />
