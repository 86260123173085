import React from "react"
import ReactDOM from "react-dom"
import { Router } from "react-router-dom"
import { reportWebVitals } from "./utilities"

import App from "./App"
import "./assets/css/vendor/tailwind.css"
import history from "./routes/history"
import * as Sentry from "@sentry/react"
import {
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  RELEASE_VERSION,
  STRETCH_API_HOSTNAME,
} from "./config"

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
  release: RELEASE_VERSION,
  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: [STRETCH_API_HOSTNAME],
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: 1.0,
})

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root"),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
