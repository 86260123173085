import React from "react"
import { useAppSelector } from "src/hooks"
import { selectLoggedInUser } from "src/store"
import UserProfile from "./UserProfile"

const UserProfileContainer: React.VFC = () => {
  const user = useAppSelector(selectLoggedInUser)
  return <UserProfile user={user} />
}

export default UserProfileContainer
