/* eslint-disable no-unused-vars */
class LocalAppStorage {
  private static storage = window.localStorage

  public static save(key: LocalDataKey, data: unknown): void {
    this.storage.setItem(key, JSON.stringify(data))
  }

  public static loadAndClear(key: LocalDataKey): unknown {
    const data = this.storage.getItem(key)
    this.storage.removeItem(key)

    return JSON.parse(data ?? "{}")
  }

}

enum LocalDataKey {
  USER_SESSION_INFO = "userSessionInfo",
  INTERCOM_ID = "intercomId",
  INTERCOM_HASH = "intercomHash",
}

export { LocalAppStorage, LocalDataKey }
