import { Text } from "@mantine/core"
import { Form, FormikHelpers } from "formik"
import React, { ReactNode, useState } from "react"
import {
  Flex,
  FlexItem,
  MantineSubmitButton as Button,
  MaskedNumericInput,
  Messages,
  Spacing,
  StretchForm,
} from "src/components"
import { UiFeedback } from "src/types"
import { stripNonAlphaNumericCharacters } from "src/utilities"
import { parseError } from "src/utilities/errorUtils"
import * as yup from "yup"

const validationSchema = yup.object({
  verificationCode: yup
    .string()
    .transform(value => stripNonAlphaNumericCharacters(value))
    .matches(/^[0-9]+$/, "Must be only digits")
    .length(6, "The code should be six digits long")
    .required("Please provide the six-digit code"),
})

type FormData = yup.InferType<typeof validationSchema>

type Props = {
  submitButtonLabel?: string
  otherButtons?: ReactNode
  submitSuccessMessage?: string
  handleSubmit: (_value: FormData) => Promise<void>
}

const PhoneVerifyForm: React.VFC<Props> = ({
  submitButtonLabel = "Submit",
  submitSuccessMessage,
  otherButtons,
  handleSubmit,
}) => {
  const fieldName = "verificationCode"
  const initialValues = { verificationCode: "" }
  const [submitResult, setSubmitResult] = useState<UiFeedback>()

  const onSubmitClicked = async (
    { verificationCode }: FormData,
    helpers: FormikHelpers<FormData>,
  ) => {
    try {
      await handleSubmit({ verificationCode })
      if (submitSuccessMessage) {
        setSubmitResult({
          message: submitSuccessMessage,
          messageType: "positive",
        })
      }
    } catch (err) {
      const { errorMessage } = parseError(err)
      setSubmitResult({ message: errorMessage, messageType: "negative" })
    } finally {
      helpers.setSubmitting(false)
    }
  }

  return (
    <Flex.Vertical hAlign="center">
      <StretchForm
        initialValues={initialValues}
        onSubmit={onSubmitClicked}
        validationSchema={validationSchema}
        validateOnBlur
      >
        {({
          errors,
          values,
          handleChange,
          handleBlur,
          touched,
          isValid,
          isSubmitting,
        }) => (
          <Form style={{ width: "100%" }}>
            {!!submitResult && (
              <Messages.Ephemeral
                message={submitResult.message}
                messageType={submitResult.messageType}
                onTimeout={() => {
                  setSubmitResult(undefined)
                }}
              />
            )}
            <Text>Enter the code sent to your phone.</Text>
            <Spacing.Horizontal />
            <MaskedNumericInput
              name={fieldName}
              mask="999999"
              value={values.verificationCode}
              error={
                touched.verificationCode === true
                  ? errors.verificationCode
                  : undefined
              }
              onChange={handleChange}
              onBlur={handleBlur}
              autoFocus
              autoComplete="off"
              centered
            />
            <Spacing.Horizontal />
            <Flex.Horizontal hAlign="center" noWrap>
              {!!otherButtons && (
                <>
                  {otherButtons}
                  <Spacing.Vertical />
                </>
              )}
              <FlexItem
                grow="equally"
                component={Button}
                label={submitButtonLabel}
                disabled={!isValid || isSubmitting}
                isLoading={isSubmitting}
              />
            </Flex.Horizontal>
          </Form>
        )}
      </StretchForm>
    </Flex.Vertical>
  )
}

export default PhoneVerifyForm
