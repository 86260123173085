import { DateTime } from "luxon"
import { Address } from "src/types"

export function toProperCase(text: string): string {
  return toProperCaseWithSkipList(text, [])
}

export function toProperCaseWithSkipList(
  text: string,
  skipList: string[],
): string {
  return text.replace(/\w\S*/g, function (txt) {
    if (skipList.includes(txt.toLowerCase())) {
      return txt
    }
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function snakeCaseTextToReadableText(text: string): string {
  return text
    .split("_")
    .map(word => toProperCase(word))
    .join(" ")
}

export function maskText(
  text: string,
  maskLength: number,
  maskChar = "*",
): string {
  if (maskLength > text.length) {
    throw new Error("mask length is too long")
  }
  if (maskChar.length != 1) {
    throw new Error("invalid mask char")
  }
  return `${text.slice(0, maskLength).replace(/./g, maskChar)}${text.slice(
    maskLength,
  )}`
}

export function getUserFullName(firstName: string, lastName: string): string {
  return `${firstName} ${lastName}`.trim()
}

export function getFormattedDate(date: string): string {
  return DateTime.fromFormat(date, "yyyy-MM-dd").toFormat("LLLL dd, yyyy")
}

export function getFormattedAddress({
  firstLine,
  secondLine,
  locality,
  region,
  postalCode,
}: Address): string {
  return `${firstLine}, ${
    secondLine ? secondLine + ", " : ""
  }${locality}, ${region} ${postalCode}`
}

export function getFormattedUSPhoneNumber(phoneNumber: string): string {
  return phoneNumber.length === 12
    ? `(${phoneNumber.slice(2, 5)}) ${phoneNumber.slice(
        5,
        8,
      )}-${phoneNumber.slice(8)}`
    : phoneNumber
}

export function stripNonAlphaNumericCharacters(value: string): string {
  return value
    .replace(/-/g, "")
    .replace(/ /g, "")
    .replace(/\//g, "")
    .replace(/_/g, "")
}

export function formatBytes(bytes: number, precision = 2): string {
  if (!+bytes) return "0 Bytes"

  const k = 1000
  const dm = precision < 0 ? 0 : precision
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}
