import { Loader as MantineLoader, MantineNumberSize } from "@mantine/core"
import React from "react"
import theme from "src/assets/theme"

type LoaderProps = {
  color?: string
  width?: MantineNumberSize
}

const Loader: React.VFC<LoaderProps> = ({ color = theme.red, width }) => {
  return <MantineLoader color={color} size={width} />
}

export default Loader
