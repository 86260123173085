import { Duration } from "luxon"
import React, { createContext, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "src/hooks/redux"
import { usePoller } from "src/hooks/usePoller"
import {
  selectCardApplication,
  selectUserState,
  thunkRefreshCardApplication,
  thunkRefreshFinancialAccount,
  thunkRefreshPaymentCard,
  thunkRefreshPhysicalCardOrder,
} from "src/store"

const BankingContext = createContext({})

export const BankingProvider: React.FC = ({ children }) => {
  const { loggedInUser: currentStretchUser } = useAppSelector(selectUserState)
  const dispatch = useAppDispatch()

  const vCardStatus = currentStretchUser?.accountHolder?.temporaryCardStatus
  useEffect(() => {
    if (vCardStatus) {
      dispatch(thunkRefreshPaymentCard("TEMPORARY"))
    }
  }, [vCardStatus, dispatch])

  const cardOrderId = currentStretchUser?.accountHolder?.physicalCardOrderId
  useEffect(() => {
    if (cardOrderId !== undefined) {
      dispatch(thunkRefreshPhysicalCardOrder())
    }
  }, [cardOrderId, dispatch])

  const cardApplication = useAppSelector(selectCardApplication)
  const applicationStatus = cardApplication?.status
  const shouldPollAppStatus =
    !!applicationStatus && ["PENDING"].includes(applicationStatus)

  usePoller(shouldPollAppStatus, Duration.fromObject({ seconds: 5 }), () => {
    dispatch(thunkRefreshCardApplication())
  })

  const financialAccountStatus =
    currentStretchUser?.accountHolder?.debitAccountStatus

  useEffect(() => {
    if (financialAccountStatus) {
      dispatch(thunkRefreshFinancialAccount(false))
    }
  }, [financialAccountStatus, dispatch])

  return (
    <BankingContext.Provider value={{}}>{children}</BankingContext.Provider>
  )
}
