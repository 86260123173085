import { matchPath } from "react-router-dom"
import history from "src/routes/history"
import { RedirectionMessageType } from "src/types"

export const HOME_PAGE_ROUTE = "/"
export const SIGN_UP_PAGE_ROUTE = "/signup"
export const SIGN_UP_VERIFY_PAGE_ROUTE = "/signup/verify"
export const LOGIN_PAGE_ROUTE = "/login"
export const UNLOCK_ACCOUNT_PAGE_ROUTE = "/unlock"
export const FORGOT_PASSCODE_PAGE_ROUTE = "/forgotPasscode"
export const FORGOT_PASSCODE_SENT_PAGE_ROUTE = "/forgotPasscode/sent"
export const RESET_PASSCODE_PAGE_ROUTE = "/resetPasscode/:code"
export const LOGOUT_PAGE_ROUTE = "/logout"
export const DASHBOARD_PAGE_ROUTE = "/dashboard"
export const CARD_VIEW_PAGE_ROUTE = "/card"
export const ACCOUNT_PAGE_ROUTE = "/account"
export const PROFILE_PAGE_ROUTE = "/profile"
export const ACCOUNT_EMAIL_VERIFY_PAGE_ROUTE = "/account/verify/:code"
export const BANKING_ONBOARDING_START_PAGE_ROUTE = "/banking/apply"
export const BANKING_DOC_UPLOAD_PAGE_ROUTE = "/banking/doc-upload"
export const CONTACTS_PAGE_ROUTE = "/contact"

const PUBLIC_ROUTES = [
  SIGN_UP_PAGE_ROUTE,
  SIGN_UP_VERIFY_PAGE_ROUTE,
  `${SIGN_UP_VERIFY_PAGE_ROUTE}/:code`,
  LOGIN_PAGE_ROUTE,
  `${LOGIN_PAGE_ROUTE}/:code`,
  ACCOUNT_EMAIL_VERIFY_PAGE_ROUTE,
]

export function isPublicRoute(pathName: string): boolean {
  return !!matchPath(pathName, {
    path: PUBLIC_ROUTES,
    exact: true,
  })
}

export function redirectAfterSessionExpired(): void {
  redirectToLogin("Your session expired. Please login.", "negative")
}

export function redirectToLoginWithError(message: string): void {
  redirectToLogin(message, "negative")
}

export function redirectToLogin(
  message?: string,
  messageType?: RedirectionMessageType,
): void {
  redirectTo(LOGIN_PAGE_ROUTE, {
    message,
    messageType,
  })
}

export function autoLogout(): void {
  redirectTo(LOGOUT_PAGE_ROUTE, {
    message:
      "You have been automatically logged out. Please log in to continue.",
  })
}

export function redirectTo<HistoryDataType>(
  pathname: string,
  state?: HistoryDataType,
): void {
  history.push({
    pathname,
    state,
  })
}
