import React from "react"
import theme from "src/assets/theme"
import { Loader, LoadingOverlay } from "@mantine/core"

const Spinner: React.VFC = () => {
  return (
    <LoadingOverlay
      loader={<Loader color={theme.red} size="lg" />}
      visible
      overlayBlur={2}
    />
  )
}

export default Spinner
