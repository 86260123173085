import { DateTime } from "luxon"
import {
  createDocUploadLink,
  DocUploadSessionId,
  DocUploadSessionStatus,
  endDocUploadSession,
  getDocUploadSession,
  parseHnApiErrors,
  startDocUploadSession,
  UploadedDocument,
  uploadFile,
} from "src/api"
import {
  ApplicationDocuments,
  ApplicationDocumentType,
  ClientToken,
  DocumentToUpload,
} from "src/types"
import { delay, parseError } from "src/utilities"

/**
 * An abstraction for modelling the complex lifecycles and behaviors of a DocumentUploadSession
 * and it's related child objects.
 */
export default class DocUploadSession {
  // Assigned in the initialize method
  private id!: DocUploadSessionId
  // Assigned in the initialize method
  private status!: DocUploadSessionStatus
  private uploadedDocuments: UploadedDocument[]
  private requiredDocumentTypes: ApplicationDocumentType[]

  private isSubmitting = false
  private isInitialized = false

  constructor() {
    this.uploadedDocuments = []
    this.requiredDocumentTypes = []
  }

  public getRequiredDocTypes(): ApplicationDocumentType[] {
    return this.requiredDocumentTypes
  }
  public getUploadeDocuments(): UploadedDocument[] {
    return this.uploadedDocuments
  }
  public getIsSubmitting(): boolean {
    return this.isSubmitting
  }
  public getIsInitialized(): boolean {
    return this.isInitialized
  }

  public async initialize(
    id: DocUploadSessionId,
    token: ClientToken,
  ): Promise<void> {
    this.isInitialized = false

    this.id = id
    await this.refreshSessionState(token)

    if (!this.isRemoteSessionInitialized()) {
      const startResult = await startDocUploadSession(this.id, token.value)
      // TODO: Better handling of the different error scenarios. See:
      // - https://www.apollographql.com/docs/react/data/error-handling/
      // - https://highnote.com/docs/reference/union/CreateDocumentUploadLinkPayload
      if (!startResult || startResult?.errors) {
        const errMessage = parseHnApiErrors(startResult)
        throw new Error(errMessage)
      }
    }

    this.isInitialized = true
  }

  public async complete(
    documents: DocumentToUpload[],
    token?: ClientToken,
  ): Promise<void> {
    if (!token) {
      throw new Error("No client token received.")
    }

    if (!this.isInitialized) {
      throw new Error("Session object is not initialized.")
    }

    if (DateTime.fromISO(token.expiresAt).diffNow().toMillis() <= 0) {
      throw new Error("Cannot complete doc upload due to expired token.")
    }

    this.isSubmitting = true

    try {
      const uploads: Promise<void>[] = []
      documents.forEach(doc => {
        uploads.push(this.uploadDocument(doc, token))
      })
      await Promise.all(uploads)

      // The intention here is to check the session state and ensure no more primary docs are required
      // before trying to end the session. Add a delay so that we get consistent state from HN
      await delay(3000)

      await this.refreshSessionState(token)

      if (!this.areRequirementsMet()) {
        return
      }

      // TODO: Test the various ways this request can error out:
      // - DocumentLink status is not complete
      // - Not all documents are uploaded
      const endResult = await endDocUploadSession(this.id, token.value)
      // TODO: Better handling of the different error scenarios. See:
      // - https://www.apollographql.com/docs/react/data/error-handling/
      // - https://highnote.com/docs/reference/union/CreateDocumentUploadLinkPayload
      if (endResult?.errors) {
        const errMessage = parseHnApiErrors(endResult)
        throw new Error(errMessage)
      }
    } finally {
      this.isSubmitting = false
    }
  }

  private async uploadDocument(doc: DocumentToUpload, token: ClientToken) {
    const result = await createDocUploadLink(
      {
        documentUploadSessionId: this.id,
        documentType: doc.type,
      },
      token.value,
    )

    // TODO: Better handling of the different error scenarios. See:
    // - https://www.apollographql.com/docs/react/data/error-handling/
    // - https://highnote.com/docs/reference/union/CreateDocumentUploadLinkPayload
    if (!result) {
      throw new Error("Failed to create upload link")
    }

    try {
      await uploadFile(result.uploadUrl, doc.file)
    } catch (err) {
      const { errorMessage } = parseError(err)
      throw new Error(errorMessage)
    }
  }

  private async refreshSessionState(token: ClientToken) {
    // TODO: Handle error conditions
    const { primaryDocumentTypes, documents, status } =
      await getDocUploadSession(this.id, token.value)
    this.status = status
    this.requiredDocumentTypes = primaryDocumentTypes
      .filter(str =>
        ApplicationDocuments.includes(str as ApplicationDocumentType),
      )
      .map(type => type as ApplicationDocumentType)

    this.uploadedDocuments = documents ?? []
  }

  private isRemoteSessionInitialized(): boolean {
    return ["INITIATED", "IN_PROGRESS"].includes(this.status)
  }

  private areRequirementsMet(): boolean {
    return (
      this.requiredDocumentTypes.length <= 0
      // Ideally we'd include the condition below but HN API is not strongly consistent with
      // the status of the uploaded documents
      // && this.uploadedDocuments.filter(doc => doc.status !== "COMPLETED").length <= 0
    )
  }
}
