import { Form } from "formik"
import React, { useState } from "react"
import { Helmet } from "react-helmet-async"
import * as yup from "yup"
import { requestPasscodeResetEmail } from "src/api"
import {
  Card,
  Feedback,
  Headings,
  MantineSubmitButton as Button,
  StretchForm,
  TextInput,
} from "src/components"
import { parseError } from "src/utilities/errorUtils"
import {
  FORGOT_PASSCODE_SENT_PAGE_ROUTE,
  redirectTo,
} from "src/utilities/routingUtils"
import { useLocation } from "react-router-dom"

const validationSchema = yup.object({
  email: yup.string().email().required(""),
})

const ForgotPasscode: React.FC = () => {
  const { state } = useLocation<{ email?: string } | undefined>()
  const [error, setError] = useState("")
  const initialValues = {
    email: state?.email ?? "",
  }

  const handleSubmit = async ({ email }: typeof initialValues) => {
    try {
      await requestPasscodeResetEmail(email)
      redirectTo(FORGOT_PASSCODE_SENT_PAGE_ROUTE)
    } catch (error) {
      const { errorMessage } = parseError(error)
      setError(errorMessage)
    }
  }

  return (
    <>
      <Helmet>
        <title>Stretch - Passcode Reset</title>
      </Helmet>
      <Card $page $fullPage>
        <Headings.H1>Forgot Passcode</Headings.H1>
        <p>
          Enter your account’s email address for instructions on how to reset
          your passcode.
        </p>

        <StretchForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, errors, handleChange, isSubmitting, isValid }) => {
            return (
              <Form>
                <TextInput
                  name="email"
                  label="Email Address"
                  value={values.email}
                  error={errors.email}
                  onChange={handleChange("email")}
                  type="email"
                />
                <Button
                  disabled={!isValid || isSubmitting}
                  label="Send a Reset Link"
                  isLoading={isSubmitting}
                />
                {!!error && <Feedback message={error} />}
              </Form>
            )
          }}
        </StretchForm>
      </Card>
    </>
  )
}

export default ForgotPasscode
