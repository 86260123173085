//import React, { PropsWithChildren, useEffect, useState } from "react"
import React, { PropsWithChildren, useEffect, useState} from "react"
import {
  CopyTextButton,
  Headings,
  Spinner,
  Text,
  VisibilityIconButton
} from "src/components"


import { useAppDispatch, useAppSelector } from "src/hooks"
import { Flex } from "src/layouts"
import {
  selectAreDirectDepositDetailsLoading,
  selectDirectDepositDetails,
  selectFinancialAccount,
  thunkGetDirectDepositDetails,
} from "src/store"

//import { maskText, snakeCaseTextToReadableText } from "src/utilities"

import {maskText, snakeCaseTextToReadableText } from "src/utilities"

import { getAccountHolderStatusInfo } from "src/api"

//import { DirectDepositDetailsData } from "src/api"



interface LabelWithCopyValueButtonProps {
  label: string
  copyText?: string
}
const LabelWithCopyValueButton: React.FC<
  PropsWithChildren<LabelWithCopyValueButtonProps>
> = ({ label, children, copyText }) => (
  <Flex className="mb-3" column>
    <Headings.H3 margin="reduced">{label}</Headings.H3>
    <Flex hAlign="space-between">
      {children}
      <CopyTextButton className="ml-8" copyText={copyText} dataName={label} />
    </Flex>
  </Flex>
)

export const BankingDetails: React.FC = () => {
  
  const dispatch = useAppDispatch()
  const financialAccount = useAppSelector(selectFinancialAccount)
  const directDepositId = financialAccount?.directDepositDetailsId
  const directDepositDetails = useAppSelector(selectDirectDepositDetails)
  const [isKyc,setIsKyc] = useState(true)
  /*
  let directDepositDetails:DirectDepositDetailsData|undefined;

  if(isKyc){
    directDepositDetails = useAppSelector(selectDirectDepositDetails)
  }
  */

  const isLoading = useAppSelector(selectAreDirectDepositDetailsLoading)
 
  useEffect(() => {
    console.log("Direct deposit details id")
    console.log(directDepositId)
    if (directDepositId) {
      dispatch(thunkGetDirectDepositDetails(directDepositId))
    }
  }, [directDepositId])

  const [isInitialized, setInitialized] = useState(false);

  

  //const [isNonDescript,setNonDescript] = useState(false);

  useEffect(() => {
    console.log("Getting status");
    getAccountHolderStatuses();
  },[])


  function getAccountHolderStatuses(){
    if(!isInitialized){
      getAccountHolderStatusInfo().then(res => {
  
        if(res?.success == true){

          setInitialized(true);
          if(res?.isNondescriptUser){
            setIsKyc(false);
          }
          
        }
    
      })
    }
  }

  const [isAccountNumberVisible, setIsAccountNumberVisible] = useState(false)

  /*
  const {
    bank,
    number: accountNumber,
    type,
  } = directDepositDetails?.node.restrictedDetails ?? {}
  */
  const {
    bank,
    number: accountNumber,
    routingNumber,
    type
  } = directDepositDetails?.node.restrictedDetails ?? {}


  const formattedAccountType = snakeCaseTextToReadableText(type ?? "CHECKING")

  
  const maskedAccountNumber =
    accountNumber !== undefined ? maskText(accountNumber, 9) : ""
    

  const { extendedAddress, streetAddress, locality, region, postalCode } =
    bank?.address ?? {}

  const addressFieldsToJoin = extendedAddress
    ? [streetAddress, extendedAddress, locality, region, postalCode]
    : [streetAddress, locality, region, postalCode]
  const bankAddress = addressFieldsToJoin.join(", ")

  const formattedBankName = snakeCaseTextToReadableText(bank?.name ?? "")

  //return isLoading || !directDepositDetails ? (

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <LabelWithCopyValueButton
        label="Account Type"
        copyText={formattedAccountType}
      >
        <Text.Body>{formattedAccountType}</Text.Body>
      </LabelWithCopyValueButton>
      {isKyc && (
        <LabelWithCopyValueButton label="Bank Name" copyText={formattedBankName}>
        <Text.Body>{formattedBankName}</Text.Body>
      </LabelWithCopyValueButton>
      )}

      {isKyc && (
        <LabelWithCopyValueButton label="Account Number" copyText={accountNumber}>
        <Flex vAlign="center">
          <Text.Body className="flex items-center mr-4">
            {isAccountNumberVisible ? accountNumber : maskedAccountNumber}
          </Text.Body>
          <VisibilityIconButton
            color="black"
            visible={isAccountNumberVisible}
            toggleVisibility={() => {
              setIsAccountNumberVisible(!isAccountNumberVisible)
            }}
            size="lg"
          />
        </Flex>
      </LabelWithCopyValueButton>
      )}
      {isKyc && (
        <LabelWithCopyValueButton label="Routing Number" copyText={routingNumber}>
        <Text.Body>{routingNumber}</Text.Body>
        </LabelWithCopyValueButton>
      )}
      {isKyc && (
        <LabelWithCopyValueButton label="Bank Address" copyText={bankAddress}>
        <Text.Body>{bankAddress}</Text.Body>
        </LabelWithCopyValueButton> 
      )}
        
    </>
    
  )

  /*
  old code without removing account number and routing number

  import {
  CopyTextButton,
  Headings,
  Spinner,
  Text,
  VisibilityIconButton,
} from "src/components"

const {
    bank,
    number: accountNumber,
    routingNumber,
    type,
  } = directDepositDetails?.node.restrictedDetails ?? {}

  <LabelWithCopyValueButton label="Account Number" copyText={accountNumber}>
        <Flex vAlign="center">
          <Text.Body className="flex items-center mr-4">
            {isAccountNumberVisible ? accountNumber : maskedAccountNumber}
          </Text.Body>
          <VisibilityIconButton
            color="black"
            visible={isAccountNumberVisible}
            toggleVisibility={() => {
              setIsAccountNumberVisible(!isAccountNumberVisible)
            }}
            size="lg"
          />
        </Flex>
      </LabelWithCopyValueButton>

  <LabelWithCopyValueButton label="Routing Number" copyText={routingNumber}>
        <Text.Body>{routingNumber}</Text.Body>
      </LabelWithCopyValueButton>
  */
  
}
