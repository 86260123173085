import * as Sentry from "@sentry/react"
import React from "react"
import { Route, Switch } from "react-router-dom"
import { CardApplication } from "src/pages/CardApplication"
import DashboardV2 from "src/pages/DashboardV2"
import { BankAccount } from "src/pages/DashboardV2/BankAccount/BankAccount"
import Contact from "src/pages/DashboardV2/Contact"
import {
  EmailChangeVerification,
  UserProfile,
} from "src/pages/DashboardV2/UserProfile"
import Home from "src/pages/Home"
import Login from "src/pages/Login/Login"
import Logout from "src/pages/Logout"
import {
  ForgotPasscode,
  PasscodeEmailSent,
  ResetPasscode,
} from "src/pages/Passcode"
import { ManageCards } from "src/pages/PaymentCard"
import GetVerified from "src/pages/SignUp/GetVerified"
import SignUp from "src/pages/SignUp/SignUp"
import VerifyEmail from "src/pages/SignUp/VerifyEmail"
import UnlockAccount from "src/pages/UnlockAccount/UnlockAccount"
import {
  ACCOUNT_EMAIL_VERIFY_PAGE_ROUTE,
  ACCOUNT_PAGE_ROUTE,
  BANKING_ONBOARDING_START_PAGE_ROUTE,
  CARD_VIEW_PAGE_ROUTE,
  CONTACTS_PAGE_ROUTE,
  DASHBOARD_PAGE_ROUTE,
  FORGOT_PASSCODE_PAGE_ROUTE,
  FORGOT_PASSCODE_SENT_PAGE_ROUTE,
  HOME_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE,
  LOGOUT_PAGE_ROUTE,
  PROFILE_PAGE_ROUTE,
  RESET_PASSCODE_PAGE_ROUTE,
  SIGN_UP_PAGE_ROUTE,
  SIGN_UP_VERIFY_PAGE_ROUTE,
  UNLOCK_ACCOUNT_PAGE_ROUTE,
} from "src/utilities"
import AuthenticatedView from "./AuthenticatedView"
import PublicView from "./PublicView"
import {
  selectFinancialAccount
} from "src/store"
import {useAppSelector } from "src/hooks/redux"

const SentryRoute = Sentry.withSentryRouting(Route)

const openFinancialAccountRoutes = [

  {
    id:0,
    route: CARD_VIEW_PAGE_ROUTE,
    component: ManageCards,
    isPublic:false,
    isExact:false
  },

  {
    id:1,
    route: BANKING_ONBOARDING_START_PAGE_ROUTE,
    component: CardApplication,
    isPublic:false,
    isExact:false
  },

  {
    id:2,
    route: ACCOUNT_PAGE_ROUTE,
    component: BankAccount,
    isPublic:false,
    isExact:true
  }

]

const baseRoutes = [
  {
    id:3,
    route: HOME_PAGE_ROUTE,
    component: Home,
    isPublic: true,
    isExact:true
  },

  {
    id:4,
    route: SIGN_UP_PAGE_ROUTE,
    component: SignUp,
    isPublic: true,
    isExact:true
  },

  {
    id:5,
    route: SIGN_UP_VERIFY_PAGE_ROUTE,
    component: VerifyEmail,
    isPublic:true,
    isExact:true
  },

  {
    id:6,
    route: `${SIGN_UP_VERIFY_PAGE_ROUTE}/:code`,
    component: GetVerified,
    isPublic:true,
    isExact:false
  },

  {
    id:7,
    route: ACCOUNT_EMAIL_VERIFY_PAGE_ROUTE,
    component: EmailChangeVerification,
    isPublic:true,
    isExact:true
  },

  {
    id:8,
    route: FORGOT_PASSCODE_PAGE_ROUTE,
    component: ForgotPasscode,
    isPublic:true,
    isExact:true
  },

  {
    id:9,
    route: FORGOT_PASSCODE_SENT_PAGE_ROUTE,
    component: PasscodeEmailSent,
    isPublic:true,
    isExact:false
  },

  {
    id:10,
    route: RESET_PASSCODE_PAGE_ROUTE,
    component: ResetPasscode,
    isPublic:true,
    isExact:true
  },

  {
    id:11,
    route: UNLOCK_ACCOUNT_PAGE_ROUTE,
    component: UnlockAccount,
    isPublic:true,
    isExact:true
  },

  {
    id:12,
    route: `${UNLOCK_ACCOUNT_PAGE_ROUTE}/:code`,
    component: UnlockAccount,
    isPublic:true,
    isExact:true
  },

  {
    id:13,
    route: LOGIN_PAGE_ROUTE,
    component: Login,
    isPublic:true,
    isExact:true
  },

  {
    id:14,
    route: `${LOGIN_PAGE_ROUTE}/:code`,
    component: Login,
    isPublic:true,
    isExact:true
  },

  {
    id:15,
    route: LOGOUT_PAGE_ROUTE,
    component: Logout,
    isPublic:true,
    isExact:false
  },

  {
    id:16,
    route: DASHBOARD_PAGE_ROUTE,
    component: DashboardV2,
    isPublic:false,
    isExact:false
  },

  {
    id:17,
    route: BANKING_ONBOARDING_START_PAGE_ROUTE,
    component: CardApplication,
    isPublic:false,
    isExact:false
  },

  {
    id:18,
    route: PROFILE_PAGE_ROUTE,
    component: UserProfile,
    isPublic:false,
    isExact:true
  },

  {
    id:19,
    route: ACCOUNT_EMAIL_VERIFY_PAGE_ROUTE,
    component: EmailChangeVerification,
    isPublic:false,
    isExact:true
  },

  {
    id:20,
    route: CONTACTS_PAGE_ROUTE,
    component: Contact,
    isPublic:false,
    isExact:false
  }

]

const allRoutes = openFinancialAccountRoutes.concat(baseRoutes);

const allRouteSentries = allRoutes.map(item => 
  {
    const { isExact, isPublic, route, component, id } = item
    const AuthWrapperComponent = isPublic ? PublicView : AuthenticatedView
    return (
      <SentryRoute
        key={id}
        exact={isExact}
        path={route}
        render={() => <AuthWrapperComponent component={component} />}
      />
    )
  }  
)

const baseRouteSentries = baseRoutes.map(item => 
  {
    const { isExact, isPublic, route, component, id } = item
    const AuthWrapperComponent = isPublic ? PublicView : AuthenticatedView
    return (
      <SentryRoute
        key={id}
        exact={isExact}
        path={route}
        render={() => <AuthWrapperComponent component={component} />}
      />
    )
  }  
)


const standardUserRoutes = () => {
  return (
    <Switch>{allRouteSentries}</Switch>
  )
}

const closedFinancialAccountUserRoutes = () => {
  return (
    <Switch>{baseRouteSentries}</Switch>
  )
}

const RouteDirectory: React.VFC = () => {

  const financialAccount = useAppSelector(selectFinancialAccount)

    if(financialAccount?.status == 'CLOSED'){
      return(
        closedFinancialAccountUserRoutes()
      )
    }else{
      return(
        standardUserRoutes()
      )
    } 

}

export default RouteDirectory
