import React, { useEffect, useState } from "react"
import { Link, NavLink, useLocation } from "react-router-dom"
import logo from "src/assets/images/logo.svg"
import theme from "src/assets/theme"
import styled, { css } from "styled-components"

import { Icon } from "src/components"
import { useAppSelector } from "src/hooks/redux"
import { selectFinancialAccount, selectHasCardsToManage, selectHasFinancialAccount } from "src/store"
import {
  ACCOUNT_PAGE_ROUTE,
  CARD_VIEW_PAGE_ROUTE,
  CONTACTS_PAGE_ROUTE,
  DASHBOARD_PAGE_ROUTE,
  LOGOUT_PAGE_ROUTE,
  PROFILE_PAGE_ROUTE,
} from "src/utilities/routingUtils"

interface NavigationProps {
  useV3Styles?: boolean
}

const Navigation: React.VFC<NavigationProps> = ({ useV3Styles }) => {
  const [narrowOpen, setNarrowOpen] = useState(false)
  const location = useLocation()
  const hasFinancialAccount = useAppSelector(selectHasFinancialAccount)
  const hasPaymentOpenCards = useAppSelector(selectHasCardsToManage)
  const financialAccount = useAppSelector(selectFinancialAccount)

  useEffect(() => {
    setNarrowOpen(false)
  }, [location])

  return (
    <Container useV3Styles={useV3Styles}>
      <Head>
        <Link to={DASHBOARD_PAGE_ROUTE}>
          <Logo src={logo} alt="Stretch Finance" />
        </Link>
        <NarrowToggle onClick={() => setNarrowOpen(!narrowOpen)}>
          <NarrowToggleIcon>
            <Icon id="menu" title="Open Menu" />
          </NarrowToggleIcon>
        </NarrowToggle>
      </Head>

      <MenuContainer $narrowOpen={narrowOpen}>
        <NarrowHead>
          <Link to={DASHBOARD_PAGE_ROUTE}>
            <Logo src={logo} alt="Stretch Finance" />
          </Link>
          <NarrowToggle onClick={() => setNarrowOpen(!narrowOpen)}>
            <NarrowToggleIcon>
              <Icon id="close" title="Close Menu" />
            </NarrowToggleIcon>
          </NarrowToggle>
        </NarrowHead>
        <Menu>
          <NavLink to={DASHBOARD_PAGE_ROUTE}>
            <Icon id="dashboard" />
            Dashboard
          </NavLink>
          {hasFinancialAccount && financialAccount?.status != 'CLOSED' && (
            <NavLink to={`${ACCOUNT_PAGE_ROUTE}?layout=v2`}>
              <Icon id="person" />
              Account
            </NavLink>
          )}
          {hasPaymentOpenCards && financialAccount?.status != 'CLOSED' && (
            <NavLink to={CARD_VIEW_PAGE_ROUTE}>
              <Icon id="card" />
              Cards
            </NavLink>
          )}
          <NavLink to={PROFILE_PAGE_ROUTE}>
            <Icon id="person" />
            Profile
          </NavLink>
          <NavLink to={CONTACTS_PAGE_ROUTE}>
            <Icon id="chat" />
            Contact
          </NavLink>
          <NavLink to={LOGOUT_PAGE_ROUTE}>
            <Icon id="logout" />
            Log Out
          </NavLink>
        </Menu>
      </MenuContainer>
    </Container>
  )
}

// Styles

const Container = styled.div<NavigationProps>`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  @media (min-width: 50em) {
    background: ${theme.black};
    height: ${props => (props.useV3Styles ? "100%" : "100vh")};
    margin: unset;
    padding: 0 1.5rem;
    position: sticky;
    top: 0;
  }
`

type MenuContainerProps = {
  $narrowOpen?: boolean
}
const MenuContainer = styled.div<MenuContainerProps>`
  background: ${theme.black};
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  z-index: 30;

  transition: 500ms transform ${theme.easeOutCubic},
    500ms visibility ${theme.easeOutCubic};
  transform: translateX(-100%);
  visibility: hidden;
  ${props =>
    props.$narrowOpen &&
    css`
      transform: none;
      visibility: visible;
    `}
  @media (min-width: 50em) {
    height: unset;
    width: unset;
    transform: unset;
    position: relative;
    padding: unset;
    z-index: unset;
    visibility: unset;

    flex-grow: 1;
    flex-direction: column;
  }
`

const Menu = styled.div`
  a,
  button {
    align-items: center;
    border-radius: 6px;
    color: white;
    display: flex;
    font-family: ${theme.heading};
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
    padding: 0.75rem 1rem;
    width: 100%;
    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
    &:last-child {
      margin-top: 2rem;
    }
    &.active {
      background: rgba(255, 255, 255, 0.35);
    }
  }
  svg {
    font-size: 1.75rem;
    margin-right: 0.5rem;
    transition: 250ms transform ease;
    margin-top: -0.125rem;
  }

  @media (min-width: 50em) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: 1.25rem;
    a,
    button {
      font-size: 1.125rem;
      padding: 0.625rem 0.75rem;
      &:last-child {
        margin-top: auto;
      }
    }
    svg {
      font-size: 1.25rem;
      margin-top: 0;
    }
  }
`

const Logo = styled.img`
  height: 1.5rem;
  width: auto;
  @media (min-width: 50em) {
    height: 2rem;
  }
`

const Head = styled.div`
  background: ${theme.black};
  padding: 1rem;
  display: flex;
  justify-content: center;
  @media (min-width: 50em) {
    background: unset;
    display: block;
    padding: 2rem 1rem;
  }
`

const NarrowHead = styled.div`
  background: ${theme.black};
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  margin: -1rem -1rem 1rem;
  padding: 1rem;
  @media (min-width: 50em) {
    display: none;
  }
`

const NarrowToggle = styled.button.attrs({ type: "button" })`
  font-size: 1.125rem;
  position: absolute;
  top: 0rem;
  left: 0;
  padding: calc(0.625rem + 1px) 0.625rem calc(0.625rem - 1px);
  @media (min-width: 50em) {
    display: none;
  }
`

const NarrowToggleIcon = styled.div`
  color: white;
  font-size: 2.25rem;
  &:hover,
  &:focus {
    color: ${theme.redSSS};
  }
`

export default Navigation
