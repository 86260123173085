import { getOsEnvOptional, toNumber } from "./environment"

const APTO_API_KEY = process.env.REACT_APP_APTO_API_KEY || "APTO-API-KEY"
const APTO_API_URL =
  process.env.REACT_APP_APTO_API_URL || "http://apto.api.url/"
const GTM_AUTH = process.env.REACT_APP_GTM_AUTH
const GTM_CONTAINER_ID = process.env.REACT_APP_GTM_CONTAINER_ID
const GTM_PREVIEW = process.env.REACT_APP_GTM_PREVIEW
const SENTRY_ENVIRONMENT =
  process.env.REACT_APP_SENTRY_ENVIRONMENT || "development"
const LOB_API_KEY = process.env.REACT_APP_LOB_API_KEY || "LOB-API-KEY"
const STRETCH_API_URL =
  process.env.REACT_APP_STRETCH_API_URL || "http://stretch.api.url"
const isDevelopment = process.env.NODE_ENV === "development"
const isProduction = process.env.NODE_ENV === "production"
const isTest = process.env.NODE_ENV === "test"

const SENTRY_DSN = getOsEnvOptional("REACT_APP_SENTRY_DSN")

const stretchURL = new URL(STRETCH_API_URL)
const STRETCH_API_HOSTNAME = stretchURL.hostname

const APTO_ENVIRONMENT =
  isProduction && APTO_API_URL.indexOf("sbx") == -1 ? "prd" : "sbx"
const STRETCH_SESSION_DURATION_MINUTES = toNumber(
  getOsEnvOptional("REACT_APP_STRETCH_SESSION_DURATION_MINUTES") ?? "10",
)
const STRETCH_SESSION_WARNING_DURATION_MINUTES = toNumber(
  getOsEnvOptional("REACT_APP_STRETCH_SESSION_WARNING_DURATION_MINUTES") ?? "2",
)

const HIGHNOTE_ENV = getOsEnvOptional("REACT_APP_HIGHNOTE_ENV") ?? "test"
const RELEASE_VERSION = getOsEnvOptional("REACT_APP_RELEASE_VERSION") ?? "dev"
const isHighnoteProdEnv = HIGHNOTE_ENV === "production"

export {
  APTO_API_KEY,
  APTO_API_URL,
  APTO_ENVIRONMENT,
  GTM_AUTH,
  GTM_CONTAINER_ID,
  GTM_PREVIEW,
  isDevelopment,
  isProduction,
  isTest,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  STRETCH_API_HOSTNAME,
  LOB_API_KEY,
  STRETCH_API_URL,
  STRETCH_SESSION_DURATION_MINUTES,
  STRETCH_SESSION_WARNING_DURATION_MINUTES,
  isHighnoteProdEnv,
  RELEASE_VERSION,
}
