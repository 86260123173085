import React, { ButtonHTMLAttributes, FC } from "react"

import Button, { ButtonProps } from "./Button"

interface SubmitButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string
}

const SubmitButton: FC<SubmitButtonProps & ButtonProps> = ({
  label,
  ...props
}) => {
  return (
    <Button type="submit" $grow {...props}>
      {label || "Submit"}
    </Button>
  )
}

export default SubmitButton
