import { Text, useMantineTheme } from "@mantine/core"
import {
  IconAlertTriangle,
  IconCircleCheck,
  IconInfoSquare,
} from "@tabler/icons"
import React, { ReactNode, useEffect } from "react"
import { Flex, Spacing } from "src/components/shared"
import { UiFeedbackType } from "src/types"
import { EPHEMERAL_MESSAGE_TIMEOUT_DURATION_MILLIS } from "src/uiConstants"

type TimeoutConfig = {
  duration: number
  onTimeout: () => void
}
type MessageProps = {
  message: string
  messageType: UiFeedbackType
  timeoutConfig?: TimeoutConfig
}

const Message: React.VFC<MessageProps> = ({
  message,
  messageType,
  timeoutConfig,
}) => {
  let color: string
  let Icon: ReactNode
  const theme = useMantineTheme()

  switch (messageType) {
    case "positive":
      color = theme.colors.green[2]
      Icon = <IconCircleCheck />
      break

    case "negative":
      color = theme.colors.yellow[2]
      Icon = <IconAlertTriangle />
      break

    case "informational":
      color = theme.colors["stretch-red"][2]
      Icon = <IconInfoSquare />
      break
  }

  useEffect(() => {
    if (timeoutConfig) {
      setTimeout(() => {
        timeoutConfig.onTimeout()
      }, timeoutConfig.duration)
    }
  }, [])

  return (
    <Flex.Horizontal
      vAlign="center"
      noWrap
      backgroundColor={color}
      style={{
        padding: ".7em",
      }}
    >
      <Flex.Horizontal
        hAlign="center"
        vAlign="center"
        noWidth
        style={{
          minWidth: "10%",
        }}
      >
        {Icon}
      </Flex.Horizontal>
      <Spacing.Vertical size="xs" />
      <Flex.Horizontal vAlign="center" noWidth>
        <Text>{message}</Text>
      </Flex.Horizontal>
    </Flex.Horizontal>
  )
}

type Props = {
  message: string
  messageType: UiFeedbackType
}
export const Permanent: React.VFC<Props> = ({ message, messageType }) => (
  <Message message={message} messageType={messageType} />
)

type EphemeralProps = {
  message: string
  messageType: UiFeedbackType
  onTimeout: () => void
}
export const Ephemeral: React.VFC<EphemeralProps> = ({
  message,
  messageType,
  onTimeout,
}) => (
  <Message
    message={message}
    messageType={messageType}
    timeoutConfig={{
      duration: EPHEMERAL_MESSAGE_TIMEOUT_DURATION_MILLIS,
      onTimeout: onTimeout,
    }}
  />
)
