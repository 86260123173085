import { Text } from "@mantine/core"
import { useCounter } from "@mantine/hooks"
import React from "react"
import { MantineModal as Modal } from "src/components"
import { NoOpFunctionType, PaymentCard } from "src/types"
import ConfirmCancelCard from "./ConfirmCancelCard"
import ViewCancelCardResult from "./ViewCancelCardResult"

type Props = {
  showSequence: boolean
  card: PaymentCard
  cancelSequence: NoOpFunctionType
  completeSequence: NoOpFunctionType
}

type ScreenName = "ConfirmCancel" | "ViewCancelResult"
type ScreenChangeDirection = "Forward" | "Backward"
const CancelCardSequence: React.VFC<Props> = ({
  showSequence,
  card,
  cancelSequence,
  completeSequence,
}) => {
  const screenSequence: ScreenName[] = ["ConfirmCancel", "ViewCancelResult"]
  const [currentScreenIndex, { increment, decrement }] = useCounter(0, {
    min: 0,
    max: 1,
  })
  const changeScreen = (direction: ScreenChangeDirection) => {
    const changeFn = direction === "Forward" ? increment : decrement
    changeFn()
  }

  const renderScreen = () => {
    const screen = screenSequence[currentScreenIndex]
    switch (screen) {
      case "ConfirmCancel":
        return (
          <ConfirmCancelCard
            onGoBackward={cancelSequence}
            onGoForward={() => changeScreen("Forward")}
          />
        )
      case "ViewCancelResult":
        return (
          <ViewCancelCardResult
            card={card}
            completionPauseSeconds={3}
            onCompleted={completeSequence}
          />
        )
    }
  }
  return (
    <Modal
      opened={showSequence}
      title={<Text weight="bold">Cancel Card</Text>}
      onClose={cancelSequence}
    >
      {renderScreen()}
    </Modal>
  )
}

export default CancelCardSequence
