import React, { FC } from "react"
import styled, { css } from "styled-components"

import Topbar from "./Topbar"
import { SessionModal } from "src/components"
import { useStretchAuthContext } from "src/context/StretchAuthContext"

type LayoutProps = {
  children?: React.ReactNode
  preventLogoLink?: boolean
  type: string
}
const Layout: FC<LayoutProps> = ({ children, preventLogoLink, type }) => {
  const { showSessionMessage } = useStretchAuthContext()

  return (
    <Container>
      {type === "basic" ? <Topbar preventLogoLink={preventLogoLink} /> : <></>}
      <Content centerNarrow={type === "basic"}>
        {children}
        {type === "basic" && <Padding />}
      </Content>
      {showSessionMessage && <SessionModal />}
    </Container>
  )
}

// Styles

const Container = styled.div`
  background: white;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

type ContentProps = {
  centerNarrow: boolean
}
const Content = styled.div<ContentProps>`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  justify-content: flex-start;
  padding: 0;
  ${props =>
    props.centerNarrow &&
    css`
      padding-top: 2rem;
      min-height: calc(100vh - 3.75rem);
    `}
  @media (min-width: 50em) {
    padding: 0;
    ${props =>
      props.centerNarrow &&
      css`
        padding-top: 0;
        min-height: calc(100vh - 3.75rem - 10vh);
        padding: 10vh 2rem 0;
      `}
  }
`

const Padding = styled.div`
  padding-bottom: 5rem;
`

export default Layout
