import React from "react"
import { Redirect, useLocation } from "react-router-dom"
import { Layout, LayoutDashboardV2, NavigationV2 } from "src/components"
import { useAppSelector } from "src/hooks"
import { AppLayout } from "src/layouts"
import { selectIsLoggedIn } from "src/store"
import { LOGIN_PAGE_ROUTE } from "src/utilities"

type Props = {
  component: React.ElementType
}
const AuthenticatedView: React.VFC<Props> = ({ component: Component }) => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const { search } = useLocation()

  return (
    <>
      {isLoggedIn ? (
        new URLSearchParams(search).get("layout") === "v2" ? (
          <AppLayout
            sideBarComponent={<NavigationV2 useV3Styles />}
            bodyComponent={<Component />}
          />
        ) : (
          <Layout type="stretch" preventLogoLink>
            <LayoutDashboardV2>
              <Component />
            </LayoutDashboardV2>
          </Layout>
        )
      ) : (
        <Redirect to={LOGIN_PAGE_ROUTE} />
      )}
    </>
  )
}

export default AuthenticatedView
