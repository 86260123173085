import React from "react"
//import {Headings } from "src/components"
import { FinancialAccount } from "src/types"
import { Table } from '@mantine/core';
import SearchBar from "./SearchBar";
import TransactionsFilterView from "./TransactionsFilterView";

//import { presentAmount } from "src/utilities/currencyUtils"


type Props = {
  financialAccount?: FinancialAccount
  todayFilterClickedCallback:any
  oneWeekFilterClickedCallback:any
  twoWeekFilterClickedCallback:any
  noFilterClickedCallback:any
  searchBarClickedCallback:any
  clearSearchBarClickedCallback:any
}

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});



/*

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

const formatCurrency = (transaction:Transaction) => {

    //raw amount
    let amount = transaction.amount;

    amount = amount/100.0; // move decimal places over

    //formmatted amount
    let formattedAmount = formatter.format(amount);

    //credit or debit
    const type = transaction.type;

    if(amount != 0){ //only apply logic to non-zero amounts
        if(type === "DEBIT"){
            formattedAmount = "-" + formattedAmount
        }else if(type === "CREDIT"){
            formattedAmount = "+" + formattedAmount
        }
    }

    if(transaction.state === "DECLINED"){//if declined apply strikethrough to amount

    }

    return formattedAmount;


}





*/

/*

const rows = transactions.map((element) => (
        <tr key={element.id} >
          <td style={{border: '0'}}>{TransactionLeftColumn(element)}</td>
          <td style={{border: '0'}}>{TransactionMiddleColumn(element)}</td>
          <td style={{border: '0', textAlign:'right'}}>{TransactionRightColumn(element)}</td>
        </tr>
      ));

      return (
        <Table>
          <thead >
            <tr>
              <th style={{width:"10%"}}></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody >{rows}</tbody>
        </Table>
      );
*/

/*
<Headings.H2 align="center">Available Balance</Headings.H2> 
*/

//formatter.format(amount) financialAccount?.availableCashBalance




const TransactionsViewHeader: React.VFC<Props> = ({ financialAccount, todayFilterClickedCallback, oneWeekFilterClickedCallback, twoWeekFilterClickedCallback, noFilterClickedCallback, searchBarClickedCallback, clearSearchBarClickedCallback}) => {

  let amount = financialAccount?.availableCashBalance;

  if(financialAccount != null){
    amount = amount!/100.0; // move decimal places over
  }else{
    amount = 0.0;
  }

  const formattedAmount = formatter.format(amount);

  return (

    <div>
    <Table style={{border:'0', boxShadow: 'none', borderTop: 'none', borderSpacing:'0 1px', borderCollapse:'separate'}}>
        
        <tbody style={{border:'0', boxShadow: 'none'}}>
            <tr style={{border:'0', boxShadow: 'none'}}>
                
              <div style={{fontSize:32,color:"black",fontFamily:"Poppins", fontWeight:800, textAlign:'center'}}>{formattedAmount}</div>
                
            </tr>
            <tr>
                <div style={{fontSize:18,color:"black",fontFamily:"Poppins", fontWeight:800, textAlign:'center'}}>Available Balance</div>
            </tr>
            <tr>

                <div style={{margin:'auto', width:'75%',minWidth:'400px', maxWidth:'550px', padding:'10px', backgroundColor:'transparent'}}><SearchBar searchBarClicked={searchBarClickedCallback} clearSearchBarClicked={clearSearchBarClickedCallback}></SearchBar></div>

            </tr>
            <tr>
                <div style={{display:'flex', justifyContent:'center'}}><TransactionsFilterView todayFilterClickedCallback={todayFilterClickedCallback} oneWeekFilterClickedCallback={oneWeekFilterClickedCallback} twoWeekFilterClickedCallback={twoWeekFilterClickedCallback} noFilterClickedCallback={noFilterClickedCallback}></TransactionsFilterView></div>
            </tr>
        </tbody>
        
        
    </Table>
    </div>

    /*
        <thead style={{border: '0'}}>
            <tr style={{border:'0', boxShadow: 'none'}}>
                <th></th>
            </tr>
        </thead>
    */
    /*
    <tbody style={{border:'0', boxShadow: 'none'}}>
        
        </tbody>
    */

    /*

    <tr style={{border:'0', boxShadow: 'none'}}>
            {financialAccount?.availableCashBalance}
            </tr>
            <tr>
                Second Row 
            </tr>
            <tr>
                Third Row
            </tr>
            <tr>
                Fourth Row
            </tr>
    */

    /*
    <>
         <Table verticalSpacing="sm" fontSize="xl">
          <tr style={{textAlign: 'center'}}>
            <td style={{border: '0'}}>{financialAccount?.availableCashBalance}</td>
          </tr>
          <tr style={{textAlign: 'center'}}>
            <td style={{border: '0'}}>Available Balance</td>
          </tr>
        </Table>
       
    </>
    */

    /*
    <>
    <Headings.H3>Available Balance</Headings.H3>
    {financialAccount?.availableCashBalance}
    </>
    */

    /*
    <Bucket $spreadH $grow $wideBlock>
      <div>
        <Headings.H3>Available Balance</Headings.H3>
        <div className="mb-6 wideOnly"></div>
      </div>
      <Headings.H1>
        {presentAmount(
          financialAccount?.availableCashBalance ?? 0,
          financialAccount?.currencyCode ?? "USD",
        )}
      </Headings.H1>
    </Bucket>
    */
  )
}

export default TransactionsViewHeader
