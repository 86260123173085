import React from "react"
import { Card, ChangePin, Grid, GridItem, Headings } from "src/components"
import { PaymentCard } from "src/types"
import { CancelCard } from "./CancelCard"
import CardImageContainer from "./CardImage/CardImageContainer"
import LockCard from "./LockCard"

type Props = {
  card: PaymentCard
}

const CardView: React.VFC<Props> = ({ card, card: { lifecycle } }) => {
  const gridColProps = {
    md: 6,
    style: { minHeight: 200 },
    $span: 6,
    $spanS: 12,
    $equalHeight: true,
  }

  const cardTitle: string = lifecycle === "TEMPORARY" ? "Temporary" : "Debit"
  return (
    <>
      <Headings.H1>{cardTitle} Card</Headings.H1>

      <Grid>
        <GridItem {...gridColProps}>
          <Card>
            <CardImageContainer card={card} />
          </Card>
        </GridItem>
        <GridItem {...gridColProps}>
          <ChangePin card={card} />
        </GridItem>
      </Grid>
      <div className="mb-6 wideOnly"></div>
      <Grid>
        <GridItem {...gridColProps}>
          <LockCard card={card} />
        </GridItem>
        <GridItem {...gridColProps}>
          <CancelCard card={card} />
        </GridItem>
      </Grid>
    </>
  )
}

export default CardView
