import React, { useState } from "react"
import { DocumentUploadSessionPayload } from "src/api/highnote-api"
import {
  Flex,
  FormFeedback,
  MantineDefaultButton,
  Spacing,
} from "src/components"
import {
  ApplicationDocuments,
  ApplicationDocumentType,
  UiFeedback,
  UploadRequirements,
} from "src/types"
import { parseError } from "src/utilities/errorUtils"
import { default as DocUploadForm } from "../DocUploadFormV1/DocUploadForm"

type Props = {
  initializeDocUpload: () => Promise<DocumentUploadSessionPayload>
  submitDocuments: (_reqs: UploadRequirements) => Promise<void>
}

const PRISON_DOCS_INDEX = 2
const ManageDocUpload: React.VFC<Props> = ({
  initializeDocUpload,
  submitDocuments,
}) => {
  const [initialized, setInitialized] = useState(false)
  const [submitResult, setSubmitResult] = useState<UiFeedback>()

  const [primaryDocuments, setPrimaryDocuments] = useState<
    ApplicationDocumentType[]
  >([])
  const [secondaryDocuments, setSecondaryDocuments] = useState<
    ApplicationDocumentType[]
  >([])

  const initDocUpload = async () => {
    let startResult
    try {
      startResult = await initializeDocUpload()
    } catch (err) {
      setSubmitResult({
        message: parseError(err).errorMessage,
        messageType: "negative",
      })
      return
    }

    setPrimaryDocuments(
      prepDocumentSelectionList(startResult.primaryDocumentTypes, "PRISON_ID"),
    )
    setSecondaryDocuments(
      prepDocumentSelectionList(
        startResult?.secondaryDocumentTypes ?? [],
        "PRISON_RELEASE_PAPERWORK",
      ),
    )

    setInitialized(true)
  }

  const prepDocumentSelectionList = (
    docList: string[],
    additionalDocType: ApplicationDocumentType,
  ) => {
    const newList = docList
      .filter(str =>
        ApplicationDocuments.includes(str as ApplicationDocumentType),
      )
      .map(type => type as ApplicationDocumentType)

    newList.splice(
      docList.length == 0 ? 0 : Math.min(docList.length - 1, PRISON_DOCS_INDEX),
      0,
      additionalDocType,
    )

    return newList
  }

  return (
    <Flex.Vertical hAlign="center">
      <FormFeedback
        feedback={submitResult}
        onTimerExpired={() => {
          setSubmitResult(undefined)
        }}
      />
      <Spacing.Horizontal />
      {!initialized ? (
        <>
          {/* TODO: Bring explainer text here from ApplicationStatus component */}
          <MantineDefaultButton label="Continue" onClick={initDocUpload} />
        </>
      ) : (
        <DocUploadForm
          primaryDocumentsSelection={primaryDocuments}
          secondaryDocumentsSelection={secondaryDocuments}
          submitDocuments={submitDocuments}
        />
      )}
    </Flex.Vertical>
  )
}

export default ManageDocUpload
