import React from "react"
import { Helmet } from "react-helmet-async"
import styled from "styled-components"
import {
  Bucket,
  Button,
  Card,
  Grid,
  GridItem,
  Headings,
  Icon,
} from "src/components"

const Contact: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Contact – Stretch</title>
      </Helmet>
      <Headings.H1>Contact</Headings.H1>
      <Card $widePadding>
        <Grid>
          <GridItem $span={8} $spanS={12}>
            <div>
              <Bucket $spaceS>
                <LargeIcon>
                  <Icon id="phone" />
                </LargeIcon>
                <Headings.H2>Phone</Headings.H2>
              </Bucket>
              <p className="mb-0 mt-3">
                Have an urgent issue? Please call customer service at{" "}
                <a href="tel:1-877-275-4115">1-877-275-4115</a>.
              </p>
            </div>
          </GridItem>
          <GridItem $span={1} $spanS={0}></GridItem>
          <GridItem $span={3} $spanS={12} $centerContent>
            <Button $grow as="a" href="tel:1-877-275-4115">
              Call
            </Button>
          </GridItem>
        </Grid>
      </Card>
      <div className="mb-8"></div>
      <Card $widePadding>
        <Grid>
          <GridItem $span={8} $spanS={12}>
            <div>
              <Bucket $spaceS>
                <LargeIcon>
                  <Icon id="question" />
                </LargeIcon>
                <Headings.H2>FAQs</Headings.H2>
              </Bucket>
              <p className="mb-0 mt-3">
                Read through our library of helpful articles.
              </p>
            </div>
          </GridItem>
          <GridItem $span={1} $spanS={0}></GridItem>
          <GridItem $span={3} $spanS={12} $centerContent>
            <Button
              $grow
              as="a"
              href="https://help.stretch.money"
              target="_blank"
            >
              Read
            </Button>
          </GridItem>
        </Grid>
      </Card>
      <div className="mb-8"></div>
      <Card $widePadding>
        <Grid>
          <GridItem $span={8} $spanS={12}>
            <div>
              <Bucket $spaceS>
                <LargeIcon>
                  <Icon id="chat" />
                </LargeIcon>
                <Headings.H2>Chat</Headings.H2>
              </Bucket>
              <p className="mb-0 mt-3">Start a chat with our support team.</p>
            </div>
          </GridItem>
          <GridItem $span={1} $spanS={0}></GridItem>
          <GridItem $span={3} $spanS={12} $centerContent>
            <Button
              $grow
              as="a"
              href="mailto:help@stretch.money"
              id="contact-chat"
              onClick={e => e.preventDefault()}
            >
              Chat
            </Button>
          </GridItem>
        </Grid>
      </Card>
    </>
  )
}

const LargeIcon = styled.div`
  font-size: 2rem;
  position: relative;
  @media (min-width: 50em) {
    font-size: 2rem;
  }
`

export default Contact
