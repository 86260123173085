import React, { FC } from "react"
import styled, { css } from "styled-components"

import { Bucket } from "src/components"

import honestJobs from "src/assets/images/honest_jobs.svg"

const Poweredby: FC<{ $alt?: boolean }> = ({ $alt }) => (
  <Container $alt={$alt}>
    <Bucket $spaceS $center>
      <span>powered by</span>
      <Image src={honestJobs} alt="Honest Jobs" />
    </Bucket>
  </Container>
)

export default Poweredby

type ContainerProps = {
  $alt?: boolean
}
const Container = styled.div<ContainerProps>`
  padding-top: 3rem;
  ${props =>
    props.$alt &&
    css`
      padding-top: 1rem;
    `}
  span {
    margin-top: 3px;
  }
  @media (min-width: 30em) {
    margin-top: auto;
    padding-top: 2rem;
  }
`
const Image = styled.img`
  height: 1.75rem;
  width: auto;
`
