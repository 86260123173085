import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useLocation, useParams } from "react-router-dom"
import { requestAccountUnlockEmail, unlockAccount } from "src/api"
import { Card, EmailInputForm, Flex, Headings, Spinner } from "src/components"
import { useStateMachine, XStateConfig } from "src/hooks"
import { LoginPageRoutingState, UnlockPageRoutingState } from "src/types"
import { LOGIN_PAGE_ROUTE, parseError, redirectTo } from "src/utilities"

const VIEWS = {
  LOADING: "LOADING",
  START_UNLOCK: "START_UNLOCK",
  VERIFICATION_EMAIL_SENT: "VERIFICATION_EMAIL_SENT",
  TOKEN_VERIFICATION: "TOKEN_VERIFICATION",
} as const

const TRANSITIONS = {
  PAGE_LOADED: "PAGE_LOADED",
  EMAIL_SUBMITTED: "EMAIL_SUBMITTED",
  TOKEN_RECEIVED: "TOKEN_RECEIVED",
  TOKEN_VERIFICATION_FAILED: "TOKEN_VERIFICATION_FAILED",
} as const

const SCREEN_STATE_MACHINE: XStateConfig = {
  [VIEWS.LOADING]: {
    [TRANSITIONS.PAGE_LOADED]: VIEWS.START_UNLOCK,
    [TRANSITIONS.TOKEN_RECEIVED]: VIEWS.TOKEN_VERIFICATION,
  },
  [VIEWS.START_UNLOCK]: {
    [TRANSITIONS.EMAIL_SUBMITTED]: VIEWS.VERIFICATION_EMAIL_SENT,
  },
  [VIEWS.VERIFICATION_EMAIL_SENT]: {},
  [VIEWS.TOKEN_VERIFICATION]: {
    [TRANSITIONS.TOKEN_VERIFICATION_FAILED]: VIEWS.START_UNLOCK,
  },
}

const UnlockAccount: React.VFC = () => {
  const location = useLocation<UnlockPageRoutingState>()
  const { routingMessage, routingMessageType, email } = location.state || {}
  const [unlockError, setUnlockError] = useState<string>()
  const [screenState, transition] = useStateMachine(
    "AccountUnlock",
    VIEWS.START_UNLOCK,
    SCREEN_STATE_MACHINE,
  )
  const [initialEmail] = useState(email)

  const { code } = useParams<{ code?: string }>()
  useEffect(() => {
    if (code) {
      transition(TRANSITIONS.TOKEN_RECEIVED)
      submitCode(code)
    }
  }, [code])

  const submitCode = async (token: string) => {
    try {
      await unlockAccount(token)

      redirectTo(LOGIN_PAGE_ROUTE, {
        routingMessage:
          "Your account has been unlocked. Please log in to continue.",
        routingMessageType: "positive",
      } as LoginPageRoutingState)
    } catch (error) {
      const { errorMessage } = parseError(error)
      setUnlockError(errorMessage)
      transition(TRANSITIONS.TOKEN_VERIFICATION_FAILED)
    }
  }

  const handleSubmit = async (email: string) => {
    await requestAccountUnlockEmail(email)
    transition(TRANSITIONS.EMAIL_SUBMITTED)
  }

  if (screenState.matches(VIEWS.START_UNLOCK)) {
    return (
      <>
        <Helmet>
          <title>Stretch - Unlock your account</title>
        </Helmet>

        <Card $page $fullPage>
          <Headings.H1>Unlock your account</Headings.H1>
          <EmailInputForm
            email={initialEmail}
            handleSubmit={handleSubmit}
            feedback={
              unlockError || routingMessage
                ? {
                    message: routingMessage || unlockError || "",
                    messageType: routingMessageType || "negative",
                  }
                : undefined
            }
          />
        </Card>
      </>
    )
  } else if (screenState.matches(VIEWS.TOKEN_VERIFICATION)) {
    return (
      <>
        <Helmet>
          <title>Stretch - Unlock your account</title>
        </Helmet>

        <Headings.H1>Verifying...</Headings.H1>
        <p className="mb-0">You will be automatically redirected.</p>
      </>
    )
  } else if (screenState.matches(VIEWS.VERIFICATION_EMAIL_SENT)) {
    return (
      <>
        <Helmet>
          <title>Stretch - Unlock your account</title>
        </Helmet>
        <Card $marginAuto $page $fullPage>
          <Headings.H2>Unlock your account</Headings.H2>
          <p>
            To unlock your account, please click on the link in the email we
            just sent. If you don’t see it, please check your “Spam” or
            “Promotions” folder.
          </p>
        </Card>
      </>
    )
  }

  return (
    <>
      <Flex.Vertical hAlign="center">
        <Spinner />
      </Flex.Vertical>
    </>
  )
}

export default UnlockAccount
