import { Text } from "@mantine/core"
import { IconX } from "@tabler/icons"
import React from "react"
import theme from "src/assets/theme"
import { Flex } from "src/components"

const SystemError: React.VFC = () => {
  return (
    <Flex.Horizontal vAlign="center">
      <IconX color={theme.red} />
      <Text span>ERROR</Text>
    </Flex.Horizontal>
  )
}

export default SystemError
