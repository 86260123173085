import React from "react"
import { Link } from "react-router-dom"
import theme from "src/assets/theme"
import styled from "styled-components"

import logo from "src/assets/images/logo.svg"
import { HOME_PAGE_ROUTE } from "src/utilities"

const Topbar: React.VFC<{ preventLogoLink?: boolean }> = ({
  preventLogoLink,
}) => {
  return (
    <Container>
      {preventLogoLink ? (
        <Logo src={logo} alt="Stretch Finance" />
      ) : (
        <Link to={HOME_PAGE_ROUTE}>
          <Logo src={logo} alt="Stretch Finance" />
        </Link>
      )}
    </Container>
  )
}

const Container = styled.div`
  background: ${theme.black};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 100%;
`

const Logo = styled.img`
  height: 1.75rem;
  width: auto;
`

export default Topbar
