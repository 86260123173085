import { Text } from "@mantine/core"
import { Form } from "formik"
import React, { ReactNode, useState } from "react"
import {
  Flex,
  FlexItem,
  MantineSubmitButton as Button,
  Messages,
  PhoneNumberInput,
  Spacing,
  StretchForm,
} from "src/components"
import { UiFeedback } from "src/types"
import { parseError, didValuesChange } from "src/utilities"
import * as yup from "yup"

const validationSchema = yup.object({
  phoneNumber: yup
    .string()
    .required("Please provide a phone number")
    // The PhoneInput component adds 2 additional characters to the value "+1"
    // so the validated length should be 12 instead of 10
    .length(12, "Make sure your phone number is 10 digits"),
})

type FormData = yup.InferType<typeof validationSchema>

type Props = {
  phoneNumber?: string
  submitButtonLabel?: string
  submitSuccessMessage?: string
  shouldDisableSubmit?: (_valueChanged: boolean) => boolean
  otherButtons?: ReactNode
  handleSubmit: (_value: FormData) => Promise<void>
}

const PhoneForm: React.VFC<Props> = ({
  phoneNumber = "",
  submitButtonLabel = "Submit",
  submitSuccessMessage,
  shouldDisableSubmit,
  otherButtons,
  handleSubmit,
}) => {
  const fieldName = "phoneNumber"
  const initialValues = { phoneNumber }
  const [submitResult, setSubmitResult] = useState<UiFeedback>()

  const onSubmitClicked = async (data: FormData) => {
    try {
      await handleSubmit(data)
      if (submitSuccessMessage) {
        setSubmitResult({
          message: submitSuccessMessage,
          messageType: "positive",
        })
      }
    } catch (err) {
      const { errorMessage } = parseError(err)
      setSubmitResult({ message: errorMessage, messageType: "negative" })
    }
  }

  return (
    <Flex.Vertical hAlign="center">
      <StretchForm
        initialValues={initialValues}
        onSubmit={onSubmitClicked}
        validationSchema={validationSchema}
      >
        {({
          errors,
          values,
          handleBlur,
          setFieldValue,
          isValid,
          isSubmitting,
          touched,
        }) => (
          <Form style={{ width: "100%" }}>
            {!!submitResult && (
              <Messages.Ephemeral
                message={submitResult.message}
                messageType={submitResult.messageType}
                onTimeout={() => {
                  setSubmitResult(undefined)
                }}
              />
            )}
            <Text>What&apos;s your new mobile phone number?</Text>
            <Spacing.Horizontal />
            <PhoneNumberInput
              name={fieldName}
              placeholder="(555) 321-1212"
              value={values.phoneNumber}
              error={
                touched.phoneNumber === true ? errors.phoneNumber : undefined
              }
              onChange={(value: string) => {
                setFieldValue(fieldName, value)
              }}
              onBlur={handleBlur}
            />
            <Spacing.Horizontal />
            <Flex.Horizontal hAlign="center" noWrap>
              {!!otherButtons && (
                <>
                  {otherButtons}
                  <Spacing.Vertical />
                </>
              )}
              <FlexItem
                grow="equally"
                component={Button}
                label={submitButtonLabel}
                disabled={
                  !isValid ||
                  isSubmitting ||
                  shouldDisableSubmit?.(didValuesChange(initialValues, values))
                }
                isLoading={isSubmitting}
              />
            </Flex.Horizontal>
          </Form>
        )}
      </StretchForm>
    </Flex.Vertical>
  )
}

export default PhoneForm
