import { Text } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { Form, FormikHelpers } from "formik"
import React, { ReactNode, useState } from "react"
import {
  Flex,
  FlexItem,
  MantineSubmitButton as Button,
  Messages,
  PasscodeInput,
  Spacing,
  StretchForm,
} from "src/components"
import { UiFeedback } from "src/types"
import { parseError } from "src/utilities/errorUtils"
import * as yup from "yup"

const validationSchema = yup.object({
  currentPasscode: yup
    .string()
    .min(4, "Your current passcode must be 4 digits")
    .max(4, "Your current passcode must be 4 digits")
    .required("Please provide your current 4-digit passcode"),
  passcode: yup
    .string()
    .min(4, "Your new passcode must be 4 digits")
    .max(4, "Your new passcode must be 4 digits")
    .required("Please provide your new 4-digit passcode"),
  passcodeConfirmation: yup
    .string()
    .oneOf([yup.ref("passcode")], "The new passcodes must match")
    .required("Please confirm your new 4-digit passcode"),
})

type FormData = yup.InferType<typeof validationSchema>

type Props = {
  submitButtonLabel?: string
  submitSuccessMessage?: string
  otherButtons?: ReactNode
  handleSubmit: (_value: FormData) => Promise<void>
  onSuccess?: () => void
}

const ChangePasscodeForm: React.VFC<Props> = ({
  submitButtonLabel = "Submit",
  submitSuccessMessage,
  otherButtons,
  handleSubmit,
  onSuccess,
}) => {
  const fieldNames = ["currentPasscode", "passcode", "passcodeConfirmation"]
  const initialValues = {
    currentPasscode: "",
    passcode: "",
    passcodeConfirmation: "",
  }
  const [submitResult, setSubmitResult] = useState<UiFeedback>()

  const onSubmitClicked = async (
    data: FormData,
    { resetForm }: FormikHelpers<FormData>,
  ) => {
    try {
      await handleSubmit(data)
      resetForm()
      if (submitSuccessMessage) {
        setSubmitResult({
          message: submitSuccessMessage,
          messageType: "positive",
        })
      }
      onSuccess?.()
    } catch (err) {
      const { errorMessage } = parseError(err)
      setSubmitResult({ message: errorMessage, messageType: "negative" })
    }
  }

  const [visible, { toggle }] = useDisclosure(false)

  return (
    <Flex.Vertical hAlign="center">
      <StretchForm
        initialValues={initialValues}
        onSubmit={onSubmitClicked}
        validationSchema={validationSchema}
        validateOnBlur
      >
        {({
          errors,
          values,
          handleChange,
          handleBlur,
          setFieldTouched,
          touched,
          isValid,
          isSubmitting,
        }) => (
          <Form style={{ width: "100%" }}>
            {!!submitResult && (
              <Messages.Ephemeral
                message={submitResult.message}
                messageType={submitResult.messageType}
                onTimeout={() => {
                  setSubmitResult(undefined)
                }}
              />
            )}
            <Spacing.Horizontal />
            <Text>
              This is a secret four-digit number used to access your account.
            </Text>
            <Spacing.Horizontal />
            <PasscodeInput
              label="Current Passcode"
              name={fieldNames[0]}
              value={values.currentPasscode}
              error={
                touched.currentPasscode === true
                  ? errors.currentPasscode
                  : undefined
              }
              onChange={handleChange}
              onBlur={handleBlur}
              visible={visible}
              onVisibilityChange={toggle}
            />
            <Spacing.Horizontal size="sm" />
            <PasscodeInput
              label="New Passcode"
              name={fieldNames[1]}
              value={values.passcode}
              error={touched.passcode === true ? errors.passcode : undefined}
              onChange={handleChange}
              onBlur={evt => {
                handleBlur(fieldNames[1])(evt)
                values.passcodeConfirmation.length > 0 &&
                  setFieldTouched(fieldNames[2])
              }}
              visible={visible}
              onVisibilityChange={toggle}
            />
            <Spacing.Horizontal size="sm" />
            <PasscodeInput
              label="Confirm New Passcode"
              name={fieldNames[2]}
              value={values.passcodeConfirmation}
              error={
                touched.passcodeConfirmation === true
                  ? errors.passcodeConfirmation
                  : undefined
              }
              onChange={handleChange}
              onBlur={handleBlur}
              visible={visible}
              onVisibilityChange={toggle}
            />
            <Spacing.Horizontal />
            <Flex.Horizontal hAlign="center" noWrap>
              {!!otherButtons && (
                <>
                  {otherButtons}
                  <Spacing.Vertical />
                </>
              )}
              <FlexItem
                grow="equally"
                component={Button}
                label={submitButtonLabel}
                disabled={!isValid || isSubmitting}
                isLoading={isSubmitting}
              />
            </Flex.Horizontal>
          </Form>
        )}
      </StretchForm>
    </Flex.Vertical>
  )
}

export default ChangePasscodeForm
