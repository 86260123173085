const XS_BREAKPOINT_WIDTH_PX = 480
const XS_BREAKPOINT_HEIGHT_PX = 600
const SM_BREAKPOINT_WIDTH_PX = 640
const SM_BREAKPOINT_HEIGHT_PX = 801
const MD_BREAKPOINT_WIDTH_PX = 960
const MD_BREAKPOINT_HEIGHT_PX = 700
const LG_BREAKPOINT_WIDTH_PX = 1200
const LG_BREAKPOINT_HEIGHT_PX = 800
const XL_BREAKPOINT_WIDTH_PX = 1400
const XL_BREAKPOINT_HEIGHT_PX = 900

export {
  XS_BREAKPOINT_HEIGHT_PX,
  XS_BREAKPOINT_WIDTH_PX,
  SM_BREAKPOINT_HEIGHT_PX,
  SM_BREAKPOINT_WIDTH_PX,
  MD_BREAKPOINT_HEIGHT_PX,
  MD_BREAKPOINT_WIDTH_PX,
  LG_BREAKPOINT_HEIGHT_PX,
  LG_BREAKPOINT_WIDTH_PX,
  XL_BREAKPOINT_HEIGHT_PX,
  XL_BREAKPOINT_WIDTH_PX,
}

export const EPHEMERAL_MESSAGE_TIMEOUT_DURATION_MILLIS = 3500
