import React, { createContext, useContext, useState } from "react"
import { Accordion as MantineAccordion } from "@mantine/core"

interface AccordionContext {
  activeAccordion: string | null
  setActiveAccordion: (_newActiveAccordion: string) => void
}
const AccordionContext = createContext<AccordionContext>({
  activeAccordion: null,
  setActiveAccordion: () => {},
})

export const useAccordionContext = (): AccordionContext =>
  useContext(AccordionContext)

interface AccordionProps {
  initialOpenAccordion: string | null
}
export const Accordion: React.FC<AccordionProps> = ({
  initialOpenAccordion = null,
  children,
}) => {
  const [activeAccordion, setActiveAccordion] = useState<string | null>(
    initialOpenAccordion,
  )

  return (
    <MantineAccordion
      onChange={setActiveAccordion}
      value={activeAccordion}
      styles={() => ({
        label: {
          fontSize: "initial",
        },
      })}
    >
      <AccordionContext.Provider
        value={{
          activeAccordion,
          setActiveAccordion,
        }}
      >
        {children}
      </AccordionContext.Provider>
    </MantineAccordion>
  )
}
