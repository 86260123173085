import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "src/store"
import { AsyncThunkLoadingStatus, Transaction } from "src/types"

type State = {
  transactions: Transaction[]
  loadingStatus: AsyncThunkLoadingStatus
}
const initialState: State = {
  transactions: [],
  loadingStatus: "idle",
}

const slice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    setTransactions: (state, action: PayloadAction<Transaction[]>) => {
      state.transactions = action.payload
    },
  },
})

export const transactionsSlice = slice.reducer

export const { setTransactions } = slice.actions

export const selectTransactions = (rootState: RootState): Transaction[] => {
  return rootState.transactions.transactions
}
