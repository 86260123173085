import React from "react"
import { Messages } from "src/components/notification"
import { Spacing } from "src/components/shared"
import { NoOpFunctionType, UiFeedback } from "src/types"

type Props = {
  feedback?: UiFeedback
  onTimerExpired?: NoOpFunctionType
}

const FormFeedback: React.VFC<Props> = ({ feedback, onTimerExpired }) => {
  const MessageComponent =
    onTimerExpired !== undefined ? Messages.Ephemeral : Messages.Permanent

  return (
    <>
      {feedback ? (
        <>
          <MessageComponent
            message={feedback.message}
            messageType={feedback.messageType}
            onTimeout={() => {
              if (onTimerExpired) {
                onTimerExpired()
              }
            }}
          />
          <Spacing.Horizontal />
        </>
      ) : null}
    </>
  )
}

export default FormFeedback
