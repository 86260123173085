import { createSelector } from "@reduxjs/toolkit"
import { RootState } from "src/store/store"
import { selectPermanentPaymentCard } from "."
import { selectHasBankingRestrictions } from "../user/userReducer"
import { selectPhysicalCardOrder } from "./physicalCardOrder"

export const selectShouldTrackPhysicalCardOrder: (
  _rootState: RootState,
) => boolean = createSelector(
  selectPermanentPaymentCard,
  selectPhysicalCardOrder,
  (permCard, cardOrder) => {
    return (
      permCard?.status === "ACTIVATION_REQUIRED" &&
      cardOrder?.status !== undefined
    )
  },
)

export const selectCanHaveTempCard: (_rootState: RootState) => boolean =
  createSelector(
    selectHasBankingRestrictions,
    selectPermanentPaymentCard,
    selectPhysicalCardOrder,
    (hasBankingRestrictions, permCard, cardOrder) => {
      return (
        hasBankingRestrictions === false &&
        permCard?.status === "ACTIVATION_REQUIRED" &&
        cardOrder !== undefined &&
        ["NEW", "SENT_TO_PRINTER", "APPROVED", "SHIPPED"].includes(
          cardOrder?.status,
        )
      )
    },
  )
